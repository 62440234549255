import React, {
  FC,
  useRef,
  useMemo,
  useState,
  useEffect,
} from 'react';
import {
  Dialog,
  InputText,
  Button,
  Messages,
  Dropdown,
} from '@agro1desenvolvimento/react-components';
import classNames from 'classnames';
import * as Yup from 'yup';
import { ptForm } from 'yup-locale-pt';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { find } from 'lodash';
import EquipamentosService from '../../../../services/laboratorio/equipamentos';
import { Equipamento } from '../../../../@types/laboratorio/equipamento';
import {
  fetchEquipamentos,
  fetchTiposEquipamentos,
  selectEquipamentoState,
} from '../equipamentoSlice';
import catchApiErrorsAndSetFormErrors from '../../../../utilities/catch-api-errors';
import ShowErrorHelper from '../../../../components/ShowErrorHelper';
import { CrudCreateUpdate, promiseWithLoaderAndMessages, transitionOptionsTimeout as timeout } from '../../../../utilities';
import { toastSaveMessages } from '../../../../utilities/default-confirmation-messages';

const Footer: React.FC<{close: () => void, valid: boolean}> = ({ close, valid }) => (
  <div>
    <Button label="Cancelar" icon="pi pi-times" onClick={close} className="p-button-text" />
    <Button label="Salvar" disabled={!valid} icon="pi pi-check" autoFocus type="submit" form="form-save-equipamento" />
  </div>
);

Yup.setLocale(ptForm);
const EquipamentoSchema = Yup.object().shape({
  descricao: Yup.string().required(),
  tipo: Yup.string().required(),
  casasDecimais: Yup.number().positive().required(),
});

interface EquipamentoFormField {
  id?: string,
  descricao?: string,
  tipo?: string,
  casasDecimais?: number,
}

const FormEquipamento: FC<{ equipamento?: Equipamento | undefined }> = ({ equipamento }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const messages = useRef<Messages>(null);
  const { tiposEquipamentos } = useSelector(selectEquipamentoState);
  const [modalVisible, setModalVisible] = useState(true);

  const onClose = () => history.push('/sementes/equipamentos');

  const fechaModal = () => {
    setModalVisible(false);
  };

  const form = useFormik<EquipamentoFormField>({
    validationSchema: EquipamentoSchema,
    initialValues: {
      id: equipamento?.id,
      descricao: equipamento?.descricao,
      tipo: equipamento?.tipo.id,
      casasDecimais: equipamento?.casasDecimais,
    },
    onSubmit: async (values) => {
      const toSave: Partial<CrudCreateUpdate<Equipamento>> = {
        id: values.id,
        descricao: values.descricao,
        tipo: find(tiposEquipamentos, { id: values.tipo }),
        casasDecimais: values?.casasDecimais,
      };

      try {
        await promiseWithLoaderAndMessages(
          dispatch,
          EquipamentosService.createOrUpdate(toSave),
          toastSaveMessages('equipamento'),
        );

        fechaModal();
        dispatch(fetchEquipamentos());
      } catch (err) {
        catchApiErrorsAndSetFormErrors(form.setFieldError, err.response.data, true);
      }
    },
  });

  useEffect(() => {
    dispatch(fetchTiposEquipamentos());
  }, []);

  const classes = useMemo(() => (
    classNames({ 'p-invalid': form.errors.descricao })
  ), [form.errors]);

  return (
    <Dialog
      onHide={fechaModal}
      transitionOptions={{ timeout, onExited: onClose }}
      visible={modalVisible}
      maximizable
      header={equipamento?.id ? 'Alterar equipamento' : 'Adicionar equipamento'}
      className="dialog-md"
      footer={<Footer close={fechaModal} valid={form.dirty && form.isValid} />}
    >
      <form onSubmit={form.handleSubmit} id="form-save-equipamento">
        <div className="p-fluid">
          <div className="p-field">
            <label htmlFor="descricao">Descrição</label>
            <InputText
              required
              name="descricao"
              id="descricao"
              value={form.values.descricao}
              onChange={form.handleChange}
              className={classes}
              aria-describedby="descricao-help"
            />
            <ShowErrorHelper id="descricao-help" error={form.errors.descricao} />
          </div>
        </div>
        <div className="p-fluid">
          <div className="p-field">
            <label htmlFor="tipo">Tipo</label>
            <Dropdown
              value={form.values.tipo}
              name="tipo"
              id="tipo"
              appendTo={document.body}
              options={tiposEquipamentos}
              optionLabel="descricao"
              optionValue="id"
              onChange={form.handleChange}
              placeholder="Selecione o tipo do equipamento"
              filter
              className={classNames({ 'p-invalid': form.errors.tipo })}
              aria-describedby="tipo-help"
            />
            <ShowErrorHelper id="tipo-help" error={form.errors.tipo} />
          </div>
        </div>
        <div className="p-fluid">
          <div className="p-field">
            <label htmlFor="casasDecimais">Casas decimais</label>
            <InputText
              type="number"
              name="casasDecimais"
              id="casasDecimais"
              value={form.values.casasDecimais}
              placeholder="Casas decimais"
              onChange={form.handleChange}
              className={classNames({ 'p-invalid': form.errors.casasDecimais })}
              aria-describedby="casasDecimais-help"
            />
            <ShowErrorHelper id="casasDecimais-help" error={form.errors.casasDecimais} />
          </div>
        </div>
      </form>
      <Messages ref={messages} />
    </Dialog>
  );
};

export default FormEquipamento;
