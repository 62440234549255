import ListRegistros from './List';

const RegistrosExcluidosPage = () => (
  <>
    <div className="p-grid">
      <div className="p-col-12">
        <div className="card auditoria">
          <h1>Registros Excluídos</h1>
          <ListRegistros />
        </div>
      </div>
    </div>
  </>
);

export default RegistrosExcluidosPage;
