import React, { FC, useEffect, useState } from 'react';
import { Dialog, Message } from '@agro1desenvolvimento/react-components';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import promiseWithLoaderAndMessages from '../../../../../utilities/promise-with-loader-and-messages';
import analisesService from '../../../../../services/sementes/analises';
import AnaliseCreateEditForm from './AnaliseCreateEditForm';
import { Analise } from '../../../../../@types/sementes/analises';
import { falhaAoCarregar } from '../../../../../utilities/default-confirmation-messages';

const AnaliseCreateEditPage: FC<AnaliseCreateEditPageProps> = ({ loteId, amostraId, onClose }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState('');
  const { id, action } = useParams<{ id: string; action: string, page: string }>();
  const [analise, setAnalise] = useState<Analise | undefined>();

  const onFormClose = () => {
    history.push({
      pathname: `/sementes/lotes/${loteId}/amostras/${amostraId}/analises`,
      search: location.search,
    });
    onClose();
  };

  const fetchAnalise = async () => {
    try {
      if (id) {
        setAnalise(await promiseWithLoaderAndMessages(
          dispatch,
          analisesService.find(loteId, amostraId, id),
          { errorMessage: falhaAoCarregar('análise') },
        ));
      }
    } catch (error) {
      setErrorMessage(error);
    }
  };

  useEffect(() => {
    fetchAnalise();
  }, []);

  if (errorMessage) {
    return (
      <Dialog onHide={onFormClose} visible header="Erro ao buscar Amostra" className="dialog-md">
        <Message severity="error" text={errorMessage} className="width-full p-justify-start" />
      </Dialog>
    );
  }

  return (
    <AnaliseCreateEditForm
      loteId={loteId}
      amostraId={amostraId}
      analise={analise}
      onClose={onFormClose}
      displayOnly={action === 'visualizar'}
    />
  );
};

export default AnaliseCreateEditPage;

interface AnaliseCreateEditPageProps {
  loteId: string;
  amostraId: string;
  onClose: () => void;
}
