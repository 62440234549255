import { useEffect, useState } from 'react';
import { Button, Toolbar } from '@agro1desenvolvimento/react-components';
import { Link, useParams, withRouter } from 'react-router-dom';
import { VizState } from '@cubejs-client/react';
import { useDispatch } from 'react-redux';
import ExploreQueryBuilder from './components/ExploreQueryBuilder';
import DialogSaveChart from './components/DialogSaveChart';
import { CrudCreateUpdate, promiseWithLoaderAndMessages } from '../../../utilities';
import { Dashboard, ExtraVizState } from '../../../@types/geral/dashboard';
import dashboardService from '../../../services/geral/dashboard';
import { falhaAoCarregar } from '../../../utilities/default-confirmation-messages';

const ToolBarLeft: React.FC<
  { onSavePress: () => void, id?: string, isValid: boolean }
> = ({ onSavePress, id, isValid }) => (
  <>
    <Link to="/dashboard">
      <Button
        label="Voltar"
        icon="pi pi-arrow-left"
        className="p-mr-auto p-button-primary"
      />
    </Link>
    <Button
      disabled={!isValid}
      icon={`pi ${id ? 'pi-pencil' : 'pi-plus'}`}
      key="button"
      className="p-button-success p-ml-2"
      onClick={onSavePress}
      label={id ? 'Atualizar' : 'Adicionar'}
    />
  </>
);

const CreateEditDashboardPage = withRouter(({ history }) => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id?: string }>();
  const [loading, setLoading] = useState(true);
  const [isValid, setIsValid] = useState(false);
  const [dialogVisible, setDialogVisible] = useState(false);

  const [dashboard, setDashboard] = useState<CrudCreateUpdate<Dashboard>>({
    layout: {
      h: 0, w: 0, x: 0, y: 0,
    },
    payload: {},
    extraPayload: {},
    titulo: 'Novo dashboard',
  });

  const fetchDashboard = async () => {
    try {
      if (!id) return;
      setLoading(true);

      const fetchedDashboard = await promiseWithLoaderAndMessages(
        dispatch,
        dashboardService.find(id),
        { errorMessage: falhaAoCarregar('dashboard') },
      );

      setDashboard(fetchedDashboard);
    } catch (error) {
      console.error();
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDashboard();
  }, []);

  const onVizStateChanged = (newVizState: VizState) => {
    const isDifferentChartType = newVizState.chartType !== dashboard.payload.chartType;

    setDashboard(
      (futureDashboard) => ({
        ...futureDashboard,
        payload: newVizState,
        extraPayload: isDifferentChartType ? {} : futureDashboard.extraPayload,
      }),
    );
  };

  const updateExtraVizState = (newExtraVizState: ExtraVizState) => {
    setDashboard(
      (prevDashboard) => ({ ...prevDashboard, extraPayload: newExtraVizState }),
    );
  };

  if (loading) return <></>;

  return (
    <>
      <DialogSaveChart
        afterSave={() => history.push('/dashboard')}
        item={dashboard}
        onHide={() => setDialogVisible(false)}
        visible={dialogVisible}
      />
      <Toolbar
        left={(
          <ToolBarLeft
            id={id}
            onSavePress={() => setDialogVisible(true)}
            isValid={isValid}
          />
        )}
        className="p-mb-3"
      />

      <ExploreQueryBuilder
        vizState={dashboard.payload}
        extraVizState={dashboard.extraPayload}
        updateExtraVizState={updateExtraVizState}
        onVizStateChanged={onVizStateChanged}
        setIsValid={setIsValid}
      />
    </>
  );
});

export default CreateEditDashboardPage;
