import { isIOS } from 'react-device-detect';

export default (
  data: string,
  fileName: string,
  options: { assignDownloadProp: boolean } = { assignDownloadProp: isIOS },
) => {
  const link = document.createElement('a');
  link.href = data;
  link.target = '_blank';
  if (options.assignDownloadProp) {
    link.download = fileName;
  }
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
