import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Dashboard } from '../../../@types/geral/dashboard';
import type { RootState } from '../../../app/rootReducer';
import dashboardService from '../../../services/geral/dashboard';
import { CrudCreateUpdate, promiseWithLoaderAndMessages, localStorage } from '../../../utilities';
import { toastExcludeMessages, falhaAoCarregar, toastSaveMessages } from '../../../utilities/default-confirmation-messages';

interface DashboardsState {
  dashboards: Dashboard[],
  currentEspeciesIds: string[],
  currentCultivaresIds: string[],
}

const initialState: DashboardsState = {
  dashboards: [],
  currentEspeciesIds: localStorage.getItem('currentEspeciesIds') || [],
  currentCultivaresIds: localStorage.getItem('currentCultivaresIds') || [],
};

export const fetchDashboards = createAsyncThunk(
  `${dashboardService.endpoint}/listAll`,
  (_param, { dispatch }) => promiseWithLoaderAndMessages(
    dispatch,
    dashboardService.listAll(),
    { errorMessage: falhaAoCarregar('dashboards') },
  ),
);

export const createOrUpdateDashboard = createAsyncThunk(
  `${dashboardService.endpoint}/createOrUpdate`,
  (dashboard: CrudCreateUpdate<Dashboard>, { dispatch }) => (
    promiseWithLoaderAndMessages(
      dispatch,
      dashboardService.createOrUpdate(dashboard),
      toastSaveMessages('dashboard'),
    )
  ),
);

export const deleteDashboard = createAsyncThunk(
  `${dashboardService.endpoint}/delete`,
  (id: string, { dispatch }) => promiseWithLoaderAndMessages(
    dispatch,
    dashboardService.delete(id),
    toastExcludeMessages('dashboard'),
  ),
);

const onDataIDChange = (
  key: 'currentCultivaresIds' | 'currentEspeciesIds',
  ids: string[],
) => {
  localStorage.setItem(key, ids);
};

export const dashboardsSlice = createSlice({
  name: 'dashboards',
  initialState,
  reducers: {
    setCurrentEspeciesIds: (state, action: PayloadAction<string[]>) => {
      state.currentEspeciesIds = action.payload;
      onDataIDChange('currentEspeciesIds', state.currentEspeciesIds);
    },
    setCurrentCultivaresIds: (state, action: PayloadAction<string[]>) => {
      state.currentCultivaresIds = action.payload;
      onDataIDChange('currentCultivaresIds', state.currentCultivaresIds);
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchDashboards.fulfilled, (state, { payload }) => {
      state.dashboards = payload;
    });
  },
});

export const {
  setCurrentEspeciesIds,
  setCurrentCultivaresIds,
} = dashboardsSlice.actions;

export const selectDashboardsState = (state: RootState) => state.dashboardReducer;

export default dashboardsSlice.reducer;
