import { Message } from '@agro1desenvolvimento/react-components';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { selectTopBarState } from '../../../components/Topbar/topBarSlice';
import { fetchArmazens } from './armazemSlice';
import ListArmazem from './components/List';
import Header from './components/Header';

const ArmazemPage = () => {
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState('');
  const { currentProdutor } = useSelector(selectTopBarState);

  useEffect(() => {
    if (!currentProdutor) {
      setErrorMessage('Selecione o produtor.');
      return;
    }

    setErrorMessage('');
    dispatch(fetchArmazens());
  }, [currentProdutor]);

  return (
    <>
      <div className="p-grid">
        <div className="p-col-12">
          <div className="card">
            <Header />
            <h1>Armazém</h1>
            {
              errorMessage
                ? <Message severity="error" text={errorMessage} className="width-full p-justify-start" />
                : <ListArmazem />
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default ArmazemPage;
