import { camelCase, forEach } from 'lodash';

const catchApiErrorsAndSetFormErrors = (
  setFormErrors: (field: string, error: string) => void,
  error: any,
  rejectedWithValue?: boolean,
) => {
  let obj = error;
  if (!rejectedWithValue) {
    obj = error?.response?.data;
  }

  const keys = Object.keys(obj);
  if (keys) {
    forEach(keys, (k) => {
      setFormErrors(camelCase(k), obj[k].join('\n'));
    });
  }
};

export default catchApiErrorsAndSetFormErrors;
