import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../../app/rootReducer';
import { Condition, Sort } from '../../services/queryable';

interface FilterStateItem {
  conditions: Record<string, Condition[]>,
  sort?: Sort
}

type FilterStateKeys = 'lotes' | 'camposProducao' | 'planejamentos' | 'relatorioTemplate' | 'auditoriaFilter' | 'registrosExcluidos'| 'descartes' | 'localizacao' | 'itensEstoque' | 'reembalagens';
export type FilterState = Record<FilterStateKeys, FilterStateItem>

interface Action {
  context: keyof FilterState;
}
interface ActionCondition extends Action {
  conditions: Record<string, Condition[]>;
}

interface ActionSort extends Action {
  sort: Sort;
}

const initialState: FilterState = {
  lotes: { conditions: {} },
  camposProducao: { conditions: {} },
  planejamentos: { conditions: {} },
  relatorioTemplate: { conditions: {} },
  auditoriaFilter: { conditions: {} },
  registrosExcluidos: { conditions: {} },
  descartes: { conditions: {} },
  localizacao: { conditions: {} },
  itensEstoque: { conditions: {} },
  reembalagens: { conditions: {} },
};

export const filterSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    addFilterValues(state: FilterState, action: PayloadAction<ActionCondition>) {
      state[action.payload.context].conditions = action.payload.conditions;
    },
    addSortValue(state: FilterState, action: PayloadAction<ActionSort>) {
      state[action.payload.context].sort = action.payload.sort;
    },
    resetFilterState(state: FilterState, action: PayloadAction<Action>) {
      state[action.payload.context] = { conditions: {} };
    },
  },
});

export const {
  addFilterValues,
  addSortValue,
  resetFilterState,
} = filterSlice.actions;

export const selectFilterState = (state: RootState) => state.filterReducer;

export default filterSlice.reducer;
