import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route } from 'react-router-dom';
import Header from './Header';
import ListScripts from './List';
import NewEditScriptPage from './NewEditScriptPage';
import { fetchTemplateScripts } from './templateScriptSlice';
import { scrollToTop } from '../../../utilities';

const RelatorioScriptPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    scrollToTop();
    dispatch(fetchTemplateScripts());
  }, []);

  return (
    <>
      <div className="p-grid">
        <div className="p-col-12">
          <div className="card">
            <Header />
            <h1>Scripts</h1>
            <ListScripts />
          </div>
        </div>
      </div>
      <Route path="/geral/relatorio_script/:action(editar|novo)/:id?">
        <NewEditScriptPage />
      </Route>
    </>
  );
};
export default RelatorioScriptPage;
