import { Dropdown } from '@agro1desenvolvimento/react-components';
import { filter, find } from 'lodash';
import { FC, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { Armazem } from '../../../../../@types/sementes/armazem';
import { addFilterValues } from '../../../../../components/Filter/filterSlice';
import { selectArmazemState } from '../../../Armazem/armazemSlice';
import Filters from './Filters';

const Header: FC<PropTypes> = ({
  armazem, setArmazem,
}) => {
  const dispatch = useDispatch();
  const { armazens } = useSelector(selectArmazemState);
  const armazensFiltred = useMemo(() => filter(armazens, 'croquiId'), [armazens]);
  const routeMatch = useRouteMatch<{ armazemId: string, numeroLote: string }>();

  useEffect(() => {
    const { armazemId, numeroLote } = routeMatch.params;
    if (armazemId) {
      const armazemFound = find(armazens, { id: armazemId });
      if (armazemFound) setArmazem(armazemFound);

      if (numeroLote) {
        dispatch(addFilterValues({
          conditions: {
            globalSearch: [{ field: 'globalSearch', operator: '', value: decodeURIComponent(numeroLote) }],
          },
          context: 'localizacao',
        }));
      }
    }
  }, [routeMatch.params, armazens]);

  useEffect(() => {
    if (armazensFiltred.length === 1) setArmazem(armazensFiltred[0]);
  }, [armazensFiltred]);

  return (
    <div className="p-pb-3 p-grid">
      <div className="p-col-12">
        <h1>Movimentação de lotes no armazém</h1>
      </div>
      <div className="p-col">
        <Dropdown
          value={armazem?.id}
          name="armazem"
          id="armazem"
          options={armazensFiltred}
          optionLabel="descricao"
          optionValue="id"
          disabled={!armazensFiltred.length}
          onChange={(e) => { setArmazem(find(armazens, ['id', e.value])); }}
          placeholder="Selecione o armazém"
          appendTo={document.body}
          filter
        />
        <div className="p-mt-2" hidden={!armazem}>
          <Filters />
        </div>
      </div>
    </div>
  );
};

interface PropTypes {
  armazem: Armazem | undefined,
  setArmazem: (armaze: Armazem | undefined) => void,
}

export default Header;
