import { loginService } from '@agro1desenvolvimento/apis-js-package';
import { SaldoCultivar } from '../../@types/sementes/saldoCultivar';
import api from '../erp-api';

interface SaldosProps {
  cultivarId: string,
  safraId: string,
  produtorId: string,
  depositoOrigemId?: string,
  camposProducaoIds?: string[],
}
class SaldosCultivaresService {
  get endpoint() {
    return `/${loginService.scope}/sementes/saldos_cultivares`;
  }

  async saldos({
    cultivarId, safraId, produtorId, depositoOrigemId, camposProducaoIds,
  }: SaldosProps) {
    const { data } = await api.get<SaldoCultivar>(
      `${this.endpoint}/${cultivarId}`,
      {
        params: {
          safra: safraId,
          produtor: produtorId,
          deposito: depositoOrigemId,
          campos_producao_ids: camposProducaoIds,
        },
      },
    );
    return data;
  }
}

export default new SaldosCultivaresService();
