import React, {
  FC, useEffect, useMemo, useRef, useState,
} from 'react';
import {
  Button, Dialog, Dropdown, InputText, Messages,
} from '@agro1desenvolvimento/react-components';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { ptForm } from 'yup-locale-pt';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { selectTipoAnalisesState } from './analiseSlice';
import DynamicForm from '../../../../../components/DynamicForm';
import analisesService from '../../../../../services/sementes/analises';
import { Analise, AnaliseTipoStruct } from '../../../../../@types/sementes/analises';
import { AnaliseTipos } from '../../../../../utilities/static-enums';
import {
  CrudModalModelBase, crudModalTitle, promiseWithLoaderAndMessages, transitionOptionsTimeout,
} from '../../../../../utilities';
import catchApiErrorsAndSetFormErrors from '../../../../../utilities/catch-api-errors';
import ShowErrorHelper from '../../../../../components/ShowErrorHelper';
import { toastSaveMessages } from '../../../../../utilities/default-confirmation-messages';

const Footer: React.FC<FooterProps> = ({ onClose, valid }) => (
  <div>
    <Button label="Cancelar" icon="pi pi-times" onClick={onClose} className="p-button-text" />
    <Button label="Salvar" disabled={!valid} icon="pi pi-check" autoFocus type="submit" form="form-save-analise" />
  </div>
);

Yup.setLocale(ptForm);
const analiseSchema = Yup.object().shape({
  tipo: Yup.string().required(),
  descricao: Yup.string().required(),
});

const AnaliseCreateEditForm: FC<AnaliseCreateFormProps> = ({
  loteId, amostraId, analise, onClose, displayOnly,
}) => {
  const dispatch = useDispatch();
  const messages = useRef<Messages>(null);
  const { tipoAnalises } = useSelector(selectTipoAnalisesState);
  const { action } = useParams<{ action: CrudModalModelBase }>();
  const [formValid, setFormValid] = useState(false);
  const [currentTipoAnalise, setCurrentTipoAnalise] = useState<AnaliseTipoStruct | undefined>();
  const header = useMemo(() => `${crudModalTitle(action)} Analise`, [displayOnly, analise?.id]);
  const [modalVisible, setModalVisible] = useState(true);

  const fechaModal = () => {
    setModalVisible(false);
  };

  const onFormSubmit = async (values: Analise) => {
    const { tipo, descricao, ...dados } = values;
    try {
      await promiseWithLoaderAndMessages(
        dispatch,
        analisesService.createOrUpdate(loteId, amostraId, {
          id: analise?.id,
          tipo,
          descricao,
          dados: {
            ...dados,
          },
        }),
        toastSaveMessages('análise'),
      );
      fechaModal();
    } catch (error) {
      catchApiErrorsAndSetFormErrors(formik.setFieldError, error);
    }
  };

  const formik = useFormik({
    validationSchema: analiseSchema,
    initialValues: {
      tipo: analise?.tipo,
      descricao: analise?.descricao,
    },
    onSubmit: async (values) => onFormSubmit(values as Analise),
  });

  const isFormValid = useMemo(
    () => (formik.dirty && formik.isValid && formValid),
    [formik.dirty, formik.isValid, formValid],
  );

  useEffect(() => {
    formik.setFieldValue('tipo', analise?.tipo);
    formik.setFieldValue('descricao', analise?.descricao);
  }, [analise?.id]);

  useEffect(() => {
    setCurrentTipoAnalise(tipoAnalises.find((v) => v.id === formik.values.tipo));
    formik.validateForm();
  }, [formik.values.tipo]);

  const typeClass = useMemo(() => classNames({ 'p-invalid': formik.errors.tipo }), [formik.errors, formik.values.tipo]);

  return (
    <Dialog
      onHide={fechaModal}
      transitionOptions={{ timeout: transitionOptionsTimeout, onExited: onClose }}
      visible={modalVisible}
      header={header}
      className="dialog-md"
      footer={displayOnly || <Footer onClose={fechaModal} valid={isFormValid} />}
      maximizable
    >
      <DynamicForm<Analise>
        data={formik.values}
        dataKeys={['tipo', 'descricao']}
        dynamicData={analise?.dados}
        dynamicStruct={currentTipoAnalise?.fields || []}
        formId="form-save-analise"
        formValid={setFormValid}
        displayOnly={displayOnly}
        onSubmit={onFormSubmit}
      >
        <div className="p-field p-col-6">
          <label htmlFor="tipo">Tipo</label>
          <Dropdown
            value={formik.values.tipo}
            name="tipo"
            id="tipo"
            options={AnaliseTipos}
            onChange={formik.handleChange}
            placeholder="Selecione um tipo"
            optionValue="key"
            optionLabel="value"
            className={typeClass}
            disabled={!!analise?.id || displayOnly}
            appendTo={document.body}
            autoFocus
            required
            filter
            aria-describedby="tipo-help"
          />
          <ShowErrorHelper id="tipo-help" error={formik.errors.tipo} />
        </div>
        <div className="p-field p-col-6">
          <label htmlFor="descricao">Descrição</label>
          <InputText
            name="descricao"
            id="descricao"
            value={formik.values.descricao}
            onChange={formik.handleChange}
            className={classNames({ 'p-invalid': formik.errors.descricao })}
            disabled={displayOnly}
            aria-describedby="descricao-help"
          />
          <ShowErrorHelper id="descricao-help" error={formik.errors.descricao} />
        </div>
      </DynamicForm>
      <Messages ref={messages} />
    </Dialog>
  );
};

export default AnaliseCreateEditForm;

interface AnaliseCreateFormProps {
  loteId: string;
  amostraId: string;
  analise: Analise | undefined;
  onClose: () => void;
  displayOnly?: boolean;
}

interface FooterProps {
  valid: boolean;
  onClose: () => void;
}
