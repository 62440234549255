import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import ListEquipamentos from './components/List';
import Header from './components/Header';
import EditEquipamento from './components/EditEquipamento';
import { fetchEquipamentos, selectEquipamentoState } from './equipamentoSlice';
import FormEquipamento from './components/FormEquipamento';
import { usePagination } from '../../../hooks';

const EquipamentoPage = () => {
  const dispatch = useDispatch();
  const { pagination: { maxPerPage = 1 } } = useSelector(selectEquipamentoState);
  const { currentPage } = usePagination({ maxPerPage });

  useEffect(() => {
    dispatch(fetchEquipamentos({
      extraParameters: {
        page: currentPage.toString(),
      },
    }));
  }, [currentPage]);

  return (
    <>
      <div className="p-grid">
        <div className="p-col-12">
          <div className="card">
            <Header />
            <h1>Equipamentos</h1>
            <ListEquipamentos />
          </div>
        </div>
      </div>

      <Switch>
        <Route exact path="/sementes/equipamentos/editar/:id?">
          <EditEquipamento />
        </Route>
        <Route exact path="/sementes/equipamentos/novo">
          <FormEquipamento />
        </Route>
      </Switch>
    </>
  );
};

export default EquipamentoPage;
