import { Button, Toolbar } from '@agro1desenvolvimento/react-components';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';
import { isEmpty, some } from 'lodash';
import { promiseWithLoaderAndMessages, downloader } from '../../../../utilities';
import LotesService from '../../../../services/sementes/lotes';
import { selectLotesState } from '../lotesSlice';
import SelectTemplateToPrint from '../../../../components/SelectTemplateToPrint';
import { fetchTemplatesByTipo, selectTemplateState } from '../../../Relatorio/RelatorioTemplate/relatorioTemplateslice';

const Header = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { lotesSelecionados } = useSelector(selectLotesState);
  const [downloadTemplate, setDownloadTemplate] = useState(false);
  const { templates } = useSelector(selectTemplateState);

  const isValidReembalar = useMemo(() => {
    if (isEmpty(lotesSelecionados) || some(lotesSelecionados, { status: 'rascunho' })) {
      return false;
    }

    return new Set(lotesSelecionados.map((lote) => `${lote.cultivar.id}_${lote.periodo}_${lote.peneira?.id}`)).size === 1;
  }, [lotesSelecionados]);

  const print = async (templateId: string) => {
    setDownloadTemplate(false);
    const etiquetas = await promiseWithLoaderAndMessages(
      dispatch,
      LotesService.etiquetas(lotesSelecionados.map((lote) => lote.id), templateId),
      {
        errorMessage: {
          summary: 'Erro',
          detail: 'Falha ao gerar etiquetas',
        },
      },
    );
    downloader(URL.createObjectURL(etiquetas), 'Etiquetas');
  };

  useEffect(() => {
    dispatch(fetchTemplatesByTipo('etiqueta'));
  }, []);

  const rightContents = (
    <>
      <span title={isValidReembalar ? 'Reembalar' : 'Disponível para lotes ativos com cultivar, peneira e período iguais.'}>
        <Button
          id="btn-reembalar"
          label="Reembalar"
          icon="pi pi-refresh"
          className="p-ml-auto p-button-help p-mr-1 btn-reembalar"
          disabled={!isValidReembalar}
          onClick={() => history.push('/sementes/reembalagens/novo')}
        />
      </span>
      <Button
        id="btn-gerar-etiquetas"
        label="Etiquetas"
        icon="pi pi-print"
        className="p-ml-auto p-button-help"
        onClick={() => setDownloadTemplate(true)}
        disabled={isEmpty(lotesSelecionados)}
      />
    </>
  );

  return (
    <>
      <Toolbar right={rightContents} />
      <SelectTemplateToPrint
        templates={templates}
        print={print}
        onClose={() => setDownloadTemplate(false)}
        targetId="btn-gerar-etiquetas"
        download={downloadTemplate}
      />
    </>
  );
};

export default Header;
