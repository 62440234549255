import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Column, ConfirmPopup, DataTable } from '@agro1desenvolvimento/react-components';
import { useHistory } from 'react-router-dom';
import { Amostra } from '../../../../@types/sementes/amostra';
import amostrasService from '../../../../services/sementes/amostras';
import promiseWithLoaderAndMessages from '../../../../utilities/promise-with-loader-and-messages';
import DropwDownButtons from '../../../../components/DropwDownButtons';
import { AuditoriaType } from '../../../../@types/enums';
import { toastExcludeMessages } from '../../../../utilities/default-confirmation-messages';

const AmostrasList: FC<AmostrasListProps> = ({ amostras, setAmostra, loteId }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [selectedAmostra, setSelectedAmostra] = useState<Amostra>();

  const removeItemFromArray = (amostra: Amostra) => {
    setAmostra((prev) => [...prev.filter((v) => v.id !== amostra.id)]);
  };

  const onDelete = async () => {
    if (!selectedAmostra) return;
    await promiseWithLoaderAndMessages(
      dispatch,
      amostrasService.delete(loteId, selectedAmostra),
      {
        ...toastExcludeMessages('amostra'),
        withLoader: false,
      },
    );
    removeItemFromArray(selectedAmostra);
  };

  const customActions = (amostra: Amostra) => [
    {
      command: () => history.push({
        pathname: `/sementes/lotes/${loteId}/amostras/${amostra.id}/analises`,
        search: location.search,
      }),
      label: 'Análises',
      icon: 'pi pi-fw pi-file',
    },
  ];

  const optionButtonId = (id: string | undefined) => `btn-option-${id}`;

  const renderActionButtons = (amostra: Amostra) => (
    <div>
      <DropwDownButtons
        editAction={() => history.push(`/sementes/lotes/${loteId}/amostras/${amostra.id}/editar`)}
        deleteAction={() => setSelectedAmostra(amostra)}
        auditoriaAction={() => history.push(`/auditoria/${AuditoriaType.amostras}/${amostra.id}`)}
        customActions={customActions(amostra)}
        optionButtonId={optionButtonId(amostra.id)}
      />
    </div>
  );

  return (
    <>
      <ConfirmPopup
        target={document.getElementById(optionButtonId(selectedAmostra?.id)) || undefined}
        visible={!!selectedAmostra}
        onHide={() => setSelectedAmostra(undefined)}
        message="Deseja realmente excluir?"
        icon="pi pi-exclamation-triangle"
        accept={() => onDelete()}
      />

      <DataTable
        dataKey="id"
        className="amostras"
        value={amostras}
        emptyMessage="Nenhum registro encontrado."
        sortField="numeroAmostra"
        sortOrder={1}
        removableSort
        responsiveLayout="scroll"
      >
        <Column field="numeroAmostra" header="Número da amostra" sortable />
        <Column header="Ações" body={renderActionButtons} className="col-actions" />
      </DataTable>
    </>
  );
};

export default AmostrasList;

interface AmostrasListProps {
  amostras: Amostra[];
  setAmostra: React.Dispatch<React.SetStateAction<Amostra[]>>;
  loteId: string;
}
