import { FC, useState } from 'react';
import {
  DataTable,
  Column,
  ConfirmPopup,
} from '@agro1desenvolvimento/react-components';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Armazem } from '../../../../@types/sementes/armazem';
import {
  deleteArmazem, fetchArmazens, selectArmazemState, setCurrentArmazem,
} from '../armazemSlice';
import DropwDownButton from '../../../../components/DropwDownButtons';
import { AuditoriaType } from '../../../../@types/enums';

const ListArmazem: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { armazens } = useSelector(selectArmazemState);
  const [selectedArmazem, setSelectedArmazem] = useState<Armazem | null>();

  const confirm = async (id: string) => {
    if ((await dispatch(deleteArmazem(id))).meta.requestStatus === 'fulfilled') {
      setSelectedArmazem(null);
      dispatch(fetchArmazens());
    }
  };

  const optionButtonId = (id: string | undefined) => `btn-option-${id}`;

  const goToCroqui = (armazem: Armazem) => {
    dispatch(setCurrentArmazem(armazem));
    history.push(`/sementes/armazens/croqui/${armazem.id}`);
  };

  const goToEdit = (armazem: Armazem) => {
    dispatch(setCurrentArmazem(armazem));
    history.push(`/sementes/armazens/editar/${armazem.id}`);
  };

  const renderBtnAcoes = (armazem: Armazem) => {
    const customActions = [
      {
        command: () => goToCroqui(armazem),
        label: 'Croqui',
        icon: 'pi-fw fas fa-grip-horizontal',
      },
    ];

    return (
      <DropwDownButton
        editAction={() => goToEdit(armazem)}
        deleteAction={() => setSelectedArmazem(armazem)}
        auditoriaAction={() => history.push(`/auditoria/${AuditoriaType.armazem}/${armazem.id}`)}
        optionButtonId={optionButtonId(armazem.id)}
        customActions={customActions}
      />
    );
  };
  return (
    <>
      <ConfirmPopup
        target={document.getElementById(optionButtonId(selectedArmazem?.id)) || undefined}
        visible={!!selectedArmazem}
        onHide={() => setSelectedArmazem(undefined)}
        message="Deseja realmente excluir?"
        icon="pi pi-exclamation-triangle"
        accept={() => confirm(selectedArmazem?.id as string)}
      />

      <DataTable value={armazens} className="armazem-lista" emptyMessage="Nenhum registro disponível" responsiveLayout="scroll" filterDisplay="row">
        <Column
          body={(rowData: Armazem) => rowData.descricao}
          header="Descrição"
          field="descricao"
          filter
          filterMatchMode="contains"
          sortable
          filterHeaderClassName="input-full-width"
          showFilterMenu={false}
          bodyClassName="p-col-6"
        />
        <Column header="Ações" body={renderBtnAcoes} />
      </DataTable>
    </>
  );
};

export default ListArmazem;
