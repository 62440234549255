import { Tag } from '@agro1desenvolvimento/react-components';
import { FC, useMemo } from 'react';
import { AuditoriaAction } from '../../@types/enums';

type PropsType = { action: AuditoriaAction }

enum SeverityStatus {
  update = 'success',
  create = 'info',
  destroy = 'danger',
}

const AuditoriaActionTag: FC<PropsType> = (props) => {
  const actionValues = useMemo(() => (props.action === 'create' ? 'criar' : 'editar'), [props]);
  const actions = <Tag value={actionValues} severity={SeverityStatus[props.action]} />;
  return actions;
};

export default AuditoriaActionTag;
