import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../../../app/rootReducer';
import { Config, ConfiguracaoFormField } from '../../../@types/sementes/config';
import SementesConfigService from '../../../services/sementes/config';
import { toastSaveMessages } from '../../../utilities/default-confirmation-messages';
import { promiseWithLoaderAndMessages } from '../../../utilities';

interface ConfigState {
  sementes?: Config,
}

const initialState: ConfigState = {
  sementes: undefined,
};

export const fetchSementesConfig = createAsyncThunk<Config>(
  'sementes/configs', () => SementesConfigService.get(),
);

export const updateConfiguracoes = createAsyncThunk(
  `${SementesConfigService.endpoint}/update`,
  async (configuracao: ConfiguracaoFormField, { dispatch, rejectWithValue }) => (
    promiseWithLoaderAndMessages(
      dispatch,
      SementesConfigService.create(configuracao),
      toastSaveMessages('configurações'),
    ).catch((error) => rejectWithValue(error.response.data))
  ),
);

export const configSlice = createSlice({
  name: 'sementes-config',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(fetchSementesConfig.fulfilled, (state, { payload }) => {
      state.sementes = payload;
    });
  },
});

export const selectConfigSementesState = (state: RootState) => state.configReducer.sementes;

export default configSlice.reducer;
