import { Dialog, ProgressSpinner } from '@agro1desenvolvimento/react-components';
import {
  FC, useEffect, useMemo, useState,
} from 'react';
import { useSelector } from 'react-redux';
import { selectLoaderState } from './loaderSlice';

// eslint-disable-next-line @typescript-eslint/no-empty-function
const onClose = () => {};
const INTERVAL_TO_SHOW = 25;

const Loader: FC = () => {
  const { loadersVisible } = useSelector(selectLoaderState);
  const [timeOutId, setTimeOutId] = useState<number>();
  const hasLoader = useMemo(() => !!loadersVisible.length, [loadersVisible]);
  const [visible, setVisible] = useState(hasLoader);

  useEffect(() => {
    if (hasLoader) {
      setTimeOutId(
        setTimeout(
          () => setVisible(true),
          INTERVAL_TO_SHOW,
        ) as unknown as number,
      );
    } else {
      setVisible(false);
      clearTimeout(timeOutId);
    }
  }, [hasLoader]);

  return (
    <Dialog
      modal
      visible={visible}
      onHide={onClose}
      closable={false}
      baseZIndex={10}
      className="loader"
    >
      <div className="p-grid p-mt-1">
        <ProgressSpinner className="spinner p-justify-center" />
      </div>
    </Dialog>
  );
};

export default Loader;
