import {
  Checkbox, Column, DataTable,
} from '@agro1desenvolvimento/react-components';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { CSSTransition } from 'react-transition-group';
import { useDispatch, useSelector } from 'react-redux';
import {
  compact, find, isEmpty, map,
} from 'lodash';
import { SaldoCultivar } from '../../../../../@types/sementes/saldoCultivar';
import { SteepComponent } from '../../../VinculoLotesCamposProducao/types';
import SaldosPanel from '../../components/SaldosPanel';
import SaldosCultivaresService from '../../../../../services/sementes/saldos-cultivares';
import { selectTopBarState } from '../../../../../components/Topbar/topBarSlice';
import { promiseWithLoaderAndMessages, formatNumber } from '../../../../../utilities';
import { falhaAoCarregar } from '../../../../../utilities/default-confirmation-messages';
import { goToStep, selectDescartesState, setFiltrosSecundarios } from '../novoDescarteSlice';
import { CampoProducaoWithSaldo } from '../../../../../@types/sementes/saldoDeposito';
import { fetchCampos, selectCamposProducaoState } from '../../../CamposProducao/camposProducaoSlice';

const ResumoSaldosDescarte: SteepComponent = forwardRef(({ setCanGoNext }, ref) => {
  const dispatch = useDispatch();
  const { currentSafra, currentProdutor } = useSelector(selectTopBarState);
  const [showSaldosByDeposito, setShowSaldosByDeposito] = useState(false);
  const { cultivar, depositoOrigem, saldosByDeposito } = useSelector(selectDescartesState);
  const { campos } = useSelector(selectCamposProducaoState);
  const [saldoCultivarByDeposito, setSaldoCultivarByDeposito] = useState<SaldoCultivar>();
  const [saldosByCampos, setSaldosByCampos] = useState<CampoProducaoWithSaldo[]>([]);
  const [camposSelecionados, setCamposSelecionados] = useState<CampoProducaoWithSaldo[]>([]);
  const [informarCampos, setInformarCampos] = useState(false);

  useImperativeHandle(ref, () => ({
    submit: () => {
      if (!saldoCultivarByDeposito) return;
      dispatch(setFiltrosSecundarios({ camposProducoes: camposSelecionados }));
      dispatch(goToStep('next'));
    },
  }));

  useEffect(() => {
    dispatch(fetchCampos({
      conditions: [
        {
          field: 'id', operator: 'in', value: map(saldosByDeposito?.camposProducoes, 'campoProducaoId'),
        },
        { field: 'produtor_id', operator: 'eq', value: currentProdutor!.id },
        { field: 'safra_id', operator: 'eq', value: currentSafra!.id },
      ],
    }));
  }, [saldosByDeposito]);

  useEffect(() => {
    const buildCamposWithSaldos = () => {
      if (!isEmpty(saldosByDeposito)) {
        const mappedSaldosByCampos = map(saldosByDeposito?.camposProducoes,
          ({ saldo, campoProducaoId }) => {
            const campo = find(campos, { id: campoProducaoId });
            if (!campo) return;
            return { saldo, campo };
          });

        if (mappedSaldosByCampos) setSaldosByCampos(compact(mappedSaldosByCampos));
      }
    };

    if (campos) buildCamposWithSaldos();
  }, [saldosByDeposito, campos]);

  useEffect(() => {
    const loadSaldosCultivares = async () => {
      if (!cultivar || !depositoOrigem) return;

      const params = {
        cultivarId: cultivar.id,
        safraId: currentSafra!.id,
        produtorId: currentProdutor!.id,
        depositoOrigemId: depositoOrigem.id,
      };

      setSaldoCultivarByDeposito(await promiseWithLoaderAndMessages(
        dispatch,
        SaldosCultivaresService.saldos(params),
        { errorMessage: falhaAoCarregar('saldos de cultivares') },
      ));
    };

    loadSaldosCultivares();
    setInformarCampos(false);
  }, [cultivar, depositoOrigem, currentSafra, currentProdutor]);

  useEffect(() => {
    if (saldoCultivarByDeposito) {
      setShowSaldosByDeposito(true);
      setCanGoNext(true);
    }
  }, [saldoCultivarByDeposito]);

  useEffect(() => {
    if (depositoOrigem) setCamposSelecionados([]);
  }, [depositoOrigem]);

  useEffect(() => {
    if (!informarCampos) setCamposSelecionados([]);
  }, [informarCampos]);

  return (
    <>
      <div>
        <h5 className="p-ml-2 p-my-1">Cultivar: <strong>{cultivar?.descricao}</strong></h5>
        <h5 className="p-ml-2 p-my-1">Depósito de matéria prima: <strong>{depositoOrigem?.descricao}</strong></h5>
      </div>
      <div className="p-mt-4">
        <div className="p-field-checkbox">
          <Checkbox disabled={!saldosByCampos?.length} inputId="informar-campos" checked={informarCampos} onChange={(e) => setInformarCampos(e.checked)} />
          <label htmlFor="informar-campos">Informar os campos de produção</label>
        </div>
        <CSSTransition classNames="p-toggleable-content" timeout={600} in={informarCampos} unmountOnExit hidden={!saldosByCampos}>
          <DataTable
            showGridlines
            value={saldosByCampos}
            selectionMode="multiple"
            selection={camposSelecionados}
            className="p-datatable-hoverable-rows"
            emptyMessage="Nenhum registro encontrado"
            onSelectionChange={(e) => setCamposSelecionados(e.value)}
            rowClassName={({ campo }) => ({ 'tr-success': camposSelecionados.includes(campo) })}
            responsiveLayout="scroll"
          >
            <Column selectionMode="multiple" />
            <Column field="campo.identificacaoSigef" header="Campo de produção" />
            <Column body={({ saldo }) => formatNumber(saldo)} header="Saldo" />
          </DataTable>
        </CSSTransition>
      </div>
      <div className="p-mt-4" hidden={!showSaldosByDeposito}>
        <h1 className="p-p-0">Saldos do {depositoOrigem?.descricao}</h1>
        <SaldosPanel saldosCultivares={saldoCultivarByDeposito} />
      </div>
    </>
  );
});

export default ResumoSaldosDescarte;
