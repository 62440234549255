import {
  DataTable,
  Column,
  PaginatorPageState,
} from '@agro1desenvolvimento/react-components';
import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import DropwDownButton from '../../../components/DropwDownButtons';
import { AuditoriaType } from '../../../@types/enums';
import Filters from './Filters';
import Pagination from '../../../components/Pagination';
import { usePagination } from '../../../hooks';
import { selectItensEstoqueState } from './itensEstoqueSlice';
import { Cultivar, Item, Produto } from '../../../@types/estoque/item';
import ExpandedRowItemTemplate from '../../../components/ExpandedRowItemTemplate';

const RowExpansionTemplate: FC<{item: Item & Cultivar & Produto}> = ({ item }) => {
  const itemHasProperty = (property: string) => property in item;

  return (
    <div className="p-p-2 row-expanded">
      <ExpandedRowItemTemplate label="ID" value={item.id} />
      <ExpandedRowItemTemplate label="Tipo" value={item.tipo} />
      <ExpandedRowItemTemplate label="Descricao" value={item.descricao} />
      <ExpandedRowItemTemplate label="Unidade" value={item.unidade.descricao} />
      <ExpandedRowItemTemplate label="Especie" value={item.especie.descricao} hidden={!itemHasProperty('especie')} />
      <ExpandedRowItemTemplate label="Obtentor" value={item.obtentor?.descricao} hidden={!itemHasProperty('obtentor')} />
      <ExpandedRowItemTemplate label="Categoria" value={item.categoria?.descricao} hidden={!itemHasProperty('categoria')} />
      <ExpandedRowItemTemplate label="Sigef" value={item.sigefId} hidden={!itemHasProperty('sigefId')} />
    </div>
  );
};

const ListItensEstoque: FC = () => {
  const { itens, pagination } = useSelector(selectItensEstoqueState);
  const {
    maxPerPage = 1,
    totalRecords,
    pages,
  } = pagination;
  const history = useHistory();
  const [expandedRows, setExpandedRows] = useState<Item[]>([]);
  const { firstIndexOnPage, goToPage } = usePagination({ maxPerPage });

  const onPageChange = (e: PaginatorPageState) => goToPage(e.page + 1);
  const isEditable = (item: Item) => (item.tipo === 'Estoque::Cultivar');

  const renderActionButtons = (item: Item) => (
    <div>
      <DropwDownButton
        auditoriaAction={() => history.push(`/auditoria/${AuditoriaType.item}/${item.id}`)}
        editAction={isEditable(item) ? (() => history.push(`/estoque/itens/${item.id}/editar`)) : undefined}
      />
    </div>
  );

  return (
    <>
      <DataTable
        value={itens}
        emptyMessage="Nenhum registro encontrado."
        header={<Filters />}
        rowExpansionTemplate={(item) => <RowExpansionTemplate item={item} />}
        expandedRows={expandedRows}
        onRowToggle={(event) => setExpandedRows(event.data)}
        responsiveLayout="scroll"
      >
        <Column expander className="expander-column" />
        <Column body={(item) => item.descricaoCategoria || item.descricao} header="Descrição" sortable sortField="descricao" />
        <Column field="tipo" header="Tipo" sortable />
        <Column field="unidade.abreviatura" header="Unidade" sortable />
        <Column header="Ações" body={renderActionButtons} className="col-actions" />
      </DataTable>
      <Pagination
        pages={pages}
        first={firstIndexOnPage}
        rows={maxPerPage}
        totalRecords={totalRecords}
        onPageChange={(e) => onPageChange(e)}
        pageRecords={itens.length}
      />
    </>

  );
};

export default ListItensEstoque;
