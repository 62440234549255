import React, { FC, useEffect, useState } from 'react';
import {
  FileUpload,
  Button,
  Dialog,
  InputText,
  Dropdown,
} from '@agro1desenvolvimento/react-components';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { ptForm } from 'yup-locale-pt';
import { capitalize, omit } from 'lodash';
import { RelatorioTemplate } from '../../../@types/relatorio/template';
import { createOrUpdateRelatorio } from './relatorioTemplateslice';
import catchApiErrorsAndSetFormErrors from '../../../utilities/catch-api-errors';
import ShowErrorHelper from '../../../components/ShowErrorHelper';
import { fetchTemplateScripts, selectScriptState } from '../TemplateScript/templateScriptSlice';
import { transitionOptionsTimeout as timeout } from '../../../utilities';

const Footer: React.FC<{close: () => void, valid: boolean}> = ({ close, valid }) => (
  <div>
    <Button label="Cancelar" icon="pi pi-times" onClick={close} className="p-button-text" />
    <Button label="Salvar" icon="pi pi-check" type="submit" disabled={!valid} form="form-save-template" />
  </div>
);

Yup.setLocale(ptForm);
const RelatorioTemplateSchema = Yup.object().shape({
  descricao: Yup.string().required(),
  tipo: Yup.string().required(),
  script: Yup.string().required(),
});

interface FormularioProps {
  template: RelatorioTemplate;
  close: () => void;
  fetchRelatoriosTemplate: () => void
}

const tiposTemplate = () => (
  ['etiqueta', 'planejamento', 'reembalagem'].map((tipo) => ({ label: capitalize(tipo), value: tipo }))
);

const FormularioTemplate: FC<FormularioProps> = ({ template, close, fetchRelatoriosTemplate }) => {
  const { scripts } = useSelector(selectScriptState);
  const dispatch = useDispatch();
  const [modalVisible, setModalVisible] = useState(true);

  const fechaModal = () => {
    setModalVisible(false);
  };

  const formik = useFormik({
    validationSchema: RelatorioTemplateSchema,
    initialValues: {
      id: template.id || '',
      descricao: template.descricao,
      tenantsHabilitados: template.tenantsHabilitados.join(', '),
      tenantsDesabilitados: template.tenantsDesabilitados.join(', '),
      tipo: template.tipo,
      script: template.scriptId,
      reportTemplate: template.reportTemplate,
    },

    onSubmit: async (values) => {
      const formikValues = { ...omit(values, 'script'), scriptId: values.script };
      formikValues.tenantsHabilitados = handleTenants(formikValues.tenantsHabilitados);
      formikValues.tenantsDesabilitados = handleTenants(formikValues.tenantsDesabilitados);

      const promise = await dispatch(createOrUpdateRelatorio(formikValues));
      const success = promise.meta.requestStatus === 'fulfilled';

      if (success) {
        fechaModal();
        fetchRelatoriosTemplate();
      } else {
        catchApiErrorsAndSetFormErrors(formik.setFieldError, promise.payload, true);
        close();
      }
    },
  });

  const validate = () => {
    const formikValidation = formik.dirty && formik.isValid;
    if (template.id !== '') {
      return formikValidation;
    }
    return !!formik.values.reportTemplate && formikValidation;
  };

  const handleUpload = (event: { files: any}) => {
    const fileReader = new FileReader();
    fileReader.readAsText(event.files[0], 'UTF-8');
    fileReader.onload = (file) => {
      formik.setFieldValue('reportTemplate', file.target?.result);
    };
  };

  const handleTenants = (tenant: string) => {
    if (typeof tenant === 'string') {
      const listaTenants = tenant.split(',');
      return listaTenants.map((value) => value.trim());
    }
    return tenant;
  };

  const loadScripts = async () => {
    dispatch(fetchTemplateScripts());
  };

  useEffect(() => {
    loadScripts();
  }, []);

  return (
    <Dialog
      onHide={fechaModal}
      transitionOptions={{ timeout, onExited: close }}
      visible={modalVisible}
      maximizable
      header="Template"
      className="dialog-md"
      closeOnEscape={false}
      footer={(
        <Footer
          close={fechaModal}
          valid={validate()}
        />
      )}
    >
      <form onSubmit={formik.handleSubmit} id="form-save-template">
        <div className="p-fluid">
          <div className="p-field">
            <label htmlFor="descricao">Descricao</label>
            <InputText
              required
              value={formik.values.descricao}
              name="descricao"
              id="descricao"
              onChange={formik.handleChange}
              aria-describedby="descricao-help"
            />
            <ShowErrorHelper id="descricao-help" error={formik.errors.descricao} />
          </div>
          <div className="p-field">
            <label htmlFor="tenantsHabilitados">Tenants habilitados</label>
            <InputText
              value={formik.values.tenantsHabilitados}
              name="tenantsHabilitados"
              id="tenantsHabilitados"
              onChange={formik.handleChange}
              aria-describedby="tenants-habilitados-help"
            />
            <ShowErrorHelper id="tenants-habilitados-help" error={formik.errors.tenantsHabilitados} />
          </div>
          <div className="p-field">
            <label htmlFor="tenantsDesabilitados">Tenants desabilitados</label>
            <InputText
              value={formik.values.tenantsDesabilitados}
              name="tenantsDesabilitados"
              id="tenantsDesabilitados"
              onChange={formik.handleChange}
              aria-describedby="tenants-desabilitados-help"
            />
            <ShowErrorHelper id="tenants-desabilitados-help" error={formik.errors.tenantsDesabilitados} />
          </div>
          <div className="p-field">
            <label aria-labelledby="tipoEmbalagem" htmlFor="tipoEmbalagem">Tipo</label>
            <Dropdown
              required
              value={formik.values.tipo}
              name="tipo"
              id="tipo"
              appendTo={document.body}
              options={tiposTemplate()}
              optionLabel="label"
              optionValue="value"
              onChange={formik.handleChange}
              placeholder="Selecione o tipo"
              aria-describedby="tipo-help"
            />
            <ShowErrorHelper id="tipo-help" error={formik.errors.tipo} />
          </div>
          <div className="p-field">
            <label aria-labelledby="script" htmlFor="script">Fonte de dados</label>
            <Dropdown
              required
              value={formik.values.script}
              name="script"
              id="script"
              appendTo={document.body}
              options={scripts}
              optionLabel="descricao"
              optionValue="id"
              onChange={formik.handleChange}
              placeholder="Selecione a fonte de dados"
              aria-describedby="script-help"
            />
            <ShowErrorHelper id="script-help" error={formik.errors.script} />
          </div>
          <div className="p-field">
            <label aria-labelledby="documentJson" htmlFor="documentJson">Upload de arquivo</label>
            <FileUpload name="templateJson" uploadHandler={handleUpload} auto customUpload accept=".tlf" />
          </div>
        </div>
      </form>
    </Dialog>
  );
};

export default FormularioTemplate;
