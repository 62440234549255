import { loginService } from '@agro1desenvolvimento/apis-js-package';
import { orderBy } from 'lodash';
import { EndpointAuditoriaTypeReturn } from '../../../@types/geral/auditoria';
import responseHeaders from '../../../utilities/response-headers';
import api from '../../erp-api';

class AuditoriaTypesService {
  async listAll() {
    const { data, headers } = await api.get<EndpointAuditoriaTypeReturn[]>(this.endpoint);
    return { headers: responseHeaders(headers), data: orderBy(data, ['humanized'], 'asc') };
  }

  get endpoint() {
    return `/${loginService.scope}/geral/auditorias/types`;
  }
}

export default new AuditoriaTypesService();
