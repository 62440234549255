import { FC, useEffect, useState } from 'react';
import { Dialog, Steps } from '@agro1desenvolvimento/react-components';
import { useRouteMatch } from 'react-router-dom';
import { useIsMobile } from '@agro1desenvolvimento/react-hooks';
import { useDispatch, useSelector } from 'react-redux';
import FormReembalagem from './components/FormReembalagem';
import ListaLotesToConfirm from './components/ListaLotesToConfirm';
import {
  confirmLotesReembalagem,
  fetchReembalagem,
  clearConfirmacaoReembalagemState,
  selectConfirmacaoReembalagemState,
} from './confirmacaoReembalagemSlice';
import Footer from './components/Footer';
import { transitionOptionsTimeout as timeout } from '../../../../utilities';

const steps = [
  { label: 'Básico', component: FormReembalagem },
  { label: 'Lotes', component: ListaLotesToConfirm },
];

const ConfirmacaoReembalagemPage: FC<{ closeModal: () => void }> = ({ closeModal }) => {
  const { params } = useRouteMatch<{ id: string}>();
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [activeStep, setActiveStep] = useState(1);
  const { reembalagem } = useSelector(selectConfirmacaoReembalagemState);
  const isMobile = useIsMobile();

  const fechaModal = () => setVisible(false);

  const confirmaLotes = () => {
    dispatch(confirmLotesReembalagem());
    fechaModal();
  };

  useEffect(() => {
    dispatch(fetchReembalagem({ reembalagemId: params.id }));
  }, [params.id]);

  useEffect(() => {
    setVisible(!!reembalagem);
  }, [reembalagem]);

  useEffect(() => {
    const clearState = () => {
      dispatch(clearConfirmacaoReembalagemState());
    };

    return clearState();
  }, []);

  return (
    <Dialog
      maximizable
      visible={visible}
      onHide={fechaModal}
      className="p-lg-11 p-p-0 custom-steps"
      header="Reembalagem - Confirmação"
      transitionOptions={{ timeout, onExited: closeModal }}
      footer={<Footer onSubmit={confirmaLotes} closeModal={fechaModal} />}
      maximized={isMobile}
      closeOnEscape={false}
    >
      <Steps
        model={steps}
        readOnly={false}
        activeIndex={activeStep}
        onSelect={(e) => setActiveStep(e.index)}
      />

      { steps.map((step, index) => {
        const Component = step.component;
        return <Component key={index} visible={activeStep === index} />;
      }) }

    </Dialog>
  );
};

export default ConfirmacaoReembalagemPage;
