import { Toast } from '@agro1desenvolvimento/react-components';
import { FC, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { clearMessages, selectToastState } from './toast';

const Agro1Toast: FC = () => {
  const toastRef = useRef<Toast>(null);
  const dispatch = useDispatch();
  const { messages } = useSelector(selectToastState);

  useEffect(() => {
    if (messages.length) {
      toastRef.current?.show(messages.map((v) => ({ ...v })));
      dispatch(clearMessages());
    }
  }, [messages]);

  return <Toast baseZIndex={100} ref={toastRef} />;
};

export default Agro1Toast;
