import classNames from 'classnames';
import { first, isEmpty } from 'lodash';
import { FC, useEffect, useMemo } from 'react';
import { ArmazemSaldo } from '../../../../../@types/sementes/armazem';
import { Croqui } from '../../../../../@types/sementes/croqui';
import { CroquiItem } from '../../../../../@types/sementes/croquiItem';
import { Lote } from '../../../../../@types/sementes/lote';
import CroquiService from '../../../../../services/estoque/croqui';
import CroquiMapItem from './CroquiMapItem';

const getCroquiItemElementId = (item: CroquiItem) => `croqui-item-${item.id}`;

const CroquiMap: FC<PropTypes> = ({
  saldos, croqui, onLoteClick, filtered,
}) => {
  const items: (CroquiItemComSaldo | undefined)[] = useMemo(() => {
    if (!croqui) return [];

    const getItemSaldos = (item: CroquiItem) => (
      saldos.filter((saldo) => saldo.itemCroqui.id === item.id)
    );

    return CroquiService.getMatriz(croqui)
      .flat()
      .map((croquiItem) => croquiItem && { ...croquiItem, saldos: getItemSaldos(croquiItem) });
  }, [croqui, saldos]);

  const selectedItems = useMemo(() => {
    if (!filtered) return [];
    return items.filter((item) => !isEmpty(item?.saldos));
  }, [items]);

  useEffect(() => {
    const matrizItem = first(selectedItems);
    if (matrizItem) {
      document.querySelector(`#${getCroquiItemElementId(matrizItem)}`)?.scrollIntoView();
    }
  }, [selectedItems]);

  return (
    <div className="croqui-map">
      <div className="matriz" style={{ gridTemplateColumns: `repeat(${croqui.quantidadeColunas}, auto)` }}>
        {items.map((item, index) => (
          <div
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            className={classNames(
              'croqui-item',
              item?.tipo,
              {
                contrasted: selectedItems.includes(item),
                'with-saldo': item?.saldos.length,
              },
            )}
            id={item && getCroquiItemElementId(item)}
          >
            <CroquiMapItem croquiItem={item} onLoteClick={onLoteClick} />
          </div>
        ))}
      </div>
    </div>
  );
};

export interface CroquiItemComSaldo extends CroquiItem {
  saldos: ArmazemSaldo[]
}

export type OnLoteClick = (item: CroquiItem, lote: Lote) => void

interface PropTypes {
  saldos: ArmazemSaldo[],
  croqui: Croqui,
  onLoteClick: OnLoteClick,
  filtered: boolean,
}

export default CroquiMap;
