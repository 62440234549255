import { loginService } from '@agro1desenvolvimento/apis-js-package';
import { keysToSnakeCase, keysToSnakeCaseDeep } from '@agro1desenvolvimento/utils-js';
import { map } from 'lodash';
import { HeaderParams } from '../../@types/headers';
import {
  Descarte,
  LotesCamposSimplified,
  SimulacaoDescarte,
} from '../../@types/sementes/descarte';
import { CrudCreateUpdate } from '../../utilities';
import CrudBase from '../crud-base';
import api from '../erp-api';

export interface DescarteListReturnType extends HeaderParams {
  data: Descarte[];
}

class DescartesService extends CrudBase<Descarte> {
  get endpoint() {
    return `/${loginService.scope}/sementes/descartes`;
  }

  async confirmarDescartes(descartes: SimulacaoDescarte[]) {
    return (await api.post<Descarte[]>(
      this.endpoint, { descartes: map(descartes, prepareToSave) },
    )).data[0];
  }

  async simulaDescarteTotal(simulacaoParams: SimulacaoDescartesParams) {
    return (await api.get<{ descartes: SimulacaoDescarte[]}>(
      `${this.endpoint}/simular_descarte/`, {
        params: keysToSnakeCase(simulacaoParams),
      },
    )).data;
  }

  async simulaVinculosLotes(simulacaoParams: SimulacaoVinculosParams) {
    return (await api.get<{ lotesCampos: LotesCamposSimplified[]}>(
      `/${loginService.scope}/sementes/vinculo_lotes_campos_producao/simulacao`, {
        params: keysToSnakeCase(simulacaoParams),
      },
    )).data;
  }
}

interface SimulacaoParams {
  cultivarId: string,
  safraId: string,
  produtorId: string,
  depositoId: string,
  camposProducaoIds?: string[],
}

interface SimulacaoDescartesParams extends SimulacaoParams {
  quantidade: number,
}

interface SimulacaoVinculosParams extends SimulacaoParams {
  tipo: string,
}

const prepareToSave = (descarte: SimulacaoDescarte): CrudCreateUpdate<Descarte> => (
  keysToSnakeCaseDeep({
    data: descarte.data,
    produtorId: descarte.produtor?.id,
    safraId: descarte.safra?.id,
    depositoOrigemId: descarte.depositoOrigem?.id,
    depositoDestinoId: descarte.depositoDestino?.id,
    subProdutoId: descarte.subProduto?.id,
    quantidade: descarte.quantidadeDescarte,
    campoProducaoId: descarte.campoProducao?.id,
    cultivarId: descarte.cultivar?.id,
    observacao: descarte.observacao,
  })
);

export default new DescartesService();
