import { keysToSnakeCaseDeep } from '@agro1desenvolvimento/utils-js';
import { loginService } from '@agro1desenvolvimento/apis-js-package';
import api from '../erp-api';
import { RelatorioTemplate, RelatorioTipo } from '../../@types/relatorio/template';

class GeradorService {
  get endpoint() {
    return `/${loginService.scope}/relatorio/gerador`;
  }

  async list(tipo?: RelatorioTipo) {
    return (await api.get<RelatorioTemplate[]>(this.endpoint, {
      params: {
        tipo,
      },
    })).data;
  }

  async gerar(tipo: RelatorioTipo, filterValues?: Record<keyof any, any>, id?: string) {
    const response = await api.get<Blob>(`${this.endpoint}/pdf`, {
      params: keysToSnakeCaseDeep({
        ...filterValues,
        tipo,
        id,
      }),
      responseType: 'blob',
    });
    return new Blob([response.data], { type: 'application/pdf' });
  }
}

export default new GeradorService();
