import type { ToastMessage } from '@agro1desenvolvimento/react-components';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../../app/rootReducer';

interface ToastState {
  messages: ToastMessage[]
}

const initialState: ToastState = {
  messages: [],
};

export const toastSlice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    showMessage(state, action: PayloadAction<ToastMessage>) {
      state.messages.push(action.payload);
    },
    clearMessages(state) {
      state.messages = [];
    },
  },
});

export const {
  showMessage,
  clearMessages,
} = toastSlice.actions;

export const selectToastState = (state: RootState) => state.toastReducer;

export default toastSlice.reducer;
