import { Produto } from '../../@types/estoque/item';
import { ListReturnType } from '../queryable-base';
import { ItensService } from './itens';

class ProdutosService extends ItensService {
  async listAll(params?: any): Promise<ListReturnType<Produto>> {
    const onlyProducts = {
      ...params,
      q: { ...params?.q, tipo_matches: 'Estoque::Produto' },
    };
    return <Promise<ListReturnType<Produto>>> super.listAll(onlyProducts);
  }
}

export default new ProdutosService();
