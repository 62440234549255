import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import templateService from '../../../services/relatorio/template';
import GeradorService from '../../../services/relatorio/gerador';
import { promiseWithLoaderAndMessages } from '../../../utilities';
import { RelatorioTemplate, RelatorioTipo } from '../../../@types/relatorio/template';
import type { RootState } from '../../../app/rootReducer';
import queryable, { Query } from '../../../services/queryable';
import { ListReturnType } from '../../../services/queryable-base';
import { HeaderParams } from '../../../@types/headers';
import { toastExcludeMessages, falhaAoCarregar, toastSaveMessages } from '../../../utilities/default-confirmation-messages';

interface RelatorioTemplateState {
  templates: RelatorioTemplate[],
  pagination: HeaderParams,
}

const initialState: RelatorioTemplateState = {
  templates: [],
  pagination: {
    currentPage: undefined,
    totalRecords: undefined,
    maxPerPage: undefined,
    pages: undefined,
  },
};

export const fetchRelatoriosTemplate = createAsyncThunk<
  ListReturnType<RelatorioTemplate>,
  Omit<Query<RelatorioTemplate>, 'service'>
>(
  `${templateService.endpoint}/list`,
  async (params = {}, { dispatch }) => {
    const query = queryable.query({
      ...params,
      service: templateService,
    });

    return promiseWithLoaderAndMessages(
      dispatch,
      query,
      { errorMessage: falhaAoCarregar('templates') },
    );
  },
);

export const fetchTemplatesByTipo = createAsyncThunk(
  `${GeradorService.endpoint}/list`,
  (tipo: RelatorioTipo, { dispatch }) => (
    promiseWithLoaderAndMessages(
      dispatch,
      GeradorService.list(tipo),
      { errorMessage: falhaAoCarregar('templates') },
    )
  ),
);

export const deleteRelatorioTemplate = createAsyncThunk(
  `${templateService.endpoint}/delete`,
  async (id: string, { dispatch }) => {
    await promiseWithLoaderAndMessages(
      dispatch,
      templateService.delete(id),
      toastExcludeMessages('template'),
    );

    return id;
  },
);

export const createOrUpdateRelatorio = createAsyncThunk(
  `${templateService.endpoint}/createOrUpdate`,
  (data: Parameters<typeof templateService.createOrUpdate>[0], { dispatch, rejectWithValue }) => (
    promiseWithLoaderAndMessages(
      dispatch,
      templateService.createOrUpdate(data),
      toastSaveMessages('template'),
    ).catch((error) => rejectWithValue(error.response.data))
  ),
);

export const relatorioTemplateSlice = createSlice({
  name: 'relatorioTemplate',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(fetchRelatoriosTemplate.fulfilled, (state, { payload }) => {
      state.templates = payload.data;
      state.pagination = payload.headers;
    });

    builder.addCase(fetchTemplatesByTipo.fulfilled, (state, { payload }) => {
      state.templates = payload;
    });
  },
});

export const selectTemplateState = (state: RootState) => state.relatorioTemplateReducer;

export default relatorioTemplateSlice.reducer;
