import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../../../app/rootReducer';
import armazemService from '../../../services/sementes/armazem';
import { Armazem } from '../../../@types/sementes/armazem';
import promiseWithLoaderAndMessages from '../../../utilities/promise-with-loader-and-messages';
import { ListReturnType } from '../../../services/queryable-base';
import {
  falhaAoCarregar,
  falhaAoExcluir,
  toastSaveMessages,
  excluidoComSucesso,
} from '../../../utilities/default-confirmation-messages';

interface ArmazemState {
  armazens: Armazem[],
  currentArmazem: Armazem | undefined,
}

const initialState: ArmazemState = {
  armazens: [],
  currentArmazem: undefined,
};

export const fetchArmazens = createAsyncThunk<ListReturnType<Armazem>>(
  `${armazemService.endpoint}/list`,
  (_, { dispatch }) => (
    promiseWithLoaderAndMessages(
      dispatch,
      armazemService.listAll(),
      { errorMessage: falhaAoCarregar('armazéns') },
    )
  ),
);

export const deleteArmazem = createAsyncThunk(
  `${armazemService.endpoint}/delete`,
  async (id: string, { dispatch }) => {
    await promiseWithLoaderAndMessages(
      dispatch,
      armazemService.delete(id),
      {
        successMessage: excluidoComSucesso('armazém'),
        errorMessage: (error: any) => {
          const errors = error?.response?.data;
          return errors ? {
            summary: 'Erro',
            detail: `Falha ao excluir: ${errors}`,
          } : falhaAoExcluir('armazém');
        },
      },
    );

    return id;
  },
);

export const createOrUpdateArmazem = createAsyncThunk(
  `${armazemService.endpoint}/createOrUpdate`,
  (data: Parameters<typeof armazemService.createOrUpdate>[0], { dispatch, rejectWithValue }) => (
    promiseWithLoaderAndMessages(
      dispatch,
      armazemService.createOrUpdate(data),
      toastSaveMessages('armazém'),
    ).catch((error) => rejectWithValue(error.response.data))
  ),
);

export const armazemSlice = createSlice({
  name: 'armazem',
  initialState,
  reducers: {
    setCurrentArmazem: (state, action: PayloadAction<Armazem | undefined>) => {
      state.currentArmazem = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchArmazens.fulfilled, (state, { payload }) => {
      state.armazens = payload.data;
    });
  },
});

export const selectArmazemState = (state: RootState) => state.armazemReducer;

export const { setCurrentArmazem } = armazemSlice.actions;

export default armazemSlice.reducer;
