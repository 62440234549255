import React, {
  FC,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Dialog,
  Dropdown,
  InputText,
  Button,
  Messages,
} from '@agro1desenvolvimento/react-components';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ptForm } from 'yup-locale-pt';
import classNames from 'classnames';
import { sortBy } from 'lodash';
import { selectTopBarState } from '../../../../components/Topbar/topBarSlice';
import CamposProducaoService from '../../../../services/sementes/campos-producao';
import { promiseWithLoaderAndMessages, transitionOptionsTimeout as timeout } from '../../../../utilities';
import { fetchEspecies, selectEspecieState } from '../../../geral/Especies/especiesSlice';
import ShowErrorHelper from '../../../../components/ShowErrorHelper';
import { fetchPeriodos, selectPeriodoState } from '../../../geral/Periodos/periodosSlice';

const Footer: React.FC<{close: () => void, valid: boolean}> = ({ close, valid }) => (
  <div>
    <Button label="Cancelar" icon="pi pi-times" onClick={close} className="p-button-text" />
    <Button label="Sincronizar" disabled={!valid} icon="pi pi-check" autoFocus type="submit" form="form-sync-sigef" />
  </div>
);

Yup.setLocale(ptForm);
const SyncSigefSchema = Yup.object().shape({
  especie: Yup.string().required(),
  periodo: Yup.string().required(),
  safraLocalId: Yup.string().required(),
  produtorId: Yup.string().required(),
});

interface CamposProducaoSyncSigefPageProps {
  close: () => void;
}

const CamposProducaoSyncSigefPage: FC<CamposProducaoSyncSigefPageProps> = ({ close }) => {
  const history = useHistory();
  const { especies } = useSelector(selectEspecieState);
  const { periodos } = useSelector(selectPeriodoState);
  const messages = useRef<Messages>(null);
  const dispatch = useDispatch();
  const [modalVisible, setModalVisible] = useState(true);

  const fechaModal = () => {
    close();
    setModalVisible(false);
  };

  const {
    currentSafra,
    currentProdutor,
  } = useSelector(selectTopBarState);

  const goToList = () => {
    history.push({
      pathname: '/sementes/campos_producao',
      search: location.search,
    });
  };

  useEffect(() => {
    dispatch(fetchEspecies());
    dispatch(fetchPeriodos());
  }, []);

  if (!currentSafra || !currentProdutor) {
    history.replace({
      pathname: '/sementes/campos_producao',
      search: location.search,
    });
    return null;
  }

  const formik = useFormik({
    validationSchema: SyncSigefSchema,
    initialValues: {
      especie: '',
      periodo: '',
      safraLocalId: currentSafra.id,
      produtorId: currentProdutor.id,
    },
    onSubmit: async (values) => {
      try {
        await promiseWithLoaderAndMessages(
          dispatch,
          CamposProducaoService.sync({
            especieId: values.especie,
            periodo: values.periodo,
            safraLocalId: values.safraLocalId,
            produtorId: values.produtorId,
          }),
          {
            successMessage: 'A sincronização com o SIGEF foi concluída com sucesso',
          },
        );
        fechaModal();
      } catch (error) {
        console.error(error);
        messages.current?.show([
          {
            summary: error.response.status === 400 ? error.response.data : 'Falha ao sincronizar campos',
            closable: true,
            severity: 'error',
          },
        ]);
      }
    },
  });

  return (
    <Dialog
      onHide={fechaModal}
      transitionOptions={{ timeout, onExited: goToList }}
      visible={modalVisible}
      maximizable
      header="Sincronização de campos com SIGEF"
      className="dialog-md"
      footer={(
        <Footer
          close={fechaModal}
          valid={formik.dirty && formik.isValid}
        />
      )}
    >
      <form onSubmit={formik.handleSubmit} id="form-sync-sigef">
        <div className="p-fluid">

          <div className="p-field p-disabled">
            <label htmlFor="safraLocalId">Safra Local</label>
            <InputText
              name="safraLocalId"
              id="safraLocalId"
              value={currentSafra?.descricao}
              readOnly
            />
          </div>

          <div className="p-field p-disabled">
            <label htmlFor="safraLocalId">Produtor</label>
            <InputText
              name="produtorId"
              id="produtorId"
              value={currentProdutor?.nome}
              readOnly
            />
          </div>

          <div className="p-field">
            <label aria-labelledby="especie" htmlFor="especie">Espécie</label>
            <Dropdown
              autoFocus
              value={formik.values.especie}
              name="especie"
              id="especie"
              options={sortBy(especies, ['descricao'])}
              optionLabel="descricao"
              optionValue="id"
              onChange={formik.handleChange}
              required
              className={classNames({ 'p-invalid': formik.errors.especie, 'p-disabled': especies.length === 0 })}
              placeholder="Selecione a espécie"
              appendTo={document.body}
              aria-describedby="especie-help"
            />
            <ShowErrorHelper id="especie-help" error={formik.errors.especie} />
          </div>

          <div className="p-field">
            <label aria-labelledby="periodo" htmlFor="periodo">Período</label>
            <Dropdown
              value={formik.values.periodo}
              name="periodo"
              id="periodo"
              appendTo={document.body}
              options={periodos}
              optionLabel="descricao"
              optionValue="id"
              disabled={!periodos.length}
              onChange={formik.handleChange}
              placeholder="Selecione o período"
              filter
              className={classNames({ 'p-invalid': formik.errors.periodo })}
              aria-describedby="periodo-help"
            />
            <ShowErrorHelper id="periodo-help" error={formik.errors.periodo} />
          </div>
        </div>
      </form>
      <Messages ref={messages} />
    </Dialog>
  );
};

export default CamposProducaoSyncSigefPage;
