import { FC } from 'react';

type ShowErrorHelperOptions = {
 id: string,
 error: string | undefined,
};

const ShowErrorHelper: FC<ShowErrorHelperOptions> = ({ id, error }) => (
  <small id={id} className="p-error p-d-block show-error-helper">{error}</small>
);

export default ShowErrorHelper;
