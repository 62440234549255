import { Route } from 'react-router-dom';
import Novo from './Novo';
import Header from './components/Header';
import DescartesList from './components/List';

const SubprodutoPage = () => (
  <div className="p-grid">
    <div className="p-col-12">
      <div className="card">
        <Header />
        <h1>Descarte para subprodutos</h1>
        <Route path="/sementes/subproduto" exact>
          <DescartesList />
        </Route>
        <Route path="/sementes/subproduto/descarte/simulacao">
          <Novo />
        </Route>
      </div>
    </div>
  </div>
);

export default SubprodutoPage;
