import {
  FC, useEffect, useRef, useState,
} from 'react';
import dayjs from 'dayjs';
import { Button, Calendar } from '@agro1desenvolvimento/react-components';
import { FilterFieldProps } from './FilterFieldProps';
import { dateAmericanFormat } from '../../utilities';

const FilterDateInterval: FC<FilterFieldProps> = ({
  filterField, setFilterConditions, filterConditions,
}) => {
  const [value, setValue] = useState<Date[]>([]);
  const calendarRef = useRef<Calendar>(null);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const values = filterConditions[filterField.field];
    if (!values) return;

    setValue(values.map((v) => dayjs(v.value).toDate()));
  }, []);

  useEffect(() => {
    if (value?.length !== 2) {
      setFilterConditions(filterField.field, []);
    } else {
      setFilterConditions(filterField.field, [
        {
          field: filterField.field,
          value: `${dateAmericanFormat(value[0])} 00:00:00`,
          operator: 'gteq',
        },
        {
          field: filterField.field,
          value: `${dateAmericanFormat(value[1] || value[0])} 23:59:59`,
          operator: 'lteq',
        },
      ]);
    }
  }, [value]);

  const footerTemplate = () => (
    <Button
      label="Selecionar"
      tooltip="Selecionar"
      title="Selecionar"
      onClick={() => {
        setVisible(false);
      }}
    />
  );

  return (
    <span className="p-float-label">
      <Calendar
        id={`filter-${filterField.field}`}
        visible={visible}
        ref={calendarRef}
        value={value}
        onChange={(e) => setValue(e.value as Date[])}
        selectionMode="range"
        showButtonBar
        placeholder={filterField.label}
        footerTemplate={footerTemplate}
        onVisibleChange={() => setVisible(!visible)}
      />
      <label htmlFor={`filter-${filterField.field}`}>{filterField.label}</label>
    </span>
  );
};
export default FilterDateInterval;
