import {
  DataTable,
  Column,
  Tag,
  ConfirmPopup,
  Badge,
  PaginatorPageState,
} from '@agro1desenvolvimento/react-components';
import { useDispatch, useSelector } from 'react-redux';
import { FC, useState } from 'react';
import { useIsMobile } from '@agro1desenvolvimento/react-hooks';
import { useHistory } from 'react-router-dom';
import {
  deletePlanejamento, selectPlanejamentoState, setPlanejamentosSelecionados,
} from '../planejamentosSlice';
import { Planejamento } from '../../../../@types/sementes/planejamento';
import DropwDownButton from '../../../../components/DropwDownButtons';
import { AuditoriaType } from '../../../../@types/enums';
import { formatWithoutTime, getIncrementedUrlPath } from '../../../../utilities';
import Filters from './Filters';
import Pagination from '../../../../components/Pagination';
import { usePagination } from '../../../../hooks';
import { setMode } from '../newEditPlanejamentoSlice';

const PlanejamentosList: FC<PropTypes> = ({ onDestroy }) => {
  const dispatch = useDispatch();
  const {
    planejamentos, planejamentosSelecionados, pagination,
  } = useSelector(selectPlanejamentoState);
  const {
    maxPerPage = 1,
    totalRecords,
    pages,
  } = pagination;
  const isMobile = useIsMobile();
  const [planejamentoToDestroy, setPlanejamentoToDestroy] = useState<Planejamento>();
  const history = useHistory();
  const { firstIndexOnPage, goToPage } = usePagination({ maxPerPage });

  const onPageChange = (e: PaginatorPageState) => {
    goToPage(e.page + 1);
  };

  const renderStatus = (planejamento: Planejamento) => {
    const severityStatus = {
      criado: 'warning',
      lotes_planejados: 'info',
      finalizado: 'success',
    };
    return (
      <Tag value={planejamento.status} severity={severityStatus[planejamento.status]} />
    );
  };

  const renderQuantidadeLotes = (planejamento: Planejamento) => (
    planejamento.quantidadeLotes ? <Badge value={planejamento.quantidadeLotes} /> : 'N/A'
  );

  const customActions = (planejamento: Planejamento) => [
    {
      command: () => {
        dispatch(setMode('list'));
        history.push({
          pathname: getIncrementedUrlPath(`${planejamento.id}/lotes`),
          search: location.search,
        });
      },
      label: 'Lotes',
      icon: 'pi pi-fw pi-inbox',
    },
    {
      command: () => history.push({
        pathname: getIncrementedUrlPath(`${planejamento.id}/duplicar`),
        search: location.search,
      }),
      label: 'Duplicar',
      icon: 'pi pi-fw pi-clone',
    },
  ];

  const optionButtonId = (id: string | undefined) => `btn-option-${id}`;

  const renderActions = (planejamento: Planejamento) => (
    <div>
      <DropwDownButton
        editAction={() => history.push({
          pathname: getIncrementedUrlPath(`${planejamento.id}/editar`),
          search: location.search,
        })}
        deleteAction={() => setPlanejamentoToDestroy(planejamento)}
        auditoriaAction={() => history.push(`/auditoria/${AuditoriaType.planejamentos}/${planejamento.id}`)}
        customActions={customActions(planejamento)}
        optionButtonId={optionButtonId(planejamento.id)}
      />
    </div>
  );

  const destroy = async () => {
    if (!planejamentoToDestroy) return;

    const success = (await dispatch(deletePlanejamento(planejamentoToDestroy))).meta.requestStatus === 'fulfilled';

    if (success) onDestroy();
  };

  return (
    <>
      <ConfirmPopup
        target={document.getElementById(optionButtonId(planejamentoToDestroy?.id)) || undefined}
        visible={!!planejamentoToDestroy}
        onHide={() => setPlanejamentoToDestroy(undefined)}
        message="Deseja realmente excluir?"
        icon="pi pi-exclamation-triangle"
        accept={() => destroy()}
        acceptLabel="Sim"
        rejectLabel="Não"
      />

      <DataTable
        value={planejamentos}
        emptyMessage="Nenhum registro encontrado."
        selection={planejamentosSelecionados}
        selectionMode="multiple"
        onSelectionChange={(e) => dispatch(setPlanejamentosSelecionados(e.value))}
        dataKey="id"
        className="planejamento-lista"
        header={<Filters />}
        responsiveLayout="scroll"
      >
        <Column selectionMode="multiple" />
        <Column field="numeroPlanejamento" header="Nº" sortable className={!isMobile ? 'p-column-width-6' : ''} bodyClassName="cy-column-number-planning" />
        {isMobile || <Column body={(planejamento: Planejamento) => formatWithoutTime(planejamento.data)} header="Data" sortable className="p-column-width-6" bodyClassName="cy-column-date" />}
        {isMobile || <Column field="cultivar.especie.descricao" header="Espécie" sortable />}
        {isMobile || <Column field="cultivar.descricaoCategoria" header="Cultivar" sortable />}
        {isMobile || <Column field="ubs.descricao" header="UBS" sortable />}
        {isMobile || <Column field="depositoMateriaPrima.descricao" header="Depósito Matéria Prima" sortable />}
        {isMobile || <Column field="depositoDestino.descricao" header="Depósito Destino" sortable />}
        {isMobile || <Column body={renderQuantidadeLotes} header="QTD. Lotes" sortable sortField="quantidadeLotes" />}
        <Column body={renderStatus} header="Status" sortable sortField="status" />
        <Column header="Ações" body={renderActions} className="acoes-column" />
      </DataTable>
      <Pagination
        pages={pages}
        first={firstIndexOnPage}
        rows={maxPerPage}
        totalRecords={totalRecords}
        onPageChange={(e) => onPageChange(e)}
        pageRecords={planejamentos.length}
      />
    </>
  );
};

interface PropTypes {
  onDestroy: () => void
}

export default PlanejamentosList;
