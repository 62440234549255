import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../../../app/rootReducer';
import UnidadesService from '../../../services/estoque/unidades';
import { AsyncThunkParams } from '../../../@types/redux';
import { Unidade } from '../../../@types/estoque/unidade';
import promiseWithLoaderAndMessages from '../../../utilities/promise-with-loader-and-messages';
import { falhaAoCarregar } from '../../../utilities/default-confirmation-messages';

interface UnidadeState {
  unidades: Unidade[],
}

const initialState: UnidadeState = {
  unidades: [],
};

export const fetchUnidades = createAsyncThunk<
  Unidade[],
  AsyncThunkParams | undefined
>(
  'unidade/list',
  (_, { dispatch }) => promiseWithLoaderAndMessages(
    dispatch,
    UnidadesService.list(),
    { errorMessage: falhaAoCarregar('unidades') },
  ),
);

export const unidadesSlice = createSlice({
  name: 'unidade',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(fetchUnidades.fulfilled, (state, action) => {
      state.unidades = action.payload;
    });
  },
});

export const selectUnidadeState = (state: RootState) => state.unidadesReducer;

export default unidadesSlice.reducer;
