import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../../../app/rootReducer';
import tiposEmbalagensService from '../../../services/sementes/tipos-embalagens';
import { AsyncThunkParams } from '../../../@types/redux';
import { TipoEmbalagem } from '../../../@types/sementes/tiposEmbalagens';
import promiseWithLoaderAndMessages from '../../../utilities/promise-with-loader-and-messages';
import { falhaAoCarregar } from '../../../utilities/default-confirmation-messages';

interface TiposEmbalagensState {
  tiposEmbalagens: TipoEmbalagem[],
}

const initialState: TiposEmbalagensState = {
  tiposEmbalagens: [],
};

export const fetchTiposEmbalagens = createAsyncThunk<
  TipoEmbalagem[],
  AsyncThunkParams | undefined
>(
  'tiposEmbalagens/list',
  (_, { dispatch }) => promiseWithLoaderAndMessages(
    dispatch,
    tiposEmbalagensService.list(),
    { errorMessage: falhaAoCarregar('tipos de embalagem') },
  ),
);

export const tiposEmbalagensSlice = createSlice({
  name: 'tiposEmbalagens',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(fetchTiposEmbalagens.fulfilled, (state, action) => {
      state.tiposEmbalagens = action.payload;
    });
  },
});

export const selectTiposEmbalagensState = (state: RootState) => state.tiposEmbalagensReducer;

export default tiposEmbalagensSlice.reducer;
