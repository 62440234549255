import {
  FC,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Column, DataTable,
} from '@agro1desenvolvimento/react-components';
import LoteStatusTag from '../../../Lote/components/LoteStatusTag';
import { Lote } from '../../../../../@types/sementes/lote';
import LotesActionButtons from './LotesActionButtons';
import MovimentacaoLote from '../../../Planejamento/components/MovimentacaoLote';
import { selectConfirmacaoReembalagemState, setLotesMovimentados } from '../confirmacaoReembalagemSlice';
import { formatNumber } from '../../../../../utilities';
import { useScreenSize } from '../../../../../hooks';

const ListaLotesToConfirm: FC<{ visible: boolean }> = ({ visible }) => {
  const dispatch = useDispatch();
  const { isXL, isXXL } = useScreenSize();
  const {
    reembalagem,
    lotesConfirmados,
    lotesMovimentados,
  } = useSelector(selectConfirmacaoReembalagemState);
  const [selectedLote, setSelectedLote] = useState<Lote>();
  const { lotesDestino: lotes } = reembalagem!;

  return (
    <div hidden={!visible}>
      <DataTable
        stripedRows
        value={lotes}
        emptyMessage="Nenhum registro encontrado."
        className="p-datatable-hoverable-rows p-mt-5"
        dataKey="id"
        sortField="data"
        rowClassName={(lote:Lote) => ({ 'tr-success': lotesConfirmados.includes(lote) })}
        responsiveLayout="scroll"
      >
        <Column field="numeroLote" header="Nº do lote" sortable className="p-text-center" />
        <Column body={(lote: Lote) => lote.unidade?.abreviatura || 'N/D'} header="Unidade" className="p-text-center" />
        <Column
          body={(lote: Lote) => formatNumber(lote.quantidade, 1) || 'N/D'}
          header={(isXL || isXXL) ? 'Quantidade' : 'Qtd.'}
          className="p-text-center"
        />
        <Column
          body={(lote: Lote) => formatNumber(lote.pesoTotalKg || 0, 1)}
          header="Peso Total (kg)"
          className="p-text-center"
        />
        <Column body={(lote) => <LoteStatusTag lote={lote} />} header="Status" className="p-text-center" />
        <Column
          body={(lote: Lote) => (
            <LotesActionButtons
              lote={lote}
              confirmCallback={() => setSelectedLote(lote)}
            />
          )}
        />
      </DataTable>

      <MovimentacaoLote
        onHide={() => setSelectedLote(undefined)}
        lote={selectedLote}
        totalDisponivel={selectedLote?.quantidade || 0}
        onSubmit={(newLotesMovimentados) => (
          dispatch(setLotesMovimentados([...newLotesMovimentados, ...lotesMovimentados]))
        )}
      />
    </div>
  );
};

export default ListaLotesToConfirm;
