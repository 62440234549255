import { FC, useState } from 'react';
import {
  DataTable,
  Column,
  ConfirmPopup,
  PaginatorPageState,
} from '@agro1desenvolvimento/react-components';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Peneira } from '../../../@types/sementes/peneira';
import { deletePeneira, fetchPeneiras, selectPeneiraState } from './peneiraSlice';
import DropwDownButton from '../../../components/DropwDownButtons';
import { AuditoriaType } from '../../../@types/enums';
import { usePagination } from '../../../hooks';
import Pagination from '../../../components/Pagination';

const ListPeneiras: FC = () => {
  const { peneiras, pagination } = useSelector(selectPeneiraState);
  const {
    maxPerPage = 1,
    totalRecords,
    pages,
  } = pagination;
  const dispatch = useDispatch();
  const [selectedPeneira, setSelectedPeneira] = useState<Peneira | null>();
  const history = useHistory();
  const { goToPage, firstIndexOnPage } = usePagination({ maxPerPage });

  const confirm = async (id: string) => {
    if ((await dispatch(deletePeneira(id))).meta.requestStatus === 'fulfilled') {
      setSelectedPeneira(null);
      dispatch(fetchPeneiras({}));
    }
  };

  const onPageChange = (e: PaginatorPageState) => goToPage(e.page + 1);

  const optionButtonId = (id: string | undefined) => `btn-option-${id}`;

  const renderBtnAcoes = (peneira: Peneira) => (
    <div>
      <DropwDownButton
        editAction={() => history.push(`/sementes/peneiras/editar/${peneira.id}`)}
        deleteAction={() => setSelectedPeneira(peneira)}
        auditoriaAction={() => history.push(`/auditoria/${AuditoriaType.peneira}/${peneira.id}`)}
        optionButtonId={optionButtonId(peneira.id)}
      />
    </div>
  );

  return (
    <>
      <ConfirmPopup
        target={document.getElementById(optionButtonId(selectedPeneira?.id)) || undefined}
        visible={!!selectedPeneira}
        onHide={() => setSelectedPeneira(undefined)}
        message="Deseja realmente excluir?"
        icon="pi pi-exclamation-triangle"
        accept={() => confirm(selectedPeneira?.id as string)}
      />

      <DataTable
        value={peneiras}
        className="peneira-lista"
        emptyMessage="Nenhum registro encontrado."
        responsiveLayout="scroll"
        filterDisplay="row"
      >
        <Column
          header="Descrição"
          showFilterMenu={false}
          field="descricao"
          filter
          filterMatchMode="contains"
          sortable
          filterHeaderClassName="input-full-width"
          bodyClassName="p-col-4"
        />
        <Column header="Espécie" field="especie.descricao" sortable />
        <Column header="Ações" body={renderBtnAcoes} bodyClassName="p-col-2" />
      </DataTable>
      <Pagination
        first={firstIndexOnPage}
        pages={pages}
        rows={maxPerPage}
        totalRecords={totalRecords}
        onPageChange={onPageChange}
        pageRecords={peneiras.length}
      />
    </>
  );
};

export default ListPeneiras;
