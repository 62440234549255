import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../../../app/rootReducer';
import { Cidade } from '../../../@types/geral/cidade';
import promiseWithLoaderAndMessages from '../../../utilities/promise-with-loader-and-messages';
import { ListReturnType } from '../../../services/queryable-base';
import queryable, { Query } from '../../../services/queryable';
import { HeaderParams } from '../../../@types/headers';
import CidadesService from '../../../services/geral/cidades';
import { falhaAoCarregar } from '../../../utilities/default-confirmation-messages';

interface CidadeState {
  cidades: Cidade[],
  pagination: HeaderParams,
}

const initialState: CidadeState = {
  cidades: [],
  pagination: {
    currentPage: undefined,
    totalRecords: undefined,
    maxPerPage: undefined,
    pages: undefined,
  },

};

export const fetchCidades = createAsyncThunk<
  ListReturnType<Cidade>,
  undefined | Omit<Query<Cidade>, 'service'>
>(
  `${CidadesService.endpoint}/list`,
  (params = {}, { dispatch }) => {
    const query = queryable.query({
      ...params,
      service: CidadesService,
    });

    return promiseWithLoaderAndMessages(
      dispatch,
      query,
      { errorMessage: falhaAoCarregar('cidades') },
    );
  },
);

export const cidadesSlice = createSlice({
  name: 'cidade',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(fetchCidades.fulfilled, (state, { payload }) => {
      state.cidades = payload.data;
      state.pagination = payload.headers;
    });
  },
});

export const selectCidadeState = (state: RootState) => state.cidadesReducer;

export default cidadesSlice.reducer;
