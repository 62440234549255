import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route } from 'react-router-dom';
import { flatten } from 'lodash';
import Header from './Header';
import ListTemplates from './ListTemplates';
import NewEditTemplatePage from './NewEditTemplatePage';
import { fetchRelatoriosTemplate, selectTemplateState } from './relatorioTemplateslice';
import { selectFilterState } from '../../../components/Filter/filterSlice';
import { usePagination } from '../../../hooks';
import { scrollToTop } from '../../../utilities';

const RelatorioTemplatePage = () => {
  const dispatch = useDispatch();
  const { pagination: { maxPerPage = 1 } } = useSelector(selectTemplateState);
  const { relatorioTemplate } = useSelector(selectFilterState);
  const { currentPage } = usePagination({ maxPerPage });

  const dispatchFetchRelatorios = () => {
    scrollToTop();
    dispatch(fetchRelatoriosTemplate({
      conditions: flatten(Object.values(relatorioTemplate.conditions)),
      sorts: relatorioTemplate.sort ? [relatorioTemplate.sort] : undefined,
      extraParameters: { page: currentPage.toString() },
    }));
  };

  useEffect(() => {
    dispatchFetchRelatorios();
  }, [relatorioTemplate, currentPage]);

  return (
    <>
      <div className="p-grid">
        <div className="p-col-12">
          <div className="card">
            <Header />
            <h1>Templates</h1>
            <ListTemplates fetchRelatoriosTemplate={dispatchFetchRelatorios} />
          </div>
        </div>
      </div>
      <Route path="/geral/relatorio_template/:action(editar|novo)/:id?">
        <NewEditTemplatePage fetchRelatoriosTemplate={dispatchFetchRelatorios} />
      </Route>
    </>
  );
};
export default RelatorioTemplatePage;
