import {
  Button, Dialog, Dropdown, DropdownChangeParams, Panel,
} from '@agro1desenvolvimento/react-components';
import { useDispatch, useSelector } from 'react-redux';
import {
  useState,
  useMemo,
  useEffect,
} from 'react';
import {
  map,
  reject,
  some,
  uniq,
} from 'lodash';
import classNames from 'classnames';
import EditLoteForm, { PlanejamentoLoteFormFields } from '../../../Lote/EditLoteForm';
import ListaLotes from '../../components/ListaLotes';
import { selectNewReembalagensState, setLotesDestino } from '../newReembalagemSlice';
import { CrudCreateUpdateLote } from '../../../../../services/sementes/lotes';
import { showMessage } from '../../../../../components/Agro1Toast/toast';
import ShowErrorHelper from '../../../../../components/ShowErrorHelper';
import DropwDownButtons from '../../../../../components/DropwDownButtons';
import { Lote } from '../../../../../@types/sementes/lote';

const CriacaoNovosLotes = ({ saldoDisponivel }: { saldoDisponivel: number }) => {
  const dispatch = useDispatch();
  const { lotesDestino, lotesComLocalizacao } = useSelector(selectNewReembalagensState);
  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [enableSubmit, setEnableSubmitForm] = useState(false);
  const [numeroLote, setNumeroLote] = useState('');
  const [numeroLoteErrorMessage, setNumeroLoteErrorMessage] = useState<string>('');
  const [loteFormEnableSubmit, setLoteFormEnableSubmit] = useState(false);
  const closeCreateModal = () => {
    setCreateModalVisible(false);
    setNumeroLote('');
  };

  const loteBase = useMemo((): PlanejamentoLoteFormFields | undefined => {
    if (!lotesComLocalizacao?.length) return undefined;
    const {
      cultivar,
      ubs,
      periodo,
      peneira,
      especieId,
      dataValidade,
    } = lotesComLocalizacao[0].lote;

    return {
      cultivar,
      ubs,
      periodo: periodo || undefined,
      peneira: peneira?.id,
      especieId,
      dataLote: new Date().toISOString(),
      validade: new Date(dataValidade),
    };
  }, [lotesComLocalizacao]);

  const haveSameNumeroLoteAndUnidade = (lote: CrudCreateUpdateLote) => {
    const unidade = { id: lote.unidade.id };

    const inOrigens = some(lotesComLocalizacao, { 'lote.numeroLote': lote.numeroLote, unidade });
    const inDestinos = some(lotesDestino, { numeroLote: lote.numeroLote, unidade });

    return inOrigens || inDestinos;
  };

  const numeroLoteDropdown = (
    onChange: (option: DropdownChangeParams) => void, errors?: string,
  ) => (
    <>
      <Dropdown
        autoFocus
        value={numeroLote}
        onChange={
          (option) => {
            onChange(option);
            setNumeroLote(option.value);
          }
        }
        name="numeroLote"
        id="numeroLote"
        appendTo={document.body}
        options={uniq(map(lotesComLocalizacao, 'lote.numeroLote'))}
        placeholder="Número Lote"
        editable
        className={classNames({ 'p-invalid': !!errors })}
        aria-describedby="numero-lote-help"
      />
      <ShowErrorHelper id="numero-lote-help" error={numeroLoteErrorMessage || errors} />
    </>
  );
  const fieldsOptions = (
    {
      numeroLote: {
        render: numeroLoteDropdown,
      },
      peneira: { disabled: true },
      periodo: { disabled: true },
    }
  );

  const listActionButtons = (lote: Lote) => (
    <div className="p-text-center">
      <DropwDownButtons
        deleteAction={() => {
          dispatch(setLotesDestino(reject(lotesDestino, { numeroLote: lote.numeroLote })));
        }}
      />
    </div>
  );

  useEffect(() => {
    setNumeroLoteErrorMessage('');
  }, [numeroLote]);

  useEffect(() => {
    setEnableSubmitForm(loteFormEnableSubmit && !!numeroLote.length);
  }, [loteFormEnableSubmit, numeroLote]);

  const submitLote = async (lote: CrudCreateUpdateLote) => {
    lote.numeroLote = numeroLote;
    if (haveSameNumeroLoteAndUnidade(lote)) {
      setNumeroLoteErrorMessage('Já em uso para a unidade selecionada.');
      return;
    }

    if ((lote.pesoTotalKg || 0) <= saldoDisponivel) {
      dispatch(setLotesDestino([...lotesDestino, lote]));
      setNumeroLote('');
      closeCreateModal();
    } else {
      dispatch(showMessage({
        severity: 'error',
        summary: 'Erro',
        detail: 'O peso do lote ultrapassa o saldo disponível para reembalagem',
      }));
    }
  };

  return (
    <>
      <Panel
        className="p-mt-3"
        headerTemplate={(options) => (
          <div className={`${options.className} p-jc-between`}>
            <h3 className="p-mb-0">Novos Lotes</h3>
            <Button
              label="Novo lote"
              className="p-button-success"
              onClick={() => setCreateModalVisible(true)}
            />
          </div>
        )}
      >
        <ListaLotes lotes={lotesDestino} actionButtons={listActionButtons} />
      </Panel>
      <Dialog
        onHide={closeCreateModal}
        visible={createModalVisible}
        maximizable
        header="Lote - Novo"
        footer={(
          <div className="p-buttonset p-col">
            <Button label="Cancelar" icon="pi pi-times" onClick={closeCreateModal} className="p-button p-button-text p-m-0" />
            <Button
              label="Salvar"
              aria-label="salvar"
              disabled={!enableSubmit}
              icon="pi pi-check"
              autoFocus
              className="p-button"
              type="submit"
              form="form-lote"
            />
          </div>
    )}
      >
        <EditLoteForm
          lote={loteBase}
          showSubmitButton={false}
          setEnableSubmitForm={setLoteFormEnableSubmit}
          onSubmit={submitLote}
          fieldsOptions={fieldsOptions}
        />
      </Dialog>
    </>
  );
};

export default CriacaoNovosLotes;
