import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { promiseWithLoaderAndMessages } from '../../../utilities';
import type { RootState } from '../../../app/rootReducer';
import queryable, { Query } from '../../../services/queryable';
import { ListReturnType } from '../../../services/queryable-base';
import { HeaderParams } from '../../../@types/headers';
import { Produto } from '../../../@types/estoque/item';
import ProdutosService from '../../../services/estoque/produtos';
import { falhaAoCarregar } from '../../../utilities/default-confirmation-messages';

interface ProdutosState {
  produtos: Produto[],
  pagination: HeaderParams,
}

const initialState: ProdutosState = {
  produtos: [],
  pagination: {
    currentPage: undefined,
    totalRecords: undefined,
    maxPerPage: undefined,
    pages: undefined,
  },
};

export const fetchProdutos = createAsyncThunk<
  ListReturnType<Produto>,
  undefined | Omit<Query<Produto>, 'service'>
>(
  `${ProdutosService.endpoint}/list`,
  async (params = {}, { dispatch }) => {
    const query = queryable.query({
      ...params,
      service: ProdutosService,
    });

    return promiseWithLoaderAndMessages(
      dispatch,
      query,
      {
        errorMessage: falhaAoCarregar('produtos'),
        withLoader: false,
      },
    );
  },
);

export const produtosSlice = createSlice({
  name: 'produto',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(fetchProdutos.fulfilled, (state, { payload }) => {
      state.produtos = payload.data;
      state.pagination = payload.headers;
    });
  },
});

export const selectProdutoState = (state: RootState) => state.produtoReducer;

export default produtosSlice.reducer;
