import { loginService } from '@agro1desenvolvimento/apis-js-package';
import { TipoEquipamento } from '../../@types/laboratorio/equipamento';
import QueryableBase from '../queryable-base';

class TiposEquipamentosService extends QueryableBase<TipoEquipamento> {
  get endpoint() {
    return `/${loginService.scope}/sementes/tipos_equipamentos`;
  }
}

export default new TiposEquipamentosService();
