import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import scriptService from '../../../services/relatorio/script';
import { promiseWithLoaderAndMessages } from '../../../utilities';
import { TemplateScript } from '../../../@types/relatorio/script';
import type { RootState } from '../../../app/rootReducer';
import { AsyncThunkParams } from '../../../@types/redux';
import { ListReturnType } from '../../../services/queryable-base';
import { toastExcludeMessages, falhaAoCarregar, toastSaveMessages } from '../../../utilities/default-confirmation-messages';

interface TemplateScriptState {
  scripts: TemplateScript[],
}

const initialState: TemplateScriptState = {
  scripts: [],
};

export const fetchTemplateScripts = createAsyncThunk<
  ListReturnType<TemplateScript>, AsyncThunkParams | undefined
>(
  `${scriptService.endpoint}/list`,
  async (_, { dispatch }) => promiseWithLoaderAndMessages(
    dispatch,
    scriptService.listAll(),
    { errorMessage: falhaAoCarregar('scripts') },
  ),
);

export const deleteTemplateScript = createAsyncThunk(
  `${scriptService.endpoint}/delete`,
  async (id: string, { dispatch }) => {
    await promiseWithLoaderAndMessages(
      dispatch,
      scriptService.delete(id),
      toastExcludeMessages('script'),
    );

    return id;
  },
);

export const createOrUpdateScript = createAsyncThunk(
  `${scriptService.endpoint}/createOrUpdate`,
  (data: Parameters<typeof scriptService.createOrUpdate>[0], { dispatch, rejectWithValue }) => (
    promiseWithLoaderAndMessages(
      dispatch,
      scriptService.createOrUpdate(data),
      toastSaveMessages('script'),
    ).catch((error) => rejectWithValue(error.response.data))
  ),
);

export const templateScriptSlice = createSlice({
  name: 'templateScript',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(fetchTemplateScripts.fulfilled, (state, { payload }) => {
      state.scripts = payload.data;
    });
  },
});

export const selectScriptState = (state: RootState) => state.templateScriptReducer;

export default templateScriptSlice.reducer;
