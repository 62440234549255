import { FC } from 'react';
import type { CroquiItemComSaldo, OnLoteClick } from './CroquiMap';
import { getCroquiItemLabel, CROQUI_ELEMENT_ICONS, formatNumber } from '../../../../../utilities';

const getItemLabel = (item: CroquiItemComSaldo) => {
  if (item.tipo !== 'bloco') return '';
  return getCroquiItemLabel({ item });
};

const CroquiMapItem: FC<PropTypes> = ({ croquiItem, onLoteClick }) => {
  if (!croquiItem) return null;

  if (croquiItem.tipo !== 'bloco') {
    return (
      <div className="element-icon">
        <i className={CROQUI_ELEMENT_ICONS[croquiItem.tipo]} />
      </div>
    );
  }

  return (
    <>
      <p className="title p-mb-2">{getItemLabel(croquiItem)}</p>
      <div className="content">
        {croquiItem.saldos.map((saldo) => (
          <span
            key={saldo.lote.id}
            className="saldo"
            title="Movimentar"
            role="button"
            tabIndex={0}
            onClick={() => onLoteClick(croquiItem, saldo.lote)}
          >
            <span className="p-text-nowrap p-text-truncate p-mr-3">{saldo.lote.numeroLote}</span>
            <span>{formatNumber(saldo.saldo)} ({saldo.unidade.abreviatura})</span>
          </span>
        ))}
      </div>
    </>
  );
};

interface PropTypes {
  croquiItem: CroquiItemComSaldo | undefined,
  onLoteClick: OnLoteClick
}

export default CroquiMapItem;
