import { FC, useMemo } from 'react';
import { find } from 'lodash';
import { CampoProducao } from '../../../../@types/sementes/campoProducao';
import { SaldoCampoProducao } from '../../../../@types/sementes/saldoCampoProducao';
import { formatNumber } from '../../../../utilities';

const CampoProducaoItemTemplate: FC<CampoItemTemplateProps> = ({
  campoProducao, saldosCampoProducao,
}) => {
  const saldo = useMemo(() => find(saldosCampoProducao, ['campoProducaoId', campoProducao.id]),
    [saldosCampoProducao, campoProducao.id]);

  if (!saldo) return <div>{campoProducao.identificacaoSigef}</div>;

  const {
    saldoMateriaPrima,
    quantidadeLotesRascunho: qtdLotesRascunho,
    saldoLotesRascunho,
    quantidadeLotesConfirmados: qtdLotesConfirmados,
    saldoLotesConfirmados,
  } = saldo;

  return (
    <span className="campo-producao-template">
      <div>{campoProducao.identificacaoSigef}</div>
      <div className="cp-item-template">
        Saldo de matéria prima: {formatNumber(saldoMateriaPrima)}KG
      </div>
      <div className="cp-item-template">
        Lotes em beneficiamento: {formatNumber(qtdLotesRascunho)}&nbsp;
        ({formatNumber(saldoLotesRascunho)}KG)
      </div>
      <div className="cp-item-template">
        Lotes já beneficiados: {formatNumber(qtdLotesConfirmados)}&nbsp;
        ({formatNumber(saldoLotesConfirmados)}KG)
      </div>
    </span>
  );
};

interface CampoItemTemplateProps {
  campoProducao: CampoProducao,
  saldosCampoProducao: SaldoCampoProducao[],
}

export default CampoProducaoItemTemplate;
