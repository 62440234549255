import { FC, useState } from 'react';
import {
  DataTable,
  Column,
  ConfirmPopup,
  PaginatorPageState,
} from '@agro1desenvolvimento/react-components';
import { useDispatch, useSelector } from 'react-redux';
import { formatNumber } from '@agro1desenvolvimento/utils-js';
import { useHistory } from 'react-router-dom';
import EquipamentosService from '../../../../services/laboratorio/equipamentos';
import { Equipamento } from '../../../../@types/laboratorio/equipamento';
import { fetchEquipamentos, selectEquipamentoState } from '../equipamentoSlice';
import DropwDownButton from '../../../../components/DropwDownButtons';
import { AuditoriaType } from '../../../../@types/enums';
import { usePagination } from '../../../../hooks';
import Pagination from '../../../../components/Pagination';
import { promiseWithLoaderAndMessages } from '../../../../utilities';
import { toastExcludeMessages } from '../../../../utilities/default-confirmation-messages';

const ListEquipamentos: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { equipamentos, pagination } = useSelector(selectEquipamentoState);
  const { maxPerPage = 1, totalRecords, pages } = pagination;
  const [selectedEquipamento, setSelectedEquipamento] = useState<Equipamento>();
  const { goToPage, firstIndexOnPage } = usePagination({ maxPerPage });

  const confirm = async (id: string) => {
    await promiseWithLoaderAndMessages(
      dispatch,
      EquipamentosService.delete(id),
      toastExcludeMessages('equipamento'),
    );
    setSelectedEquipamento(undefined);
    dispatch(fetchEquipamentos());
  };

  const onPageChange = (e: PaginatorPageState) => goToPage(e.page + 1);

  const optionButtonId = (id: string | undefined) => `btn-option-${id}`;

  const renderBtnAcoes = (equipamento: Equipamento) => (
    <div>
      <DropwDownButton
        editAction={() => history.push(`/sementes/equipamentos/editar/${equipamento.id}`)}
        deleteAction={() => setSelectedEquipamento(equipamento)}
        auditoriaAction={() => history.push(`/auditoria/${AuditoriaType.equipamento}/${equipamento.id}`)}
        optionButtonId={optionButtonId(equipamento.id)}
      />
    </div>
  );

  return (
    <>
      <ConfirmPopup
        target={document.getElementById(optionButtonId(selectedEquipamento?.id)) || undefined}
        visible={!!selectedEquipamento}
        onHide={() => setSelectedEquipamento(undefined)}
        message="Deseja realmente excluir?"
        icon="pi pi-exclamation-triangle"
        accept={() => confirm(selectedEquipamento!.id)}
      />

      <DataTable value={equipamentos} emptyMessage="Nenhum registro encontrado." responsiveLayout="scroll">
        <Column header="Descrição" showFilterMenu={false} field="descricao" sortable bodyClassName="p-col-4" />
        <Column header="Tipo" field="tipo.descricao" sortable />
        <Column header="Casas decimais" body={(equipamento: Equipamento) => formatNumber(equipamento.casasDecimais)} sortField="casasDecimais" sortable />
        <Column header="Ações" body={renderBtnAcoes} bodyClassName="p-col-2" />
      </DataTable>

      <Pagination
        first={firstIndexOnPage}
        pages={pages}
        rows={maxPerPage}
        totalRecords={totalRecords}
        onPageChange={onPageChange}
        pageRecords={equipamentos.length}
      />
    </>
  );
};

export default ListEquipamentos;
