import { flatten } from 'lodash';
import { Route, useHistory } from 'react-router-dom';
import { useIsFirstRender } from '@agro1desenvolvimento/react-hooks';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import ListItensEstoque from './List';
import EditItemPage from './components/EditItemPage';
import { fetchItensEstoque, selectItensEstoqueState } from './itensEstoqueSlice';
import { selectFilterState } from '../../../components/Filter/filterSlice';
import { usePagination } from '../../../hooks';
import { scrollToTop } from '../../../utilities';

const ItensEstoquePage = () => {
  const history = useHistory();
  const isFirstRender = useIsFirstRender();
  const { pagination: { maxPerPage = 1 } } = useSelector(selectItensEstoqueState);
  const { itensEstoque: filterItensEstoque } = useSelector(selectFilterState);
  const dispatch = useDispatch();
  const { currentPage, goToPage } = usePagination({ maxPerPage });

  const dispatchFetchItensEstoque = (page = currentPage.toString()) => {
    scrollToTop();
    dispatch(fetchItensEstoque({
      conditions: flatten(Object.values(filterItensEstoque.conditions)),
      sorts: filterItensEstoque.sort ? [filterItensEstoque.sort] : undefined,
      extraParameters: {
        page,
      },
    }));
  };

  useEffect(() => {
    dispatchFetchItensEstoque();
  }, [currentPage]);

  useEffect(() => {
    if (isFirstRender) return;
    goToPage(1);
    dispatchFetchItensEstoque('1');
  }, [filterItensEstoque]);

  const onFormClose = () => {
    dispatchFetchItensEstoque('1');
    history.push({
      pathname: '/estoque/itens',
      search: history.location.search,
    });
  };

  return (
    <>
      <div className="p-grid">
        <div className="p-col-12">
          <div className="card">
            <h1>Itens de estoque</h1>
            <ListItensEstoque />
          </div>
        </div>
      </div>
      <Route path="/estoque/itens/:itemId/editar">
        <EditItemPage onClose={onFormClose} />
      </Route>
    </>
  );
};

export default ItensEstoquePage;
