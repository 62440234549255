import { QueryBuilder, VizState } from '@cubejs-client/react';
import { Card, InputSwitch } from '@agro1desenvolvimento/react-components';
import ChartRenderer from './ChartRenderer';
import SelectChartType from './SelectChartType';
import QueryMultiSelect from './QueryMultiSelect';
import TimeGroup from './TimeGroup';
import FilterGroup from './FilterGroup';
import OrderGroup from './OrderGroup';
import { ExtraVizState } from '../../../../@types/geral/dashboard';

const ExploreQueryBuilder: React.FC<PropTypes> = ({
  vizState,
  extraVizState,
  updateExtraVizState,
  onVizStateChanged,
  setIsValid,
}) => (
  <QueryBuilder
    initialVizState={vizState}
    wrapWithQueryRenderer={false}
    onVizStateChanged={onVizStateChanged}
    render={({
      measures,
      updateMeasures,
      availableMeasures,
      dimensions,
      updateDimensions,
      availableDimensions,
      segments,
      updateSegments,
      availableSegments,
      timeDimensions,
      updateTimeDimensions,
      availableTimeDimensions,
      filters,
      availableFilterMembers,
      updateFilters,
      chartType,
      updateChartType,
      isQueryPresent,
      orderMembers,
      updateOrder,
      pivotConfig,
      validatedQuery,
    }) => {
      setIsValid(isQueryPresent);

      return (
        <>
          <div className="p-grid">
            <QueryMultiSelect values={measures} updater={updateMeasures} options={availableMeasures} placeholder="Métricas" />
            <QueryMultiSelect values={dimensions} updater={updateDimensions} options={availableDimensions} placeholder="Dimensões" />
            <QueryMultiSelect values={segments} updater={updateSegments} options={availableSegments} placeholder="Segmentos" />

            <OrderGroup orderMembers={orderMembers} updateOrder={updateOrder} />
            <SelectChartType chartType={chartType} updateChartType={updateChartType} />

            <TimeGroup
              availableTimeDimensions={availableTimeDimensions}
              timeDimensions={timeDimensions}
              updateTimeDimensions={updateTimeDimensions}
            />

            <FilterGroup
              filters={filters}
              availableFilterMembers={availableFilterMembers}
              updateFilters={updateFilters}
            />
          </div>

          {vizState?.chartType === 'bar' && (
            <div className="p-grid p-my-auto">
              <div className="p-col-3">
                <b className="p-mx-2 p-my-auto">Exibir valores direto no gráfico:</b>
                <InputSwitch
                  onChange={({ value }) => updateExtraVizState({ displayValuesOnBarGraph: value })}
                  checked={extraVizState?.displayValuesOnBarGraph}
                />
              </div>
            </div>
          )}

          {isQueryPresent ? (
            <Card>
              <ChartRenderer
                vizState={{
                  query: validatedQuery,
                  chartType,
                  pivotConfig,
                }}
                extraVizState={extraVizState}
                chartTitle=""
              />
            </Card>
          ) : (
            <h2 style={{ textAlign: 'center' }}>
              Escolha a medida ou dimensão para começar.
            </h2>
          )}
        </>
      );
    }}
  />
);

interface PropTypes {
  vizState?: VizState,
  extraVizState?: ExtraVizState,
  updateExtraVizState: (extraVizState: ExtraVizState) => void,
  onVizStateChanged: (vizState: VizState) => void,
  setIsValid: (isValid: boolean) => void
}

export default ExploreQueryBuilder;
