import { loginService } from '@agro1desenvolvimento/apis-js-package';
import { Especie } from '../../@types/geral/especie';
import { HeaderParams } from '../../@types/headers';
import CrudBase from '../crud-base';

export interface EspecieListReturnType extends HeaderParams {
  data: Especie[];
}

class EspeciesService extends CrudBase<Especie> {
  get endpoint() {
    return `/${loginService.scope}/geral/especies`;
  }
}

export default new EspeciesService();
