import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import analisesService from '../../../../../services/sementes/analises';
import { Analise, AnaliseCreateUpdate, AnaliseTipoStruct } from '../../../../../@types/sementes/analises';
import type { RootState } from '../../../../../app/rootReducer';
import { promiseWithLoaderAndMessages } from '../../../../../utilities';
import { toastExcludeMessages, falhaAoCarregar, toastSaveMessages } from '../../../../../utilities/default-confirmation-messages';

const initialState: AnalisesState = {
  analises: [],
  tipoAnalises: [],
};

export const fetchAnalises = createAsyncThunk(
  'sementes/analises',
  ({ loteId, amostraId }: { loteId: string, amostraId: string }, { dispatch }) => (
    promiseWithLoaderAndMessages(dispatch, analisesService.list(loteId, amostraId), { errorMessage: falhaAoCarregar('análises') })
  ),
);

export const createOrUpdateAnalise = createAsyncThunk(
  'sementes/analises',
  ({ loteId, amostraId, analise }: AnaliseCreateUpdateProps, { dispatch }) => (
    promiseWithLoaderAndMessages(dispatch, analisesService.createOrUpdate(loteId, amostraId, analise), toastSaveMessages('análise'))
  ),
);

export const fetchAnalise = createAsyncThunk(
  'sementes/analises',
  (
    { loteId, amostraId, analiseId }: { loteId: string, amostraId: string, analiseId: string },
    { dispatch },
  ) => (
    promiseWithLoaderAndMessages(dispatch, analisesService.find(loteId, amostraId, analiseId), { errorMessage: falhaAoCarregar('análise') })
  ),
);

export const deleteAnalise = createAsyncThunk(
  'sementes/analises',
  (
    { loteId, amostraId, analise }: { loteId: string, amostraId: string, analise: Analise },
    { dispatch },
  ) => (
    promiseWithLoaderAndMessages(dispatch, analisesService.delete(loteId, amostraId, analise), toastExcludeMessages('análise'))
  ),
);

export const fetchTipoAnalises = createAsyncThunk(
  'sementes/tipos_analises',
  (_, { dispatch }) => (
    promiseWithLoaderAndMessages(dispatch, analisesService.tiposAnalises(), { errorMessage: falhaAoCarregar('tipos de análises') })
  ),
);

export const tipoAnalisesSlice = createSlice({
  name: 'analises',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(fetchAnalises.fulfilled, (state, action) => {
      state.analises = action.payload;
    });
    builder.addCase(fetchTipoAnalises.fulfilled, (state, action) => {
      state.tipoAnalises = action.payload;
    });
  },
});

export const selectTipoAnalisesState = (state: RootState) => state.tipoAnalisesReducer;

export default tipoAnalisesSlice.reducer;

interface AnalisesState {
  analises: Analise[],
  tipoAnalises: AnaliseTipoStruct[],
}

interface AnaliseCreateUpdateProps {
  loteId: string,
  amostraId: string,
  analise: AnaliseCreateUpdate,
}
