import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../../../app/rootReducer';
import PeriodosService from '../../../services/geral/periodos';
import { Periodo } from '../../../@types/geral/periodo';
import promiseWithLoaderAndMessages from '../../../utilities/promise-with-loader-and-messages';
import { ListReturnType } from '../../../services/queryable-base';
import { falhaAoCarregar } from '../../../utilities/default-confirmation-messages';

interface PeriodoState {
  periodos: Periodo[],
}

const initialState: PeriodoState = {
  periodos: [],
};

export const fetchPeriodos = createAsyncThunk<ListReturnType<Periodo>>(
  'periodo/list',
  (_, { dispatch }) => promiseWithLoaderAndMessages(
    dispatch,
    PeriodosService.listAll(),
    { errorMessage: falhaAoCarregar('períodos') },
  ),
);

export const periodosSlice = createSlice({
  name: 'periodo',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(fetchPeriodos.fulfilled, (state, { payload }) => {
      state.periodos = payload.data;
    });
  },
});

export const selectPeriodoState = (state: RootState) => state.periodosReducer;

export default periodosSlice.reducer;
