import { useHistory } from 'react-router-dom';
import { Button, Toolbar } from '@agro1desenvolvimento/react-components';

const Header = () => {
  const history = useHistory();

  return (
    <>
      <Toolbar left={(
        <Button
          label="Voltar"
          icon="pi pi-arrow-left"
          className="p-button-primary p-mr-2"
          onClick={() => history.push({
            pathname: '/sementes/lotes',
            search: location.search,
          })}
        />
      )}
      />
    </>
  );
};

export default Header;
