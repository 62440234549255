import { ThunkDispatch } from '@reduxjs/toolkit';
import { fetchCampos } from '../features/sementes/CamposProducao/camposProducaoSlice';

const dispatchFetchCamposProducao = async ({
  dispatch, cultivarId, produtorId, safraId, extraParameters,
} : Params) => {
  dispatch(fetchCampos({
    conditions: [
      { field: 'cultivar_id', operator: 'eq', value: cultivarId },
      { field: 'produtor_id', operator: 'eq', value: produtorId },
      { field: 'safra_id', operator: 'eq', value: safraId },
    ],
    sorts: [
      { field: 'identificacao_sigef', direction: 1 },
    ],
    extraParameters,
  }));
};

type Params = {
  dispatch: ThunkDispatch<any, any, any>,
  query?: string,
  extraParameters?: any,
  cultivarId: string,
  produtorId: string,
  safraId: string,
}
export default dispatchFetchCamposProducao;
