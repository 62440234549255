import React, {
  useRef,
  useMemo,
  useState,
  useEffect,
} from 'react';
import {
  Dialog,
  InputText,
  Button,
  Messages,
} from '@agro1desenvolvimento/react-components';
import classNames from 'classnames';
import * as Yup from 'yup';
import { ptForm } from 'yup-locale-pt';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { createOrUpdateArmazem, fetchArmazens, selectArmazemState } from '../armazemSlice';
import catchApiErrorsAndSetFormErrors from '../../../../utilities/catch-api-errors';
import ShowErrorHelper from '../../../../components/ShowErrorHelper';
import { transitionOptionsTimeout as timeout } from '../../../../utilities';

const Footer: React.FC<{close: () => void, valid: boolean}> = ({ close, valid }) => (
  <div>
    <Button label="Cancelar" icon="pi pi-times" onClick={close} className="p-button-text" />
    <Button label="Salvar" disabled={!valid} icon="pi pi-check" autoFocus type="submit" form="form-save-armazem" />
  </div>
);

Yup.setLocale(ptForm);
const ArmazemSchema = Yup.object().shape({
  descricao: Yup.string().required(),
});

const FormularioArmazem = () => {
  const messages = useRef<Messages>(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const [modalVisible, setModalVisible] = useState(true);
  const { currentArmazem } = useSelector(selectArmazemState);

  const close = () => {
    history.push('/sementes/armazens');
    dispatch(fetchArmazens());
  };

  const fechaModal = () => {
    setModalVisible(false);
  };

  const formik = useFormik({
    validationSchema: ArmazemSchema,
    initialValues: {
      id: currentArmazem?.id,
      descricao: currentArmazem?.descricao,
    },

    onSubmit: async (values) => {
      const promise = await dispatch(createOrUpdateArmazem(values));
      const success = promise.meta.requestStatus === 'fulfilled';
      if (success) {
        fechaModal();
      } else {
        catchApiErrorsAndSetFormErrors(formik.setFieldError, promise.payload, true);
      }
    },
  });

  const classes = useMemo(() => (
    classNames({ 'p-invalid': formik.errors.descricao })
  ), [formik.errors]);

  useEffect(() => {
    if (!currentArmazem) return;

    formik.setFieldValue('id', currentArmazem.id, false);
    formik.setFieldValue('descricao', currentArmazem.descricao, false);
  }, [currentArmazem]);

  return (
    <Dialog
      onHide={fechaModal}
      transitionOptions={{ timeout, onExited: close }}
      visible={modalVisible}
      maximizable
      header={currentArmazem?.id ? 'Alterar armazém' : 'Adicionar armazém'}
      className="dialog-md"
      footer={<Footer close={fechaModal} valid={formik.dirty && formik.isValid} />}
    >
      <form onSubmit={formik.handleSubmit} id="form-save-armazem">
        <div className="p-fluid">
          <div className="p-field">
            <label htmlFor="descricao">Descrição</label>
            <InputText
              autoFocus
              required
              name="descricao"
              id="descricao"
              value={formik.values.descricao}
              onChange={formik.handleChange}
              className={classes}
              aria-describedby="descricao-help"
            />
            <ShowErrorHelper id="descricao-help" error={formik.errors.descricao} />
          </div>
        </div>
      </form>
      <Messages ref={messages} />
    </Dialog>
  );
};

export default FormularioArmazem;
