import { Button, Toolbar } from '@agro1desenvolvimento/react-components';
import { FC } from 'react';
import { useHistory } from 'react-router-dom';

type amostrasProps = {
   loteId: string;
   amostraId: string;
};

const Header: FC<amostrasProps> = ({ loteId, amostraId }) => {
  const history = useHistory();

  const leftContents = (
    <>
      <Button
        label="Voltar"
        icon="pi pi-arrow-left"
        className="p-mr-2 p-button-primary"
        onClick={() => history.push(`/sementes/lotes/${loteId}/amostras`)}
      />

      <Button
        label="Novo"
        icon="pi pi-plus"
        className="p-ml-auto p-button-success"
        onClick={() => history.push(`/sementes/lotes/${loteId}/amostras/${amostraId}/analises/novo`)}
      />
    </>
  );

  return (
    <Toolbar left={leftContents} />
  );
};

export default Header;
