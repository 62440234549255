import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../../../app/rootReducer';
import PessoasService from '../../../services/geral/pessoas';
import { Pessoa, PessoaFuncao } from '../../../@types/geral/pessoa';
import promiseWithLoaderAndMessages from '../../../utilities/promise-with-loader-and-messages';
import { ListReturnType } from '../../../services/queryable-base';
import queryable, { Query } from '../../../services/queryable';
import { HeaderParams } from '../../../@types/headers';
import { falhaAoCarregar } from '../../../utilities/default-confirmation-messages';

interface PessoaState {
  pessoas: Pessoa[],
  funcoes: PessoaFuncao[],
  pagination: HeaderParams,
}

const initialState: PessoaState = {
  pessoas: [],
  funcoes: [],
  pagination: {
    currentPage: undefined,
    totalRecords: undefined,
    maxPerPage: undefined,
    pages: undefined,
  },

};

export const fetchPessoas = createAsyncThunk<
  ListReturnType<Pessoa>,
  undefined | Omit<Query<Pessoa>, 'service'>
>(
  `${PessoasService.endpoint}/list`,
  (params = {}, { dispatch }) => {
    const query = queryable.query({
      ...params,
      service: PessoasService,
    });

    return promiseWithLoaderAndMessages(
      dispatch,
      query,
      { errorMessage: falhaAoCarregar('pessoas') },
    );
  },
);

export const fetchPessoasFuncoes = createAsyncThunk<PessoaFuncao[]>(
  `${PessoasService.endpoint}/funcoes`,
  (_, { dispatch }) => promiseWithLoaderAndMessages(
    dispatch,
    PessoasService.funcoes(),
    { errorMessage: falhaAoCarregar('pessoas') },
  ),
);

export const pessoasSlice = createSlice({
  name: 'pessoa',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(fetchPessoas.fulfilled, (state, { payload }) => {
      state.pessoas = payload.data;
      state.pagination = payload.headers;
    });
    builder.addCase(fetchPessoasFuncoes.fulfilled, (state, { payload }) => {
      state.funcoes = payload;
    });
  },
});

export const selectPessoaState = (state: RootState) => state.pessoasReducer;

export default pessoasSlice.reducer;
