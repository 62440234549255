import { Button, Toolbar } from '@agro1desenvolvimento/react-components';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setCurrentArmazem } from '../armazemSlice';

const Header = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const goToNew = () => {
    dispatch(setCurrentArmazem(undefined));
    history.push('/sementes/armazens/novo');
  };

  return (
    <Toolbar left={(
      <Button
        label="Novo"
        aria-label="Novo"
        icon="pi pi-plus"
        onClick={goToNew}
        className="p-mr-2 p-button-success"
      />
    )}
    />
  );
};

export default Header;
