import { useMemo } from 'react';
import { useScreenClassification } from '@agro1desenvolvimento/react-hooks';

const useScreenSize = () => {
  const screenClassification = useScreenClassification();

  const isXXL = useMemo(() => screenClassification === 'xxl', [screenClassification]);
  const isXL = useMemo(() => screenClassification === 'xl', [screenClassification]);
  const isLG = useMemo(() => screenClassification === 'lg', [screenClassification]);
  const isMD = useMemo(() => screenClassification === 'md', [screenClassification]);
  const isSM = useMemo(() => screenClassification === 'sm', [screenClassification]);
  const isXS = useMemo(() => screenClassification === 'xs', [screenClassification]);

  return {
    isXXL, isXL, isLG, isMD, isSM, isXS,
  };
};

export default useScreenSize;
