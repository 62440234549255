import { flatten } from 'lodash';
import { Message } from '@agro1desenvolvimento/react-components';
import { useIsFirstRender } from '@agro1desenvolvimento/react-hooks';
import { useDispatch, useSelector } from 'react-redux';
import { Route, useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { selectFilterState } from '../../../components/Filter/filterSlice';
import { usePagination } from '../../../hooks';
import { scrollToTop } from '../../../utilities';
import ListReembalagens from './components/List';
import { fetchReembalagens, selectReembalagensState } from './reembalagemSlice';
import Header from './components/Header';
import { selectTopBarState } from '../../../components/Topbar/topBarSlice';
import ConfirmacaoReembalagemPage from './Confirmacao';
import { clearConfirmacaoReembalagemState } from './Confirmacao/confirmacaoReembalagemSlice';

const ReembalagensPage = () => {
  const isFirstRender = useIsFirstRender();
  const { pagination: { maxPerPage = 1 } } = useSelector(selectReembalagensState);
  const { reembalagens: filterReembalagens } = useSelector(selectFilterState);
  const dispatch = useDispatch();
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState('');
  const { currentProdutor, currentSafra } = useSelector(selectTopBarState);
  const { currentPage, goToPage } = usePagination({ maxPerPage });

  const loadReembalagens = (page = currentPage.toString()) => {
    if (!currentProdutor || !currentSafra) {
      setErrorMessage('Selecione safra/produtor.');
      return;
    }
    setErrorMessage('');

    scrollToTop();
    dispatch(fetchReembalagens({
      conditions: flatten(Object.values(filterReembalagens.conditions)),
      sorts: filterReembalagens.sort ? [filterReembalagens.sort] : undefined,
      extraParameters: {
        page,
        produtor: currentProdutor.id,
        safra: currentSafra.id,
      },
    }));
  };

  const closeConfirmationModal = () => {
    loadReembalagens('1');
    history.push({
      pathname: '/sementes/reembalagens',
      search: history.location.search,
    });
    dispatch(clearConfirmacaoReembalagemState());
  };

  useEffect(() => {
    loadReembalagens();
  }, [currentPage, currentProdutor, currentSafra]);

  useEffect(() => {
    if (isFirstRender) return;
    goToPage(1);
    loadReembalagens('1');
  }, [filterReembalagens]);

  return (
    <>
      <div className="p-grid">
        <div className="p-col-12">
          <div className="card">
            <Header />
            <h1>Reembalagens</h1>
            {
              errorMessage
                ? <Message severity="error" text={errorMessage} className="width-full p-justify-start" />
                : <ListReembalagens />
            }
          </div>
        </div>
      </div>
      <Route path="/sementes/reembalagens/:id/confirmar">
        <ConfirmacaoReembalagemPage closeModal={closeConfirmationModal} />
      </Route>
    </>
  );
};

export default ReembalagensPage;
