import React, {
  FC,
  SyntheticEvent,
  useState,
} from 'react';
import { Link } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import classNames from 'classnames';
import { MenuItem } from '@agro1desenvolvimento/react-components';

interface SubMenuProps {
  items: MenuItem[] | undefined,
  onItemClick?: (event: { originalEvent: SyntheticEvent, item: MenuItem }) => void,
  root?: boolean,
  className?: string
}

const SubMenu: FC<SubMenuProps> = ({
  items, onItemClick, className, root,
}) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const onMenuItemClick = (event: SyntheticEvent, item: MenuItem, index: number) => {
    if (!item.visible) {
      event.preventDefault();
      return true;
    }

    setActiveIndex(index === activeIndex ? null : index);

    if (item.command) item.command({ originalEvent: event, item });
    if (onItemClick) onItemClick({ originalEvent: event, item });
  };

  const renderLinkContent = (item: MenuItem) => {
    const submenuIcon = item.items && <i className="pi pi-fw pi-angle-down menuitem-toggle-icon" />;
    const badge = item.badge && <span className="menuitem-badge">{item.badge}</span>;

    return (
      <>
        <i className={item.icon} />
        <span>{item.label}</span>
        {submenuIcon}
        {badge}
      </>
    );
  };

  const renderLink = (item: MenuItem, i: number) => {
    const content = renderLinkContent(item);
    if (item.to) {
      return (
        <Link className={item.className} to={item.to}>
          {content}
        </Link>
      );
    }
    return (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <a role="button" onClick={(e) => onMenuItemClick(e, item, i)} target={item.target} tabIndex={0}>
        {content}
      </a>
    );
  };

  const menuItems = items && items.map((item, i) => {
    const active = activeIndex === i || (!activeIndex && item.expanded);
    const styleClass = classNames(item.badgeStyleClass, { 'active-menuitem': active && !item.to });

    return (
      <li className={styleClass} key={item.label}>
        {item.items && root === true}
        { item.visible && renderLink(item, i) }
        <CSSTransition classNames="p-toggleable-content" timeout={{ enter: 1000, exit: 500 }} in={active} unmountOnExit>
          <SubMenu items={item.items} onItemClick={onItemClick} />
        </CSSTransition>
      </li>
    );
  });

  return menuItems ? <ul className={className}>{menuItems}</ul> : null;
};

export default SubMenu;
