import {
  AutoComplete,
  Button,
  Column,
  DataTable,
  Message,
} from '@agro1desenvolvimento/react-components';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import {
  compact,
  find,
  map,
  sum,
} from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Cultivar } from '../../../../../@types/estoque/item';
import { SaldoCultivar } from '../../../../../@types/sementes/saldoCultivar';
import { selectTopBarState } from '../../../../../components/Topbar/topBarSlice';
import { promiseWithLoaderAndMessages, formatNumber } from '../../../../../utilities';
import { falhaAoCarregar } from '../../../../../utilities/default-confirmation-messages';
import SaldosDepositosService from '../../../../../services/sementes/saldos-depositos';
import DepositosService from '../../../../../services/estoque/depositos';
import SaldosCultivaresService from '../../../../../services/sementes/saldos-cultivares';
import dispatchFetchCultivares from '../../../../../utilities/dispatch-fetch-cultivares';
import { selectCultivarState } from '../../../../Estoque/Cultivar/cultivaresSlice';
import { SteepComponent } from '../../../VinculoLotesCamposProducao/types';
import SaldosPanel from '../../components/SaldosPanel';
import { Deposito } from '../../../../../@types/estoque/deposito';
import { clearSlice, goToStep, setFiltrosIniciais } from '../novoDescarteSlice';
import { SaldoDepositoSimplified } from '../../../../../@types/sementes/saldoDeposito';

const FiltrosDescarte: SteepComponent = forwardRef(({ setCanGoNext }, ref) => {
  const dispatch = useDispatch();
  const { currentSafra, currentProdutor } = useSelector(selectTopBarState);
  const { cultivares } = useSelector(selectCultivarState);
  const [cultivar, setCurrentCultivar] = useState<Cultivar>();
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [depositoOrigem, setCurrentDeposito] = useState<Deposito>();
  const [saldosGerais, setSaldosGerais] = useState<SaldoCultivar>();
  const [saldosByDeposito, setSaldosDepositos] = useState<SaldoDepositoSimplified[]>();

  const showSaldosGerais = useMemo(() => {
    if (!saldosGerais) return false;
    return sum(Object.values(saldosGerais)) > 0;
  }, [saldosGerais]);

  const params = useMemo(() => {
    if (!cultivar || !currentSafra || !currentProdutor) return undefined;
    return {
      cultivarId: cultivar.id, safraId: currentSafra.id, produtorId: currentProdutor.id,
    };
  }, [cultivar, currentSafra, currentProdutor]);

  const localizar = () => {
    if (!params) return;

    const loadSaldosCultivares = async () => {
      setSaldosGerais(await promiseWithLoaderAndMessages(
        dispatch,
        SaldosCultivaresService.saldos(params),
        { errorMessage: falhaAoCarregar('saldos de cultivares') },
      ));
    };

    const loadSaldosDepositos = async () => {
      const saldosDepositosSimplified = await promiseWithLoaderAndMessages(
        dispatch,
        SaldosDepositosService.saldos(params),
        { errorMessage: falhaAoCarregar('saldos de depósitos') },
      );

      const saldosWithDeposito = await promiseWithLoaderAndMessages(
        dispatch,
        Promise.all(map(saldosDepositosSimplified,
          async (saldoDeposito) => {
            const depositoFound = await DepositosService.find(saldoDeposito.depositoId);
            if (!depositoFound) return;
            return { ...saldoDeposito, deposito: depositoFound };
          })),
      );

      setShowErrorMessage(!saldosWithDeposito.length);
      setSaldosDepositos(compact(saldosWithDeposito));
    };

    loadSaldosCultivares();
    loadSaldosDepositos();
  };

  const fetchCultivaresByParams = (query?: string) => {
    dispatchFetchCultivares({
      dispatch,
      query,
      extraParameters: {
        safra: currentSafra?.id,
        produtor: currentProdutor?.id,
      },
    });
  };

  useImperativeHandle(ref, () => ({
    submit: () => {
      if (!depositoOrigem || !cultivar) return;
      const saldosDepositoOrigem = find(saldosByDeposito, { depositoId: depositoOrigem.id });

      if (!saldosDepositoOrigem) return;
      dispatch(setFiltrosIniciais({ depositoOrigem, cultivar, saldosDepositoOrigem }));
      dispatch(goToStep('next'));
    },
  }));

  useEffect(() => {
    setCanGoNext(!!(depositoOrigem && cultivar));
  }, [depositoOrigem, cultivar]);

  useEffect(() => {
    dispatch(clearSlice());
    setCurrentDeposito(undefined);
  }, [cultivar]);

  return (
    <>
      <div className="p-grid p-mt-1 p-mx-1">
        <AutoComplete
          dropdown
          autoFocus
          id="cultivarId"
          value={cultivar}
          name="cultivarId"
          placeholder="Cultivar"
          suggestions={cultivares}
          field="descricaoCategoria"
          onChange={(e) => setCurrentCultivar(e.target.value)}
          completeMethod={(e) => fetchCultivaresByParams(e.query)}
          appendTo={document.body}
        />
        <div className="p-my-auto" title={!cultivar?.id ? 'Cultivar é obrigatória' : ''}>
          <Button
            type="button"
            icon="pi pi-search"
            title="Localizar"
            label="Localizar"
            disabled={!cultivar?.id}
            onClick={localizar}
            className="p-button p-mx-1 btn-localizar"
          />
        </div>
      </div>
      <div className="p-mt-3 p-mx-1" hidden={!showErrorMessage}>
        <Message severity="error" text="Nenhum resultado encontrado para a cultivar" className="width-full p-justify-start" />
      </div>
      <div className="p-mt-4" hidden={!saldosByDeposito?.length}>
        <h1 className="p-p-0">Selecionar depósito de matéria prima</h1>
        <DataTable
          value={saldosByDeposito}
          showGridlines
          className="p-datatable-hoverable-rows"
          rowClassName={({ deposito }) => ({ 'tr-success': deposito.id === depositoOrigem?.id })}
          responsiveLayout="scroll"
        >
          <Column field="deposito.descricao" bodyClassName="cy-deposito" header="Depósito" />
          <Column body={({ saldo }) => formatNumber(saldo)} bodyClassName="cy-saldo" header="Saldo" />
          <Column
            bodyClassName="p-text-center"
            body={(depositoSaldo) => (
              <Button
                className="p-button-rounded p-button-success"
                onClick={() => setCurrentDeposito(depositoSaldo.deposito)}
                disabled={depositoOrigem?.id === depositoSaldo.deposito.id}
                aria-label="Selecionar"
                tooltip="Selecionar"
                title="Selecionar"
                tooltipOptions={{ position: 'top' }}
                icon="pi pi-fw pi-check"
              />
            )}
          />
        </DataTable>
      </div>
      <div className="p-mt-4" hidden={!showSaldosGerais}>
        <h1 className="p-p-0">Saldos de todos os depósitos</h1>
        <SaldosPanel saldosCultivares={saldosGerais} />
      </div>
    </>
  );
});

export default FiltrosDescarte;
