import { Dialog, Button } from '@agro1desenvolvimento/react-components';
import { isMobile } from '@agro1desenvolvimento/utils-js';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Planejamento } from '../../../@types/sementes/planejamento';
import lotesService from '../../../services/sementes/lotes';
import { promiseWithLoaderAndMessages, transitionOptionsTimeout as timeout } from '../../../utilities';
import { falhaAoCarregar } from '../../../utilities/default-confirmation-messages';
import EditLoteForm, {
  PlanejamentoLoteFormFields,
} from './EditLoteForm';

const Footer: React.FC<{
  isEnableSubmit: () => boolean,
  closeModal: () => void,
}> = ({
  isEnableSubmit,
  closeModal,
}) => (
  <div className="p-buttonset p-col">
    <Button label="Cancelar" icon="pi pi-times" onClick={closeModal} className="p-button p-button-text p-m-0" />
    <Button
      label="Salvar"
      aria-label="salvar"
      disabled={!isEnableSubmit()}
      icon="pi pi-check"
      autoFocus
      className="p-button"
      type="submit"
      form="form-lote"
    />
  </div>
);

const EditLotePage = ({ onClose }: { onClose: () => void }) => {
  const dispatch = useDispatch();
  const mobile = useMemo(isMobile, []);
  const [lote, setLote] = useState<PlanejamentoLoteFormFields|undefined>(undefined);
  const [planejamento, setPlanejamento] = useState<Planejamento>();
  const [visible, setVisible] = useState(false);
  const [isEnableSubmit, setEnableSubmitForm] = useState(false);
  const { loteId } = useParams<{ loteId: string}>();

  const fechaModal = () => setVisible(false);

  const loadLote = async () => {
    const loteFound = await promiseWithLoaderAndMessages(
      dispatch,
      lotesService.find(loteId),
      { errorMessage: falhaAoCarregar('lote') },
    );
    const tipoEmbalagem = await lotesService.fetchTipoEmbalagem(loteFound);

    setPlanejamento(loteFound.planejamento || undefined);
    setLote(lotesService.toEdit(loteFound, tipoEmbalagem));
  };

  useEffect(() => {
    loadLote();
  }, []);

  useEffect(() => {
    setVisible(!!lote);
  }, [lote]);

  return (
    <Dialog
      onHide={fechaModal}
      transitionOptions={{ timeout, onExited: onClose }}
      visible={visible}
      maximizable
      header="Lote - Editar"
      footer={(
        <Footer
          isEnableSubmit={() => isEnableSubmit}
          closeModal={fechaModal}
        />
      )}
      maximized={mobile}
      closeOnEscape={false}
    >
      <EditLoteForm
        lote={lote}
        showSubmitButton={false}
        setEnableSubmitForm={setEnableSubmitForm}
        afterSubmit={fechaModal}
        planejamento={planejamento}
      />
    </Dialog>
  );
};

export default EditLotePage;
