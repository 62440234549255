import { isMobile } from '@agro1desenvolvimento/utils-js';
import classNames from 'classnames';
import { useFormik } from 'formik';
import { find, sortBy } from 'lodash';
import {
  Dialog,
  Dropdown,
  InputText,
  Button,
} from '@agro1desenvolvimento/react-components';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { ptForm } from 'yup-locale-pt';
import { useParams } from 'react-router-dom';
import { Cultivar } from '../../../../@types/estoque/item';
import ShowErrorHelper from '../../../../components/ShowErrorHelper';
import itensService from '../../../../services/estoque/itens';
import { promiseWithLoaderAndMessages, transitionOptionsTimeout as timeout } from '../../../../utilities';
import { falhaAoCarregar, toastSaveMessages } from '../../../../utilities/default-confirmation-messages';
import { fetchObtentores, selectObtentoresState } from '../../../sementes/Obtentores/obtentoresSlice';
import { Obtentor } from '../../../../@types/sementes/obtentor';

const Footer: React.FC<{ isEnableSubmit: () => boolean, closeModal: () => void }> = ({
  isEnableSubmit, closeModal,
}) => (
  <div className="p-buttonset p-col">
    <Button label="Cancelar" icon="pi pi-times" onClick={closeModal} className="p-button p-button-text p-m-0" />
    <Button
      label="Salvar"
      aria-label="salvar"
      disabled={!isEnableSubmit()}
      icon="pi pi-check"
      autoFocus
      className="p-button"
      type="submit"
      form="form-item"
    />
  </div>
);

Yup.setLocale(ptForm);
const ItemSchema = Yup.object().shape({
  obtentorId: Yup.string().required(),
});

const EditItemPage = ({ onClose }: { onClose: () => void }) => {
  const dispatch = useDispatch();
  const mobile = useMemo(isMobile, []);
  const [item, setItem] = useState<Cultivar>();
  const [visible, setVisible] = useState(false);
  const { itemId } = useParams<{ itemId: string}>();
  const { obtentores } = useSelector(selectObtentoresState);
  const obtentoresSorted = useMemo(() => sortBy(obtentores, ['descricao']), [obtentores]);

  const fechaModal = () => setVisible(false);

  const loadItem = async () => {
    const itemFound = await promiseWithLoaderAndMessages(
      dispatch,
      itensService.find<Cultivar>(itemId),
      { errorMessage: falhaAoCarregar('item de estoque') },
    );
    setItem(itemFound);
  };

  useEffect(() => {
    loadItem();
    dispatch(fetchObtentores());
  }, []);

  useEffect(() => {
    setVisible(!!item);
    if (item) formItem.setFieldValue('obtentorId', item.obtentor?.id);
  }, [item]);

  const formItem = useFormik({
    validationSchema: ItemSchema,
    initialValues: {
      obtentorId: undefined,
    },
    onSubmit: async (values) => {
      await promiseWithLoaderAndMessages(
        dispatch,
        itensService.update({
          id: item?.id,
          obtentor: find(obtentores, { id: values.obtentorId }),
        }),
        toastSaveMessages('cultivar'),
      );
      fechaModal();
    },
  });

  const obtentorItemTemplate = (obtentor: Obtentor) => (
    <div className="obtentor-item p-d-flex">
      <div className="p-grid p-align-center">
        <img alt={obtentor.descricao} src={obtentor.logoUrl} width={60} className="flag" hidden={!obtentor.logoUrl} />
      </div>
      <div className="p-ml-3 p-grid p-align-center">
        {obtentor.descricao}
      </div>
    </div>
  );

  return (
    <Dialog
      onHide={fechaModal}
      transitionOptions={{ timeout, onExited: onClose }}
      visible={visible}
      maximizable
      header="Alterar item"
      className="dialog-md"
      footer={(
        <Footer
          isEnableSubmit={() => formItem.isValid}
          closeModal={fechaModal}
        />
      )}
      maximized={mobile}
      closeOnEscape={false}
    >
      <form onSubmit={formItem.handleSubmit} id="form-item">
        <div className="p-fluid">
          <div className="p-field p-disabled">
            <label htmlFor="safraLocalId">Descrição</label>
            <InputText
              name="descricao"
              id="descricao"
              value={item?.descricao}
              readOnly
            />
          </div>
          <div className="p-field">
            <label aria-labelledby="obtentor" htmlFor="obtentor">Obtentor</label>
            <Dropdown
              required
              autoFocus
              id="obtentorId"
              name="obtentorId"
              appendTo={document.body}
              value={formItem.values.obtentorId}
              filter
              filterBy="descricao"
              options={obtentoresSorted}
              optionValue="id"
              optionLabel="descricao"
              onChange={formItem.handleChange}
              className={classNames({ 'p-invalid': formItem.errors.obtentorId, 'p-disabled': obtentores.length === 0 })}
              placeholder="Selecione o obtentor"
              aria-describedby="obtentor-help"
              itemTemplate={obtentorItemTemplate}
            />
            <ShowErrorHelper id="obtentor-help" error={formItem.errors.obtentorId} />
          </div>
        </div>
      </form>
    </Dialog>
  );
};

export default EditItemPage;
