import Filter from '../../../components/Filter';
import { FilterField } from '../../../components/Filter/FilterField';
import AuditoriaTypesService from '../../../services/geral/auditoria/types';

const filters: FilterField[] = [
  {
    field: 'auditable_type',
    type: 'select',
    label: 'Tipo Auditoria',
    service: AuditoriaTypesService,
    optionKey: 'model',
    optionLabel: 'humanized',
  },
  { field: 'created_at', label: 'Período', type: 'dateInterval' },
  { field: 'username', type: 'string', label: 'Usuário' },
];

const Filters = () => (
  <Filter
    context="registrosExcluidos"
    filters={filters}
  />
);

export default Filters;
