import {
  Dialog,
  Steps,
  MenuItem,
} from '@agro1desenvolvimento/react-components';
import React, {
  FC, useEffect, useMemo, useState,
} from 'react';
import {
  generatePath, useHistory, useParams, useRouteMatch,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { map } from 'lodash';
import { useIsMobile } from '@agro1desenvolvimento/react-hooks';
import classNames from 'classnames';
import { selectTopBarState } from '../../../components/Topbar/topBarSlice';
import { crudModalTitle } from '../../../utilities/crud';
import type { PlanejamentoPageRouteParams } from './components/planejamentoPageRouteParams';
import PlanejamentoForm from './components/PlanejamentoForm';
import PlanejamentoLoteForm from './components/PlanejamentoLoteForm';
import LotesService from '../../../services/sementes/lotes';
import movimentoLoteArmazem from '../../../services/sementes/movimento-lote-armazem';
import { promiseWithLoaderAndMessages, downloader, transitionOptionsTimeout as timeout } from '../../../utilities';
import {
  setLotesConfirmados,
  setLotesMovimentados,
  setLotesExcluidos,
  selectNewEditPlanejamentoState,
  fetchPlanejamentoLotes,
  fetchPlanejamento,
  setPlanejamento,
  resetNewEditPlanejamentoState,
} from './newEditPlanejamentoSlice';
import SelectTemplateToPrint from '../../../components/SelectTemplateToPrint';
import { fetchTemplatesByTipo, selectTemplateState } from '../../Relatorio/RelatorioTemplate/relatorioTemplateslice';
import Footer from './components/Footer';
import { Lote } from '../../../@types/sementes/lote';
import { useScreenSize } from '../../../hooks';

const NewEditPlanejamentoPage: FC<PropTypes> = ({ fetchPlanejamentos, closeModal }) => {
  const { currentSafra, currentProdutor } = useSelector(selectTopBarState);
  const mobileDevice = useIsMobile();
  const history = useHistory();
  const routeMatch = useRouteMatch<PlanejamentoPageRouteParams>();
  const { params } = routeMatch;
  const dispatch = useDispatch();
  const {
    lotesConfirmados,
    lotesExcluidos,
    dataConfirmacao,
    formDirty,
    lotes,
    formIsValid,
    planejamento,
    mode,
    lotesMovimentados,
  } = useSelector(selectNewEditPlanejamentoState);
  const [modalVisible, setModalVisible] = useState(true);
  const [downloadTemplate, setDownloadTemplate] = useState(false);
  const { templates } = useSelector(selectTemplateState);
  const { isSM, isMD } = useScreenSize();
  const { action } = useParams<PlanejamentoPageRouteParams>();

  const fechaModal = () => {
    setModalVisible(false);
  };

  const print = async (templateId: string) => {
    setDownloadTemplate(false);
    const etiquetas = await promiseWithLoaderAndMessages(
      dispatch,
      LotesService.etiquetas(lotes.map((lote) => lote.id), templateId),
      {
        errorMessage: {
          summary: 'Erro',
          detail: 'Falha ao gerar etiquetas',
        },
      },
    );
    downloader(URL.createObjectURL(etiquetas), 'Etiquetas');
  };

  const changeRouteAction = (newAction: PlanejamentoPageRouteParams['action']) => (
    history.replace(generatePath(routeMatch.path, { action: newAction, id: params.id }))
  );

  const confirmLotes = async () => {
    const successfulConfirmation: Lote[] = [];
    const promises = map(lotesConfirmados, async (lote) => {
      await LotesService.update({ ...lote, status: 'analisando', dataLote: dataConfirmacao });
      successfulConfirmation.push(lote);
    });

    const promiseConfirmadosResult = await Promise.allSettled(promises);

    return { successfulConfirmation, promiseConfirmadosResult };
  };

  const deleteLotes = () => Promise.all(
    map(lotesExcluidos, (lote) => LotesService.delete(lote.id)),
  );

  const submitLotes = async (): Promise<void> => {
    const sendLotesChanges = async () => {
      const promisesExcluidos = deleteLotes();

      const { successfulConfirmation, promiseConfirmadosResult } = await confirmLotes();

      await movimentoLoteArmazem.create(
        lotesMovimentados.filter(
          ({ lote }) => successfulConfirmation.includes(lote),
        ),
      );

      const confirmationRejected = promiseConfirmadosResult.find(
        (result): result is PromiseRejectedResult => result.status === 'rejected',
      );
      if (confirmationRejected) {
        if (planejamento) {
          dispatch(fetchPlanejamentoLotes({ planejamento }));
          clearLotesList();
        }
        throw confirmationRejected.reason;
      }
      await promisesExcluidos;
    };

    await promiseWithLoaderAndMessages(
      dispatch,
      sendLotesChanges(),
      {
        withLoader: false,
        errorMessage: (error) => {
          const erro = error?.response?.data;
          return {
            summary: 'Erro',
            detail: `Falha ao confirmar/excluir lotes${erro ? `: ${erro}` : ''}`,
          };
        },
        successMessage: {
          summary: 'Salvo',
          detail: 'Lotes atualizados/excluídos com sucesso!',
        },
      },
    );
    fechaModal();
  };

  const clearLotesList = () => {
    dispatch(setLotesMovimentados([]));
    dispatch(setLotesConfirmados([]));
    dispatch(setLotesExcluidos([]));
    dispatch(fetchTemplatesByTipo('planejamento'));
  };

  const onCloseModal = () => {
    clearLotesList();
    closeModal();
  };

  const isEnableSubmitForm = () => (
    !!(lotesConfirmados.length || lotesExcluidos.length)
    || (formIsValid && !lotes.length)
  );

  const planejamentoActionName = useMemo(
    () => (params.action === 'lotes' ? 'Lotes' : crudModalTitle(params.action)),
    [params.action],
  );

  const stepsItems = useMemo<MenuItem[]>(() => [
    { label: 'Básico', command: () => changeRouteAction('editar') },
    { label: 'Lotes', command: () => changeRouteAction('lotes') },
  ], []);

  const stepsReadOnly = useMemo(
    () => formDirty || !['editar', 'lotes'].includes(params.action) || mode === 'newEdit',
    [formDirty, params.action, mode],
  );

  useEffect(() => {
    if (planejamento && action !== 'duplicar' && !lotes.length) {
      dispatch(fetchPlanejamentoLotes({ planejamento }));
    }
  }, [planejamento, lotes.length]);

  useEffect(() => {
    dispatch(
      params.id
        ? fetchPlanejamento({ planejamentoId: params.id })
        : setPlanejamento(undefined),
    );
  }, [params.id]);

  useEffect(() => {
    dispatch(fetchTemplatesByTipo('etiqueta'));

    return () => {
      dispatch(resetNewEditPlanejamentoState());
    };
  }, []);

  useEffect(() => {
    if (!currentSafra || !currentProdutor) closeModal();
  }, [currentSafra, currentProdutor]);

  return (
    <>
      <Dialog
        onHide={fechaModal}
        transitionOptions={{ timeout, onExited: onCloseModal }}
        visible={modalVisible}
        maximizable
        header={`Planejamento - ${planejamentoActionName}`}
        className={classNames('planejamento-lista p-lg-11 p-p-0 custom-steps', { 'p-dialog-maximized': (isSM || isMD) })}
        footer={(
          <Footer
            isEnableSubmitForm={isEnableSubmitForm}
            print={() => setDownloadTemplate(true)}
            submitLotes={submitLotes}
            action={params.action}
            closeModal={fechaModal}
          />
        )}
        maximized={mobileDevice}
        closeOnEscape={false}
      >
        <Steps
          model={stepsItems}
          readOnly={stepsReadOnly}
          activeIndex={+(params.action === 'lotes')}
        />

        <PlanejamentoForm
          visible={params.action !== 'lotes'}
          closeModal={fechaModal}
          fetchPlanejamentos={fetchPlanejamentos}
        />

        <PlanejamentoLoteForm visible={params.action === 'lotes'} />
      </Dialog>
      <SelectTemplateToPrint
        templates={templates}
        print={print}
        onClose={() => setDownloadTemplate(false)}
        targetId="btn-gerar-etiquetas"
        download={downloadTemplate}
      />
    </>
  );
};

interface PropTypes {
  fetchPlanejamentos: () => void,
  closeModal: () => void
}

export default NewEditPlanejamentoPage;
