import { createSlice } from '@reduxjs/toolkit';
import { isMobile } from '@agro1desenvolvimento/utils-js';
import type { RootState } from '../../app/rootReducer';

interface SideBarState {
  active: boolean,
}

const initialState: SideBarState = {
  active: !isMobile(),
};

export const sideBarSlice = createSlice({
  name: 'sideBar',
  initialState,
  reducers: {
    toggleActive: (state) => {
      state.active = !state.active;
    },
    deactivate: (state) => {
      state.active = false;
    },
    activate: (state) => {
      state.active = true;
    },
  },
});

export const { toggleActive, deactivate, activate } = sideBarSlice.actions;

export const selectSidebarActive = (state: RootState) => state.sideBarReducer.active;

export default sideBarSlice.reducer;
