import {
  forwardRef, useEffect, useImperativeHandle, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Column, DataTable } from '@agro1desenvolvimento/react-components';
import { map } from 'lodash';
import { selectTopBarState } from '../../../../components/Topbar/topBarSlice';
import { formatNumber, formatWithoutTime, promiseWithLoaderAndMessages } from '../../../../utilities';
import { SteepComponent } from '../types';
import DescartesService from '../../../../services/sementes/descartes';
import { goToStep, selectVinculoLotesCamposProducaoState, clearSlice } from '../VinculoLotesCamposProducaoSlice';
import { falhaAoCarregar } from '../../../../utilities/default-confirmation-messages';
import { LotesCampos, LotesCamposSimplified } from '../../../../@types/sementes/descarte';
import LotesService from '../../../../services/sementes/lotes';

const Simulacao: SteepComponent = forwardRef(({ setCanGoNext, visible }, ref) => {
  const dispatch = useDispatch();
  const { currentProdutor, currentSafra } = useSelector(selectTopBarState);
  const { tipoDeVinculo, filtros } = useSelector(selectVinculoLotesCamposProducaoState);
  const [lotes, setLotes] = useState<LotesCamposSimplified[]>([]);

  const confirmarVinculos = async () => {
    await promiseWithLoaderAndMessages(
      dispatch,
      LotesService.vinculaLotesCampos(lotes),
      {
        errorMessage: {
          summary: 'Erro',
          detail: 'Falha ao confirmar vínculos',
        },
        successMessage: {
          summary: 'Confirmado',
          detail: 'Vínculos confirmados com sucesso',
        },
      },
    );
    dispatch(clearSlice());
  };

  useEffect(() => {
    if (!visible || !currentSafra || !currentProdutor) return;

    setLotes([]);

    if (!tipoDeVinculo) {
      dispatch(goToStep('previos'));
      return;
    }

    const fetchSimulacaoVinculo = async () => {
      try {
        const result = await promiseWithLoaderAndMessages(
          dispatch,
          DescartesService.simulaVinculosLotes({
            safraId: currentSafra.id,
            produtorId: currentProdutor.id,
            tipo: tipoDeVinculo,
            cultivarId: filtros.cultivarId,
            depositoId: filtros.depositoOrigemId,
            camposProducaoIds: filtros.camposProducaoIds,
          }),
          { errorMessage: falhaAoCarregar('simulação de vínculos de lotes') },
        );
        setLotes(result.lotesCampos);
      } catch (error) {
        console.error(error);
      }
    };

    fetchSimulacaoVinculo();
  }, [visible]);

  useEffect(() => {
    setCanGoNext(true);
  }, [visible]);

  useImperativeHandle(ref, () => ({
    submit: () => confirmarVinculos(),
  }));

  return (
    <>
      <div className="p-col-12">
        <h4>Simulação</h4>
        <DataTable
          dataKey="id"
          value={lotes}
          selectionMode="multiple"
          className="p-datatable-gridlines"
          emptyMessage="Nenhum registro encontrado"
          responsiveLayout="scroll"
        >
          <Column
            header="Número do lote"
            body={(ltCampos: LotesCampos) => ltCampos.lote.numeroLote}
          />
          <Column
            header="Data do lote"
            body={(ltCampos: LotesCampos) => formatWithoutTime(ltCampos.lote.dataLote)}
          />
          <Column
            header="Campo(s) de produção"
            body={(ltCampos: LotesCampos) => map(ltCampos.campos, 'identificacaoSigef').join(', ')}
          />
          <Column
            header="Peso total do lote (KG)"
            body={(ltCampos: LotesCampos) => (
              formatNumber(ltCampos.lote.pesoTotalKg || 0, 1)
            )}
          />
        </DataTable>
      </div>
    </>
  );
});

export default Simulacao;
