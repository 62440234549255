import { loginService } from '@agro1desenvolvimento/apis-js-package';
import { SaldoDepositoSimplified } from '../../@types/sementes/saldoDeposito';
import api from '../erp-api';

interface Props {
  safraId: string,
  produtorId: string,
  cultivarId: string,
}
class SaldosDepositosService {
  get endpoint() {
    return `/${loginService.scope}/sementes/saldos_cultivar_depositos/`;
  }

  async saldos({
    safraId, produtorId, cultivarId,
  }: Props) {
    const { data } = await api.get<SaldoDepositoSimplified[]>(
      this.endpoint,
      {
        params: {
          safra_id: safraId,
          produtor_id: produtorId,
          cultivar_id: cultivarId,
        },
      },
    );
    return data;
  }
}

export default new SaldosDepositosService();
