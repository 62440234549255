export type CrudModalModelBase = 'novo' | 'editar' | 'duplicar' | 'visualizar';

export type CrudCreateUpdate<T extends Record<keyof any, any>> = Omit<T, 'createdAt' | 'updatedAt'| 'id'> & Partial<Pick<T, 'id'>>

export const crudModalTitle = (mode: CrudModalModelBase) => {
  switch (mode) {
    case 'novo':
    case 'duplicar':
      return 'Inserção';
    case 'editar':
      return 'Edição';
    case 'visualizar':
      return 'Visualizando';
    default:
      return '';
  }
};
