import { Tag } from '@agro1desenvolvimento/react-components';
import { FC, useMemo } from 'react';
import { Lote, LoteStatus } from '../../../../@types/sementes/lote';

enum SeverityStatus {
  rascunho = 'warning',
  analisando = 'info',
  disponivel = 'success',
  bloqueado = 'danger',
}

const LoteStatusTag: FC<PropsType> = (props) => {
  const status = useMemo(() => ('status' in props ? props.status : props.lote.status), [props]);

  return <Tag value={status} severity={SeverityStatus[status]} />;
};

type PropsType = { lote: Lote } | { status: LoteStatus }

export default LoteStatusTag;
