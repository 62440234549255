import {
  Button, Dialog, InputNumber, InputText, SelectButton,
} from '@agro1desenvolvimento/react-components';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import * as yup from 'yup';
import { Croqui, MatrizCroqui } from '../../../../../@types/sementes/croqui';
import { selectArmazemState, setCurrentArmazem } from '../../armazemSlice';
import ArmazemService from '../../../../../services/sementes/armazem';
import { salvoComSucesso } from '../../../../../utilities/default-confirmation-messages';
import { CROQUI_ELEMENT_ICONS, promiseWithLoaderAndMessages, getCroquiItemLabel } from '../../../../../utilities';
import CroquiSketch from '../../../../../components/CroquiSketch';
import ShowErrorHelper from '../../../../../components/ShowErrorHelper';
import { ItemProps, OnClickInCroquiItem } from '../../../../../components/CroquiSketch/CroquiSketchItem';
import { CroquiItem, CroquiTipo } from '../../../../../@types/sementes/croquiItem';
import { showMessage } from '../../../../../components/Agro1Toast/toast';
import CroquiService from '../../../../../services/estoque/croqui';
import { useYup } from '../../../../../hooks';

interface EditCroquiProps {
  croqui: Croqui;
}

const ELEMENTS: {value: ELEMENT, label: string, icon: string}[] = [
  { icon: CROQUI_ELEMENT_ICONS.bloco, value: 'bloco', label: 'Bloco' },
  { icon: CROQUI_ELEMENT_ICONS.porta, value: 'porta', label: 'Porta' },
  { icon: CROQUI_ELEMENT_ICONS.parede, value: 'parede', label: 'Parede' },
  { icon: 'fas fa-eraser', value: 'borracha', label: 'Borracha' },
];

const validacaoDescricao = yup
  .string()
  .required();

const submitRequestOptions = {
  errorMessage: (error: any) => ({
    summary: 'Erro',
    detail: `Falha ao salvar: ${Object.values(error?.response?.data) || ''}`,
  }),
  withLoader: false,
};

const EditCroqui: FC<EditCroquiProps> = ({ croqui }) => {
  const { currentArmazem } = useSelector(selectArmazemState);
  const [edit, setEdit] = useState<boolean>(false);
  const dispatch = useDispatch();
  const [quantidadeLinhas, setQuantidadeLinhas] = useState<number>(croqui.quantidadeLinhas);
  const [quantidadeColunas, setQuantidadeColunas] = useState<number>(croqui.quantidadeColunas);
  const [actualItem, setActualItem] = useState<ItemProps>();
  const [option, setOption] = useState<ELEMENT>('bloco');
  const [matrizCroqui, setCroquiMatriz] = useState<MatrizCroqui>([]);
  const [[descricao, setDescricao], descricaoError] = useYup(validacaoDescricao, '');
  const [submitting, setSubmitting] = useState(false);

  const saveItem = async ({ item, xIndex, yIndex }: ItemProps) => {
    const deleteItem = option === 'borracha';
    const descricaoChanged = option === 'bloco' && item?.descricao !== descricao;
    const deletingItem = deleteItem && !!item?.id;
    const changingItemTipo = !!option && item?.tipo !== option;
    const needsChange = deletingItem || changingItemTipo || descricaoChanged;

    const updateMatrizItem = (newItem: CroquiItem | undefined) => {
      setCroquiMatriz((v) => {
        v[xIndex][yIndex] = newItem;
        return [...v];
      });
    };

    if (!needsChange || !currentArmazem) return;

    setSubmitting(true);
    try {
      if (deleteItem) {
        await promiseWithLoaderAndMessages(
          dispatch,
          ArmazemService.deleteCroquiItem(currentArmazem.id, item!.id),
          submitRequestOptions,
        );

        updateMatrizItem(undefined);
      } else {
        const payloadForNewItem = {
          id: item?.id,
          posicao: {
            x: xIndex,
            y: yIndex,
          },
          tipo: option as CroquiTipo,
          descricao: option === 'bloco' ? descricao : '',
        };

        const newItem = await promiseWithLoaderAndMessages(
          dispatch,
          ArmazemService.createOrUpdateCroquiItem(currentArmazem.id, payloadForNewItem),
          submitRequestOptions,
        );

        updateMatrizItem(newItem);
      }
      dispatch(showMessage({
        summary: 'Salvo',
        detail: 'Alterações salvas',
        life: 700,
        closable: false,
        severity: 'success',
      }));
    } catch (error) {
      console.error(error);
    } finally {
      setSubmitting(false);
    }
  };

  const onCroquiItemClick: OnClickInCroquiItem = (params) => {
    if (option === 'bloco') {
      setActualItem(params);
      setDescricao(getCroquiItemLabel({
        x: params.xIndex,
        y: params.yIndex,
        item: params?.item,
      }));
    } else {
      saveItem(params);
    }
  };

  const updateCroqui = async () => {
    if (!currentArmazem) {
      setEdit(false);
      return;
    }

    const croquiUpdated = await promiseWithLoaderAndMessages(
      dispatch,
      ArmazemService.createOrUpdateCroqui(currentArmazem?.id as string, {
        quantidadeColunas,
        quantidadeLinhas,
      }),
      {
        errorMessage: (error) => ({
          summary: 'Erro',
          detail: `Falha ao salvar: ${Object.values(error?.response?.data)}`,
        }),
        successMessage: salvoComSucesso('croqui'),
      },
    );

    const newArmazem = {
      ...currentArmazem,
      croquiId: croquiUpdated.id,
    };

    dispatch(setCurrentArmazem(newArmazem));
    setEdit(false);
  };

  const submitDescricao = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (descricaoError) return;
    if (actualItem) saveItem(actualItem);

    setActualItem(undefined);
  };

  useEffect(() => {
    setCroquiMatriz(CroquiService.getMatriz(croqui));
  }, [croqui]);

  return (
    <>
      <Dialog
        header="Descrição do bloco"
        visible={!!actualItem}
        onHide={() => setActualItem(undefined)}
      >
        <form onSubmit={submitDescricao}>
          <InputText
            autoFocus
            name="descricaoBloco"
            id="descricaoBloco"
            placeholder="Descrição do bloco"
            value={descricao}
            onChange={(e) => setDescricao(e.target.value)}
            onFocus={(e) => e.target.select()}
            aria-describedby="descricao-bloco-help"
          />
          <Button
            icon="pi pi-check"
            className="p-button p-m-1"
            type="submit"
          />
          <ShowErrorHelper id="descricao-bloco-help" error={descricaoError} />
        </form>
      </Dialog>

      <div className={classNames({ submitting }, 'croqui-edit')}>
        <small>Selecione o elemento desejado para desenhar</small>
        <div className="elements p-shadow-1 p-d-flex p-jc-between p-flex-column p-flex-md-row p-flex-lg-row">
          <SelectButton
            value={option}
            onChange={(e) => setOption(e.value)}
            options={ELEMENTS}
            itemTemplate={(item) => (
              <div className="p-d-flex p-flex-column p-ai-center">
                <i className={classNames('fas', item.icon || item.value)} />
                <p>{item.label}</p>
              </div>
            )}
          />

          <div className={classNames({ 'p-d-flex': edit }, 'p-ai-center')} hidden={!edit}>
            <InputNumber
              autoFocus
              value={quantidadeLinhas}
              placeholder="Linhas"
              onValueChange={
            (e) => e.value && setQuantidadeLinhas(e.value)
          }
              showButtons
            />
            <span className="p-ml-1 p-mr-1">x</span>
            <InputNumber
              value={quantidadeColunas}
              placeholder="Colunas"
              onValueChange={(e) => e.value && setQuantidadeColunas(e.value)}
              showButtons
            />
            <Button
              className="p-ml-1 p-button-text"
              onClick={updateCroqui}
              icon="pi pi-fw pi-check"
            />
          </div>

          <div className={classNames({ 'p-d-flex': !edit }, 'p-ai-center')} hidden={edit}>
            Tamanho: {croqui.quantidadeLinhas} x {croqui.quantidadeColunas}
            <Button
              className="p-ml-1 p-button-text"
              data-test="sizeCroqui"
              onClick={() => setEdit(true)}
              icon="pi pi-fw pi-pencil"
            />
          </div>
        </div>
        <CroquiSketch
          croqui={croqui}
          onItemClick={onCroquiItemClick}
          matriz={matrizCroqui}
        />
      </div>
    </>
  );
};

type ELEMENT = CroquiTipo | 'borracha';

export default EditCroqui;
