import { LocalStorage } from '@agro1desenvolvimento/utils-js';

const localStorage = new LocalStorage<StoreData>();

export const removeProjectItens = () => {
  localStorage.removeItem('safraId');
  localStorage.removeItem('produtorId');
  localStorage.removeItem('currentEspeciesIds');
  localStorage.removeItem('currentCultivaresIds');
};

type StoreData = {
  safraId: string,
  produtorId: string,
  currentEspeciesIds: string[],
  currentCultivaresIds: string[],
}

export default localStorage;
