import { loginService } from '@agro1desenvolvimento/apis-js-package';
import { Unidade } from '../../@types/estoque/unidade';
import CrudBase from '../crud-base';
import api from '../erp-api';

class UnidadesService extends CrudBase<Unidade> {
  get endpoint() {
    return `/${loginService.scope}/estoque/unidades`;
  }

  async list() {
    return (await api.get<Unidade[]>(this.endpoint)).data;
  }

  async listCompativeis(identificador: string) {
    return (await api.get<Unidade[]>(`${this.endpoint}/${identificador}/compativeis`)).data;
  }
}

export default new UnidadesService();
