import { FC, useMemo, useRef } from 'react';
import { Button, Menu } from '@agro1desenvolvimento/react-components';
import { useDispatch, useSelector } from 'react-redux';
import { useIsMobile } from '@agro1desenvolvimento/react-hooks';
import { reject } from 'lodash';
import { Lote } from '../../../../@types/sementes/lote';
import {
  selectNewEditPlanejamentoState, setLotesConfirmados, setLotesExcluidos, setLotesMovimentados,
} from '../newEditPlanejamentoSlice';
import { PlanejamentoLoteFormFields } from '../../Lote/EditLoteForm';
import { promiseWithLoaderAndMessages } from '../../../../utilities';
import lotesService from '../../../../services/sementes/lotes';
import { falhaAoCarregar } from '../../../../utilities/default-confirmation-messages';

const DropDownButtons: FC<PropTypes> = ({ lote, openEdit, onPressConfirmation }) => {
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const menu = useRef<Menu>(null);
  const {
    lotesConfirmados,
    lotesExcluidos,
    lotesMovimentados,
  } = useSelector(selectNewEditPlanejamentoState);
  const isRascunho = lote.status === 'rascunho';

  const loteStatus = useMemo(() => {
    const confirmado = lotesConfirmados.includes(lote);
    const excluido = lotesExcluidos.includes(lote);

    return {
      confirmado,
      excluido,
      hasActionExecuted: confirmado || excluido,
    };
  }, [lotesConfirmados, lotesExcluidos]);

  const actionsDisabled = !isRascunho || loteStatus.hasActionExecuted;

  const fetchTipoEmbalagem = () => (
    promiseWithLoaderAndMessages(
      dispatch,
      lotesService.fetchTipoEmbalagem(lote),
      { errorMessage: falhaAoCarregar('tipo de embalagem') },
    )
  );

  const editarLote = async () => {
    const tipoEmbalagem = await fetchTipoEmbalagem();

    openEdit(lotesService.toEdit(lote, tipoEmbalagem));
  };

  const duplicarLote = async () => {
    const tipoEmbalagem = await fetchTipoEmbalagem();

    openEdit(lotesService.toDuplicate(lote, tipoEmbalagem));
  };

  const removerLote = (): void => {
    dispatch(setLotesExcluidos([...lotesExcluidos, lote]));
  };

  const desfazer = (): void => {
    dispatch(setLotesExcluidos(reject(lotesExcluidos, { id: lote.id })));
    dispatch(setLotesConfirmados(reject(lotesConfirmados, { id: lote.id })));
    dispatch(setLotesMovimentados(
      reject(lotesMovimentados, (movimento) => movimento.lote.id === lote.id),
    ));
  };

  const actionButtonsLotes = () => {
    const buttons = [
      {
        command: editarLote,
        disabled: actionsDisabled,
        label: 'Editar',
        icon: 'pi pi-fw pi-pencil',
      },
      {
        disabled: actionsDisabled,
        command: removerLote,
        label: 'Remover',
        icon: 'pi pi-fw pi-trash',
      },
      {
        disabled: !loteStatus.hasActionExecuted,
        command: desfazer,
        label: 'Desfazer',
        icon: 'pi pi-fw pi-replay',
      },
      {
        command: duplicarLote,
        label: 'Duplicar',
        icon: 'pi pi-fw pi-clone',
      },
    ];

    const btnConfirmar = {
      disabled: actionsDisabled,
      command: onPressConfirmation,
      label: 'Confirmar',
      icon: 'pi pi-fw pi-check',
    };

    if (isMobile) buttons.unshift(btnConfirmar);

    return buttons;
  };

  return (
    <>
      <span hidden={isMobile}>
        <Button
          className="p-mx-1 p-button-rounded p-button-success"
          onClick={onPressConfirmation}
          aria-label="Confirmar"
          tooltip="Confirmar"
          title="Confirmar"
          disabled={actionsDisabled}
          tooltipOptions={{ position: 'top' }}
          icon="pi pi-fw pi-check"
        />
      </span>

      <Menu
        model={actionButtonsLotes()}
        popup
        ref={menu}
        appendTo={document.body}
        baseZIndex={5}
        autoZIndex={false}
      />
      <Button
        className="p-button p-component p-button-rounded"
        icon="pi pi-fw pi-ellipsis-v"
        onClick={(event) => menu.current?.toggle(event)}
        aria-controls="popup_menu"
        title="Opções"
        aria-haspopup
      />
    </>
  );
};

interface PropTypes {
  lote: Lote;
  openEdit: (lote: PlanejamentoLoteFormFields) => void;
  onPressConfirmation: () => void
}

export default DropDownButtons;
