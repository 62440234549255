import React, { PropsWithChildren, useEffect, useMemo } from 'react';
import { Calendar, InputText } from '@agro1desenvolvimento/react-components';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { chunk, isObject, snakeCase } from 'lodash';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { DadoDinamico } from '../../@types/estrutura-dinamica';

const buildYupShape = (struct: DadoDinamico) => {
  let shape;

  if (struct.type === 'number') {
    shape = struct.required ? Yup.number().required() : Yup.number().notRequired();
  } else if (struct.type === 'date') {
    shape = struct.required ? Yup.date().required() : Yup.date().notRequired();
  } else {
    shape = struct.required ? Yup.string().required() : Yup.string().notRequired();
  }

  if (struct.name.includes('.')) {
    const attr = struct.name.split('.')[1];
    return Yup.object().shape({
      [attr]: shape,
    });
  }

  return shape;
};

const getYupModelName = (name: string): string => {
  if (name.includes('.')) {
    return name.split('.')[0];
  }

  return name;
};

const DynamicForm: DynamicFormType = ({
  data, dataKeys, formId, formValid,
  children, displayOnly, dynamicData, dynamicStruct, onSubmit, fieldColumn = 12, chunkSize = 2,
}) => {
  const chunkInputs = useMemo(() => chunk(dynamicStruct, chunkSize), [dynamicStruct]);
  const dynamicSchema = useMemo(() => (
    Yup.object().shape(
      dynamicStruct.reduce<Record<string, any>>((acc, struct) => ({
        ...acc,
        [getYupModelName(struct.name)]: buildYupShape(struct),
      }), {}),
    )
  ), [dynamicStruct]);

  const formik = useFormik({
    validationSchema: dynamicSchema,
    initialValues: {},
    onSubmit: async (values: any) => onSubmit(values),
  });

  const preSubmit = (event: React.FormEvent<HTMLFormElement> | undefined) => {
    if (data) {
      dataKeys.forEach((value) => {
        formik.setFieldValue(`${value}`, data[value]);
      });
    }

    formik.handleSubmit(event);
  };

  useEffect(() => {
    formValid((!chunkInputs.length || formik.dirty) && formik.isValid);
  }, [formik.dirty, formik.isValid, chunkInputs]);

  useEffect(() => {
    formik.setTouched(formik.values);
  }, [formik.values]);

  useEffect(() => {
    formik.resetForm();
    formik.validateForm();

    if (dynamicData) {
      Object.entries(dynamicData).forEach(([key, value]) => {
        const snakeKey = snakeCase(key);
        if (isObject(value)) {
          Object.entries(value).forEach(([objectKey, objectValue]) => {
            const snakeObjectKey = snakeCase(objectKey);
            formik.setFieldValue(`${snakeKey}.${snakeObjectKey}`, objectValue);
          });
        } else {
          formik.setFieldValue(snakeKey, value);
        }
      });
    }
  }, [dynamicData]);

  const classes = useMemo(() => (
    dynamicStruct.reduce<Record<string, any>>((acc, struct) => ({
      ...acc,
      [struct.name]: classNames({ 'p-invalid': (formik.errors as Record<string, any>)[struct.name] }),
    }), {})
  ), [formik.errors]);

  const getFieldValue = (name: string): string => {
    if (name.includes('.')) {
      const [objectKey, value] = name.split('.');
      const object = (formik.values as Record<string, any>)[objectKey];
      return object ? object[value] : '';
    }
    return (formik.values as Record<string, any>)[name];
  };

  return (
    <>
      <form onSubmit={preSubmit} id={formId}>
        <div className="p-fluid p-formgrid p-grid">
          {children}
          {chunkInputs.flat(2).map((values) => (
            <div key={values.name} className={`p-field p-col-${fieldColumn / chunkSize}`}>
              <label htmlFor={values.name}>{values.descricao}</label>
              {values.type === 'date' && (
              <Calendar
                name={values.name}
                id={values.name}
                value={
                      (formik.values as Record<string, any>)[values.name]
                        ? dayjs((formik.values as Record<string, any>)[values.name]).toDate()
                        : undefined
                    }
                onChange={formik.handleChange}
                className={(classes as Record<string, any>)[values.name]}
                appendTo={document.body}
                placeholder={values.descricao}
                disabled={displayOnly}
                showIcon
              />
              )}
              {values.type !== 'date' && (
              <InputText
                name={values.name}
                id={values.name}
                type={values.type}
                value={getFieldValue(values.name)}
                onChange={formik.handleChange}
                className={(classes as Record<string, any>)[values.name]}
                disabled={displayOnly}
                required
              />
              )}
            </div>
          ))}
        </div>
      </form>
    </>
  );
};

export default DynamicForm;

type DynamicFormType = <T>(props: PropsWithChildren<DynamicFormProps<T>>) => JSX.Element | null;

interface DynamicFormProps<T extends Record<string, any>> {
  data: Record<keyof any, any> | undefined;
  formId: string;
  dataKeys: (keyof T)[];
  dynamicData: Record<keyof any, any> | undefined;
  dynamicStruct: DadoDinamico[];
  formValid: (valid: boolean) => void;
  onSubmit: (values: any) => Promise<void> | void;
  displayOnly?: boolean;
  fieldColumn?: number;
  chunkSize?: number;
}
