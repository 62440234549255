import {
  Route, Switch, useHistory, useLocation,
} from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ArmazemPage from './ArmazemPage';
import NewEditArmazemPage from './NewEditPage';
import CroquiPage from './Croqui';
import { promiseWithLoaderAndMessages } from '../../../utilities';
import ArmazemService from '../../../services/sementes/armazem';
import { Armazem } from '../../../@types/sementes/armazem';
import { selectArmazemState, setCurrentArmazem } from './armazemSlice';
import { extractUUID } from '../../../utilities/uuid';

const ArmazemIndex = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { currentArmazem } = useSelector(selectArmazemState);

  useEffect(() => {
    const id = extractUUID(location.pathname);
    if (!id || id === currentArmazem?.id) return;

    const fetchArmazem = async () => {
      try {
        const armazem = await promiseWithLoaderAndMessages<Armazem>(
          dispatch,
          ArmazemService.find(id as string),
          {
            errorMessage: `Armazém ${id} não encontrado`,
          },
        );

        dispatch(setCurrentArmazem(armazem));
      } catch (error) {
        history.push('/sementes/armazens');
        console.error(error);
      }
    };

    fetchArmazem();
  }, [location]);

  return (
    <div className="armazem-page">
      <Switch>
        <Route exact path="/sementes/armazens">
          <ArmazemPage />
        </Route>
        <Route exact path="/sementes/armazens/:action(novo|editar)/:id?">
          <ArmazemPage />
          <NewEditArmazemPage />
        </Route>
        <Route exact path="/sementes/armazens/croqui/:id">
          <CroquiPage />
        </Route>
      </Switch>
    </div>
  );
};

export default ArmazemIndex;
