import { FC, useRef } from 'react';
import { useIsMobile } from '@agro1desenvolvimento/react-hooks';
import { includes, reject } from 'lodash';
import {
  Button, Menu,
} from '@agro1desenvolvimento/react-components';
import { useDispatch, useSelector } from 'react-redux';
import { selectConfirmacaoReembalagemState, setLotesConfirmados, setLotesMovimentados } from '../confirmacaoReembalagemSlice';
import { Lote } from '../../../../../@types/sementes/lote';

const LotesActionButtons: FC<{
  lote: Lote, confirmCallback: () => void,
}> = ({ lote, confirmCallback }) => {
  const isMobile = useIsMobile();
  const menu = useRef<Menu>(null);
  const dispatch = useDispatch();
  const { lotesConfirmados, lotesMovimentados } = useSelector(selectConfirmacaoReembalagemState);

  const disabledConfirm = includes(lotesConfirmados, lote) || lote.status !== 'rascunho';

  const desfazer = () => {
    dispatch(setLotesConfirmados(reject(lotesConfirmados, { id: lote.id })));
    dispatch(setLotesMovimentados(reject(lotesMovimentados, ['lote.id', lote.id])));
  };

  const confirmar = () => {
    dispatch(setLotesConfirmados([...lotesConfirmados, lote]));
    confirmCallback();
  };

  const actionButtons = () => {
    const buttons = [
      {
        disabled: !includes(lotesConfirmados, lote),
        command: desfazer,
        label: 'Desfazer',
        icon: 'pi pi-fw pi-replay',
      },
    ];

    const btnConfirmar = {
      disabled: disabledConfirm,
      command: confirmar,
      label: 'Confirmar',
      icon: 'pi pi-fw pi-check',
    };

    if (isMobile) buttons.unshift(btnConfirmar);
    return buttons;
  };

  return (
    <>
      <span hidden={isMobile}>
        <Button
          className="p-mx-1 p-button-rounded p-button-success"
          onClick={confirmar}
          aria-label="Confirmar"
          tooltip="Confirmar"
          title="Confirmar"
          disabled={disabledConfirm}
          tooltipOptions={{ position: 'top' }}
          icon="pi pi-fw pi-check"
        />
      </span>
      <Menu
        model={actionButtons()}
        popup
        ref={menu}
        appendTo={document.body}
        baseZIndex={5}
        autoZIndex={false}
      />
      <Button
        className="p-button p-component p-button-rounded"
        icon="pi pi-fw pi-ellipsis-v"
        onClick={(event) => menu.current?.toggle(event)}
        aria-controls="popup_menu"
        title="Opções"
        aria-haspopup
      />
    </>
  );
};

export default LotesActionButtons;
