import React, { useRef, FC, useState } from 'react';
import {
  DataTable,
  Column,
  PaginatorPageState,
} from '@agro1desenvolvimento/react-components';
import { useDispatch, useSelector } from 'react-redux';
import { useIsMobile } from '@agro1desenvolvimento/react-hooks';
import { useHistory } from 'react-router-dom';
import { Lote } from '../../../../@types/sementes/lote';
import { selectLotesState, setLotesSelecionados } from '../lotesSlice';
import LoteStatusTag from './LoteStatusTag';
import DropwDownButton from '../../../../components/DropwDownButtons';
import { AuditoriaType } from '../../../../@types/enums';
import { formatNumber, formatWithoutTime } from '../../../../utilities';
import Filters from './Filters';
import Pagination from '../../../../components/Pagination';
import { usePagination, useScreenSize } from '../../../../hooks';
import RowExpansionTemplate from './RowExpansionTemplate';
import ReabrirLoteConfirmationModal from './ReabrirLoteConfirmationModal';

const isEditable = (lote: Lote) => lote.status === 'rascunho';

const renderStatusLoteTag = (lote: Lote) => <LoteStatusTag lote={lote} />;

const ListLotes: FC<{reloadLotes: () => void}> = ({ reloadLotes }) => {
  const dispatch = useDispatch();
  const {
    lotes, lotesSelecionados, pagination,
  } = useSelector(selectLotesState);
  const {
    maxPerPage = 1,
    totalRecords,
    pages,
  } = pagination;
  const isMobile = useIsMobile();
  const dataTableRef = useRef<DataTable>(null);
  const [expandedRows, setExpandedRows] = useState<Lote[]>([]);
  const { isXL, isXXL } = useScreenSize();
  const history = useHistory();
  const { goToPage, firstIndexOnPage } = usePagination({ maxPerPage });
  const [loteToReopen, setLoteToReopen] = useState<Lote>();

  const onPageChange = (e: PaginatorPageState) => goToPage(e.page + 1);

  const customActions = (lote: Lote) => [
    {
      command: () => setLoteToReopen(lote),
      label: 'Reabrir',
      icon: 'fas fa-reply',
      disabled: lote.status === 'rascunho',
    },
    {
      command: () => history.push({
        pathname: `/sementes/lotes/${lote.id}/amostras`,
        search: location.search,
      }),
      label: 'Amostras',
      icon: 'pi pi-fw pi-th-large',
    },
  ];

  const renderActionButtons = (lote: Lote) => (
    <div>
      <DropwDownButton
        auditoriaAction={() => history.push(`/auditoria/${AuditoriaType.lotes}/${lote.id}`)}
        customActions={customActions(lote)}
        editAction={isEditable(lote) ? (() => history.push(`/sementes/lotes/${lote.id}/editar`)) : undefined}
      />
    </div>
  );

  return (
    <>
      <ReabrirLoteConfirmationModal
        lote={loteToReopen}
        visible={!!loteToReopen}
        onHide={() => setLoteToReopen(undefined)}
        onExit={reloadLotes}
      />
      <DataTable
        ref={dataTableRef}
        value={lotes}
        emptyMessage="Nenhum registro encontrado."
        selection={lotesSelecionados}
        selectionMode="multiple"
        onSelectionChange={(e) => dispatch(setLotesSelecionados(e.value))}
        dataKey="id"
        className="lote"
        header={<Filters />}
        rowExpansionTemplate={({ id }) => <RowExpansionTemplate loteId={id} isMobile={isMobile} />}
        expandedRows={expandedRows}
        onRowToggle={(event) => setExpandedRows(event.data)}
        responsiveLayout="scroll"
      >
        <Column expander className="expander-column" />
        <Column selectionMode="multiple" />
        <Column field="numeroLote" header="Número" sortable className="col-numero overflow-auto p-text-center" />
        {isMobile || (
          <Column
            field="dataLote"
            header="Data"
            body={(lote: Lote) => formatWithoutTime(lote.dataLote)}
            sortable
            bodyClassName="cy-coluna-date"
          />
        )}
        {isMobile || <Column field="cultivar.especie.descricao" header="Espécie" sortable bodyClassName="cy-column-species" />}
        <Column field="cultivar.descricaoCategoria" header="Cultivar" sortable bodyClassName="cy-column-cultivate" />
        {isMobile || <Column body={(lote: Lote) => lote.unidade.descricao || 'N/D'} header="Unidade" sortable bodyClassName="cy-column-unit" sortField="unidade.descricao" />}
        {isMobile || <Column body={(lote: Lote) => formatNumber(lote.quantidade, 1) || 'N/D'} header={(isXL || isXXL) ? 'Quantidade' : 'QTD'} sortable sortField="quantidade" />}
        {isMobile || <Column body={(lote: Lote) => formatNumber(lote.pesoEmbalagem, 1) || 'N/D'} header={(isXL || isXXL) ? 'Peso Embalagem (kg)' : 'Peso Emb. (kg)'} sortable sortField="pesoEmbalagem" />}
        {isMobile || <Column body={(lote: Lote) => formatNumber(lote.pesoTotalKg, 1) || 'N/D'} header="Peso Total (kg)" sortable sortField="pesoTotalKg" />}
        <Column field="status" body={renderStatusLoteTag} header="Status" className="cy-column-status" />
        <Column header="Ações" body={renderActionButtons} className="cy-column-actions" />
      </DataTable>
      <Pagination
        first={firstIndexOnPage}
        pages={pages}
        rows={maxPerPage}
        totalRecords={totalRecords}
        onPageChange={onPageChange}
        pageRecords={lotes.length}
      />
    </>
  );
};

export default ListLotes;
