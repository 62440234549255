import {
  FC,
  useEffect,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import FormPessoa from './Form';
import PessoaService from '../../../../services/geral/pessoas';
import { Pessoa } from '../../../../@types/geral/pessoa';
import { promiseWithLoaderAndMessages } from '../../../../utilities';
import { falhaAoCarregar } from '../../../../utilities/default-confirmation-messages';

const EditPessoa: FC = () => {
  const dispatch = useDispatch();
  const [pessoa, setPessoa] = useState<Pessoa | undefined>();
  const { id } = useParams<{id: string}>();

  useEffect(() => {
    const findPessoa = async () => {
      setPessoa(
        await promiseWithLoaderAndMessages(
          dispatch,
          PessoaService.find(id),
          { errorMessage: falhaAoCarregar('pessoa') },
        ),
      );
    };

    if (id) findPessoa();
  }, [id]);

  return pessoa ? <FormPessoa pessoa={pessoa} /> : null;
};

export default EditPessoa;
