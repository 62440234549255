import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../../../../app/rootReducer';
import { Deposito } from '../../../../@types/estoque/deposito';
import { Cultivar, Produto } from '../../../../@types/estoque/item';
import { CampoProducaoWithSaldo, SaldoDepositoSimplified } from '../../../../@types/sementes/saldoDeposito';

export interface SimulaDescartesFormField {
  quantidade?: number,
  subProduto?: string,
  depositoDestino?: string,
}

interface DescarteSimulacaoState {
  currentStepIndex: number,
  cultivar: undefined | Cultivar,
  subProduto: undefined | Produto,
  depositoOrigem: undefined | Deposito,
  depositoDestino: undefined | Deposito,
  quantidade: number,
  camposProducoes: CampoProducaoWithSaldo[],
  saldosByDeposito: undefined | SaldoDepositoSimplified;
}

const initialState: DescarteSimulacaoState = {
  cultivar: undefined,
  subProduto: undefined,
  depositoOrigem: undefined,
  depositoDestino: undefined,
  saldosByDeposito: undefined,
  quantidade: 0,
  camposProducoes: [],
  currentStepIndex: 0,
};

export const novoDescarteSlice = createSlice({
  name: 'novoDescarte',
  initialState,
  reducers: {
    goToStep(state, action: PayloadAction<'next' | 'previos'>) {
      const { currentStepIndex } = state;
      state.currentStepIndex = action.payload === 'previos' ? currentStepIndex - 1 : currentStepIndex + 1;
    },
    setFiltrosIniciais: (state, action: PayloadAction<{
      depositoOrigem: Deposito, saldosDepositoOrigem: SaldoDepositoSimplified, cultivar: Cultivar
    }>) => {
      state.depositoOrigem = action.payload.depositoOrigem;
      state.saldosByDeposito = action.payload.saldosDepositoOrigem;
      state.cultivar = action.payload.cultivar;
    },
    setFiltrosSecundarios: (state, action: PayloadAction<
      { camposProducoes: CampoProducaoWithSaldo[] }
    >) => {
      state.camposProducoes = action.payload.camposProducoes;
    },
    setConfiguracoesDescarte: (state, action: PayloadAction<{
      quantidade: number, subProduto: Produto, depositoDestino: Deposito,
    }>) => {
      state.subProduto = action.payload.subProduto;
      state.depositoDestino = action.payload.depositoDestino;
      state.quantidade = action.payload.quantidade || 0;
    },
    clearSlice: () => initialState,
  },
});

export const selectDescartesState = (state: RootState) => state.novoDescarteReducer;

export const {
  goToStep,
  clearSlice,
  setFiltrosIniciais,
  setFiltrosSecundarios,
  setConfiguracoesDescarte,
} = novoDescarteSlice.actions;

export default novoDescarteSlice.reducer;
