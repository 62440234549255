import { FC } from 'react';
import { InputText } from '@agro1desenvolvimento/react-components';
import { FilterFieldProps } from './FilterFieldProps';

const FilterInputText: FC<FilterFieldProps> = ({
  filterField, filterConditions, setFilterConditions,
}) => {
  const addFilterValue = (value: string) => {
    setFilterConditions(filterField.field, [{
      field: filterField.field,
      value,
      operator: 'eq',
    }]);
  };

  return (
    <span className="p-float-label">
      <InputText
        className="p-inputwrapper"
        id={`filter-${filterField.field}`}
        type="text"
        value={filterConditions[filterField.field]?.[0]?.value}
        onChange={(e) => addFilterValue(e.currentTarget.value)}
      />
      <label htmlFor={`filter-${filterField.field}`}>{filterField.label}</label>
    </span>
  );
};

export default FilterInputText;
