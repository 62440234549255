import { loginService } from '@agro1desenvolvimento/apis-js-package';
import { Cultivar } from '../../@types/estoque/item';
import QueryableBase from '../queryable-base';

class CultivaresService extends QueryableBase<Cultivar> {
  get endpoint() {
    return `/${loginService.scope}/sementes/campos_producao/cultivares`;
  }
}

export default new CultivaresService();
