import {
  Column,
  ConfirmPopup,
  DataTable,
  PaginatorPageState,
} from '@agro1desenvolvimento/react-components';
import { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useIsMobile } from '@agro1desenvolvimento/react-hooks';
import { RelatorioTemplate } from '../../../@types/relatorio/template';
import { deleteRelatorioTemplate, selectTemplateState } from './relatorioTemplateslice';
import DropwDownButtons from '../../../components/DropwDownButtons';
import { AuditoriaType } from '../../../@types/enums';
import { downloader, getIncrementedUrlPath } from '../../../utilities';
import Pagination from '../../../components/Pagination';
import Filters from './Filters';
import { usePagination } from '../../../hooks';

const ListTemplates: FC<
  { fetchRelatoriosTemplate: () => void }
> = ({ fetchRelatoriosTemplate }) => {
  const [selectedTemplate, setSelectedTemplate] = useState<RelatorioTemplate | null>();
  const { templates, pagination } = useSelector(selectTemplateState);
  const { maxPerPage = 1, totalRecords, pages } = pagination;
  const history = useHistory();
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const { firstIndexOnPage, goToPage } = usePagination({ maxPerPage });

  const onPageChange = (e: PaginatorPageState) => {
    goToPage(e.page + 1);
  };

  const confirm = async (id: string) => {
    if ((await dispatch(deleteRelatorioTemplate(id))).meta.requestStatus === 'fulfilled') {
      fetchRelatoriosTemplate();
      setSelectedTemplate(null);
    }
  };

  const print = async (template: RelatorioTemplate) => {
    downloader(
      `data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(template.reportTemplate))}`,
      `template-${template.descricao}.tlf`,
      { assignDownloadProp: true },
    );
  };

  const customActions = (template: RelatorioTemplate) => [
    {
      command: () => (print(template)),
      label: 'Download',
      icon: 'pi pi-fw pi-download',
    },
  ];

  const optionButtonId = (id: string | undefined) => `btn-option-${id}`;

  const renderActionButtons = (template: RelatorioTemplate) => (
    <div>
      <DropwDownButtons
        editAction={() => history.push({
          pathname: getIncrementedUrlPath(`editar/${template.id}`),
          search: location.search,
        })}
        deleteAction={() => setSelectedTemplate(template)}
        auditoriaAction={() => history.push(`/auditoria/${AuditoriaType.template}/${template.id}`)}
        customActions={customActions(template)}
        optionButtonId={optionButtonId(template.id)}
      />
    </div>
  );

  return (
    <>
      <ConfirmPopup
        target={document.getElementById(optionButtonId(selectedTemplate?.id)) || undefined}
        visible={!!selectedTemplate}
        onHide={() => setSelectedTemplate(undefined)}
        message="Deseja realmente excluir?"
        icon="pi pi-exclamation-triangle"
        accept={() => confirm(selectedTemplate?.id as string)}
      />
      <DataTable value={templates} className="relatorio-template-lista" header={<Filters />} responsiveLayout="scroll">
        <Column
          header="Descrição"
          field="descricao"
        />
        {isMobile
          || (
          <Column
            header="Tenants Habilitados"
            field="tenantsHabilitados"
            body={(rowData: RelatorioTemplate) => rowData.tenantsHabilitados.join(', ')}
          />
          )}
        {isMobile
          || (
          <Column
            className="tenant-column"
            header="Tenants Desabilitados"
            field="tenantsDesabilitados"
            body={(rowData: RelatorioTemplate) => rowData.tenantsDesabilitados.join(', ')}
          />
          )}
        <Column
          className="tipo-column"
          header="Tipo"
          field="tipo"
        />
        <Column
          header="Ações"
          body={renderActionButtons}
          className="acoes-column"
        />
      </DataTable>
      <Pagination
        pages={pages}
        first={firstIndexOnPage}
        rows={maxPerPage}
        totalRecords={totalRecords}
        onPageChange={(e) => onPageChange(e)}
        pageRecords={templates.length}
      />
    </>
  );
};

export default ListTemplates;
