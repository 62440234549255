import { loginService } from '@agro1desenvolvimento/apis-js-package';
import { keysToCamelCase, keysToSnakeCase } from '@agro1desenvolvimento/utils-js';
import { Dashboard } from '../../@types/geral/dashboard';
import { CrudCreateUpdate } from '../../utilities';
import api from '../erp-api';

class DashboardService {
  get endpoint() {
    return `/${loginService.scope}/geral/dashboards`;
  }

  async find(id: Dashboard['id']) {
    return keysToCamelCase<Dashboard>((await api.get<Dashboard>(`${this.endpoint}/${id}`)).data);
  }

  async create(dashboard: CrudCreateUpdate<Dashboard>) {
    return (await api.post<Dashboard[]>(this.endpoint, {
      dashboards: [keysToSnakeCase(dashboard)],
    })).data[0];
  }

  async update(dashboard: CrudCreateUpdate<Dashboard>) {
    return (await api.patch<Dashboard>(`${this.endpoint}/${dashboard.id}`, {
      dashboard: keysToSnakeCase(dashboard),
    })).data;
  }

  async createOrUpdate(dashboard: CrudCreateUpdate<Dashboard>) {
    const action = dashboard.id ? 'update' : 'create';

    return { action, data: await this[action](dashboard) };
  }

  async listAll() {
    return (await api.get<Dashboard[]>(this.endpoint)).data.map((dashboard) => (
      keysToCamelCase<Dashboard>(dashboard)
    ));
  }

  async delete(id: string): Promise<''> {
    return (await api.delete(`${this.endpoint}/${id}`)).data;
  }
}

export default new DashboardService();
