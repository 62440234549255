import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../../../app/rootReducer';
import AuditoriasService from '../../../services/geral/auditoria/auditorias';
import promiseWithLoaderAndMessages from '../../../utilities/promise-with-loader-and-messages';
import { Auditoria } from '../../../@types/geral/auditoria';
import { HeaderParams } from '../../../@types/headers';
import { ListReturnType } from '../../../services/queryable-base';
import queryable, { Query } from '../../../services/queryable';
import { falhaAoCarregar } from '../../../utilities/default-confirmation-messages';

interface AuditoriaState {
  auditorias: Auditoria[],
  pagination: HeaderParams,
}

const initialState: AuditoriaState = {
  auditorias: [],
  pagination: {
    currentPage: undefined,
    totalRecords: undefined,
    maxPerPage: undefined,
    pages: undefined,
  },
};

export const fetchAuditorias = createAsyncThunk<ListReturnType<Auditoria>, Omit<Query<Auditoria>, 'service'>>(
  '/auditorias/list',
  (params = {}, { dispatch }) => {
    const query = queryable.query({
      ...params,
      service: AuditoriasService,
    });

    return promiseWithLoaderAndMessages(
      dispatch,
      query,
      { errorMessage: falhaAoCarregar('auditorias') },
    );
  },
);

export const auditoriasSlice = createSlice({
  name: 'auditorias',
  initialState,
  reducers: {
    clearAuditorias(state) {
      state.auditorias = [];
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchAuditorias.fulfilled, (state, { payload }) => {
      state.auditorias = payload.data;
      state.pagination = payload.headers;
    });
  },
});

export const {
  clearAuditorias,
} = auditoriasSlice.actions;

export const selectAuditoriasState = (state: RootState) => state.auditoriasReducer;

export default auditoriasSlice.reducer;
