import {
  Dropdown,
  AutoComplete,
  AutoCompleteChangeParams,
  Message,
  MultiSelect,
  Checkbox,
  Tooltip,
} from '@agro1desenvolvimento/react-components';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { filter } from 'lodash';
import {
  useEffect,
  useMemo,
  useState,
  useImperativeHandle,
  forwardRef,
  SetStateAction,
} from 'react';
import * as Yup from 'yup';
import { FormikErrors, useFormik } from 'formik';
import { fetchEspecies } from '../../../geral/Especies/especiesSlice';
import { selectCultivarState } from '../../../Estoque/Cultivar/cultivaresSlice';
import { fetchDepositos, selectDepositoState } from '../../../Estoque/Depositos/depositosSlice';
import { selectTopBarState } from '../../../../components/Topbar/topBarSlice';
import { promiseWithLoaderAndMessages } from '../../../../utilities';
import { Cultivar } from '../../../../@types/estoque/item';
import { falhaAoCarregar } from '../../../../utilities/default-confirmation-messages';
import { selectCamposProducaoState } from '../../CamposProducao/camposProducaoSlice';
import { SaldoCampoProducao } from '../../../../@types/sementes/saldoCampoProducao';
import saldosCamposProducao from '../../../../services/sementes/saldos-campos-producao';
import dispatchFetchCultivares from '../../../../utilities/dispatch-fetch-cultivares';
import dispatchFetchCamposProducao from '../../../../utilities/dispatch-fetch-campos-producao';
import { SimulaDescartesFormField } from '../../Subproduto/Novo/novoDescarteSlice';

const SimulaDescartesSchema = Yup.object().shape({
  cultivarId: Yup.string().required(),
  depositoOrigemId: Yup.string().required(),
});

const SaldosFilters = forwardRef<
  SaldosFiltersRef, SaldosFiltersPropsTypes
>(({ onSubmit, setValues, setIsValid }, ref) => {
  const dispatch = useDispatch();
  const { currentSafra, currentProdutor } = useSelector(selectTopBarState);
  const { cultivares } = useSelector(selectCultivarState);
  const { depositos } = useSelector(selectDepositoState);
  const [errorMessage, setErrorMessage] = useState('');
  const [toggleWithProductionFields, setToggleWithProductionFields] = useState(false);
  const { campos: campoProducoes } = useSelector(selectCamposProducaoState);
  const [saldosCampoProducao, setSaldosCampoProducao] = useState<SaldoCampoProducao[]>([]);
  const [currentCultivar, setCurrentCultivar] = useState<Cultivar>();

  const camposProducaoFiltered = useMemo(() => campoProducoes.filter((campo) => (
    filter(saldosCampoProducao, (saldo) => (
      saldo.campoProducaoId === campo.id && saldo.saldoMateriaPrima > 0
    )).length > 0
  )), [saldosCampoProducao, campoProducoes]);

  const formFilters = useFormik<LocalizaSaldosValues>({
    validationSchema: SimulaDescartesSchema,
    initialValues: {
      cultivarId: '',
      depositoOrigemId: '',
      camposProducaoIds: [],
    },
    async onSubmit(values) {
      await onSubmit(values);
    },
  });

  const safraAndProdutor = useMemo(() => (
    (currentSafra && currentProdutor)
      ? {
        safra: currentSafra.id,
        produtor: currentProdutor.id,
      } : undefined
  ), [currentSafra, currentProdutor]);

  const fetchCultivaresByParams = (query?: string) => {
    dispatchFetchCultivares({ dispatch, query, extraParameters: safraAndProdutor });
  };

  const loadLists = () => {
    dispatch(fetchEspecies({
      extraParameters: safraAndProdutor,
    }));
    dispatch(fetchDepositos());
    fetchCultivaresByParams();
  };

  const changeCultivar = (value: AutoCompleteChangeParams) => {
    const cultivarObject = {
      ...value,
      target: {
        ...value.target,
        value: value.target.value.id,
      },
    };
    setCurrentCultivar(value.target.value);
    formFilters.handleChange(cultivarObject);
  };

  useEffect(() => {
    if (!currentProdutor || !currentSafra) {
      setErrorMessage('Selecione safra/produtor.');
      return;
    }

    formFilters.resetForm();
    setErrorMessage('');
    loadLists();
  }, [currentSafra, currentProdutor]);

  useEffect(() => {
    if (!currentCultivar?.id || !currentProdutor?.id || !currentSafra?.id) return;

    dispatchFetchCamposProducao({
      dispatch,
      cultivarId: currentCultivar.id,
      produtorId: currentProdutor.id,
      safraId: currentSafra.id,
      extraParameters: { ativos: '1' },
    });
  }, [currentCultivar, currentProdutor, currentSafra]);

  useEffect(() => {
    const deposito = formFilters.values.depositoOrigemId;
    const loadSaldosCamposProducao = async () => {
      if (!currentCultivar?.id || !currentSafra || !currentProdutor || !deposito) return;

      const saldos = await promiseWithLoaderAndMessages(
        dispatch,
        saldosCamposProducao.saldos({
          cultivarId: currentCultivar.id,
          safraId: currentSafra.id,
          produtorId: currentProdutor.id,
          depositoId: deposito,
        }),
        { errorMessage: falhaAoCarregar('saldos de campos de produção') },
      );

      setSaldosCampoProducao(saldos);
    };

    loadSaldosCamposProducao();
  }, [currentCultivar, currentProdutor, currentSafra, formFilters.values.depositoOrigemId]);

  useEffect(() => {
    if (setIsValid) setIsValid(formFilters.isValid);
  }, [formFilters.isValid]);

  useEffect(() => {
    if (!toggleWithProductionFields) formFilters.setFieldValue('camposProducaoIds', undefined);
  }, [toggleWithProductionFields]);

  useEffect(() => {
    if (setValues) setValues((values) => ({ ...values, ...formFilters.values }));
  }, [formFilters.values]);

  useImperativeHandle(ref, () => ({
    submit: formFilters.submitForm,
  }));

  const filterClassNames = 'p-field p-col-12 p-sm-6 p-md-4 p-xl-3 p-align-center';

  if (errorMessage) {
    return <Message severity="error" text={errorMessage} className="width-full p-justify-start" />;
  }

  return (
    <div>
      <form id="filtros-simulacao">
        <div className="p-fluid p-formgrid p-grid p-justify-start">
          <div className={filterClassNames}>
            <AutoComplete
              dropdown
              value={currentCultivar}
              name="cultivarId"
              id="cultivarId"
              field="descricaoCategoria"
              suggestions={cultivares}
              onChange={changeCultivar}
              placeholder="Cultivar"
              appendTo={document.body}
              className={classNames({ 'p-invalid': formFilters.errors.cultivarId })}
              completeMethod={(e) => fetchCultivaresByParams(e.query)}
            />
          </div>
          <div className={filterClassNames}>
            <Dropdown
              id="depositoOrigemId"
              name="depositoOrigemId"
              optionValue="id"
              options={depositos}
              optionLabel="descricao"
              appendTo={document.body}
              disabled={!depositos.length}
              placeholder="Depósito de Matéria Prima"
              value={formFilters.values.depositoOrigemId}
              onChange={formFilters.handleChange}
              showClear
              filter
              className={classNames({ 'p-invalid': formFilters.errors.depositoOrigemId })}
            />
          </div>
          <div className={`p-field-checkbox ${filterClassNames} p-text-left`} id="checkbox-with-campos-producao-container">
            <Tooltip
              target="#checkbox-with-campos-producao-container"
              position="bottom"
              disabled={!!formFilters.values.depositoOrigemId}
              content="É necessário selecionar o deposito de materia prima"
            />
            <Checkbox
              inputId="checkbox-with-campos-producao"
              disabled={!formFilters.values.depositoOrigemId}
              checked={toggleWithProductionFields}
              onChange={(e) => setToggleWithProductionFields(e.checked)}
            />
            <label htmlFor="checkbox-with-campos-producao" className="p-my-0">Especificar campos de produção</label>
          </div>
          <div className={filterClassNames}>
            <MultiSelect
              value={formFilters.values.camposProducaoIds}
              optionValue="id"
              optionLabel="identificacaoSigef"
              name="camposProducaoIds"
              id="camposProducaoIds"
              options={camposProducaoFiltered}
              disabled={
                    !camposProducaoFiltered?.length
                    || !toggleWithProductionFields
                    || !formFilters.values.depositoOrigemId
                  }
              onChange={formFilters.handleChange}
              placeholder="Campos de Produção"
              appendTo={document.body}
              filter
              className={classNames({ 'p-invalid': formFilters.errors.camposProducaoIds })}
              showClear
            />
          </div>
        </div>
      </form>
    </div>
  );
});

export interface SaldosFiltersRef {
  submit: () => Promise<void>
}

export interface SaldosFiltersPropsTypes {
  onSubmit: (values: LocalizaSaldosValues) => void | Promise<void>
  /**
   * @deprecated
   */
  setValues?: (values: React.SetStateAction<SimulaDescartesFormField>) =>
    Promise<void> | Promise<FormikErrors<SimulaDescartesFormField>>
  setIsValid?: (isValid: boolean) => void
}

export interface LocalizaSaldosValues {
  cultivarId: string,
  depositoOrigemId: string,
  camposProducaoIds: string[],
}

export default SaldosFilters;
