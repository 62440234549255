import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PessoaProdutor } from '../../@types/geral/pessoa';
import { Safra } from '../../@types/geral/safra';
import type { RootState } from '../../app/rootReducer';
import SafrasService from '../../services/geral/safras';
import PessoasService from '../../services/geral/pessoas';
import { getURLSearchParams, localStorage, promiseWithLoaderAndMessages } from '../../utilities';
import { falhaAoCarregar } from '../../utilities/default-confirmation-messages';

interface TopBarState {
  safras: Safra[],
  currentSafra?: Safra,
  produtores: PessoaProdutor[],
  currentProdutor?: PessoaProdutor,
  loading: ('safra' | 'produtor')[]
}

const initialState: TopBarState = {
  safras: [],
  produtores: [],
  loading: ['produtor', 'safra'],
};

export const fetchSafras = createAsyncThunk(
  'topBar/fetchSafras',
  (_, { dispatch }) => (
    promiseWithLoaderAndMessages(
      dispatch,
      SafrasService.list(),
      { errorMessage: falhaAoCarregar('safras') },
    )
  ),
);

export const fetchProdutores = createAsyncThunk(
  'topBar/fetchProdutores',
  (_, { dispatch }) => (
    promiseWithLoaderAndMessages(
      dispatch,
      PessoasService.listProdutores(),
      { errorMessage: falhaAoCarregar('produtores') },
    )
  ),
);

const removeLoader = (state: TopBarState, key: 'safra' | 'produtor') => {
  state.loading = state.loading.filter((v) => v !== key);
};

const onDataIDChange = (
  key: 'safraId' | 'produtorId',
  id: string | undefined,
) => {
  if (id) localStorage.setItem(key, id);
  else localStorage.removeItem(key);
};

export const topBarSlice = createSlice({
  name: 'topBar',
  initialState,
  reducers: {
    setCurrentSafra: (state, action: PayloadAction<string>) => {
      state.currentSafra = state.safras.find((s) => s.id === action.payload);
      onDataIDChange('safraId', state.currentSafra?.id);
    },
    setCurrentProdutor: (state, action: PayloadAction<string>) => {
      state.currentProdutor = state.produtores.find((p) => p.id === action.payload);
      onDataIDChange('produtorId', state.currentProdutor?.id);
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchSafras.pending, (state) => {
      state.loading.push('safra');
    });

    builder.addCase(fetchSafras.fulfilled, (state, action) => {
      state.safras = action.payload;

      const safraId = getURLSearchParams().sId || localStorage.getItem('safraId');

      if (safraId && !state.currentSafra) {
        const safra = state.safras.find(({ id }) => id === safraId);

        state.currentSafra = safra;
        onDataIDChange('safraId', state.currentSafra?.id);
      }

      removeLoader(state, 'safra');
    });

    builder.addCase(fetchSafras.rejected, (state) => {
      removeLoader(state, 'safra');
    });

    builder.addCase(fetchProdutores.pending, (state) => {
      state.loading.push('produtor');
    });

    builder.addCase(fetchProdutores.fulfilled, (state, action) => {
      state.produtores = action.payload;

      const produtorId = getURLSearchParams().pId || localStorage.getItem('produtorId');

      if (produtorId && !state.currentProdutor) {
        const produtor = state.produtores.find(({ id }) => id === produtorId);

        state.currentProdutor = produtor;
        onDataIDChange('produtorId', state.currentProdutor?.id);
      }

      removeLoader(state, 'produtor');
    });

    builder.addCase(fetchProdutores.rejected, (state) => {
      removeLoader(state, 'produtor');
    });
  },
});

export const { setCurrentSafra, setCurrentProdutor } = topBarSlice.actions;

export const selectTopBarState = (state: RootState) => state.topBarReducer;

export default topBarSlice.reducer;
