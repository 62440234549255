import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../../../app/rootReducer';
import DepositosService from '../../../services/estoque/depositos';
import { AsyncThunkParams } from '../../../@types/redux';
import { Deposito } from '../../../@types/estoque/deposito';
import promiseWithLoaderAndMessages from '../../../utilities/promise-with-loader-and-messages';
import { ListReturnType } from '../../../services/queryable-base';
import { toastExcludeMessages, falhaAoCarregar, toastSaveMessages } from '../../../utilities/default-confirmation-messages';

interface DepositoState {
  depositos: Deposito[],
}

const initialState: DepositoState = {
  depositos: [],
};

export const fetchDepositos = createAsyncThunk<
  ListReturnType<Deposito>,
  AsyncThunkParams | undefined
>(
  `${DepositosService.endpoint}/list`,
  (_, { dispatch }) => promiseWithLoaderAndMessages(
    dispatch,
    DepositosService.listAll(),
    { errorMessage: falhaAoCarregar('depósitos') },
  ),
);

export const deleteDeposito = createAsyncThunk(
  `${DepositosService.endpoint}/delete`,
  async (id: string, { dispatch }) => {
    await promiseWithLoaderAndMessages(
      dispatch,
      DepositosService.delete(id),
      toastExcludeMessages('depósito'),
    );

    return id;
  },
);

export const createOrUpdateDeposito = createAsyncThunk(
  `${DepositosService.endpoint}/createOrUpdate`,
  (data: Parameters<typeof DepositosService.createOrUpdate>[0], { dispatch, rejectWithValue }) => (
    promiseWithLoaderAndMessages(
      dispatch,
      DepositosService.createOrUpdate(data),
      toastSaveMessages('depósito'),
    ).catch((error) => rejectWithValue(error.response.data))
  ),
);

export const depositosSlice = createSlice({
  name: 'deposito',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(fetchDepositos.fulfilled, (state, { payload }) => {
      state.depositos = payload.data;
    });
  },
});

export const selectDepositoState = (state: RootState) => state.depositosReducer;

export default depositosSlice.reducer;
