import {
  FC,
  useEffect,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import FormPeneira from './FormPeneira';
import PeneiraService from '../../../services/sementes/peneiras';
import { Peneira } from '../../../@types/sementes/peneira';
import { promiseWithLoaderAndMessages } from '../../../utilities';
import { falhaAoCarregar } from '../../../utilities/default-confirmation-messages';

const EditPeneira: FC = () => {
  const [peneira, setPeneira] = useState<Peneira | undefined>();
  const { id } = useParams<{id: string}>();
  const dispatch = useDispatch();

  const findPeneira = async () => {
    setPeneira(
      await promiseWithLoaderAndMessages(
        dispatch,
        PeneiraService.find(id),
        { errorMessage: falhaAoCarregar('peneira') },
      ),
    );
  };

  useEffect(() => {
    if (id) {
      findPeneira();
    }
  }, [id]);

  if (!peneira) return null;
  return <FormPeneira peneira={peneira} />;
};
export default EditPeneira;
