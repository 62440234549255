import { loginService } from '@agro1desenvolvimento/apis-js-package';
import { Cultivar, Item } from '../../@types/estoque/item';
import CrudBase from '../crud-base';
import api from '../erp-api';

export class ItensService extends CrudBase<Item> {
  get endpoint() {
    return `/${loginService.scope}/estoque/itens`;
  }

  async update(item: Partial<Cultivar>) {
    return (await api.patch<Cultivar>(`${this.endpoint}/${item.id}`, { obtentor_id: item.obtentor?.id })).data;
  }

  async find<T extends Item>(id: string) {
    return (await api.get<T>(`${this.endpoint}/${id}`)).data;
  }
}

export default new ItensService();
