import { isNumber } from 'lodash';
import { CroquiItem } from '../@types/sementes/croquiItem';

const getCroquiItemLabel = (param: Param) => {
  const { item } = param;
  const posicaoX = isNumber(param.x) ? param.x : item?.posicao.x;
  const posicaoY = isNumber(param.y) ? param.y : item?.posicao.y;

  if (item?.descricao) return item.descricao;
  if (isNumber(posicaoX) && isNumber(posicaoY)) return `Linha ${posicaoX + 1}, Coluna ${posicaoY + 1}`;
  return '';
};

type Param = {
  x?: number,
  y?: number,
  item?: CroquiItem
}
export default getCroquiItemLabel;
