import { SaldoCultivar } from '../../../../@types/sementes/saldoCultivar';
import CardWithValue from '../../../../components/CardWithValue';
import SaldoChart from './SaldoChart';

const SaldosPanel = ({ saldosCultivares }: { saldosCultivares: SaldoCultivar | undefined }) => (
  <div className="p-fluid p-grid resumoSaldoDeLotes" hidden={!saldosCultivares}>
    <div className="p-grid p-align-center p-col-12">
      <div className="p-col-12 p-sm-offset-1 p-md-offset-0 p-sm-10 p-md-7 p-xl-6">
        <CardWithValue className="p-col-12 p-md-11 cy-totalRecebido" value={saldosCultivares?.totalRecebido} title="Total recebido" unidade="KG" />
        <CardWithValue className="p-col-12 p-md-11 cy-totalEmLote" value={saldosCultivares?.totalLotes} title="Total em lotes" unidade="KG" />
        <CardWithValue className="p-col-12 p-md-11 cy-totalDescartado" value={saldosCultivares?.totalDescartado} title="Total já descartado" unidade="KG" />
        <CardWithValue className="p-col-12 p-md-11 cy-outrasSaidas" value={saldosCultivares?.totalSaidaOutros} title="Outras saídas" unidade="KG" />
      </div>
      <SaldoChart saldos={saldosCultivares} />
    </div>
  </div>
);

export default SaldosPanel;
