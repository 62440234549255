import { useEffect, useMemo, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import '@agro1desenvolvimento/react-components/node_modules/primereact/resources/themes/mdc-light-indigo/theme.css';
import 'primeicons/primeicons.css';
import './styles/styles.scss';
import { BrowserRouter as Router } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { loginService } from '@agro1desenvolvimento/apis-js-package';
import { useIsMobile } from '@agro1desenvolvimento/react-hooks';
import { CubeProvider } from '@cubejs-client/react';
import AppTopbar from './components/Topbar/Topbar';
import Sidebar from './components/Sidebar/Sidebar';
import { activate, deactivate, selectSidebarActive } from './components/Sidebar/sidebarSlice';
import Agro1Toast from './components/Agro1Toast';
import { showMessage } from './components/Agro1Toast/toast';
import { ENV } from './utilities/constants';
import Loader from './components/Loader/Loader';
import Routes from './Routes';
import cubeJSService from './services/cube-js';
import { fetchSementesConfig } from './features/sementes/Config/configSlice';

function App() {
  const sidebarActive = useSelector(selectSidebarActive);
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const [loadingUser, setLoadingUser] = useState(true);

  const classes = useMemo(() => (
    classNames({ 'layout-static-sidebar-inactive': !sidebarActive }, 'layout-wrapper', 'layout-static')
  ), [sidebarActive]);

  const initializer = async () => {
    setLoadingUser(true);

    try {
      const parsedEnv = ENV === 'cypress' ? 'homologation' : ENV;
      await loginService.initializeByEnvironment('sementes', parsedEnv);
      if (!loginService.isLoggedIn) loginService.redirectInvalidUser();

      cubeJSService.initialize();

      dispatch(fetchSementesConfig());
    } catch (error) {
      if (error?.response?.status === 401) {
        loginService.redirectInvalidUser();
        return;
      }

      dispatch(showMessage({
        severity: 'error',
        summary: 'Não foi possível carregar o seu usuário.',
      }));

      console.error(error);
    } finally {
      setLoadingUser(false);
    }
  };

  useEffect(() => {
    initializer();
  }, []);

  useEffect(() => {
    dispatch(isMobile ? deactivate() : activate());
  }, [isMobile]);

  if (loadingUser) return <></>;

  return (
    <CubeProvider cubejsApi={cubeJSService.api}>
      <div className={classes}>
        <Router basename="/">
          <AppTopbar />
          <Agro1Toast />
          <Sidebar />
          <Loader />

          <div className="layout-main">
            <Routes />
          </div>
        </Router>
      </div>
    </CubeProvider>
  );
}

export default App;
