import Filter from '../../../../components/Filter';
import { FilterField } from '../../../../components/Filter/FilterField';
import CultivaresService from '../../../../services/estoque/cultivares';
import EspeciesService from '../../../../services/geral/especies';
import LotesService from '../../../../services/sementes/lotes';
import PeneirasService from '../../../../services/sementes/peneiras';

const filters: FilterField[] = [
  {
    field: 'status', type: 'select', label: 'Status', items: ['rascunho', 'parcial', 'finalizado'],
  },
  { field: 'data', type: 'dateInterval', label: 'Data' },
  {
    field: 'cultivarId',
    type: 'autocomplete',
    label: 'Cultivar',
    service: CultivaresService,
    optionKey: 'id',
    optionLabel: 'descricaoCategoria',
    optionQuery: 'descricao',
  },
  {
    field: 'especieId',
    type: 'select',
    label: 'Espécie',
    service: EspeciesService,
    optionKey: 'id',
    optionLabel: 'descricao',
  },
  {
    field: 'lotesOrigemId',
    type: 'autocomplete',
    label: 'Lotes de origem',
    service: LotesService,
    optionKey: 'id',
    optionLabel: 'numeroLote',
  },
  {
    field: 'lotesDestinoId',
    type: 'autocomplete',
    label: 'Lotes de destino',
    service: LotesService,
    optionKey: 'id',
    optionLabel: 'numeroLote',
  },
  {
    field: 'peneiraId',
    type: 'select',
    label: 'Peneira',
    service: PeneirasService,
    optionKey: 'id',
    optionLabel: 'descricao',
  },
];

const Filters = () => (
  <Filter
    context="reembalagens"
    filters={filters}
  />
);

export default Filters;
