export enum AuditoriaType {
  planejamentos = 'Sementes::Planejamento',
  ubs = 'Sementes::Ubs',
  lotes = 'Sementes::Lote',
  amostras = 'Sementes::Amostra',
  analises = 'Sementes::Analise',
  camposProducao = 'Sementes::CampoProducao',
  pessoa = 'Geral::Pessoa',
  safra = 'Geral::Safra',
  item = 'Estoque::Item',
  peneira = 'Sementes::Peneira',
  deposito = 'Estoque::Deposito',
  script = 'Relatorio::Script',
  template = 'Relatorio::Template',
  movimento = 'Estoque::Movimento',
  descarte = 'Sementes::Descarte',
  armazem = 'Sementes::Armazem',
  reembalagem = 'Sementes::Reembalagem',
  equipamento = 'Sementes::Equipamento',
}

export enum AuditoriaAction {
  update = 'update',
  create = 'create',
  destroy = 'destroy',
}

export enum TipoEmbalagem {
  quantidade = 'UN',
  peso = 'KG'
}
