import { MenuItem } from '@agro1desenvolvimento/react-components';
import { useScope } from '@agro1desenvolvimento/react-hooks';
import { find, map } from 'lodash';
import { useLocation, matchPath } from 'react-router-dom';
import MultiNivelMenu from '../MultiNivelMenu';

const MainMenu = () => {
  const location = useLocation();
  const scope = useScope();

  const urlMatches = (path: string) => (
    matchPath(path, { path: location.pathname, exact: true })?.isExact
  );

  const getClassName = (path: string) => (urlMatches(path) ? 'active-route' : undefined);

  const classNameAndTo = (path: string):
    { className: string | undefined, to: string } => (
    { className: getClassName(path), to: path }
  );

  const setExpanded = (items: MenuItem[]): MenuItem[] => (
    map(items, (item) => (
      { ...item, expanded: !!find(item.items, (it: MenuItem) => urlMatches(it.to)) }
    ))
  );

  // TODO:  Solução provisoria para ocultar menu de relatórios depois será implementado permissões
  const isAdmin = scope === '-5';
  const items = [
    {
      label: 'Dashboard', icon: 'pi pi-fw pi-chart-bar', ...classNameAndTo('/dashboard'), visible: true,
    },
    {
      label: 'Cadastros',
      icon: 'pi pi-fw pi-list',
      visible: true,
      items: [
        {
          label: 'UBS', icon: 'pi pi-fw pi-map-marker', ...classNameAndTo('/sementes/ubs'), visible: true,
        },
        {
          label: 'Pessoas', icon: 'fas fa-users', ...classNameAndTo('/geral/pessoas'), visible: true,
        },
        {
          label: 'Campos Produção', icon: 'pi pi-fw pi-align-justify', ...classNameAndTo('/sementes/campos_producao'), visible: true,
        },
        {
          label: 'Peneiras', icon: 'pi pi-fw pi-sliders-h', ...classNameAndTo('/sementes/peneiras'), visible: true,
        },
        {
          label: 'Depósitos', icon: 'pi pi-fw pi-table', ...classNameAndTo('/estoque/depositos'), visible: true,
        },
        {
          label: 'Armazéns', icon: 'fas fa-warehouse', ...classNameAndTo('/sementes/armazens'), visible: true,
        },
        {
          label: 'Itens de estoque', icon: 'fas fa-boxes', ...classNameAndTo('/estoque/itens'), visible: true,
        },
        {
          label: 'Registros Excluídos', icon: 'pi pi-fw pi-info-circle', ...classNameAndTo('/geral/auditorias'), visible: true,
        },
      ],
    },
    {
      label: 'Beneficiamento de Sementes',
      icon: 'pi pi-fw pi-sitemap',
      visible: true,
      items: [
        {
          label: 'Planejamentos', icon: 'pi pi-fw pi-book', ...classNameAndTo('/sementes/planejamentos'), visible: true,
        },
        {
          label: 'Lotes', icon: 'pi pi-fw pi-th-large', ...classNameAndTo('/sementes/lotes'), visible: true,
        },
        {
          label: 'Movimentação no armazém', icon: 'fas fa-exchange-alt', ...classNameAndTo('/sementes/lotes/localizacao'), visible: true,
        },
        {
          label: 'Subproduto', icon: 'pi pi-fw pi-inbox', ...classNameAndTo('/sementes/subproduto'), visible: true,
        },
        {
          label: 'Vínculo de lotes', icon: 'fas fa-bezier-curve', ...classNameAndTo('/sementes/vinculo-lotes-campos'), visible: true,
        },
        {
          label: 'Reembalagens', icon: 'fas fa-cubes', ...classNameAndTo('/sementes/reembalagens'), visible: true,
        },
      ],
    },
    {
      label: 'Laboratório',
      icon: 'fas fa-diagnoses',
      visible: true,
      items: [
        {
          label: 'Equipamentos', icon: 'fas fa-microscope', ...classNameAndTo('/sementes/equipamentos'), visible: true,
        },
      ],
    },
    {
      label: 'Admin',
      icon: 'pi pi-fw pi-cog',
      visible: isAdmin,
      items: [
        {
          label: 'Relatório template', icon: 'fas fa-file-invoice', ...classNameAndTo('/geral/relatorio_template'), visible: isAdmin,
        },
        {
          label: 'Relatório scripts', icon: 'fas fa-file-code', ...classNameAndTo('/geral/relatorio_script'), visible: isAdmin,
        },
      ],
    },
  ];

  return <MultiNivelMenu model={setExpanded(items)} />;
};

export default MainMenu;
