import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import FormScript from './FormScript';
import ScriptService from '../../../services/relatorio/script';
import { TemplateScript } from '../../../@types/relatorio/script';
import { promiseWithLoaderAndMessages } from '../../../utilities';
import { falhaAoCarregar } from '../../../utilities/default-confirmation-messages';

const initialValues: TemplateScript = {
  id: '',
  descricao: '',
  script: '',
};

const NewEditScriptPage = () => {
  const dispatch = useDispatch();
  const { id } = useParams<{id: string, page: string}>();
  const [script, setScript] = useState<TemplateScript>();

  const loadScript = async () => {
    const scriptFound = await promiseWithLoaderAndMessages(
      dispatch,
      ScriptService.find(id),
      { errorMessage: falhaAoCarregar('script') },
    );

    if (scriptFound) setScript(scriptFound);
  };

  useEffect(() => {
    if (id) loadScript();
    if (!id) setScript(initialValues);
  }, []);

  if (!script) return null;
  return <FormScript script={script} />;
};

export default NewEditScriptPage;
