import { FC, useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { AutoComplete } from '@agro1desenvolvimento/react-components';
import { useDispatch } from 'react-redux';
import queryable from '../../services/queryable';
import { promiseWithLoaderAndMessages } from '../../utilities';
import { FilterFieldProps } from './FilterFieldProps';
import { falhaAoCarregar } from '../../utilities/default-confirmation-messages';

const FilterAutoComplete: FC<FilterFieldProps> = ({
  setFilterConditions, filterField, filterConditions,
}) => {
  const dispatch = useDispatch();
  const [suggestions, setSuggestions] = useState<any[]>([]);
  const [currentValue, setCurrentValue] = useState<Record<string, any> | undefined>(undefined);

  useEffect(() => {
    const initialiaze = async () => {
      const value = filterConditions[filterField.field]?.[0]?.value;
      if (isEmpty(value)) return;

      setCurrentValue(
        await promiseWithLoaderAndMessages(dispatch, filterField?.service?.find(value), {
          errorMessage: falhaAoCarregar('dados'),
        }),
      );
    };

    initialiaze();
  }, []);

  const searchItems = async (value: string) => {
    const { service, optionLabel } = filterField;
    const optionQuery = filterField.optionQuery || optionLabel;

    if (!service || !optionQuery) {
      return null;
    }

    const query = queryable.query<any>({
      service,
      conditions: [
        {
          field: optionQuery,
          operator: 'matches',
          value: `%${value}%`,
        },
      ],
    });

    const results = await promiseWithLoaderAndMessages(dispatch, query, {
      errorMessage: falhaAoCarregar('dados'),
    });

    setSuggestions(results.data);
  };

  useEffect(() => {
    if (!currentValue) {
      setFilterConditions(filterField.field, []);
    } else {
      setFilterConditions(filterField.field, [{
        field: filterField.field,
        value: currentValue[filterField.optionKey || ''],
        operator: 'eq',
      }]);
    }
  }, [currentValue]);

  return (
    <span className="p-float-label">
      <AutoComplete
        id={`filter-${filterField.field}`}
        field={filterField.optionLabel}
        value={currentValue}
        suggestions={suggestions}
        completeMethod={(e) => searchItems(e.query)}
        onChange={(e) => setCurrentValue(e.value)}
        forceSelection
        dropdown
      />
      <label htmlFor={`filter-${filterField.field}`}>{filterField.label}</label>
    </span>
  );
};

export default FilterAutoComplete;
