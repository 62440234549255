import { Button, Toolbar } from '@agro1desenvolvimento/react-components';
import { useHistory } from 'react-router-dom';

const Header = () => {
  const history = useHistory();

  const goToSync = () => {
    history.push({
      pathname: '/sementes/campos_producao/sync_sigef',
      search: location.search,
    });
  };

  const rightContents = (
    <Button label="Sincronizar com SIGEF" className="p-button-info p-ml-auto" onClick={goToSync} />
  );

  return (
    <Toolbar right={rightContents} />
  );
};

export default Header;
