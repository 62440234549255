import { Button, Toolbar } from '@agro1desenvolvimento/react-components';
import { map } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { selectReembalagensState } from '../reembalagemSlice';
import { fetchTemplatesByTipo, selectTemplateState } from '../../../Relatorio/RelatorioTemplate/relatorioTemplateslice';
import SelectTemplateToPrint from '../../../../components/SelectTemplateToPrint';
import { downloader, promiseWithLoaderAndMessages } from '../../../../utilities';
import ReembalagemService from '../../../../services/sementes/reembalagens';

const Header = () => {
  const dispatch = useDispatch();
  const { reembalagensSelecionadas } = useSelector(selectReembalagensState);
  const [downloadTemplate, setDownloadTemplate] = useState(false);
  const { templates } = useSelector(selectTemplateState);

  const print = async (templateId: string) => {
    setDownloadTemplate(false);
    const result = await promiseWithLoaderAndMessages(
      dispatch,
      ReembalagemService.report(map(reembalagensSelecionadas, 'id'), templateId),
      {
        errorMessage: {
          summary: 'Erro',
          detail: 'Falha ao gerar relatório de reembalagens',
        },
      },
    );
    downloader(URL.createObjectURL(result), 'Reembalagens');
  };

  useEffect(() => {
    dispatch(fetchTemplatesByTipo('reembalagem'));
  }, []);

  const rightContents = (
    <Button
      id="btn-gerar-pdf"
      label="Gerar PDF"
      aria-label="PDF"
      icon="pi pi-file-pdf"
      className="p-ml-auto p-button-help"
      onClick={() => setDownloadTemplate(true)}
      disabled={!reembalagensSelecionadas?.length}
    />
  );

  return (
    <>
      <Toolbar right={rightContents} />
      <SelectTemplateToPrint
        templates={templates}
        print={print}
        onClose={() => setDownloadTemplate(false)}
        targetId="btn-gerar-pdf"
        download={downloadTemplate}
      />
    </>
  );
};

export default Header;
