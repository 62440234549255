import Filter from '../../../../components/Filter';
import { FilterField } from '../../../../components/Filter/FilterField';
import cultivares from '../../../../services/estoque/cultivares';
import especies from '../../../../services/geral/especies';
import { planejamentoStatuses } from '../../../../utilities';

const filters: FilterField[] = [
  { field: 'numeroPlanejamento', type: 'number', label: 'Nº Planejamento' },
  { field: 'data', type: 'dateInterval', label: 'Data' },
  {
    field: 'status', type: 'select', label: 'Status', items: planejamentoStatuses,
  },
  {
    field: 'quantidadeLotes', type: 'number', label: 'Quantidade de Lotes',
  },
  {
    field: 'cultivarId',
    type: 'autocomplete',
    label: 'Cultivar',
    service: cultivares,
    optionKey: 'id',
    optionLabel: 'descricaoCategoria',
    optionQuery: 'descricao',
  },
  {
    field: 'especieId',
    type: 'select',
    label: 'Espécie',
    service: especies,
    optionKey: 'id',
    optionLabel: 'descricao',
  },
];

const Filters = () => (
  <Filter
    context="planejamentos"
    filters={filters}
  />
);

export default Filters;
