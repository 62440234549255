import Filter from '../../../../components/Filter';
import { FilterField } from '../../../../components/Filter/FilterField';
import cultivares from '../../../../services/estoque/cultivares';
import especies from '../../../../services/geral/especies';
import subprodutos from '../../../../services/estoque/produtos';

const filters: FilterField[] = [
  { field: 'data', type: 'dateInterval', label: 'Data' },
  {
    field: 'quantidade', type: 'number', label: 'Quantidade',
  },
  {
    field: 'subProdutoId',
    type: 'autocomplete',
    label: 'Subproduto',
    service: subprodutos,
    optionKey: 'id',
    optionLabel: 'descricao',
    optionQuery: 'descricao',
  },
  {
    field: 'cultivarId',
    type: 'autocomplete',
    label: 'Cultivar',
    service: cultivares,
    optionKey: 'id',
    optionLabel: 'descricaoCategoria',
    optionQuery: 'descricao',
  },
  {
    field: 'cultivarEspecieId',
    type: 'select',
    label: 'Espécie',
    service: especies,
    optionKey: 'id',
    optionLabel: 'descricao',
  },
];

const Filters = () => (
  <Filter
    context="descartes"
    filters={filters}
  />
);

export default Filters;
