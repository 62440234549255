import { useEffect, useState } from 'react';
import { Message } from '@agro1desenvolvimento/react-components';
import { useIsFirstRender } from '@agro1desenvolvimento/react-hooks';
import { flatten } from 'lodash';
import { Route, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectTopBarState } from '../../../components/Topbar/topBarSlice';
import { fetchLotes, selectLotesState, setLotesSelecionados } from './lotesSlice';
import Header from './components/Header';
import { selectFilterState } from '../../../components/Filter/filterSlice';
import ListLotes from './components/List';
import { usePagination } from '../../../hooks';
import { scrollToTop } from '../../../utilities';
import EditLotePage from './EditLotePage';

const LotesPage = () => {
  const [errorMessage, setErrorMessage] = useState('');
  const firstRender = useIsFirstRender();
  const history = useHistory();
  const { currentSafra, currentProdutor } = useSelector(selectTopBarState);
  const { pagination: { maxPerPage = 1 } } = useSelector(selectLotesState);
  const { currentPage, goToPage } = usePagination({ maxPerPage });
  const { lotes: filterLotes } = useSelector(selectFilterState);
  const dispatch = useDispatch();

  const dispatchFetchLotes = (page: number) => {
    if (currentProdutor && currentSafra) {
      scrollToTop();
      dispatch(setLotesSelecionados([]));
      dispatch(fetchLotes({
        conditions: flatten(Object.values(filterLotes.conditions)),
        sorts: filterLotes.sort ? [filterLotes.sort] : undefined,
        extraParameters: {
          produtor: currentProdutor.id,
          safra: currentSafra.id,
          page: page.toString(),
        },
      }));
    }
  };

  const onFormClose = () => {
    dispatchFetchLotes(1);
    history.push({
      pathname: '/sementes/lotes',
      search: history.location.search,
    });
  };

  useEffect(() => {
    if (!currentSafra || !currentProdutor) {
      setErrorMessage('Selecione a safra/produtor.');
      return;
    }

    setErrorMessage('');
    dispatchFetchLotes(currentPage);
  }, [currentSafra, currentProdutor, currentPage]);

  useEffect(() => {
    if (firstRender) return;
    goToPage(1);

    dispatchFetchLotes(1);
  }, [filterLotes]);

  return (
    <>
      <div className="p-grid">
        <div className="p-col-12">
          <div className="card">
            <Header />
            <h1>Lotes</h1>

            {errorMessage
              ? <Message severity="error" text={errorMessage} className="width-full p-justify-start" />
              : <ListLotes reloadLotes={onFormClose} />}
          </div>
        </div>
      </div>
      <Route path="/sementes/lotes/:loteId/editar">
        <EditLotePage onClose={onFormClose} />
      </Route>
    </>
  );
};

export default LotesPage;
