import { APIBaseItem } from '../@types/apiBaseItem';
import { Agro1Errors } from '../utilities';
import { CrudCreateUpdate } from '../utilities/crud';
import api from './erp-api';
import QueryableBase from './queryable-base';

abstract class CrudBase<Obj extends APIBaseItem<any>> extends QueryableBase<Obj> {
  abstract get endpoint(): string

  async delete(id: Obj['id']): Promise<''> {
    return (await api.delete(`${this.endpoint}/${id}`)).data;
  }

  async find(id: Obj['id']) {
    return (await api.get<Obj>(`${this.endpoint}/${id}`)).data;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  create(data: CrudCreateUpdate<Obj>): Promise<Obj> {
    throw Agro1Errors.notImplemented();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  update(data: Partial<CrudCreateUpdate<Obj>>): Promise<Obj> {
    throw Agro1Errors.notImplemented();
  }

  async createOrUpdate(data: Partial<CrudCreateUpdate<Obj>>) {
    const action = data.id ? 'update' : 'create';

    if (action === 'update') {
      return {
        action,
        data: await this.update(data),
      };
    }

    return {
      action,
      data: await this.create(data as CrudCreateUpdate<Obj>),
    };
  }
}

export default CrudBase;
