import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HeaderParams } from '../../../@types/headers';
import { Lote } from '../../../@types/sementes/lote';
import type { RootState } from '../../../app/rootReducer';
import { ListReturnType } from '../../../services/queryable-base';
import queryable, { Query } from '../../../services/queryable';
import LotesService from '../../../services/sementes/lotes';
import { promiseWithLoaderAndMessages } from '../../../utilities';
import { falhaAoCarregar } from '../../../utilities/default-confirmation-messages';

interface LotesState {
  lotes: Lote[],
  lotesSelecionados: Lote[],
  pagination: HeaderParams,
}

const initialState: LotesState = {
  lotes: [],
  lotesSelecionados: [],
  pagination: {
    currentPage: undefined,
    totalRecords: undefined,
    maxPerPage: undefined,
    pages: undefined,
  },
};

export const fetchLotes = createAsyncThunk<
  ListReturnType<Lote>,
  undefined | Omit<Query<Lote>, 'service'>
>(
  'sementes/lotes',
  (param = {}, { dispatch }) => {
    const query = queryable.query({
      ...param,
      service: LotesService,
    });

    return promiseWithLoaderAndMessages(
      dispatch,
      query,
      { errorMessage: falhaAoCarregar('lotes') },
    );
  },
);

export const reabrir = createAsyncThunk(
  'sementes/lotes/reabrir',
  async (lote: Lote, { dispatch }) => promiseWithLoaderAndMessages(
    dispatch,
    LotesService.reabrir(lote),
    {
      successMessage: {
        summary: 'Reaberto',
        detail: 'Lote reaberto com sucesso',
      },
      errorMessage: {
        summary: 'Erro',
        detail: 'Falha ao reabrir lote',
      },
    },
  ),
);

export const lotesSlice = createSlice({
  name: 'lotes',
  initialState,
  reducers: {
    setLotesSelecionados: (state, action: PayloadAction<Lote[]>) => {
      state.lotesSelecionados = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchLotes.fulfilled, (state, { payload }) => {
      state.lotes = payload.data;
      state.pagination = payload.headers;
    });
  },
});

export const selectLotesState = (state: RootState) => state.lotesReducer;

export const { setLotesSelecionados } = lotesSlice.actions;

export default lotesSlice.reducer;
