import { FC, useState } from 'react';
import {
  DataTable,
  Column,
  ConfirmPopup,
} from '@agro1desenvolvimento/react-components';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Ubs } from '../../../@types/sementes/ubs';
import { deleteUbs, selectUbsState } from './ubsSlice';
import DropwDownButton from '../../../components/DropwDownButtons';
import { AuditoriaType } from '../../../@types/enums';

const ListUbs: FC = () => {
  const { ubss } = useSelector(selectUbsState);
  const dispatch = useDispatch();
  const [selectedUbs, setSelectedUbs] = useState<Ubs | null>();
  const history = useHistory();

  const confirm = async (id: string) => {
    if ((await dispatch(deleteUbs(id))).meta.requestStatus === 'fulfilled') {
      setSelectedUbs(null);
    }
  };

  const optionButtonId = (id: string | undefined) => `btn-option-${id}`;

  const renderBtnAcoes = (ubs: Ubs) => (
    <div>
      <DropwDownButton
        editAction={() => history.push(`/sementes/ubs/editar/${ubs.id}`)}
        deleteAction={() => setSelectedUbs(ubs)}
        auditoriaAction={() => history.push(`/auditoria/${AuditoriaType.ubs}/${ubs.id}`)}
        optionButtonId={optionButtonId(ubs.id)}
      />
    </div>
  );

  return (
    <>
      <ConfirmPopup
        target={document.getElementById(optionButtonId(selectedUbs?.id)) || undefined}
        visible={!!selectedUbs}
        onHide={() => setSelectedUbs(undefined)}
        message="Deseja realmente excluir?"
        icon="pi pi-exclamation-triangle"
        accept={() => confirm(selectedUbs?.id as string)}
      />

      <DataTable
        value={ubss}
        emptyMessage="Nenhum registro encontrado."
        responsiveLayout="scroll"
        filterDisplay="row"
      >
        <Column
          bodyClassName="p-col-6"
          body={(rowData: Ubs) => rowData.descricao}
          header="Descrição"
          field="descricao"
          filter
          filterMatchMode="contains"
          sortable
          showFilterMenu={false}
          filterHeaderClassName="input-full-width"
        />
        <Column header="Ações" body={renderBtnAcoes} />
      </DataTable>
    </>
  );
};

export default ListUbs;
