import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../../../app/rootReducer';
import PeneirasService from '../../../services/sementes/peneiras';
import { Peneira } from '../../../@types/sementes/peneira';
import promiseWithLoaderAndMessages from '../../../utilities/promise-with-loader-and-messages';
import { ListReturnType } from '../../../services/queryable-base';
import { CrudCreateUpdate } from '../../../utilities';
import queryable, { Query } from '../../../services/queryable';
import { HeaderParams } from '../../../@types/headers';
import { toastExcludeMessages, falhaAoCarregar, toastSaveMessages } from '../../../utilities/default-confirmation-messages';

interface PeneiraState {
  peneiras: Peneira[],
  pagination: HeaderParams,
}

const initialState: PeneiraState = {
  peneiras: [],
  pagination: {
    currentPage: undefined,
    totalRecords: undefined,
    maxPerPage: undefined,
    pages: undefined,
  },

};

export const fetchPeneiras = createAsyncThunk<
  ListReturnType<Peneira>,
  Omit<Query<Peneira>, 'service'>
>(
  `${PeneirasService.endpoint}/list`,
  (params = {}, { dispatch }) => {
    const query = queryable.query({
      ...params,
      service: PeneirasService,
    });

    return promiseWithLoaderAndMessages(
      dispatch,
      query,
      { errorMessage: falhaAoCarregar('peneiras') },
    );
  },
);

export const createOrUpdatePeneira = createAsyncThunk(
  `${PeneirasService.endpoint}/createOrUpdate`,
  async (peneira: Partial<CrudCreateUpdate<Peneira>>, { dispatch, rejectWithValue }) => (
    promiseWithLoaderAndMessages(
      dispatch,
      PeneirasService.createOrUpdate(peneira),
      toastSaveMessages('peneira'),
    ).catch((error) => rejectWithValue(error.response.data))
  ),
);

export const deletePeneira = createAsyncThunk(
  `${PeneirasService.endpoint}/delete`,
  async (id: string, { dispatch }) => {
    await promiseWithLoaderAndMessages(
      dispatch,
      PeneirasService.delete(id),
      toastExcludeMessages('peneira'),
    );

    return id;
  },
);

export const peneirasSlice = createSlice({
  name: 'peneira',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(fetchPeneiras.fulfilled, (state, { payload }) => {
      state.peneiras = payload.data;
      state.pagination = payload.headers;
    });
  },
});

export const selectPeneiraState = (state: RootState) => state.peneirasReducer;

export default peneirasSlice.reducer;
