import { loginService } from '@agro1desenvolvimento/apis-js-package';
import { find, map } from 'lodash';
import api from '../erp-api';
import { LoteWithLocalizacao } from '../../@types/sementes/reembalagem';
import { Lote } from '../../@types/sementes/lote';

class LocalizacaoLotesService {
  async localizacao(lotes: Lote[]) {
    const lotesComLocalizacao = (await api.get<LoteWithLocalizacao[]>(`/${loginService.scope}/sementes/localizacao_lotes`, {
      params: { lotes_ids: map(lotes, 'id') },
    })).data;

    return map(lotesComLocalizacao, (localizacao) => {
      const loteLocalizado = localizacao.lote;

      return {
        ...localizacao,
        lote: { ...find(lotes, { id: loteLocalizado.id }), ...loteLocalizado },
      };
    });
  }
}

export default new LocalizacaoLotesService();
