import { MenuItem } from '@agro1desenvolvimento/react-components';
import { FC, SyntheticEvent } from 'react';
import SubMenu from './SubMenu';

export interface ParentMenuProps {
  model: MenuItem[],
  onItemClick?: ({ originalEvent, item }: { originalEvent: SyntheticEvent, item: MenuItem }) => void
}

const ParentMenu: FC<ParentMenuProps> = ({ model, onItemClick }) => (
  <div className="layout-menu-container">
    <SubMenu items={model} className="layout-menu" onItemClick={onItemClick} root />
  </div>
);

export default ParentMenu;
