import { keysToSnakeCaseDeep } from '@agro1desenvolvimento/utils-js';
import { snakeCase } from 'lodash';
import { APIBaseItem } from '../@types/apiBaseItem';
import QueryableBase from './queryable-base';

export type ConditionOperator = '' | 'eq'| 'matches' | 'gt' | 'lt' | 'gteq' | 'lteq' | 'not_eq' | 'in';
export type ConditionScopes = 'globalSearch';

export type Condition = {
  field: string,
  operator: ConditionOperator;
  value: any;
}

export type Sort = {
  field: string;
  direction: 1 | -1;
}

export type Query<T extends APIBaseItem<any>> = {
  service: QueryableBase<T>,
  conditions?: Condition[],
  sorts?: Sort[];
  orAnd?: 'or' | 'and',
  extraParameters?: Record<string, string>,
};

class Queryable {
  query<T extends APIBaseItem<any>>({
    service, conditions = [], sorts = [], orAnd = 'and', extraParameters = {},
  }: Query<T>) {
    const q = conditions.reduce((result, item) => ({
      ...result,
      [`${item.field}_${item.operator}`]: item.value,
    }), {});

    const s = sorts.map(({ field, direction }) => `${snakeCase(field.toString())} ${direction === -1 ? 'desc' : 'asc'}`);
    return service.listAll({
      ...extraParameters,
      q: keysToSnakeCaseDeep({
        ...q,
        s,
        m: orAnd,
      }),
    });
  }
}

export default new Queryable();
