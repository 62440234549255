import { FC } from 'react';
import { Link } from 'react-router-dom';

const ExpandedRowItemTemplate: FC<{
  label: string, value: any, hidden?: boolean, className?: string, link?: string
}> = ({
  label, value, hidden, className, link,
}) => {
  if (link && value) {
    return (
      <p className={`${className} row-item`} hidden={hidden}><b>{label}:</b> <Link to={link}>{value}</Link></p>
    );
  }

  return (
    <p className={`${className} row-item`} hidden={hidden}><b>{label}:</b> {value || 'N/D'}</p>
  );
};
export default ExpandedRowItemTemplate;
