import { loginService } from '@agro1desenvolvimento/apis-js-package';
import { Obtentor } from '../../@types/sementes/obtentor';
import api from '../erp-api';
import QueryableBase from '../queryable-base';

class ObtentoresService extends QueryableBase<Obtentor> {
  get endpoint() {
    return `/${loginService.scope}/sementes/obtentores`;
  }

  async list() {
    return (await api.get<Obtentor[]>(this.endpoint)).data;
  }
}

export default new ObtentoresService();
