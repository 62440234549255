import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../../../app/rootReducer';
import EquipamentosService from '../../../services/laboratorio/equipamentos';
import TiposEquipamentosService from '../../../services/laboratorio/tiposEquipamentos';
import { Equipamento, TipoEquipamento } from '../../../@types/laboratorio/equipamento';
import promiseWithLoaderAndMessages from '../../../utilities/promise-with-loader-and-messages';
import { ListReturnType } from '../../../services/queryable-base';
import queryable, { Query } from '../../../services/queryable';
import { HeaderParams } from '../../../@types/headers';
import { falhaAoCarregar } from '../../../utilities/default-confirmation-messages';

interface EquipamentoState {
  equipamentos: Equipamento[],
  tiposEquipamentos: TipoEquipamento[],
  pagination: HeaderParams,
}

const initialState: EquipamentoState = {
  equipamentos: [],
  tiposEquipamentos: [],
  pagination: {
    currentPage: undefined,
    totalRecords: undefined,
    maxPerPage: undefined,
    pages: undefined,
  },

};

export const fetchEquipamentos = createAsyncThunk<
  ListReturnType<Equipamento>,
  undefined | Omit<Query<Equipamento>, 'service'>
>(
  `${EquipamentosService.endpoint}/list`,
  (params = {}, { dispatch }) => {
    const query = queryable.query({
      ...params,
      service: EquipamentosService,
    });

    return promiseWithLoaderAndMessages(
      dispatch,
      query,
      { errorMessage: falhaAoCarregar('equipamentos') },
    );
  },
);

export const fetchTiposEquipamentos = createAsyncThunk<
  ListReturnType<TipoEquipamento>,
  undefined | Omit<Query<TipoEquipamento>, 'service'>
>(
  `${TiposEquipamentosService.endpoint}/list`,
  (params = {}, { dispatch }) => {
    const query = queryable.query({
      ...params,
      service: TiposEquipamentosService,
    });

    return promiseWithLoaderAndMessages(
      dispatch,
      query,
      { errorMessage: falhaAoCarregar('tipos de equipamentos') },
    );
  },
);

export const equipamentosSlice = createSlice({
  name: 'equipamento',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(fetchEquipamentos.fulfilled, (state, { payload }) => {
      state.equipamentos = payload.data;
      state.pagination = payload.headers;
    });
    builder.addCase(fetchTiposEquipamentos.fulfilled, (state, { payload }) => {
      state.tiposEquipamentos = payload.data;
    });
  },
});

export const selectEquipamentoState = (state: RootState) => state.equipamentosReducer;

export default equipamentosSlice.reducer;
