import React, {
  FC,
  useRef,
  useMemo,
  useState,
  useEffect,
} from 'react';
import {
  Dialog,
  InputText,
  Button,
  Messages,
  Dropdown,
} from '@agro1desenvolvimento/react-components';
import classNames from 'classnames';
import * as Yup from 'yup';
import { ptForm } from 'yup-locale-pt';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Peneira } from '../../../@types/sementes/peneira';
import { createOrUpdatePeneira, fetchPeneiras } from './peneiraSlice';
import findOrThrow from '../../../utilities/find-or-trow';
import catchApiErrorsAndSetFormErrors from '../../../utilities/catch-api-errors';
import ShowErrorHelper from '../../../components/ShowErrorHelper';
import { fetchEspecies, selectEspecieState } from '../../geral/Especies/especiesSlice';
import { CrudCreateUpdate, transitionOptionsTimeout as timeout } from '../../../utilities';

const Footer: React.FC<{close: () => void, valid: boolean}> = ({ close, valid }) => (
  <div>
    <Button label="Cancelar" icon="pi pi-times" onClick={close} className="p-button-text" />
    <Button label="Salvar" disabled={!valid} icon="pi pi-check" autoFocus type="submit" form="form-save-peneira" />
  </div>
);

Yup.setLocale(ptForm);
const PeneiraSchema = Yup.object().shape({
  descricao: Yup.string().required(),
  especie: Yup.string().required(),
});

interface PeneiraFormField {
  id?: string,
  descricao?: string,
  especie?: string,
}

interface FormularioProps {
  peneira?: Peneira | undefined
}

const FormPeneira: FC<FormularioProps> = ({ peneira }) => {
  const messages = useRef<Messages>(null);
  const history = useHistory();
  const { especies } = useSelector(selectEspecieState);
  const dispatch = useDispatch();
  const [modalVisible, setModalVisible] = useState(true);
  const onClose = () => history.push('/sementes/peneiras');

  const fechaModal = () => {
    setModalVisible(false);
  };

  const form = useFormik<PeneiraFormField>({
    validationSchema: PeneiraSchema,
    initialValues: {
      id: peneira?.id,
      descricao: peneira?.descricao,
      especie: peneira?.especie.id,
    },
    onSubmit: async (values) => {
      const toSubmit: Partial<CrudCreateUpdate<Peneira>> = {
        id: values.id,
        descricao: values.descricao,
        especie: findOrThrow(especies, { id: values.especie }),
      };

      const promise = await dispatch(createOrUpdatePeneira(toSubmit));
      const success = promise.meta.requestStatus === 'fulfilled';
      if (success) {
        fechaModal();
        dispatch(fetchPeneiras({}));
      } else {
        catchApiErrorsAndSetFormErrors(form.setFieldError, promise.payload, true);
      }
    },
  });

  useEffect(() => {
    dispatch(fetchEspecies());
  }, []);

  const classes = useMemo(() => (
    classNames({ 'p-invalid': form.errors.descricao })
  ), [form.errors]);

  return (
    <Dialog
      onHide={fechaModal}
      transitionOptions={{ timeout, onExited: onClose }}
      visible={modalVisible}
      maximizable
      header={peneira?.id ? 'Alterar peneira' : 'Adicionar peneira'}
      className="dialog-md"
      footer={<Footer close={fechaModal} valid={form.dirty && form.isValid} />}
    >
      <form onSubmit={form.handleSubmit} id="form-save-peneira">
        <div className="p-fluid">
          <div className="p-field">
            <label aria-labelledby="especie" htmlFor="especie">Espécie</label>
            <Dropdown
              autoFocus
              value={form.values.especie}
              name="especie"
              id="especie"
              options={especies}
              optionLabel="descricao"
              optionValue="id"
              disabled={!especies.length || !!form.values.id}
              onChange={form.handleChange}
              required
              placeholder="Selecione a Espécie"
              appendTo={document.body}
              filter
              className={classNames({ 'p-invalid': form.errors.especie })}
              aria-describedby="especie-help"
            />
            <ShowErrorHelper id="especie-help" error={form.errors.especie} />
          </div>
          <div className="p-field">
            <label htmlFor="descricao">Descrição</label>
            <InputText
              required
              name="descricao"
              id="descricao"
              value={form.values.descricao}
              onChange={form.handleChange}
              className={classes}
              aria-describedby="descricao-help"
            />
            <ShowErrorHelper id="descricao-help" error={form.errors.descricao} />
          </div>
        </div>
      </form>
      <Messages ref={messages} />
    </Dialog>
  );
};

export default FormPeneira;
