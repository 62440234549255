import {
  InputText,
  Calendar,
  Dropdown,
  AutoComplete,
  AutoCompleteChangeParams,
  MultiSelect,
} from '@agro1desenvolvimento/react-components';
import {
  FC,
  useEffect,
  useMemo,
  useState,
} from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { ptForm } from 'yup-locale-pt';
import { useFormik } from 'formik';
import {
  find, isEmpty, isUndefined, uniq,
} from 'lodash';
import dayjs from 'dayjs';
import { selectTopBarState } from '../../../../components/Topbar/topBarSlice';
import { createOrUpdatePlanejamento } from '../planejamentosSlice';
import { CrudCreateUpdate } from '../../../../utilities/crud';
import { Planejamento } from '../../../../@types/sementes/planejamento';
import { fetchUbs, selectUbsState } from '../../Ubs/ubsSlice';
import { fetchEspecies, selectEspecieState } from '../../../geral/Especies/especiesSlice';
import { fetchDepositos, selectDepositoState } from '../../../Estoque/Depositos/depositosSlice';
import type { PlanejamentoPageRouteParams } from './planejamentoPageRouteParams';
import findOrThrow from '../../../../utilities/find-or-trow';
import catchApiErrorsAndSetFormErrors from '../../../../utilities/catch-api-errors';
import ShowErrorHelper from '../../../../components/ShowErrorHelper';
import { selectCultivarState } from '../../../Estoque/Cultivar/cultivaresSlice';
import { Cultivar } from '../../../../@types/estoque/item';
import { selectCamposProducaoState } from '../../CamposProducao/camposProducaoSlice';
import { selectNewEditPlanejamentoState, setFormDirty, setFormIsValid } from '../newEditPlanejamentoSlice';
import { Safra } from '../../../../@types/geral/safra';
import saldosCamposProducao from '../../../../services/sementes/saldos-campos-producao';
import { promiseWithLoaderAndMessages } from '../../../../utilities';
import { SaldoCampoProducao } from '../../../../@types/sementes/saldoCampoProducao';
import CampoProducaoItemTemplate from './CampoProducaoItemTemplate';
import { CampoProducao } from '../../../../@types/sementes/campoProducao';
import { falhaAoCarregar } from '../../../../utilities/default-confirmation-messages';
import dispatchFetchCultivares from '../../../../utilities/dispatch-fetch-cultivares';
import dispatchFetchCamposProducao from '../../../../utilities/dispatch-fetch-campos-producao';

Yup.setLocale(ptForm);
const PlanejamentoSchema = Yup.object().shape({
  safra: Yup.string().required(),
  ubs: Yup.string().required(),
  cultivar: Yup.string().required(),
  especie: Yup.string().required(),
  depositoMateriaPrima: Yup.string().required(),
  data: Yup.date().required(),
  depositoDestino: Yup.string().required(),
  campoProducao: Yup.array(),
});

const initialFormValues = (safra: Safra): PlanejamentoFormField => ({
  safra: safra.id,
  especie: undefined,
  cultivar: undefined,
  depositoMateriaPrima: undefined,
  ubs: undefined,
  data: new Date(),
  depositoDestino: undefined,
  campoProducoes: [],
});

const PlanejamentoForm: FC<PropTypes> = ({
  visible, closeModal, fetchPlanejamentos,
}) => {
  const { currentSafra, currentProdutor } = useSelector(selectTopBarState);
  const { ubss } = useSelector(selectUbsState);
  const { especies } = useSelector(selectEspecieState);
  const { depositos } = useSelector(selectDepositoState);
  const { cultivares } = useSelector(selectCultivarState);
  const { planejamento } = useSelector(selectNewEditPlanejamentoState);
  const { campos: campoProducoes } = useSelector(selectCamposProducaoState);
  const { action } = useParams<PlanejamentoPageRouteParams>();
  const history = useHistory();
  const dispatch = useDispatch();
  const creating = useMemo(() => ['duplicar', 'novo'].includes(action), [action]);
  const [isPlanejamentoEditable, setPlanejamentoEditable] = useState(true);
  const [currentCultivar, setCurrentCultivar] = useState<Cultivar | undefined>(undefined);
  const [saldosCampoProducao, setSaldosCampoProducao] = useState<SaldoCampoProducao[]>([]);

  const goToList = () => history.replace({
    pathname: '/sementes/planejamentos/',
    search: location.search,
  });

  if (!currentSafra || !currentProdutor) {
    goToList();
    return null;
  }

  const form = useFormik<PlanejamentoFormField>({
    validationSchema: PlanejamentoSchema,
    initialValues: initialFormValues(currentSafra),
    async onSubmit(values) {
      if (!visible) return;

      const toSubmit: Partial<CrudCreateUpdate<Planejamento>> = {
        data: values.data?.toISOString() || '',
        safra: currentSafra,
        cultivar: currentCultivar,
        depositoMateriaPrima: findOrThrow(depositos, { id: values.depositoMateriaPrima }),
        ubs: findOrThrow(ubss, { id: values.ubs }),
        depositoDestino: findOrThrow(depositos, { id: values.depositoDestino }),
      };

      if (!isEmpty(form.values.campoProducoes)) {
        toSubmit.campoProducoes = fetchCampoProducoes();
      }

      if (!creating) {
        toSubmit.id = planejamento?.id;
      } else {
        toSubmit.status = 'criado';
      }

      const promise = await dispatch(createOrUpdatePlanejamento(toSubmit));
      const success = promise.meta.requestStatus === 'fulfilled';

      if (success) {
        closeModal();
        fetchPlanejamentos();
      } else {
        catchApiErrorsAndSetFormErrors(form.setFieldError, promise.payload, true);
      }
    },
  });

  const fetchCultivaresByParams = async (query = '') => {
    const especieId = find(especies, { id: form.values.especie })?.id;
    dispatchFetchCultivares({
      dispatch,
      query,
      extraConditions: [
        {
          field: 'especie_id',
          operator: 'eq',
          value: especieId,
        },
      ],
      extraParameters: {
        safra: currentSafra.id,
        produtor: currentProdutor.id,
      },
    });
  };

  const loadLists = () => {
    dispatch(fetchUbs({ produtor: currentProdutor }));
    dispatch(fetchEspecies({
      extraParameters: {
        safra: currentSafra.id,
        produtor: currentProdutor.id,
      },
    }));
    dispatch(fetchDepositos());
  };

  const changedField = (value: any) => {
    form.handleChange(value);
  };

  const changedEspecie = (value: any) => {
    setCurrentCultivar(undefined);
    changedField(value);
  };

  const fetchCampoProducoes = () => (
    campoProducoes.filter((campoProducao) => form.values.campoProducoes.includes(campoProducao.id))
  );

  const changeCultivar = async (value: AutoCompleteChangeParams) => {
    const cultivarObject = {
      ...value,
      target: {
        ...value.target,
        value: value.target.value.id,
      },
    };
    setCurrentCultivar(value.target.value);
    changedField(cultivarObject);
  };

  useEffect(() => {
    const loadSaldosCamposProducao = async () => {
      if (!form.values.cultivar || !currentSafra || !currentProdutor) return;
      const saldos = await promiseWithLoaderAndMessages(
        dispatch,
        saldosCamposProducao.saldos({
          cultivarId: form.values.cultivar,
          safraId: currentSafra.id,
          produtorId: currentProdutor.id,
          depositoId: form.values.depositoMateriaPrima,
        }),
        { errorMessage: falhaAoCarregar('saldos de campos de produção') },
      );

      setSaldosCampoProducao(saldos);
    };

    loadSaldosCamposProducao();
  }, [form.values.cultivar, currentProdutor, currentSafra, form.values.depositoMateriaPrima]);

  useEffect(() => {
    dispatch(setFormIsValid(form.isValid));
  }, [form.isValid]);

  useEffect(() => {
    dispatch(setFormDirty(form.dirty));
  }, [form.dirty]);

  useEffect(() => {
    loadLists();
  }, []);

  useEffect(() => {
    const initializeFields = async () => {
      const fields: PlanejamentoFormField = initialFormValues(currentSafra);

      if (planejamento) {
        fields.ubs = planejamento.ubs?.id;
        fields.data = dayjs(planejamento.data).toDate();
        fields.depositoMateriaPrima = planejamento.depositoMateriaPrima?.id;
        fields.especie = planejamento.cultivar?.especie.id;
        fields.cultivar = planejamento.cultivar?.id;
        fields.safra = planejamento.safra.id;
        fields.depositoDestino = planejamento.depositoDestino?.id;
        fields.campoProducoes = planejamento.campoProducoes?.map((campo) => campo.id) || [];

        setCurrentCultivar(planejamento.cultivar);
        setPlanejamentoEditable(creating || planejamento?.status === 'criado');
      }

      if (ubss.length === 1) fields.ubs = ubss[0].id;

      form.resetForm({ values: fields });
    };

    initializeFields();
  }, [planejamento, ubss]);

  useEffect(() => {
    if (form.values.campoProducoes && !fetchCampoProducoes().length) {
      form.setFieldValue('campoProducoes', []);
    }
  }, [campoProducoes]);

  useEffect(() => {
    if (!currentCultivar?.id) return;

    dispatchFetchCamposProducao({
      dispatch,
      cultivarId: currentCultivar.id,
      produtorId: currentProdutor.id,
      safraId: currentSafra.id,
      extraParameters: { ativos: '1' },
    });
  }, [currentCultivar, currentProdutor, currentSafra]);

  return (
    <form onSubmit={form.handleSubmit} id="form-planejamento" hidden={!visible}>
      <div className="p-fluid p-formgrid p-grid">
        <div className="p-field p-disabled p-col-6 p-md-4">
          <label htmlFor="safra">Safra</label>
          <InputText
            name="safra"
            id="safra"
            value={currentSafra?.descricao}
            readOnly
            aria-describedby="safra-help"
          />
          <ShowErrorHelper id="safra-help" error={form.errors.safra} />
        </div>
        <div className="p-field p-col-6 p-md-4">
          <label htmlFor="data">Data</label>
          <Calendar
            id="data"
            value={form.values.data}
            onChange={changedField}
            showIcon
            className={classNames({ 'p-invalid': form.errors.data })}
            appendTo={document.body}
            placeholder="Data"
            aria-describedby="data-help"
            disabled={!isPlanejamentoEditable}
          />
          <ShowErrorHelper id="data-help" error={form.errors.data} />
        </div>

        <div className="p-field p-col-6 p-md-4">
          <label aria-labelledby="ubs" htmlFor="ubs">UBS</label>
          <Dropdown
            value={form.values.ubs}
            name="ubs"
            id="ubs"
            options={ubss}
            optionLabel="descricao"
            optionValue="id"
            disabled={!ubss.length || !isPlanejamentoEditable}
            onChange={changedField}
            required
            placeholder="Selecione a UBS"
            filter
            className={classNames({ 'p-invalid': form.errors.ubs })}
            appendTo={document.body}
            aria-describedby="ubs-help"
          />
          <ShowErrorHelper id="ubs-help" error={form.errors.ubs} />
        </div>

        <div className="p-field p-col-6">
          <label aria-labelledby="depositoMateriaPrima" htmlFor="depositoMateriaPrima">Depósito Matéria Prima</label>
          <Dropdown
            value={form.values.depositoMateriaPrima}
            name="depositoMateriaPrima"
            id="depositoMateriaPrima"
            options={depositos}
            optionLabel="descricao"
            optionValue="id"
            disabled={!depositos.length || !isPlanejamentoEditable}
            onChange={changedField}
            required
            placeholder="Selecione o Depósito de matéria prima"
            filter
            className={classNames({ 'p-invalid': form.errors.depositoMateriaPrima })}
            appendTo={document.body}
            aria-describedby="deposito-materia-help"
          />
          <ShowErrorHelper id="deposito-materia-help" error={form.errors.depositoMateriaPrima} />
        </div>

        <div className="p-field p-col-6">
          <label aria-labelledby="depositoDestino" htmlFor="depositoDestino">Depósito Destino</label>
          <Dropdown
            value={form.values.depositoDestino}
            name="depositoDestino"
            id="depositoDestino"
            options={depositos}
            optionLabel="descricao"
            optionValue="id"
            disabled={!depositos.length || !isPlanejamentoEditable}
            onChange={changedField}
            required
            placeholder="Selecione o Depósito de destino"
            filter
            className={classNames({ 'p-invalid': form.errors.depositoDestino })}
            appendTo={document.body}
            aria-describedby="deposito-destino-help"
          />
          <ShowErrorHelper id="deposito-destino-help" error={form.errors.depositoDestino} />
        </div>

        <div className="p-field p-col-4">
          <label aria-labelledby="especie" htmlFor="especie">Espécie</label>
          <Dropdown
            value={form.values.especie}
            name="especie"
            id="especie"
            options={especies}
            optionLabel="descricao"
            optionValue="id"
            disabled={!especies.length || !isPlanejamentoEditable}
            onChange={changedEspecie}
            required
            placeholder="Selecione a Espécie"
            appendTo={document.body}
            filter
            className={classNames({ 'p-invalid': form.errors.especie })}
            aria-describedby="especie-help"
          />
          <ShowErrorHelper id="especie-help" error={form.errors.especie} />
        </div>

        <div className="p-field p-col-4">
          <label aria-labelledby="cultivar" htmlFor="cultivar">Cultivar</label>
          <AutoComplete
            dropdown
            value={currentCultivar}
            name="cultivar"
            id="cultivar"
            field="descricaoCategoria"
            suggestions={cultivares}
            disabled={!isPlanejamentoEditable || isUndefined(form.values.especie)}
            onChange={changeCultivar}
            placeholder="Selecione a Cultivar"
            appendTo={document.body}
            className={classNames({ 'p-invalid': form.errors.cultivar })}
            aria-describedby="cultivar-help"
            completeMethod={(e) => fetchCultivaresByParams(e.query)}
          />
          <ShowErrorHelper id="cultivar-help" error={form.errors.cultivar} />
        </div>

        <div className="p-field p-col-4">
          <label aria-labelledby="campoProducao" htmlFor="campoProducao">Campo de Produção</label>
          <MultiSelect
            value={form.values.campoProducoes}
            optionValue="id"
            optionLabel="identificacaoSigef"
            name="campoProducoes"
            id="campoProducoes"
            options={campoProducoes}
            disabled={!campoProducoes?.length || !isPlanejamentoEditable}
            onChange={changedField}
            placeholder="Selecione o Campo de Produção"
            appendTo={document.body}
            filter
            className={classNames({ 'p-invalid': form.errors.campoProducoes })}
            aria-describedby="campo-producao-help"
            showClear
            itemTemplate={
              (campoProducao: CampoProducao) => (
                <CampoProducaoItemTemplate
                  campoProducao={campoProducao}
                  saldosCampoProducao={saldosCampoProducao}
                />
              )
            }
          />
          <ShowErrorHelper
            id="campo-producao-help"
            error={uniq(form.errors.campoProducoes).join(',')}
          />
        </div>
      </div>
    </form>
  );
};

interface PlanejamentoFormField {
  safra: string,
  ubs?: string,
  cultivar?: string
  especie?: string,
  depositoMateriaPrima?: string,
  data?: Date,
  depositoDestino?: string,
  campoProducoes: string[],
}

interface PropTypes {
  visible: boolean,
  closeModal: () => void,
  fetchPlanejamentos: () => void,
}

export default PlanejamentoForm;
