import { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { CSSTransition } from 'react-transition-group';
import {
  Button, Dropdown,
} from '@agro1desenvolvimento/react-components';
import { loginService } from '@agro1desenvolvimento/apis-js-package';
import { useCurrentUser, useScope } from '@agro1desenvolvimento/react-hooks';
import { useDispatch, useSelector } from 'react-redux';
import { removeProjectItens } from '../../../utilities/local-storage';
import {
  setCurrentSafra,
  setCurrentProdutor,
  selectTopBarState,
} from '../../Topbar/topBarSlice';

const logout = () => {
  loginService.logout();
  removeProjectItens();
};

const changeContext = () => {
  loginService.redirectToScopeSelection();
  removeProjectItens();
};

const Profile = () => {
  const history = useHistory();
  const currentUser = useCurrentUser();
  const [expanded, setExpanded] = useState(false);
  const scope = useScope();
  const dispatch = useDispatch();
  const {
    safras,
    currentSafra,
    produtores,
    currentProdutor,
  } = useSelector(selectTopBarState);

  const toggleExpanded = (event: React.MouseEvent) => {
    setExpanded((prevState) => !prevState);
    event.preventDefault();
  };

  const classes = useMemo(() => (
    classNames({ 'layout-profile-expanded': expanded })
  ), [expanded]);

  return (
    <div className="layout-profile">
      <div>
        <img src={currentUser?.avatar} alt="Profile" />
      </div>

      <Button
        className="p-button-link layout-profile-link"
        onClick={toggleExpanded}
        label={`${currentUser?.name} - ${scope}`}
        icon="pi pi-fw pi-cog"
        iconPos="right"
        title="Opções"
      />

      <CSSTransition
        classNames="p-toggleable-content"
        timeout={{ enter: 1000, exit: 450 }}
        in={expanded}
        unmountOnExit
      >
        <ul className={classes}>

          <li>
            <div className="p-grid p-py-1 p-mr-1 dropdown-button">
              <i className="pi pi-briefcase p-col-1" />
              <Dropdown
                value={currentProdutor?.id}
                onChange={(event) => dispatch(setCurrentProdutor(event.value))}
                options={produtores}
                optionValue="id"
                optionLabel="nomeComCidade"
                placeholder="Produtor"
                required
                appendTo={document.body}
              />
            </div>
          </li>
          <li>
            <div className="p-grid p-py-1 p-mr-1 dropdown-button">
              <i className="pi pi-calendar p-col-1 ml-2" />
              <Dropdown
                value={currentSafra?.id}
                onChange={(event) => dispatch(setCurrentSafra(event.value))}
                options={safras}
                optionValue="id"
                optionLabel="descricao"
                placeholder="Safra"
                required
                appendTo={document.body}
              />
            </div>
          </li>
          <li>
            <Button
              type="button"
              className="p-button-link"
              label="Configurações"
              icon="pi pi-fw pi-cog"
              onClick={() => history.push('/sementes/configs')}
            />
          </li>
          <li>
            <Button
              type="button"
              className="p-button-link"
              label="Alterar escopo"
              icon="pi pi-fw pi-globe"
              onClick={changeContext}
            />
          </li>
          <li>
            <Button
              type="button"
              className="p-button-link"
              label="Logout"
              icon="pi pi-fw pi-power-off"
              onClick={logout}
            />
          </li>
        </ul>
      </CSSTransition>
    </div>
  );
};

export default Profile;
