import { loginService } from '@agro1desenvolvimento/apis-js-package';
import { Categoria } from '../../@types/sementes/categoria';
import CrudBase from '../crud-base';

class CategoriasService extends CrudBase<Categoria> {
  get endpoint() {
    return `/${loginService.scope}/sementes/categorias`;
  }
}

export default new CategoriasService();
