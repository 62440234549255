import { loginService } from '@agro1desenvolvimento/apis-js-package';
import { map, uniqBy } from 'lodash';
import { keysToSnakeCase, keysToSnakeCaseDeep } from '@agro1desenvolvimento/utils-js';
import { LoteReembaladoParaConfirmacao, Reembalagem } from '../../@types/sementes/reembalagem';
import CrudBase from '../crud-base';
import { ListReturnType } from '../queryable-base';
import LotesService, { CrudCreateUpdateLote } from './lotes';
import GeradorService from '../relatorio/gerador';
import api from '../erp-api';
import { CrudCreateUpdate } from '../../utilities';

class ReembalagemService extends CrudBase<Reembalagem> {
  get endpoint() {
    return `/${loginService.scope}/sementes/reembalagens`;
  }

  async listAll(params?: any): Promise<ListReturnType<Reembalagem>> {
    const reembalagens = await super.listAll(params);
    return { ...reembalagens, data: map(reembalagens.data, this.responseSerializer) };
  }

  responseSerializer(reembalagem: Reembalagem) {
    return {
      ...reembalagem,
      lotesOrigem: reembalagem.lotesOrigem.map(LotesService.responseSerializer),
      lotesDestino: map(reembalagem.lotesDestino, LotesService.responseSerializer),
    };
  }

  async createWithLotes(reembalagem: Omit<Reembalagem, 'id'>, lotesDestino?: CrudCreateUpdateLote[]) {
    return (await api.post<Reembalagem[]>(this.endpoint, {
      reembalagens: [this.toSnakeCase(reembalagem, lotesDestino)],
    })).data[0];
  }

  async report(reembalagemIds: Array<string>, id: string) {
    return GeradorService.gerar('reembalagem', { reembalagemIds }, id);
  }

  async confirmar(reembalagemId: string, lotes: LoteReembaladoParaConfirmacao[]) {
    const buildPayload = (lotesToConfirm: LoteReembaladoParaConfirmacao[]) => (
      map(lotesToConfirm, (loteMovimento) => (
        keysToSnakeCase({
          loteId: loteMovimento.lote.id,
          quantidade: loteMovimento.quantidade,
          dataConfirmacao: loteMovimento.dataConfirmacao,
          blocoDestinoId: loteMovimento.blocoDestino.id,
        })
      ))
    );

    return (await api.patch<Reembalagem[]>(`${this.endpoint}/${reembalagemId}/confirmar`, {
      lotes_confirmados: buildPayload(lotes),
    })).data[0];
  }

  toSnakeCase(reembalagem: CrudCreateUpdate<Reembalagem>, lotesDestino?: CrudCreateUpdateLote[]) {
    const lotesDestinoIds = lotesDestino ? [] : map(reembalagem.lotesDestino, 'id');

    return keysToSnakeCaseDeep({
      safraId: reembalagem.safra.id,
      produtorId: reembalagem.produtor.id,
      cultivarId: reembalagem.cultivar.id,
      peneiraId: reembalagem.peneira?.id,
      depositoId: reembalagem.deposito,
      periodo: reembalagem.periodo,
      data: reembalagem.data,
      status: reembalagem.status,
      observacao: reembalagem.observacao,
      lotesOrigemIds: map(uniqBy(reembalagem.lotesOrigem, 'id'), 'id'),
      lotesDestinoIds: lotesDestinoIds || [],
      reembalagemLoteDestinoAttributes: map(lotesDestino,
        (lote: CrudCreateUpdateLote) => ({
          loteAttributes: {
            numeroLote: lote.numeroLote,
            cultivarId: lote.cultivar?.id,
            safraId: lote.safra?.id,
            peneiraId: lote.peneira?.id,
            dataLote: lote.dataLote,
            unidadeId: lote.unidade?.id,
            quantidade: lote.quantidade,
            pesoTotalKg: (lote.pesoEmbalagem || 0) * (lote.quantidade || 0),
            pesoEmbalagem: lote.pesoEmbalagem,
            observacao: lote.observacao,
            fornecedorId: lote.fornecedor?.id,
            status: lote.status,
            pms: lote.pms,
            ubsId: lote.ubs?.id,
            periodo: lote.periodo,
            dataValidade: lote.dataValidade,
          },
        })),
    });
  }
}

export default new ReembalagemService();
