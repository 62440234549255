const parseEnv = () => {
  if (window.location.hostname === 'app-homologacao.agro1.inf.br') {
    return 'homologation';
  }

  if (window.location.hostname === 'localhost') {
    return 'development';
  }

  if (window.location.hostname === 'web') {
    return 'cypress';
  }

  return process.env.NODE_ENV;
};

export const ENV = parseEnv();

export const DAYJS_FORMATS = {
  american: 'YYYY-MM-DD',
  default: 'L LT',
  withoutTime: 'L',
};

export const CROQUI_ELEMENT_ICONS = {
  porta: 'fas fa-door-open',
  parede: 'fas fa-square-full',
  bloco: 'fas fa-box',
};
