import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ChartRenderer from './components/ChartRenderer';
import Dashboard from './components/Dashboard';
import DashboardItem from './components/DashboardItem';
import { Dashboard as DashboardType } from '../../../@types/geral/dashboard';
import { fetchDashboards, selectDashboardsState } from './dashboardSlice';
import Header from './Header';

const defaultLayout = (item: DashboardType) => ({
  x: item.layout.x || 0,
  y: item.layout.y || 0,
  w: item.layout.w || 4,
  h: item.layout.h || 8,
  minW: 4,
  minH: 8,
});

const Empty = () => (
  <div
    style={{
      textAlign: 'center',
      padding: 12,
    }}
  >
    <h2>Nenhum gráfico cadastrado.</h2>
  </div>
);

const DashboardPage = () => {
  const dispatch = useDispatch();
  const { dashboards } = useSelector(selectDashboardsState);

  useEffect(() => {
    dispatch(fetchDashboards());
  }, []);

  return (
    <>
      <Header />
      {dashboards.length ? (
        <Dashboard dashboardItems={dashboards}>
          {dashboards.map((item) => (
            <div key={item.id} data-grid={defaultLayout(item)}>
              <DashboardItem
                itemId={item.id}
                title={item.titulo}
                onDeleted={() => dispatch(fetchDashboards())}
              >
                <ChartRenderer
                  chartTitle={item.titulo}
                  vizState={item.payload}
                  extraVizState={item.extraPayload}
                />
              </DashboardItem>
            </div>
          ))}
        </Dashboard>
      ) : (
        <Empty />
      )}
    </>
  );
};

export default DashboardPage;
