import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { Lote, MovimentoLote } from '../../../@types/sementes/lote';
import { Planejamento } from '../../../@types/sementes/planejamento';
import type { RootState } from '../../../app/rootReducer';
import { dateAmericanFormat, promiseWithLoaderAndMessages } from '../../../utilities';
import planejamentosService from '../../../services/sementes/planejamentos';
import { falhaAoCarregar } from '../../../utilities/default-confirmation-messages';

interface NewEditPlanejamento {
  mode: 'newEdit' | 'list',
  dataConfirmacao: string,
  lotesConfirmados: Lote[],
  lotesExcluidos: Lote[],
  formDirty: boolean,
  formIsValid: boolean,
  lotes: Lote[],
  planejamento?: Planejamento;
  lotesMovimentados: MovimentoLote[]
}

const initialState: NewEditPlanejamento = {
  mode: 'list',
  dataConfirmacao: dateAmericanFormat(new Date()),
  formDirty: false,
  formIsValid: false,
  lotesMovimentados: [],
  lotesConfirmados: [],
  lotesExcluidos: [],
  lotes: [],
};

export const fetchPlanejamentoLotes = createAsyncThunk<
  Lote[],
  {planejamento: Planejamento}
>(
  'newEditPlanejamento/lotes',
  ({ planejamento }, { dispatch }) => (
    promiseWithLoaderAndMessages(
      dispatch,
      planejamentosService.lotes(planejamento?.id),
      { errorMessage: falhaAoCarregar('lotes') },
    )
  ),
);

export const fetchPlanejamento = createAsyncThunk<
  Planejamento,
  { planejamentoId: string }
>(
  'newEditPlanejamento/planejamento',
  ({ planejamentoId }, { dispatch }) => (
    promiseWithLoaderAndMessages(
      dispatch,
      planejamentosService.find(planejamentoId),
      { errorMessage: falhaAoCarregar('planejamento') },
    )
  ),
);

export const newEditPlanejamento = createSlice({
  name: 'newEditPlanejamento',
  initialState,
  reducers: {
    setMode: (state, action: PayloadAction<'newEdit' | 'list'>) => {
      state.mode = action.payload;
    },
    setLotesConfirmados: (state, action: PayloadAction<Lote[]>) => {
      state.lotesConfirmados = action.payload;
    },
    setLotesMovimentados: (state, action: PayloadAction<MovimentoLote[]>) => {
      state.lotesMovimentados = action.payload;
    },
    setLotesExcluidos: (state, action: PayloadAction<Lote[]>) => {
      state.lotesExcluidos = action.payload;
    },
    setLotes: (state, action: PayloadAction<Lote[]>) => {
      state.lotes = action.payload;
    },
    setDataConfirmacao: (state, action: PayloadAction<string>) => {
      state.dataConfirmacao = action.payload;
    },
    setFormDirty: (state, action: PayloadAction<boolean>) => {
      state.formDirty = action.payload;
    },
    setFormIsValid: (state, action: PayloadAction<boolean>) => {
      state.formIsValid = action.payload;
    },
    setPlanejamento: (state, action: PayloadAction<Planejamento | undefined>) => {
      state.planejamento = action.payload;
    },
    resetNewEditPlanejamentoState: () => initialState,
  },
  extraReducers(builder) {
    builder.addCase(fetchPlanejamento.pending, (state) => {
      state.planejamento = undefined;
    });
    builder.addCase(fetchPlanejamento.fulfilled, (state, { payload }) => {
      state.planejamento = payload;
    });

    builder.addCase(fetchPlanejamentoLotes.pending, (state) => {
      state.lotes = [];
    });
    builder.addCase(fetchPlanejamentoLotes.fulfilled, (state, { payload }) => {
      state.lotes = payload;
    });
  },
});

export const selectNewEditPlanejamentoState = (state: RootState) => (
  state.newEditPlanejamentoReducer
);

export const {
  setMode,
  setLotesConfirmados,
  setLotesExcluidos,
  setDataConfirmacao,
  setFormDirty,
  setFormIsValid,
  setLotes,
  setPlanejamento,
  resetNewEditPlanejamentoState,
  setLotesMovimentados,
} = newEditPlanejamento.actions;

export default newEditPlanejamento.reducer;
