import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { map, omit } from 'lodash';
import { Lote, MovimentoLote } from '../../../../@types/sementes/lote';
import type { RootState } from '../../../../app/rootReducer';
import { dateAmericanFormat, promiseWithLoaderAndMessages } from '../../../../utilities';
import ReembalagemService from '../../../../services/sementes/reembalagens';
import { falhaAoCarregar } from '../../../../utilities/default-confirmation-messages';
import { Reembalagem } from '../../../../@types/sementes/reembalagem';

interface ConfirmacaoReembalagem {
  dataConfirmacao: string,
  lotesConfirmados: Lote[],
  lotes: Lote[],
  reembalagem: Reembalagem | undefined;
  lotesMovimentados: MovimentoLote[]
}

const initialState: ConfirmacaoReembalagem = {
  dataConfirmacao: dateAmericanFormat(new Date()),
  lotesMovimentados: [],
  lotesConfirmados: [],
  lotes: [],
  reembalagem: undefined,
};

export const fetchReembalagem = createAsyncThunk<
  Reembalagem,
  { reembalagemId: string }
>(
  'confirmacaoReembalagem/reembalagem',
  ({ reembalagemId }, { dispatch }) => (
    promiseWithLoaderAndMessages(
      dispatch,
      ReembalagemService.find(reembalagemId),
      {
        errorMessage: falhaAoCarregar('reembalagem'),
      },
    )
  ),
);

export const confirmLotesReembalagem = createAsyncThunk(
  'confirmacaoReembalagem/confirmarLotes',
  async (_, { dispatch, getState }) => {
    const {
      lotesMovimentados,
      dataConfirmacao,
      reembalagem,
    } = selectConfirmacaoReembalagemState(getState() as RootState);

    if (!reembalagem) return;

    const lotesParaConfirmacao = map(lotesMovimentados, (movimentoLote) => omit({
      ...movimentoLote,
      dataConfirmacao,
    }, ['blocoOrigem', 'data']));

    await promiseWithLoaderAndMessages(
      dispatch,
      ReembalagemService.confirmar(reembalagem.id, lotesParaConfirmacao),
      {
        errorMessage: {
          summary: 'Erro',
          detail: 'Falha ao confirmar lotes',
        },
        successMessage: {
          summary: 'Confirmado',
          detail: 'Lotes confirmados com sucesso',
        },
      },
    );
  },
);

export const confirmacaoReembalagem = createSlice({
  name: 'confirmacaoReembalagem',
  initialState,
  reducers: {
    setLotesConfirmados: (state, action: PayloadAction<Lote[]>) => {
      state.lotesConfirmados = action.payload;
    },
    setLotesMovimentados: (state, action: PayloadAction<MovimentoLote[]>) => {
      state.lotesMovimentados = action.payload;
    },
    setLotes: (state, action: PayloadAction<Lote[]>) => {
      state.lotes = action.payload;
    },
    setDataConfirmacao: (state, action: PayloadAction<string>) => {
      state.dataConfirmacao = action.payload;
    },
    setReembalagem: (state, action: PayloadAction<Reembalagem | undefined>) => {
      state.reembalagem = action.payload;
    },
    clearConfirmacaoReembalagemState: () => initialState,
  },
  extraReducers(builder) {
    builder.addCase(fetchReembalagem.fulfilled, (state, { payload }) => {
      state.reembalagem = payload;
    });
  },
});

export const selectConfirmacaoReembalagemState = (state: RootState) => (
  state.confirmacaoReembalagemReducer
);

export const {
  setLotesConfirmados,
  setDataConfirmacao,
  setLotes,
  setReembalagem,
  clearConfirmacaoReembalagemState,
  setLotesMovimentados,
} = confirmacaoReembalagem.actions;

export default confirmacaoReembalagem.reducer;
