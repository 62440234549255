import { APIBaseItem } from '../@types/apiBaseItem';
import { HeaderParams } from '../@types/headers';
import responseHeaders from '../utilities/response-headers';
import api from './erp-api';

export interface ListReturnType<T> {
  data: T[],
  headers: HeaderParams,
}

abstract class QueryableBase<Obj extends APIBaseItem<any>> {
  abstract get endpoint(): string

  async listAll(params?: any): Promise<ListReturnType<Obj>> {
    const { data, headers } = await api.get<Obj[]>(this.endpoint, {
      params,
    });
    return { headers: responseHeaders(headers), data };
  }
}

export default QueryableBase;
