import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Lote } from '../../../../@types/sementes/lote';
import { LoteWithLocalizacao } from '../../../../@types/sementes/reembalagem';
import type { RootState } from '../../../../app/rootReducer';
import { CrudCreateUpdateLote } from '../../../../services/sementes/lotes';
import LocalizacaoLotesService from '../../../../services/sementes/localizacao-lotes';
import { promiseWithLoaderAndMessages } from '../../../../utilities';
import { falhaAoCarregar } from '../../../../utilities/default-confirmation-messages';

interface NewReembalagemState {
  lotesComLocalizacao: LoteWithLocalizacao[],
  lotesDestino: CrudCreateUpdateLote[],
}

const initialState: NewReembalagemState = {
  lotesDestino: [],
  lotesComLocalizacao: [],
};

export const fetchLotesComLocalizacao = createAsyncThunk<LoteWithLocalizacao[], Lote[]>(
  'newReembalagem/lotes',
  (lotesSelecionados, { dispatch }) => (
    promiseWithLoaderAndMessages(
      dispatch,
      LocalizacaoLotesService.localizacao(lotesSelecionados),
      { errorMessage: falhaAoCarregar('lotes') },
    )
  ),
);

export const newReembalagemSlice = createSlice({
  name: 'newReembalagens',
  initialState,
  reducers: {
    setLotesDestino: (state, action: PayloadAction<CrudCreateUpdateLote[]>) => {
      state.lotesDestino = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchLotesComLocalizacao.fulfilled, (state, { payload }) => {
      state.lotesComLocalizacao = payload;
    });
  },
});

export const selectNewReembalagensState = (state: RootState) => state.newReembalagensReducer;

export const { setLotesDestino } = newReembalagemSlice.actions;

export default newReembalagemSlice.reducer;
