import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import ListPeneiras from './List';
import Header from './Header';
import EditPeneira from './EditPeneira';
import { fetchPeneiras, selectPeneiraState } from './peneiraSlice';
import FormPeneira from './FormPeneira';
import { usePagination } from '../../../hooks';

const PeneiraPage = () => {
  const dispatch = useDispatch();
  const { pagination: { maxPerPage = 1 } } = useSelector(selectPeneiraState);
  const { currentPage } = usePagination({ maxPerPage });

  useEffect(() => {
    dispatch(fetchPeneiras({
      extraParameters: {
        page: currentPage.toString(),
      },
    }));
  }, [currentPage]);

  return (
    <>
      <div className="p-grid">
        <div className="p-col-12">
          <div className="card">
            <Header />
            <h1>Peneiras</h1>
            <ListPeneiras />
          </div>
        </div>
      </div>

      <Switch>
        <Route exact path="/sementes/peneiras/editar/:id?">
          <EditPeneira />
        </Route>
        <Route exact path="/sementes/peneiras/novo">
          <FormPeneira />
        </Route>
      </Switch>
    </>
  );
};

export default PeneiraPage;
