import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { remove } from 'lodash';
import type { RootState } from '../../../app/rootReducer';
import ubsService from '../../../services/sementes/ubs';
import { Ubs } from '../../../@types/sementes/ubs';
import { PessoaProdutor } from '../../../@types/geral/pessoa';
import promiseWithLoaderAndMessages from '../../../utilities/promise-with-loader-and-messages';
import { selectTopBarState } from '../../../components/Topbar/topBarSlice';
import { Agro1Errors } from '../../../utilities';
import { toastExcludeMessages, falhaAoCarregar, toastSaveMessages } from '../../../utilities/default-confirmation-messages';

interface UbsState {
  ubss: Ubs[],
}

const initialState: UbsState = {
  ubss: [],
};

export const fetchUbs = createAsyncThunk<Ubs[], undefined | { produtor?: PessoaProdutor }>(
  `${ubsService.endpoint}/list`,
  (params = {}, { dispatch, getState }) => {
    const topBarState: any = selectTopBarState(getState() as any);

    const produtor = params.produtor || topBarState.currentProdutor;

    if (!produtor) throw Agro1Errors.produtorNotSelected();

    return promiseWithLoaderAndMessages(
      dispatch,
      ubsService.list(produtor),
      { errorMessage: falhaAoCarregar('UBSs') },
    );
  },
);

export const deleteUbs = createAsyncThunk(
  `${ubsService.endpoint}/delete`,
  async (id: string, { dispatch }) => {
    await promiseWithLoaderAndMessages(
      dispatch,
      ubsService.delete(id),
      toastExcludeMessages('UBS'),
    );

    return id;
  },
);

export const createOrUpdateUbs = createAsyncThunk(
  `${ubsService.endpoint}/createOrUpdate`,
  (data: Parameters<typeof ubsService.createOrUpdate>[0], { dispatch, rejectWithValue }) => (
    promiseWithLoaderAndMessages(
      dispatch,
      ubsService.createOrUpdate(data),
      toastSaveMessages('UBS'),
    ).catch((error) => rejectWithValue(error.response.data))
  ),
);

export const ubsSlice = createSlice({
  name: 'ubs',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(fetchUbs.fulfilled, (state, action) => {
      state.ubss = action.payload;
    });

    builder.addCase(deleteUbs.fulfilled, (state, action) => {
      remove(state.ubss, { id: action.payload });
    });

    builder.addCase(createOrUpdateUbs.fulfilled, (state, { payload }) => {
      if (payload.action === 'update') {
        const indexOfUbsUpdated = state.ubss.findIndex(({ id }) => payload.data.id === id);

        state.ubss[indexOfUbsUpdated] = payload.data;
      } else {
        state.ubss.push(payload.data);
      }
    });
  },
});

export const selectUbsState = (state: RootState) => state.ubsReducer;

export default ubsSlice.reducer;
