import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import DepositosService from '../../../services/estoque/depositos';
import { Deposito } from '../../../@types/estoque/deposito';
import FormDeposito from './FormDeposito';
import { promiseWithLoaderAndMessages } from '../../../utilities';
import { falhaAoCarregar } from '../../../utilities/default-confirmation-messages';

const NewEditDepositoPage = () => {
  const [deposito, setDeposito] = useState<Deposito>();
  const { id } = useParams<{id: string}>();
  const dispatch = useDispatch();

  const findDeposito = async () => {
    setDeposito(
      await promiseWithLoaderAndMessages(
        dispatch,
        DepositosService.find(id),
        { errorMessage: falhaAoCarregar('depósito') },
      ),
    );
  };

  useEffect(() => {
    if (id) findDeposito();
  }, [id]);

  if (!deposito) return null;
  return <FormDeposito deposito={deposito} />;
};

export default NewEditDepositoPage;
