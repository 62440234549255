import { Message } from '@agro1desenvolvimento/react-components';
import { useDispatch, useSelector } from 'react-redux';
import {
  forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState,
} from 'react';
import { fetchEspecies } from '../../../geral/Especies/especiesSlice';
import { fetchDepositos } from '../../../Estoque/Depositos/depositosSlice';
import { selectTopBarState } from '../../../../components/Topbar/topBarSlice';
import { SaldoLotesNaoVinculados } from '../../../../@types/sementes/saldoLotesNaoVinculados';
import SaldosFilters, { LocalizaSaldosValues, SaldosFiltersRef } from './SaldosFilters';
import { SteepComponent } from '../types';
import {
  clearSlice,
  selectVinculoLotesCamposProducaoState,
  goToStep,
  setSaldoLotesSemVinculo,
  setFiltros,
} from '../VinculoLotesCamposProducaoSlice';
import { Agro1Errors, promiseWithLoaderAndMessages } from '../../../../utilities';
import SaldoLotesNaoVinculadosService from '../../../../services/sementes/saldo-lotes-nao-vinculados';
import { falhaAoCarregar } from '../../../../utilities/default-confirmation-messages';

const validateSaldoLotesSemVinculo = (value: SaldoLotesNaoVinculados | undefined) => (
  !!value?.quantidadeLotesNaoVinculados
);

const Filtros: SteepComponent = forwardRef(({ setCanGoNext }, ref) => {
  const dispatch = useDispatch();
  const { saldoLotesSemVinculo } = useSelector(selectVinculoLotesCamposProducaoState);
  const { currentSafra, currentProdutor } = useSelector(selectTopBarState);

  const [errorMessage, setErrorMessage] = useState('');
  const formRef = useRef<SaldosFiltersRef | null>(null);

  const isValid = useMemo(
    () => validateSaldoLotesSemVinculo(saldoLotesSemVinculo),
    [saldoLotesSemVinculo],
  );

  const onSubmit = async (values: LocalizaSaldosValues) => {
    if (!currentProdutor || !currentSafra) throw Agro1Errors.produtorOrSafraNotSelected();

    const promise = SaldoLotesNaoVinculadosService.saldos({
      ...values,
      safraId: currentSafra.id,
      produtorId: currentProdutor.id,
    });

    const result = await promiseWithLoaderAndMessages(
      dispatch,
      promise,
      { errorMessage: falhaAoCarregar('saldos de lotes sem vínculos') },
    );

    const isValidResult = validateSaldoLotesSemVinculo(result);

    dispatch(setSaldoLotesSemVinculo(result));

    if (isValidResult) {
      dispatch(goToStep('next'));
      dispatch(setFiltros(values));
    }
  };

  useEffect(() => {
    if (!currentProdutor || !currentSafra) {
      setErrorMessage('Selecione safra/produtor.');
      return;
    }

    const safraAndProdutor = {
      safra: currentSafra.id,
      produtor: currentProdutor.id,
    };

    dispatch(clearSlice());
    setErrorMessage('');

    dispatch(fetchEspecies({ extraParameters: safraAndProdutor }));
    dispatch(fetchDepositos());
  }, [currentSafra, currentProdutor]);

  useImperativeHandle(ref, () => ({
    submit: () => formRef.current?.submit(),
  }));

  if (errorMessage) {
    return <Message severity="error" text={errorMessage} className="width-full p-justify-start" />;
  }

  return (
    <div>
      <SaldosFilters ref={formRef} onSubmit={onSubmit} setIsValid={setCanGoNext} />
      <div className="p-col-12" hidden={isValid || !saldoLotesSemVinculo}>
        <div className="card">
          <Message severity="error" text="Nenhum resultado encontrado para a cultivar" className="width-full p-justify-start" />
        </div>
      </div>
    </div>
  );
});

export default Filtros;
