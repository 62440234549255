import { useMemo } from 'react';
import RGL from 'react-grid-layout';
import { useIsMobile } from '@agro1desenvolvimento/react-hooks';
import 'react-grid-layout/css/styles.css';
import { pick } from 'lodash';
import { useDispatch } from 'react-redux';
import ReactResizeDetector from 'react-resize-detector';
import { Dashboard as DashboardType, DashboardLayout } from '../../../../@types/geral/dashboard.d';
import dashboardService from '../../../../services/geral/dashboard';
import { promiseWithLoaderAndMessages } from '../../../../utilities';
import { falhaAoSalvar } from '../../../../utilities/default-confirmation-messages';

const DASHBOARD_LAYOUT_KEY: (keyof DashboardLayout)[] = ['x', 'y', 'w', 'h'];

const isSameDashboardLayout = (
  layoutA: DashboardLayout,
  layoutB: DashboardLayout,
) => (
  DASHBOARD_LAYOUT_KEY
    .every((key) => layoutA[key] === layoutB[key])
);

const Dashboard: React.FC<
  { dashboardItems: DashboardType[] }
> = ({ children, dashboardItems }) => {
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const dashboardCols = useMemo(() => (isMobile ? 6 : 12), [isMobile]);
  const isResizableAndDraggable = useMemo(() => (!isMobile), [isMobile]);

  const onLayoutChange = (newLayout: RGL.Layout[]) => {
    if (isMobile) return;
    newLayout.forEach((layout) => {
      const item = dashboardItems.find((dashboard) => dashboard.id === layout.i);

      if (!item || isSameDashboardLayout(item.layout, layout)) return;

      promiseWithLoaderAndMessages(
        dispatch,
        dashboardService.update({
          ...item,
          layout: pick(layout, DASHBOARD_LAYOUT_KEY),
        }),
        {
          errorMessage: falhaAoSalvar('posição do dashboard'),
          withLoader: false,
        },
      );
    });
  };

  return (
    <ReactResizeDetector refreshMode="debounce" handleWidth>{({ width }) => (
      <RGL
        cols={dashboardCols}
        rowHeight={50}
        onLayoutChange={onLayoutChange}
        isResizable={isResizableAndDraggable}
        isDraggable={isResizableAndDraggable}
        width={width || 0}
        verticalCompact
      >
        {children}
      </RGL>
    )}
    </ReactResizeDetector>
  );
};

export default Dashboard;
