import {
  FC,
  useEffect,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import FormEquipamento from './FormEquipamento';
import EquipamentoService from '../../../../services/laboratorio/equipamentos';
import { Equipamento } from '../../../../@types/laboratorio/equipamento';
import { promiseWithLoaderAndMessages } from '../../../../utilities';
import { falhaAoCarregar } from '../../../../utilities/default-confirmation-messages';

const EditEquipamento: FC = () => {
  const dispatch = useDispatch();
  const [equipamento, setEquipamento] = useState<Equipamento | undefined>();
  const { id } = useParams<{id: string}>();

  useEffect(() => {
    const findEquipamento = async () => {
      setEquipamento(
        await promiseWithLoaderAndMessages(
          dispatch,
          EquipamentoService.find(id),
          { errorMessage: falhaAoCarregar('equipamento') },
        ),
      );
    };

    if (id) findEquipamento();
  }, [id]);

  return equipamento ? <FormEquipamento equipamento={equipamento} /> : null;
};

export default EditEquipamento;
