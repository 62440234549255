import {
  DataTable,
  Column,
  PaginatorPageState,
  Tag,
} from '@agro1desenvolvimento/react-components';
import { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIsMobile } from '@agro1desenvolvimento/react-hooks';
import { useHistory } from 'react-router-dom';
import DropwDownButton from '../../../../components/DropwDownButtons';
import { AuditoriaType } from '../../../../@types/enums';
import Pagination from '../../../../components/Pagination';
import { usePagination } from '../../../../hooks';
import { selectReembalagensState, setReembalagensSelecionadas } from '../reembalagemSlice';
import { Reembalagem } from '../../../../@types/sementes/reembalagem';
import Filters from './Filters';
import RowExpandedTemplate from './RowExpandedTemplate';
import { formatWithoutTime } from '../../../../utilities';

const ListReembalagens: FC = () => {
  const {
    reembalagens,
    pagination,
    reembalagensSelecionadas,
  } = useSelector(selectReembalagensState);
  const {
    maxPerPage = 1,
    totalRecords,
    pages,
  } = pagination;
  const history = useHistory();
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const [expandRows, setExpandRows] = useState<Reembalagem[]>([]);
  const { firstIndexOnPage, goToPage } = usePagination({ maxPerPage });
  const reembalagemFinalizada = (reembalagem: Reembalagem) => reembalagem.status === 'finalizado';

  const onPageChange = (e: PaginatorPageState) => goToPage(e.page + 1);

  const renderActionButtons = (reembalagem: Reembalagem) => (
    <div>
      <DropwDownButton
        auditoriaAction={() => history.push(`/auditoria/${AuditoriaType.reembalagem}/${reembalagem.id}`)}
        customActions={[{
          command: () => history.push(`/sementes/reembalagens/${reembalagem.id}/confirmar`),
          label: reembalagemFinalizada(reembalagem) ? 'Lotes' : 'Confirmar',
          icon: 'pi pi-fw pi-check',
        }]}
      />
    </div>
  );

  return (
    <>
      <DataTable
        value={reembalagens}
        header={<Filters />}
        selection={reembalagensSelecionadas}
        onSelectionChange={(e) => dispatch(setReembalagensSelecionadas(e.value))}
        selectionMode="multiple"
        dataKey="id"
        emptyMessage="Nenhum registro encontrado."
        rowExpansionTemplate={
          (reembalagem: Reembalagem) => <RowExpandedTemplate reembalagem={reembalagem} />
        }
        expandedRows={expandRows}
        onRowToggle={(event) => setExpandRows(event.data)}
        responsiveLayout="scroll"
      >
        <Column expander className="expander-column" />
        <Column selectionMode="multiple" />
        <Column field="numeroReembalagem" header="Nº" sortable />
        { isMobile || <Column body={(reembalagem: Reembalagem) => formatWithoutTime(reembalagem.data)} header="Data" sortField="data" sortable /> }
        { isMobile || <Column field="cultivar.especie.descricao" header="Espécie" sortable /> }
        <Column field="cultivar.descricaoCategoria" header="Cultivar" sortable />
        { isMobile || <Column body={(reembalagem: Reembalagem) => (reembalagem.peneira?.descricao || 'N/D')} header="Peneira" sortable sortField="peneira.descricao" /> }
        <Column
          body={(reembalagem: Reembalagem) => {
            const status = { rascunho: 'warning', parcial: 'primary', finalizado: 'success' };
            return (
              <Tag value={reembalagem.status} severity={status[reembalagem.status]} />
            );
          }}
          header="Status"
          sortField="status"
          sortable
        />
        <Column header="Ações" body={renderActionButtons} className="col-actions" />
      </DataTable>
      <Pagination
        pages={pages}
        first={firstIndexOnPage}
        rows={maxPerPage}
        totalRecords={totalRecords}
        onPageChange={(e) => onPageChange(e)}
        pageRecords={reembalagens.length}
      />
    </>

  );
};

export default ListReembalagens;
