import { ConfirmPopup, Dropdown } from '@agro1desenvolvimento/react-components';
import {
  FC,
  useEffect,
  useRef,
  useState,
} from 'react';
import { RelatorioTemplate } from '../../@types/relatorio/template';
import { transitionOptionsTimeout as timeout } from '../../utilities';

interface Props {
  print: (id: string) => void,
  onClose: () => void,
  targetId: string,
  templates: RelatorioTemplate[],
  download: boolean
}

const SelectTemplateToPrint: FC<Props> = ({
  print, onClose, targetId, templates, download,
}) => {
  const [visible, setVisible] = useState(false);
  const dropdown = useRef<Dropdown>(null);

  const submit = (templateId: string) => {
    print(templateId);
    setVisible(false);
  };

  const loadTemplates = async () => {
    if (templates.length === 1) {
      print(templates[0].id);
    } else {
      setVisible(true);
    }
  };

  useEffect(() => {
    if (download) loadTemplates();
  }, [templates, download]);

  return (
    <ConfirmPopup
      target={document.getElementById(targetId) || undefined}
      visible={visible}
      onHide={() => setVisible(false)}
      transitionOptions={{ timeout, onExited: onClose }}
      footer={<></>}
      appendTo={document.body}
      onShow={() => dropdown?.current?.setState({ overlayVisible: true })}
      message={templates?.length ? (
        <Dropdown
          ref={dropdown}
          id="template-selector"
          options={templates}
          optionLabel="descricao"
          optionValue="id"
          disabled={!templates.length}
          onChange={(t) => submit(t.value)}
          required
          autoFocus
          placeholder="Selecione o template"
          appendTo={document.body}
        />
      ) : 'Nenhum template encontrado'}
    />
  );
};

export default SelectTemplateToPrint;
