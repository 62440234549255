import { Button, Toolbar } from '@agro1desenvolvimento/react-components';
import { useHistory } from 'react-router-dom';

const Header = () => {
  const history = useHistory();

  const leftContents = (
    <Button
      label="Novo"
      aria-label="Novo"
      icon="pi pi-plus"
      onClick={() => history.push('/sementes/equipamentos/novo')}
      className="p-mr-2 p-button-success"
    />
  );

  return (
    <Toolbar left={leftContents} />
  );
};

export default Header;
