import { Button, Message, Steps } from '@agro1desenvolvimento/react-components';
import classNames from 'classnames';
import { noop } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SteepComponentRef, StepModel } from '../../VinculoLotesCamposProducao/types';
import { clearSlice, goToStep, selectDescartesState } from './novoDescarteSlice';
import ConfirmacaoDescarte from './components/Confirmacao';
import FiltrosDescarte from './components/Filtros';
import ResumoSaldosDescarte from './components/ResumoSaldos';
import SimulacaoDescarte from './components/Simulacao';
import { selectTopBarState } from '../../../../components/Topbar/topBarSlice';

const steps: StepModel[] = [
  { label: 'Filtros', component: FiltrosDescarte },
  { label: 'Resumo', component: ResumoSaldosDescarte },
  { label: 'Simulação', component: SimulacaoDescarte },
  { label: 'Confirmação', component: ConfirmacaoDescarte },
];

const DescarteForm = () => {
  const { currentStepIndex } = useSelector(selectDescartesState);
  const { currentSafra, currentProdutor } = useSelector(selectTopBarState);
  const dispatch = useDispatch();
  const currentStepPageRef = useRef<SteepComponentRef | null>(null);
  const inFirstStep = currentStepIndex === 0;
  const inLastStep = steps.length - 1 === currentStepIndex;
  const rightIcon = inLastStep ? 'pi-check' : 'pi-angle-right';
  const [isValidSteeps, setIsValidSteeps] = useState(() => steps.map(() => false));

  const canGoNext = isValidSteeps[currentStepIndex];

  const setCanGoNext = (value: boolean) => {
    setIsValidSteeps((prev) => {
      prev[currentStepIndex] = value;
      return [...prev];
    });
  };

  useEffect(() => {
    const cleanup = () => {
      dispatch(clearSlice());
    };

    return cleanup;
  }, []);

  if (!currentProdutor || !currentSafra) return <Message severity="error" text="Selecione safra/produtor." className="width-full p-justify-start" />;

  return (
    <>
      <div className="custom-steps-parent">
        <Steps model={steps} activeIndex={currentStepIndex} />
        <div className="step-actions">
          <Button
            onClick={() => dispatch(goToStep('previos'))}
            icon="pi pi-angle-left"
            className="p-button-outlined"
            iconPos="left"
            disabled={inFirstStep}
            label="Voltar"
          />
          <Button
            onClick={() => {
              currentStepPageRef.current?.submit();
            }}
            icon={`pi ${rightIcon}`}
            className={classNames({ 'p-button-outlined': !inLastStep })}
            iconPos="right"
            disabled={!canGoNext}
            label={inLastStep ? 'Confirmar' : 'Avançar'}
          />
        </div>
      </div>
      {steps.map(({ component: Component, label }, index) => {
        const visible = index === currentStepIndex;
        return (
          <div key={label} hidden={!visible}>
            <Component
              setCanGoNext={visible ? setCanGoNext : noop}
              visible={visible}
              ref={(ref) => {
                if (visible) currentStepPageRef.current = ref;
              }}
            />
          </div>
        );
      })}
    </>
  );
};

export default DescarteForm;
