import { loginService } from '@agro1desenvolvimento/apis-js-package';
import { ArmazemSaldo } from '../../@types/sementes/armazem';
import responseHeaders from '../../utilities/response-headers';
import api from '../erp-api';
import { ListReturnType } from '../queryable-base';

class LocalizacaoSaldoService {
  get endpoint() {
    return `/${loginService.scope}/sementes/armazens`;
  }

  async listAll(
    params: { armazemId: string } & Record<string, any>,
  ): Promise<ListReturnType<ArmazemSaldo>> {
    const armazemId = params?.armazemId;
    const { data, headers } = await api.get<ArmazemSaldo[]>(
      `${this.endpoint}/${armazemId}/saldos`,
      { params },
    );
    return { headers: responseHeaders(headers), data };
  }
}

export default new LocalizacaoSaldoService();
