import { FC, useEffect, useState } from 'react';
import { Dialog, Message } from '@agro1desenvolvimento/react-components';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Amostra } from '../../../../@types/sementes/amostra';
import AmostraCreateEditForm from './AmostraCreateEditForm';
import amostrasService from '../../../../services/sementes/amostras';
import promiseWithLoaderAndMessages from '../../../../utilities/promise-with-loader-and-messages';
import { falhaAoCarregar } from '../../../../utilities/default-confirmation-messages';

const AmostraCreateEditPage: FC<AmostraCreateEditPageProps> = ({ onClose }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState('');
  const { loteId, id } = useParams<{loteId: string; id: string, page: string}>();
  const [amostra, setAmostra] = useState<Amostra | undefined>(undefined);

  const onFormClose = () => {
    history.push({
      pathname: `/sementes/lotes/${loteId}/amostras`,
      search: location.search,
    });
    onClose();
  };

  const fetchAmostra = async () => {
    try {
      if (id) {
        setAmostra(await promiseWithLoaderAndMessages(
          dispatch,
          amostrasService.find(loteId, id),
          { errorMessage: falhaAoCarregar('amostra') },
        ));

        return;
      }
    } catch (error) {
      setErrorMessage(error);
    }
  };

  useEffect(() => {
    fetchAmostra();
  }, []);

  if (errorMessage) {
    return (
      <Dialog onHide={onFormClose} visible header="Erro ao buscar Amostra" className="dialog-md">
        <Message severity="error" text={errorMessage} className="width-full p-justify-start" />
      </Dialog>
    );
  }

  return <AmostraCreateEditForm loteId={loteId} amostra={amostra} onClose={onFormClose} />;
};

export default AmostraCreateEditPage;

interface AmostraCreateEditPageProps {
  onClose: () => void;
}
