import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { pull } from 'lodash';
import type { RootState } from '../../app/rootReducer';

type loaderIDType = keyof any;

interface LoaderState {
  loadersVisible: loaderIDType[]
}

const initialState: LoaderState = {
  loadersVisible: [],
};

export const loaderSlice = createSlice({
  name: 'loader',
  initialState,
  reducers: {
    showLoader(state, action: PayloadAction<loaderIDType>) {
      if (!state.loadersVisible.includes(action.payload)) {
        state.loadersVisible.push(action.payload);
      }
    },
    closeLoader(state, action: PayloadAction<loaderIDType>) {
      pull(state.loadersVisible, action.payload);
    },
  },
});

export const {
  showLoader,
  closeLoader,
} = loaderSlice.actions;

export const selectLoaderState = (state: RootState) => state.loaderReducer;

export default loaderSlice.reducer;
