export const salvoComSucesso = (subject: string) => (
  {
    summary: 'Salvo',
    detail: `O registro de ${subject} foi salvo com sucesso`,
  }
);

export const falhaAoSalvar = (subject: string) => (
  {
    summary: 'Erro',
    detail: `Falha ao salvar ${subject}`,
  }
);

export const excluidoComSucesso = (subject: string) => (
  {
    summary: 'Excluído',
    detail: `O registro de ${subject} foi excluído com sucesso`,
  }
);

export const falhaAoExcluir = (subject: string) => (
  {
    summary: 'Erro',
    detail: `Falha ao excluir ${subject}`,
  }
);

export const falhaAoCarregar = (subject: string) => (
  {
    summary: 'Erro',
    detail: `Falha ao carregar ${subject}`,
  }
);

export const toastExcludeMessages = (subject: string) => (
  {
    successMessage: excluidoComSucesso(subject),
    errorMessage: falhaAoExcluir(subject),
  }
);

export const toastSaveMessages = (subject: string) => (
  {
    successMessage: salvoComSucesso(subject),
    errorMessage: falhaAoSalvar(subject),
  }
);
