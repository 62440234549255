import { loginService } from '@agro1desenvolvimento/apis-js-package';
import { SaldoLotesNaoVinculados } from '../../@types/sementes/saldoLotesNaoVinculados';
import api from '../erp-api';

export interface SaldosProps {
  cultivarId: string,
  safraId: string,
  produtorId: string,
  depositoOrigemId: string,
  camposProducaoIds?: string[],
}

class SaldoLotesNaoVinculadosService {
  get endpoint() {
    return `/${loginService.scope}/sementes/vinculo_lotes_campos_producao/saldos`;
  }

  async saldos({
    cultivarId, safraId, produtorId, depositoOrigemId, camposProducaoIds,
  }: SaldosProps) {
    const { data } = await api.get<SaldoLotesNaoVinculados>(
      this.endpoint,
      {
        params: {
          cultivar_id: cultivarId,
          safra_id: safraId,
          produtor_id: produtorId,
          deposito_id: depositoOrigemId,
          campos_producao_ids: camposProducaoIds,
        },
      },
    );
    return data;
  }
}

export default new SaldoLotesNaoVinculadosService();
