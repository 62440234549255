import React, {
  FC,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Dialog,
  InputText,
  Button,
  Messages,
  AutoComplete,
  AutoCompleteChangeParams,
  RadioButton,
  InputMask,
  MultiSelect,
} from '@agro1desenvolvimento/react-components';
import * as Yup from 'yup';
import classNames from 'classnames';
import {
  find,
  compact,
  map,
  some,
} from 'lodash';
import { ptForm } from 'yup-locale-pt';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PessoasService from '../../../../services/geral/pessoas';
import { Pessoa, PessoaFuncao } from '../../../../@types/geral/pessoa';
import { fetchPessoas, fetchPessoasFuncoes, selectPessoaState } from '../pessoaSlice';
import catchApiErrorsAndSetFormErrors from '../../../../utilities/catch-api-errors';
import ShowErrorHelper from '../../../../components/ShowErrorHelper';
import { CrudCreateUpdate, promiseWithLoaderAndMessages, transitionOptionsTimeout as timeout } from '../../../../utilities';
import { toastSaveMessages } from '../../../../utilities/default-confirmation-messages';
import { Cidade } from '../../../../@types/geral/cidade';
import { fetchCidades, selectCidadeState } from '../../Cidades/cidadeSlice';
import DynamicForm from '../../../../components/DynamicForm';
import { useScreenSize } from '../../../../hooks';

const Footer: React.FC<{ close: () => void, valid: boolean }> = ({ close, valid }) => (
  <div>
    <Button label="Cancelar" icon="pi pi-times" onClick={close} className="p-button-text" />
    <Button label="Salvar" disabled={!valid} icon="pi pi-check" autoFocus type="submit" form="form-save-pessoa" />
  </div>
);

Yup.setLocale(ptForm);
const PessoaSchema = Yup.object().shape({
  nome: Yup.string().required(),
  tipo: Yup.string().required(),
  cpfCnpj: Yup.string().required(),
  endereco: Yup.string().required(),
  cidade: Yup.string().required(),
  inscricaoEstadual: Yup.string().required(),
  funcoes: Yup.array().required(),
});

interface PessoaFormField {
  id?: string,
  nome?: string,
  inscricaoEstadual?: string,
  cpfCnpj?: string,
  cidade?: string,
  tipo?: 'fisica' | 'juridica',
  endereco?: string,
  funcoes?: PessoaFuncao[],
  'dados_produtor'?: { renasem: string },
  'dados_tecnico_sementes'?: { renasem: string },
  'dados_laboratorio'?: { renasem: string },
}

const tiposPessoas = [{ key: 'fisica', name: 'Física' }, { key: 'juridica', name: 'Jurídica' }];

const FormPessoa: FC<{ pessoa?: Pessoa | undefined }> = ({ pessoa }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const messages = useRef<Messages>(null);
  const { cidades } = useSelector(selectCidadeState);
  const { funcoes } = useSelector(selectPessoaState);
  const [formValid, setFormValid] = useState(true);
  const [currentCidade, setCurrentCidade] = useState<Cidade>();
  const [modalVisible, setModalVisible] = useState(true);
  const {
    isXL, isXXL, isLG, isXS,
  } = useScreenSize();

  const onClose = () => history.push('/geral/pessoas');

  const fechaModal = () => {
    setModalVisible(false);
  };

  const submitForm = async (values: Partial<PessoaFormField>) => {
    const toSave: Partial<CrudCreateUpdate<Pessoa>> = {
      id: values.id,
      nome: values.nome,
      inscricaoEstadual: values.inscricaoEstadual,
      cpfCnpj: values.cpfCnpj,
      cidade: values.cidade !== pessoa?.cidade.id
        ? find(cidades, { id: values.cidade }) : pessoa?.cidade,
      tipo: values.tipo,
      endereco: values.endereco,
      funcoes: values.funcoes,
      dadosProdutor: some(values.funcoes, { enum: 'produtor' }) ? values.dados_produtor : undefined,
      dadosLaboratorio: some(values.funcoes, { enum: 'laboratorio' }) ? values.dados_laboratorio : undefined,
      dadosTecnicoSementes: some(values.funcoes, { enum: 'responsavel_tecnico' }) ? values.dados_tecnico_sementes : undefined,
    };

    try {
      await promiseWithLoaderAndMessages(
        dispatch,
        PessoasService.createOrUpdate(toSave),
        toastSaveMessages('pessoa'),
      );

      fechaModal();
      dispatch(fetchPessoas());
    } catch (err) {
      catchApiErrorsAndSetFormErrors(form.setFieldError, err.response.data, true);
    }
  };

  const form = useFormik<PessoaFormField>({
    validationSchema: PessoaSchema,
    initialValues: {
      id: pessoa?.id,
      nome: pessoa?.nome,
      inscricaoEstadual: pessoa?.inscricaoEstadual,
      cpfCnpj: pessoa?.cpfCnpj,
      cidade: pessoa?.cidade.id,
      tipo: pessoa?.tipo,
      endereco: pessoa?.endereco,
      funcoes: pessoa?.funcoes,
      dados_produtor: pessoa?.dadosProdutor,
      dados_laboratorio: pessoa?.dadosLaboratorio,
      dados_tecnico_sementes: pessoa?.dadosTecnicoSementes,
    },
    onSubmit: async (values) => submitForm(values),
  });

  const changeCidade = async (value: AutoCompleteChangeParams) => {
    const cidadeObject = {
      ...value,
      target: {
        ...value.target,
        value: value.target.value.id,
      },
    };

    setCurrentCidade(value.target.value);
    form.handleChange(cidadeObject);
  };

  const fetchCidadesByParams = (query = '') => {
    dispatch(fetchCidades({
      conditions: [{
        field: 'descricao',
        operator: 'matches',
        value: `%${query}%`,
      }],
    }));
  };

  useEffect(() => {
    setCurrentCidade(pessoa?.cidade);
  }, [pessoa?.cidade]);

  useEffect(() => {
    dispatch(fetchCidades());
    dispatch(fetchPessoasFuncoes());
  }, []);

  const smallScreen = !(isXXL || isXL || isLG);
  const chunkSize = isXS ? 1 : 2;
  const defaultColClasses = `p-field p-col-${12 / chunkSize}`;
  const struct = useMemo(() => compact(map(form.values.funcoes, 'atributos').flat()), [form.values.funcoes]);
  const cpfCnpjMask = useMemo(() => (form.values.tipo === 'juridica' ? '99.999.999/9999-99' : '999.999.999-99'), [form.values.tipo]);

  return (
    <Dialog
      onHide={fechaModal}
      transitionOptions={{ timeout, onExited: onClose }}
      visible={modalVisible}
      maximizable
      header={pessoa?.id ? 'Alterar pessoa' : 'Adicionar pessoa'}
      className={smallScreen ? 'dialog-lg' : 'dialog-md'}
      footer={<Footer close={fechaModal} valid={form.isValid && formValid} />}
    >
      <DynamicForm<Pessoa>
        data={form.values}
        dataKeys={['nome', 'tipo', 'cpfCnpj', 'cidade', 'inscricaoEstadual', 'funcoes', 'endereco']}
        dynamicData={pessoa}
        dynamicStruct={struct || []}
        formId="form-save-pessoa"
        formValid={setFormValid}
        onSubmit={submitForm}
        chunkSize={chunkSize}
      >
        <div className={defaultColClasses}>
          <label htmlFor="nome">Nome</label>
          <InputText
            required
            name="nome"
            id="nome"
            value={form.values.nome}
            onChange={form.handleChange}
            className={classNames({ 'p-invalid': form.errors.nome })}
            aria-describedby="nome-help"
          />
          <ShowErrorHelper id="nome-help" error={form.errors.nome} />
        </div>
        <div className={`${defaultColClasses} p-field ${isXS ? 'p-pt-0' : 'p-pt-5'}`}>
          <div className="flex-row p-d-flex">
            {
                tiposPessoas.map((tipo) => (
                  <div key={tipo.key} className="p-field-radiobutton p-mb-0 p-mr-3">
                    <RadioButton
                      inputId={tipo.key}
                      name="tipo"
                      value={tipo.key}
                      key={tipo.key}
                      onChange={form.handleChange}
                      checked={form.values.tipo === tipo.key}
                    />
                    <label htmlFor={tipo.key}>{tipo.name}</label>
                  </div>
                ))
              }
          </div>
          <ShowErrorHelper id="tipo-help" error={form.errors.tipo} />
        </div>
        <div className={defaultColClasses}>
          <label htmlFor="cpfCnpj">{form.values.tipo === 'juridica' ? 'CNPJ' : 'CPF'}</label>
          <InputMask
            required
            id="cpfCnpj"
            name="cpfCnpj"
            mask={cpfCnpjMask}
            value={form.values.cpfCnpj}
            onChange={(evt) => {
              // Issue do formik, 2 alterações de state simultâneas
              // faz o valor anterior do tipo ser usado no schema de validação
              // https://github.com/jaredpalmer/formik/issues/2083
              setTimeout(() => {
                form.setFieldValue('cpfCnpj', evt.value);
              }, 50);
            }}
            aria-describedby="cpfCnpj-help"
            className={classNames({ 'p-invalid': form.errors.cpfCnpj })}
          />
          <ShowErrorHelper id="cpfCnpj-help" error={form.errors.cpfCnpj} />
        </div>
        <div className={defaultColClasses}>
          <label htmlFor="inscricaoEstadual">Inscrição Estadual</label>
          <InputText
            required
            name="inscricaoEstadual"
            id="inscricaoEstadual"
            value={form.values.inscricaoEstadual}
            onChange={form.handleChange}
            className={classNames({ 'p-invalid': form.errors.inscricaoEstadual })}
            aria-describedby="inscricaoEstadual-help"
          />
          <ShowErrorHelper id="inscricaoEstadual-help" error={form.errors.inscricaoEstadual} />
        </div>
        <div className={defaultColClasses}>
          <label htmlFor="endereco">Endereço</label>
          <InputText
            required
            name="endereco"
            id="endereco"
            value={form.values.endereco}
            onChange={form.handleChange}
            className={classNames({ 'p-invalid': form.errors.endereco })}
            aria-describedby="endereco-help"
          />
          <ShowErrorHelper id="endereco-help" error={form.errors.endereco} />
        </div>
        <div className={defaultColClasses}>
          <label aria-labelledby="cidade" htmlFor="cidade">Cidade</label>
          <AutoComplete
            dropdown
            id="cidade"
            name="cidade"
            field="descricao"
            itemTemplate={(cidade) => `${cidade.descricao} - ${cidade.uf.sigla}`}
            value={currentCidade}
            suggestions={cidades}
            onChange={changeCidade}
            placeholder="Selecione a Cidade"
            appendTo={document.body}
            className={classNames({ 'p-invalid': form.errors.cidade })}
            aria-describedby="cidade-help"
            completeMethod={(e) => fetchCidadesByParams(e.query)}
          />
          <ShowErrorHelper id="cidade-help" error={form.errors.cidade} />
        </div>
        <div className={defaultColClasses}>
          <label aria-labelledby="funcoes" htmlFor="funcoes">Funções</label>
          <MultiSelect
            id="funcoes"
            name="funcoes"
            value={form.values.funcoes}
            options={funcoes}
            onChange={form.handleChange}
            optionLabel="descricao"
            appendTo={document.body}
            display="chip"
            className={classNames({ 'p-invalid': form.errors.funcoes })}
            aria-describedby="funcoes-help"
          />
          <ShowErrorHelper id="funcoes-help" error={form.errors.funcoes} />
        </div>
      </DynamicForm>
      <Messages ref={messages} />
    </Dialog>
  );
};

export default FormPessoa;
