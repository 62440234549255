import { formatNumber as formatNumberUtils } from '@agro1desenvolvimento/utils-js';

export default (
  number: number | null | undefined,
  minimumFractionDigits?: number,
) => {
  if (number === undefined || number === null) return undefined;

  return formatNumberUtils(
    number,
    {
      useGrouping: true,
      minimumFractionDigits,
    },
  );
};
