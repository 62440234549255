import { loginService } from '@agro1desenvolvimento/apis-js-package';
import { keysToSnakeCase } from '@agro1desenvolvimento/utils-js';
import { Auditoria } from '../../../@types/geral/auditoria';
import { HeaderParams } from '../../../@types/headers';
import responseHeaders from '../../../utilities/response-headers';
import CrudBase from '../../crud-base';
import { ListReturnType } from '../../queryable-base';
import api from '../../erp-api';

export interface AuditoriaListReturnType extends HeaderParams {
  data: Auditoria[];
  headers: HeaderParams;
}

class AuditoriaService extends CrudBase<Auditoria> {
  get endpoint() {
    return `/${loginService.scope}/geral/auditorias`;
  }

  async listAll(params?: Record<string, any>): Promise<ListReturnType<Auditoria>> {
    const { data, headers } = await api.get<Auditoria[]>(this.endpoint, {
      params: keysToSnakeCase(params || {}),
    });
    return { headers: responseHeaders(headers), data };
  }
}

export default new AuditoriaService();
