import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../../../app/rootReducer';
import obtentoresService from '../../../services/sementes/obtentores';
import { Obtentor } from '../../../@types/sementes/obtentor';
import promiseWithLoaderAndMessages from '../../../utilities/promise-with-loader-and-messages';
import { falhaAoCarregar } from '../../../utilities/default-confirmation-messages';

interface ObtentoresState {
  obtentores: Obtentor[],
}

const initialState: ObtentoresState = {
  obtentores: [],
};

export const fetchObtentores = createAsyncThunk<Obtentor[]>(
  'obtentores/list',
  (_, { dispatch }) => promiseWithLoaderAndMessages(
    dispatch,
    obtentoresService.list(),
    { errorMessage: falhaAoCarregar('obtentores') },
  ),
);

export const obtentoresSlice = createSlice({
  name: 'obtentores',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(fetchObtentores.fulfilled, (state, action) => {
      state.obtentores = action.payload;
    });
  },
});

export const selectObtentoresState = (state: RootState) => state.obtentoresReducer;

export default obtentoresSlice.reducer;
