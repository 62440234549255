import { FC } from 'react';
import {
  InputText, InputTextarea,
} from '@agro1desenvolvimento/react-components';
import { useSelector } from 'react-redux';
import { formatWithoutTime } from '../../../../../utilities';
import { selectConfirmacaoReembalagemState } from '../confirmacaoReembalagemSlice';

const FormReembalagem: FC<{ visible: boolean}> = ({ visible }) => {
  const confirmacaoReembalagemState = useSelector(selectConfirmacaoReembalagemState);
  const reembalagem = confirmacaoReembalagemState.reembalagem!;

  return (
    <form hidden={!visible}>
      <div className="p-fluid p-formgrid p-grid">
        <div className="p-field p-col-6">
          <label htmlFor="data">Data</label>
          <InputText
            disabled
            name="data"
            id="data"
            value={formatWithoutTime(reembalagem.data)}
            readOnly
          />
        </div>
        <div className="p-field p-col-6">
          <label htmlFor="peneiraId">Peneira</label>
          <InputText
            disabled
            name="peneiraId"
            id="peneiraId"
            value={reembalagem.peneira?.descricao || 'N/D'}
            readOnly
          />
        </div>
        <div className="p-field p-col-6">
          <label htmlFor="especieId">Espécie</label>
          <InputText
            disabled
            name="especieId"
            id="especieId"
            value={reembalagem.cultivar.especie?.descricao}
            readOnly
          />
        </div>
        <div className="p-field p-col-6">
          <label htmlFor="cultivarId">Cultivar</label>
          <InputText
            disabled
            name="cultivarId"
            id="cultivarId"
            value={reembalagem.cultivar.descricao}
            readOnly
          />
        </div>
        <div className="p-field p-col-12">
          <label htmlFor="observacao">Observação</label>
          <InputTextarea
            className="width-full"
            id="observacao"
            rows={6}
            value={reembalagem.observacao}
            autoResize
            disabled
          />
        </div>
      </div>
    </form>
  );
};

export default FormReembalagem;
