import { loginService } from '@agro1desenvolvimento/apis-js-package';
import { PessoaProdutor } from '../../@types/geral/pessoa';
import { Ubs } from '../../@types/sementes/ubs';
import { CrudCreateUpdate } from '../../utilities';
import CrudBase from '../crud-base';
import api from '../erp-api';

class UbsService extends CrudBase<Ubs> {
  get endpoint() {
    return `/${loginService.scope}/sementes/ubs`;
  }

  async list(produtor: PessoaProdutor) {
    const { data } = await api.get<Ubs[]>(this.endpoint, {
      params: {
        produtor: produtor.id,
      },
    });
    return data;
  }

  async create(ubs: CrudCreateUpdate<Ubs>) {
    return (await api.post<Ubs[]>(
      this.endpoint,
      { ubs: [{ descricao: ubs.descricao, produtor_id: ubs.produtor.id }] },
    )).data[0];
  }

  async update(ubs: CrudCreateUpdate<Ubs>) {
    return (await api.patch<Ubs>(
      `${this.endpoint}/${ubs.id}`,
      { descricao: ubs.descricao, produtor_id: ubs.produtor.id },
    )).data;
  }
}

export default new UbsService();
