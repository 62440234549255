import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import {
  Column, DataTable, InputTextarea, Message,
} from '@agro1desenvolvimento/react-components';
import { map, sumBy } from 'lodash';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { SimulacaoDescarte } from '../../../../../@types/sementes/descarte';
import { selectTopBarState } from '../../../../../components/Topbar/topBarSlice';
import { formatWithoutTime, promiseWithLoaderAndMessages, formatNumber } from '../../../../../utilities';
import { falhaAoCarregar } from '../../../../../utilities/default-confirmation-messages';
import { SteepComponent } from '../../../VinculoLotesCamposProducao/types';
import { selectDescartesState } from '../novoDescarteSlice';
import DescartesService from '../../../../../services/sementes/descartes';

const ConfirmacaoDescarte: SteepComponent = forwardRef(({ setCanGoNext, visible }, ref) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { currentProdutor, currentSafra } = useSelector(selectTopBarState);
  const [simulacaoDescartes, setSimulacaoDescartes] = useState<SimulacaoDescarte[]>();
  const [observacao, setObservacao] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const { sumQuantidadeDescarte, campoProducaoTotalMp } = useMemo(() => (
    {
      sumQuantidadeDescarte: sumBy(simulacaoDescartes, 'quantidadeDescarte'),
      campoProducaoTotalMp: sumBy(simulacaoDescartes, 'campoProducaoTotalMp'),
    }
  ), [simulacaoDescartes]);

  const {
    cultivar, depositoOrigem, depositoDestino, subProduto, quantidade, camposProducoes,
  } = useSelector(selectDescartesState);

  const legenda = useMemo(() => [
    { label: 'Cultivar', value: cultivar?.descricao },
    { label: 'Depósito de matéria prima', value: depositoOrigem?.descricao },
    { label: 'Depósito de destino', value: depositoDestino?.descricao },
    { label: 'Sub-Produto', value: subProduto?.descricao },
  ], [cultivar, depositoOrigem, depositoDestino, subProduto]);

  useImperativeHandle(ref, () => ({
    submit: async () => {
      await promiseWithLoaderAndMessages(
        dispatch,
        DescartesService.confirmarDescartes(
          simulacaoDescartes!.map((descarte) => ({ ...descarte, observacao })),
        ),
        {
          errorMessage: {
            summary: 'Erro',
            detail: 'Falha ao confirmar descartes',
          },
          successMessage: {
            summary: 'Confirmado',
            detail: 'Descartes confirmados com sucesso',
          },
        },
      );
      history.push('/sementes/subproduto');
    },
  }));

  useEffect(() => {
    const fetchSimulacaoDescarte = async () => {
      if (!depositoOrigem || !depositoDestino || !cultivar || !subProduto) return;

      try {
        const result = await promiseWithLoaderAndMessages(
          dispatch,
          DescartesService.simulaDescarteTotal({
            quantidade,
            cultivarId: cultivar.id,
            safraId: currentSafra!.id,
            depositoId: depositoOrigem.id,
            produtorId: currentProdutor!.id,
            camposProducaoIds: map(camposProducoes, 'campo.id'),
          }),
          { errorMessage: falhaAoCarregar('simulação de descarte') },
        );

        setSimulacaoDescartes(map(result.descartes, (sdescarte) => ({
          ...sdescarte,
          subProduto,
          depositoDestino,
          depositoOrigem,
          cultivar,
          data: new Date().toISOString(),
          safra: currentSafra!,
          produtor: currentProdutor!,
        })));
      } catch (error) {
        console.error(error);
        setErrorMessage(error?.response?.data);
      }
    };

    if (visible) fetchSimulacaoDescarte();
  }, [depositoOrigem, cultivar, currentProdutor, currentSafra, visible]);

  useEffect(() => {
    setCanGoNext(!!simulacaoDescartes?.length && !errorMessage);
  }, [simulacaoDescartes]);

  if (errorMessage) return <Message severity="error" text={errorMessage} className="width-full p-justify-start" />;

  return (
    <>
      <div>
        {legenda.map((item, index) => (
          <h5 key={index} className="p-ml-2 p-my-1">{item.label}: <strong>{item.value}</strong></h5>
        ))}
      </div>
      <div className="p-mt-4">
        <DataTable
          dataKey="id"
          value={simulacaoDescartes}
          selectionMode="multiple"
          className="p-datatable-gridlines"
          emptyMessage="Nenhum registro encontrado"
          responsiveLayout="scroll"
        >
          <Column
            field="data"
            header="Data"
            body={(descarte: SimulacaoDescarte) => formatWithoutTime(descarte.data)}
            footer={<>Totais:</>}
          />
          <Column field="campoProducao.identificacaoSigef" bodyClassName="cy-campoProducao" header="Campo de produção" />
          <Column
            header="Total M.P"
            bodyClassName="cy-totalMateriaPrima"
            body={(descarte: SimulacaoDescarte) => (
              formatNumber(descarte.campoProducaoTotalMp, 1) || ''
            )}
            footer={formatNumber(campoProducaoTotalMp, 1) || ''}
            footerClassName="cy-footerTotalMP"
          />
          <Column
            header="% Descarte"
            bodyClassName="cy-descarte"
            body={(descarte: SimulacaoDescarte) => (
              formatNumber(descarte.percentualDescarte, 1) || ''
            )}
          />
          <Column
            header="Quantidade"
            bodyClassName="cy-quantidade"
            body={(descarte: SimulacaoDescarte) => (
              formatNumber(descarte.quantidadeDescarte, 1) || ''
            )}
            footer={formatNumber(sumQuantidadeDescarte, 1) || ''}
            footerClassName="cy-footerQuantidadeTotal"
          />
        </DataTable>
      </div>
      <div>
        <div className="p-mt-4">
          <label aria-labelledby="observacao" htmlFor="observacao">Observação:</label>
          <InputTextarea
            className="width-full p-mt-1"
            id="observacao"
            rows={6}
            value={observacao}
            onChange={({ currentTarget }) => setObservacao(currentTarget.value)}
            autoResize
          />
        </div>
      </div>
    </>
  );
});

export default ConfirmacaoDescarte;
