import { useDispatch, useSelector } from 'react-redux';
import {
  FC,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { flatten, isEmpty } from 'lodash';
import Header from './CroquiLotes/components/Header';
import ArmazemService from '../../../services/sementes/armazem';
import { promiseWithLoaderAndMessages } from '../../../utilities';
import { Croqui } from '../../../@types/sementes/croqui';
import { CroquiItem } from '../../../@types/sementes/croquiItem';
import { Armazem } from '../../../@types/sementes/armazem';
import MovimentacaoLote from '../Planejamento/components/MovimentacaoLote';
import { Lote, MovimentoLote } from '../../../@types/sementes/lote';
import movimentoLoteArmazem from '../../../services/sementes/movimento-lote-armazem';
import CroquiMap from './CroquiLotes/components/CroquiMap';
import { falhaAoCarregar, toastSaveMessages } from '../../../utilities/default-confirmation-messages';
import { resetFilterState, selectFilterState } from '../../../components/Filter/filterSlice';
import { fetchSaldosLotesArmazem, selectLocalizacaoState } from './localizacaoSlice';

const LocalizacaoPage: FC = () => {
  const dispatch = useDispatch();
  const { saldos } = useSelector(selectLocalizacaoState);
  const { localizacao: saldosFiltered } = useSelector(selectFilterState);
  const [croqui, setCroqui] = useState<Croqui>();
  const [armazem, setArmazem] = useState<Armazem>();
  const [loteSelected, setLoteSelected] = useState<Lote>();
  const [itemOrigem, setItemOrigem] = useState<CroquiItem>();

  const filtered = useMemo(
    () => !isEmpty(Object.values(saldosFiltered.conditions)),
    [saldosFiltered],
  );

  const totalDisponivel = useMemo(
    () => saldos.find(
      (saldo) => saldo.lote.id === loteSelected?.id && saldo.itemCroqui.id === itemOrigem?.id,
    )?.saldo || 0,
    [saldos, loteSelected, itemOrigem],
  );

  const submitMovimentacao = async (movimentos: MovimentoLote[]) => {
    await promiseWithLoaderAndMessages(
      dispatch,
      movimentoLoteArmazem.create(movimentos),
      toastSaveMessages('movimento de lote'),
    );
    fetchSaldos();
  };

  const openMovimentaLote = (item: CroquiItem, lote: Lote) => {
    setLoteSelected(lote);
    setItemOrigem(item);
  };

  const fetchSaldos = async () => {
    if (!armazem) return;
    dispatch(fetchSaldosLotesArmazem({
      conditions: flatten(Object.values(saldosFiltered.conditions)),
      sorts: saldosFiltered.sort ? [saldosFiltered.sort] : undefined,
      extraParameters: {
        armazemId: armazem.id,
      },
    }));
  };

  useEffect(() => {
    if (!armazem) dispatch(resetFilterState({ context: 'localizacao' }));
    if (!armazem?.croquiId) return;
    const fetchCroqui = async () => {
      const fetchedCroqui = await promiseWithLoaderAndMessages(
        dispatch,
        ArmazemService.croqui(armazem.id),
        { errorMessage: falhaAoCarregar('croqui') },
      );

      setCroqui(fetchedCroqui);
    };

    fetchCroqui();
    fetchSaldos();
  }, [armazem]);

  useEffect(() => {
    fetchSaldos();
  }, [saldosFiltered]);

  return (
    <div className="localizacao-page">
      <div className="p-grid croqui-lotes">
        <div className="p-col-12 card p-m-2 p-p-3">
          <Header
            armazem={armazem}
            setArmazem={setArmazem}
          />

          <MovimentacaoLote
            onHide={() => setLoteSelected(undefined)}
            lote={loteSelected}
            itemOrigem={itemOrigem}
            onSubmit={submitMovimentacao}
            allowPartialMovimentation
            totalDisponivel={totalDisponivel}
          />

          {croqui && (
            <CroquiMap
              saldos={saldos}
              croqui={croqui}
              onLoteClick={openMovimentaLote}
              filtered={filtered}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default LocalizacaoPage;
