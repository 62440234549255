import React, {
  FC, useMemo,
} from 'react';
import { VizState } from '@cubejs-client/react';
import { ProgressSpinner } from '@agro1desenvolvimento/react-components';
import {
  CartesianGrid,
  PieChart,
  Pie,
  Cell,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Legend,
  BarChart,
  Bar,
  LineChart,
  Line,
  LabelList,
} from 'recharts';
import { ChartType, PivotConfig, ResultSet } from '@cubejs-client/core';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { useCustomizedCubeQuery } from '../../../../hooks';
import { selectTopBarState } from '../../../../components/Topbar/topBarSlice';
import { ExtraVizState } from '../../../../@types/geral/dashboard';
import { selectDashboardsState } from '../dashboardSlice';
import {
  DAYJS_FORMATS, formatNumber, isISODate, LIST_LABEL_FONT_SIZE, shouldDisplayLabel,
} from '../../../../utilities';
import TableRendererWithExport from './TableRendererWithExport';

// TODO: Aguardando correção: https://github.com/iamkun/dayjs/issues/929
const isValidDate = (value: any) => (
  isISODate(value) || dayjs(value, DAYJS_FORMATS.american, true).isValid()
);

const format = (value: any) => {
  if (!isNaN(+value)) return formatNumber(value);

  const valid = isValidDate(value);
  if (valid) return dayjs(value).format('L');

  return value;
};

const displayValue = (extraVizState: ExtraVizState, eValue: any, value: any) => {
  if (!extraVizState.displayValuesOnBarGraph) return '';
  if (!shouldDisplayLabel(eValue, value)) return '';
  const formattedValue = format(value);

  return formattedValue;
};

const colors = ['#00b894', '#6c5ce7', '#0984e3', '#008EA8', '#1266DE'];

const getColor = (index: number) => colors[index % colors.length];

interface CartesianChartPropsType {
  ChartComponent: React.ComponentType<{ data?: any }>
  resultSet: ResultSet
}

const CartesianChart: React.FC<CartesianChartPropsType> = ({
  resultSet, children, ChartComponent,
}) => (
  <ResponsiveContainer width="100%" height={350}>
    <ChartComponent data={resultSet.chartPivot()}>
      <XAxis dataKey="x" tickFormatter={format} />
      <YAxis tickFormatter={format} />
      <CartesianGrid />
      {children}
      <Legend />
      <Tooltip
        formatter={format}
        labelFormatter={format}
        cursor={{ opacity: 0.25 }}
      />
    </ChartComponent>
  </ResponsiveContainer>
);

interface ChartPropsTypes {
  pivotConfig: PivotConfig,
  resultSet: ResultSet<any>,
  extraVizState: ExtraVizState,
  chartTitle: string
}

const TypeToChartComponent: Record<ChartType, React.FC<ChartPropsTypes>> = {
  line: ({ resultSet }) => (
    <CartesianChart resultSet={resultSet} ChartComponent={LineChart}>
      {resultSet.seriesNames().map((series, i) => (
        <Line
          key={series.key}
          dataKey={series.key}
          name={series.title}
          stroke={getColor(i)}
        />
      ))}
    </CartesianChart>
  ),
  bar: ({ resultSet, extraVizState }) => (
    <CartesianChart
      resultSet={resultSet}
      ChartComponent={BarChart}
    >
      {resultSet.seriesNames().map((series, i) => (
        <Bar
          key={series.key}
          stackId="a"
          dataKey={series.key}
          name={series.title}
          fill={getColor(i)}
        >
          <LabelList
            valueAccessor={(value: any) => displayValue(extraVizState, value, value[series.key])}
            fontSize={LIST_LABEL_FONT_SIZE}
            position="centerTop"
          />
        </Bar>
      ))}
    </CartesianChart>
  ),
  area: ({ resultSet }) => (
    <CartesianChart resultSet={resultSet} ChartComponent={AreaChart}>
      {resultSet.seriesNames().map((series, i) => (
        <Area
          key={series.key}
          stackId="a"
          dataKey={series.key}
          name={series.title}
          stroke={getColor(i)}
          fill={getColor(i)}
        />
      ))}
    </CartesianChart>
  ),
  pie: ({ resultSet }) => (
    <ResponsiveContainer width="100%" height={350}>
      <PieChart>
        <Pie
          isAnimationActive={false}
          data={resultSet.chartPivot()}
          nameKey="x"
          dataKey={resultSet.seriesNames()[0]?.key}
          fill="#8884d8"
        >
          {resultSet.chartPivot().map((e, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Cell key={index} fill={getColor(index)} />
          ))}
        </Pie>
        <Legend />
        <Tooltip formatter={format} labelFormatter={format} />
      </PieChart>
    </ResponsiveContainer>
  ),
  number: ({ resultSet }) => (
    <>
      {resultSet.seriesNames().map(({ key }) => (
        <h4 key={key} className="p-text-center">
          {format(resultSet.totalRow()[key])}
        </h4>
      ))}
    </>
  ),
  table: ({ resultSet, pivotConfig, chartTitle }) => (
    <TableRendererWithExport
      resultSet={resultSet}
      pivotConfig={pivotConfig}
      chartTitle={chartTitle}
    />
  ),
};

const TypeToMemoChartComponent = (
  Object.keys(TypeToChartComponent) as ChartType[]
).reduce((acc, key) => {
  acc[key] = React.memo(TypeToChartComponent[key]);
  return acc;
}, {} as Record<ChartType, React.FC<ChartPropsTypes>>);

type RenderChartType = (Component: React.FC<ChartPropsTypes>) => (
  (arg: {
    resultSet: ChartPropsTypes['resultSet'] | null,
    pivotConfig: ChartPropsTypes['pivotConfig'],
    extraVizState: ChartPropsTypes['extraVizState'],
    error: Error | null,
    chartTitle: string
  }) => React.ReactElement
)

const renderChart: RenderChartType = (Component) => ({
  resultSet, error, pivotConfig, extraVizState, chartTitle,
}) => {
  if (resultSet && pivotConfig) {
    return (
      <Component
        chartTitle={chartTitle}
        resultSet={resultSet}
        pivotConfig={pivotConfig}
        extraVizState={extraVizState}
      />
    );
  }

  if (error) return <>{error.message}</>;

  return <div className="spinner-container"> <ProgressSpinner /> </div>;
};

const ChartRenderer: React.FC<ChartRendererProps> = ({ vizState, extraVizState, chartTitle }) => {
  const { query, chartType, pivotConfig } = vizState;
  const { currentProdutor, currentSafra } = useSelector(selectTopBarState);
  const { currentEspeciesIds, currentCultivaresIds } = useSelector(selectDashboardsState);

  const queryVariables = useMemo(() => ({
    $SAFRA: currentSafra?.id,
    $PRODUTOR: currentProdutor?.id,
    $ESPECIES: currentEspeciesIds,
    $CULTIVARES: currentCultivaresIds,
  }), [currentProdutor, currentSafra, currentEspeciesIds, currentCultivaresIds]);

  const renderProps = useCustomizedCubeQuery(
    useMemo(() => query || {}, [query]),
    queryVariables,
  );

  if (!chartType || !query || !pivotConfig) return null;

  return renderChart(TypeToMemoChartComponent[chartType])(
    {
      pivotConfig,
      error: renderProps.error,
      resultSet: renderProps.resultSet,
      extraVizState: extraVizState || {},
      chartTitle,
    },
  );
};

export default ChartRenderer;

interface ChartRendererProps {
  vizState: VizState,
  extraVizState?: ExtraVizState,
  chartTitle: string
}
