import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HeaderParams } from '../../../@types/headers';
import { Reembalagem } from '../../../@types/sementes/reembalagem';
import type { RootState } from '../../../app/rootReducer';
import queryable, { Query } from '../../../services/queryable';
import { ListReturnType } from '../../../services/queryable-base';
import ReembalagensService from '../../../services/sementes/reembalagens';
import { promiseWithLoaderAndMessages } from '../../../utilities';
import { falhaAoCarregar } from '../../../utilities/default-confirmation-messages';

interface ReembalagemState {
  reembalagens: Reembalagem[],
  pagination: HeaderParams,
  reembalagensSelecionadas: Reembalagem[],
}

const initialState: ReembalagemState = {
  reembalagens: [],
  pagination: {
    currentPage: undefined,
    totalRecords: undefined,
    maxPerPage: undefined,
    pages: undefined,
  },
  reembalagensSelecionadas: [],
};

export const fetchReembalagens = createAsyncThunk<
  ListReturnType<Reembalagem>,
  undefined | Omit<Query<Reembalagem>, 'service'>
>(
  'sementes/reembalagens', (param = {}, { dispatch }) => {
    const query = queryable.query({
      ...param,
      service: ReembalagensService,
    });

    return promiseWithLoaderAndMessages(
      dispatch,
      query,
      { errorMessage: falhaAoCarregar('reembalagens') },
    );
  },
);

export const reembalagemSlice = createSlice({
  name: 'reembalagens',
  initialState,
  reducers: {
    setReembalagensSelecionadas: (state, action: PayloadAction<Reembalagem[]>) => {
      state.reembalagensSelecionadas = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchReembalagens.fulfilled, (state, { payload }) => {
      state.reembalagens = payload.data;
      state.pagination = payload.headers;
    });
  },
});

export const selectReembalagensState = (state: RootState) => state.reembalagensReducer;

export const { setReembalagensSelecionadas } = reembalagemSlice.actions;

export default reembalagemSlice.reducer;
