import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  selectTopBarState,
} from '../../../components/Topbar/topBarSlice';
import FormularioArmazem from './components/FormArmazem';

const NewEditArmazemPage: FC = () => {
  const history = useHistory();

  const {
    currentProdutor,
  } = useSelector(selectTopBarState);

  if (!currentProdutor) {
    history.replace('/sementes/armazens');
    return null;
  }

  return <FormularioArmazem />;
};
export default NewEditArmazemPage;
