import { FC, useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';
import { Dropdown } from '@agro1desenvolvimento/react-components';
import { FilterFieldProps } from './FilterFieldProps';
import { promiseWithLoaderAndMessages } from '../../utilities';
import { falhaAoCarregar } from '../../utilities/default-confirmation-messages';

const FilterSelect: FC<FilterFieldProps> = ({
  filterField, setFilterConditions, filterConditions,
}) => {
  const [items, setItems] = useState<any[] | undefined>(filterField.items);
  const dispatch = useDispatch();

  const addFilterValue = (value: string) => {
    setFilterConditions(filterField.field, [{
      field: filterField.field,
      value,
      operator: 'eq',
    }]);
  };

  useEffect(() => {
    const initialize = async () => {
      if (!isEmpty(items) || !filterField.service?.listAll) return;

      const promise = promiseWithLoaderAndMessages<any>(dispatch, filterField.service.listAll(), {
        errorMessage: falhaAoCarregar('dados'),
      });
      setItems((await promise).data);
    };

    initialize();
  }, []);

  return (
    <span className="p-float-label">
      <Dropdown
        id={`filter-${filterField.field}`}
        value={filterConditions[filterField.field]?.[0]?.value}
        onChange={(e: any) => addFilterValue(e.value)}
        options={items}
        optionLabel={filterField.optionLabel}
        optionValue={filterField.optionKey}
        showClear
        filter
        filterMatchMode="contains"
        filterBy={filterField.optionLabel}
        emptyFilterMessage="Sem resultados"
      />
      <label htmlFor={`filter-${filterField.field}`}>{filterField.label}</label>
    </span>
  );
};

export default FilterSelect;
