import { LoteStatus } from '../@types/sementes/lote';
import { PlanejamentoStatus } from '../@types/sementes/planejamento';

export const lotesStatuses: LoteStatus[] = [
  'disponivel', 'analisando', 'bloqueado', 'rascunho',
];

export const planejamentoStatuses: PlanejamentoStatus[] = [
  'criado', 'lotes_planejados', 'finalizado',
];
