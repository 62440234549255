import { FC } from 'react';
import { Paginator, PaginatorPageState } from '@agro1desenvolvimento/react-components';

type PaginationProps = {
  first: number | undefined,
  rows: number | undefined,
  totalRecords: number | undefined,
  onPageChange: (e: PaginatorPageState) => void,
  pages: number | undefined,
  pageRecords: number,
};

const Pagination: FC<PaginationProps> = ({
  first = 1,
  rows = 1,
  onPageChange,
  pages = 1,
  totalRecords,
  pageRecords,
}) => (
  pages > 1 ? (
    <div className="pagination-container">
      <Paginator
        data-testid="paginator-component"
        first={first}
        rows={rows}
        totalRecords={totalRecords}
        onPageChange={(e) => onPageChange(e)}
      />
      <div className="text" data-testid="paginator-text">
        Exibindo {first + 1} a {first + pageRecords} de {totalRecords}
      </div>
    </div>
  ) : null
);

export default Pagination;
