import classNames from 'classnames';
import { FormikErrors, useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import {
  find,
  first,
  isEmpty,
  isNumber,
  round,
} from 'lodash';
import {
  FC, useEffect, useMemo, useRef, useState,
} from 'react';
import {
  Calendar, Dropdown, InputText, Button, DropdownChangeParams,
} from '@agro1desenvolvimento/react-components';
import * as Yup from 'yup';
import { ptForm } from 'yup-locale-pt';
import ShowErrorHelper from '../../../components/ShowErrorHelper';
import { fetchPeneiras, selectPeneiraState } from '../Peneira/peneiraSlice';
import { fetchPeriodos, selectPeriodoState } from '../../geral/Periodos/periodosSlice';
import findOrThrow from '../../../utilities/find-or-trow';
import { selectTopBarState } from '../../../components/Topbar/topBarSlice';
import loteService, { CrudCreateUpdateLote } from '../../../services/sementes/lotes';
import { fetchTiposEmbalagens, selectTiposEmbalagensState } from '../TiposEmbalagens/tiposEmbalagensSlice';
import catchApiErrorsAndSetFormErrors from '../../../utilities/catch-api-errors';
import UnidadeService from '../../../services/estoque/unidades';
import { Unidade } from '../../../@types/estoque/unidade';
import { dateWithLastDayOfMonth, promiseWithLoaderAndMessages } from '../../../utilities';
import { Planejamento } from '../../../@types/sementes/planejamento';
import { Lote, LoteStatus } from '../../../@types/sementes/lote.d';
import { setMode, selectNewEditPlanejamentoState } from '../Planejamento/newEditPlanejamentoSlice';
import { TipoEmbalagem } from '../../../@types/enums';
import { PeriodoEnum } from '../../../@types/geral/periodo';
import { falhaAoCarregar, toastSaveMessages } from '../../../utilities/default-confirmation-messages';
import { selectConfigSementesState } from '../Config/configSlice';
import { Cultivar } from '../../../@types/estoque/item';
import { Ubs } from '../../../@types/sementes/ubs';

const initialValues: PlanejamentoLoteFormFields = {
  id: undefined,
  numeroLote: '',
  dataLote: new Date().toISOString(),
  peneira: undefined,
  periodo: undefined,
  pesoEmbalagem: undefined,
  pesoLote: undefined,
  pms: undefined,
  quantidade: undefined,
  tipoEmbalagem: '',
  unidade: undefined,
  validade: new Date(new Date().getFullYear(), 12, 0),
  status: 'rascunho',
  especieId: '',
  cultivar: undefined,
  ubs: undefined,
};

const EditLoteForm: FC<EditLoteFormProps> = ({
  planejamento,
  setEnableSubmitForm,
  lote,
  showSubmitButton = true,
  afterSubmit,
  onSubmit,
  fieldsOptions,
  formErrors,
}) => {
  const {
    currentSafra,
    currentProdutor,
  } = useSelector(selectTopBarState);
  const sementesConfig = useSelector(selectConfigSementesState);
  const dispatch = useDispatch();
  const { peneiras } = useSelector(selectPeneiraState);
  const { periodos } = useSelector(selectPeriodoState);
  const { tiposEmbalagens } = useSelector(selectTiposEmbalagensState);
  const [loadingPesoEmbalagem, setLoadingPesoEmbalagem] = useState(false);
  const [unidades, setUnidades] = useState<Unidade[]>([]);
  const [isByPeso, setIsByPeso] = useState(false);
  const numeroLoteInputRef = useRef<HTMLInputElement>(null);
  const especieId = useMemo(
    () => lote?.especieId || planejamento?.cultivar?.especie?.id,
    [lote, planejamento],
  );
  const newEditPlanejamentoState = useSelector(selectNewEditPlanejamentoState);

  const validationSchema = useMemo(() => Yup.object().shape({
    numeroLote: fieldsOptions?.numeroLote?.render ? Yup.string() : Yup.string().required(),
    peneira: Yup.string(),
    periodo: fieldsOptions?.periodo?.render ? Yup.string() : Yup.string().required(),
    pesoEmbalagem: fieldsOptions?.pesoEmbalagem?.render ? Yup.number() : Yup.number()
      .required().min(0),
    pesoLote: fieldsOptions?.pesoLote?.render ? Yup.number() : Yup.number()
      .required().min(0),
    pms: fieldsOptions?.pms?.render ? Yup.number() : Yup.number().min(0)
      .when('tipoEmbalagem', { is: TipoEmbalagem.quantidade, then: (schema) => schema.required() }),
    quantidade: fieldsOptions?.quantidade?.render ? Yup.number() : Yup.number().required().min(0),
    tipoEmbalagem: fieldsOptions?.tipoEmbalagem?.render ? Yup.string() : Yup.string().required(),
    unidade: fieldsOptions?.unidade?.render ? Yup.string() : Yup.string().required(),
    validade: fieldsOptions?.validade?.render ? Yup.date() : Yup.date().required(),
  }), []);

  const form = useFormik({
    validationSchema,
    initialValues,
    async onSubmit(values) {
      if (!currentSafra || !currentProdutor) return;

      const isEdit = !!values.id;
      const toSubmit: Partial<CrudCreateUpdateLote> = {
        id: values.id,
        cultivar: planejamento?.cultivar || lote?.cultivar,
        dataLote: values.dataLote,
        dataValidade: dateWithLastDayOfMonth(values.validade).toISOString(),
        fornecedor: null,
        observacao: '',
        peneira: (values.peneira && findOrThrow(peneiras, { id: values.peneira })) || null,
        pesoEmbalagemReal: 0,
        produtor: currentProdutor,
        status: values.id ? lote?.status || 'rascunho' : 'rascunho',
        safra: currentSafra,
        ubs: planejamento?.ubs || lote?.ubs,
        unidade: findOrThrow(unidades, { id: values.unidade }),
        planejamento: planejamento || null,
        pesoEmbalagem: values.pesoEmbalagem || 0,
        pms: values.pms || 0,
        quantidade: values.quantidade || 0,
        numeroLote: values.numeroLote,
        periodo: values.periodo || null,
        pesoTotalKg: values.pesoLote || 0,
        especieId: lote?.especieId || '',
      };

      if (onSubmit) {
        onSubmit(toSubmit as CrudCreateUpdateLote);
        return;
      }

      try {
        const newLote = (await promiseWithLoaderAndMessages(
          dispatch,
          loteService.createOrUpdate(toSubmit),
          toastSaveMessages('lote'),
        )).data;

        if (isEdit) {
          form.resetForm();
          dispatch(setMode('list'));
        }

        focusToNumeroLote();
        if (afterSubmit) afterSubmit(newLote, isEdit ? 'edit' : 'create');
        return newLote;
      } catch (erro) {
        catchApiErrorsAndSetFormErrors(form.setFieldError, erro);
        throw erro;
      }
    },
  });

  const enableSubmit = useMemo(() => (
    (form.isValid || form.isSubmitting || !form.dirty) && !loadingPesoEmbalagem
  ),
  [form.isValid, form.isSubmitting, form.dirty, loadingPesoEmbalagem]);

  const focusToNumeroLote = () => {
    if (!numeroLoteInputRef.current) return;

    numeroLoteInputRef.current.focus();
    numeroLoteInputRef.current.select();
  };

  const fetchStoreData = () => {
    dispatch(fetchPeriodos());
    dispatch(fetchTiposEmbalagens());
  };

  const preenchePeriodo = () => {
    if (!isEmpty(planejamento?.campoProducoes) && isEmpty(form.values.periodo)) {
      form.setFieldValue('periodo', first(planejamento?.campoProducoes)?.periodo);
    }
  };

  const isVisible = useMemo(() => newEditPlanejamentoState.mode === 'newEdit', [newEditPlanejamentoState.mode]);

  useEffect(() => {
    preenchePeriodo();
  }, [planejamento, form.values.periodo]);

  useEffect(() => {
    if (isVisible) focusToNumeroLote();
  }, [isVisible]);

  useEffect(() => {
    if (!especieId) return;

    dispatch(fetchPeneiras({
      conditions: [{
        field: 'especie_id',
        operator: 'eq',
        value: especieId,
      }],
    }));
  }, [especieId]);

  /**
   * **Atenção:** O retorno da função é o cleanup, ele deve ser chamado antes da proxima alteração
   */
  const fetchPesoEmbalagem = () => {
    const { unidade, pms } = form.values;

    const isValidPMS = (isByPeso || isNumber(pms));

    if (!isValidPMS || !unidade || !form.dirty) return;

    setLoadingPesoEmbalagem(true);

    const timeoutID = setTimeout(async () => {
      const pesoEmbalagem = await loteService.pesoEmbalagem(unidade, pms)
        .finally(() => setLoadingPesoEmbalagem(false));

      form.setFieldValue('pesoEmbalagem', pesoEmbalagem);
    }, 1000);

    return () => {
      setLoadingPesoEmbalagem(false);
      clearTimeout(timeoutID);
    };
  };

  useEffect(fetchPesoEmbalagem, [form.values.unidade]);

  useEffect(() => {
    if (form.values.tipoEmbalagem === TipoEmbalagem.quantidade) return fetchPesoEmbalagem();
  }, [form.values.pms]);

  useEffect(() => {
    setIsByPeso(form.values.tipoEmbalagem === TipoEmbalagem.peso);
  }, [form.values.tipoEmbalagem]);

  useEffect(() => {
    fetchStoreData();
  }, []);

  useEffect(() => {
    if (!formErrors) return;

    catchApiErrorsAndSetFormErrors(form.setFieldError, formErrors, true);
  }, [formErrors]);

  useEffect(() => {
    const { pesoEmbalagem, quantidade } = form.values;
    const setPesoLote = async (pesoLote: number | undefined) => {
      await form.setFieldValue('pesoLote', pesoLote);
      form.validateField('pesoLote');
    };

    if (isNumber(pesoEmbalagem) && isNumber(quantidade)) {
      setPesoLote(round(pesoEmbalagem * quantidade, 4));
    } else if (isNumber(form.values.pesoLote)) {
      setPesoLote(undefined);
    }
  }, [form.values.pesoEmbalagem, form.values.quantidade]);

  useEffect(() => {
    const fetchUnidades = async () => {
      const { tipoEmbalagem, unidade } = form.values;
      if (!tipoEmbalagem) return;

      setUnidades([]);
      const novasUnidades = await promiseWithLoaderAndMessages(
        dispatch,
        UnidadeService.listCompativeis(tipoEmbalagem),
        { withLoader: false, errorMessage: falhaAoCarregar('unidades') },
      );
      setUnidades(novasUnidades);

      if (unidade) form.setFieldValue('unidade', find(novasUnidades, { id: unidade })?.id);
    };

    if (form.values.tipoEmbalagem) {
      fetchUnidades();
    } else {
      setUnidades([]);
      form.setFieldValue('unidade', undefined);
    }
  }, [form.values.tipoEmbalagem]);

  useEffect(() => {
    if (setEnableSubmitForm) setEnableSubmitForm(enableSubmit);
  }, [form.isValid, form.isSubmitting, form.dirty]);

  useEffect(() => {
    form.resetForm({ values: lote || initialValues });
    focusToNumeroLote();
  }, [lote]);

  const tooltipPesoEmbalagem = useMemo(() => {
    const showTooltipMargem = form.values.tipoEmbalagem === 'UN' && isNumber(sementesConfig?.margemSegurancaPesoSementes);
    if (showTooltipMargem) {
      return `Está sendo aplicada uma margem de segurança de ${sementesConfig?.margemSegurancaPesoSementes?.toLocaleString()}% no cálculo`;
    }

    return '';
  }, [form.values.tipoEmbalagem, sementesConfig?.margemSegurancaPesoSementes]);

  return (
    <form id="form-lote" onSubmit={form.handleSubmit}>
      <div className="p-fluid p-formgrid p-grid ">
        <div className="p-field p-col-12 p-md-3">
          <label aria-labelledby="numeroLote" htmlFor="numeroLote">Número Lote</label>
          {
            fieldsOptions?.numeroLote?.render
              ? fieldsOptions?.numeroLote?.render(form.handleChange, form.errors.numeroLote)
              : (
                <>
                  <InputText
                    ref={numeroLoteInputRef as any}
                // TODO: https://github.com/primefaces/primereact/issues/1867
                    name="numeroLote"
                    id="numeroLote"
                    placeholder="Número Lote"
                    value={form.values.numeroLote}
                    onChange={form.handleChange}
                    className={classNames({ 'p-invalid': form.errors.numeroLote })}
                    aria-describedby="numero-lote-help"
                  />
                  <ShowErrorHelper id="numero-lote-help" error={form.errors.numeroLote} />
                </>
              )
          }
        </div>
        <div className="p-field p-col-12 p-md-3">
          <label aria-labelledby="peneira" htmlFor="peneira">Peneira</label>
          {fieldsOptions?.peneira?.render
          || (
          <>
            <Dropdown
              value={form.values.peneira}
              name="peneira"
              id="peneira"
              appendTo={document.body}
              options={peneiras}
              optionLabel="descricao"
              optionValue="id"
              disabled={!peneiras.length || fieldsOptions?.peneira?.disabled}
              onChange={form.handleChange}
              placeholder="Selecione a peneira"
              filter
              className={classNames({ 'p-invalid': form.errors.peneira })}
              aria-describedby="peneira-help"
            />
            <ShowErrorHelper id="peneira-help" error={form.errors.peneira} />
          </>
          )}

        </div>
        <div className="p-field p-col-12 p-md-3">
          <label aria-labelledby="tipoEmbalagem" htmlFor="tipoEmbalagem">Tipo de embalagem</label>
          {fieldsOptions?.tipoEmbalagem?.render
          || (
          <>
            <Dropdown
              value={form.values.tipoEmbalagem}
              name="tipoEmbalagem"
              id="tipoEmbalagem"
              appendTo={document.body}
              options={tiposEmbalagens}
              optionLabel="descricao"
              optionValue="unidadeBase"
              onChange={form.handleChange}
              placeholder="Selecione o tipo de embalagem"
              disabled={fieldsOptions?.tipoEmbalagem?.disabled}
              filter
              className={classNames({ 'p-invalid': form.errors.tipoEmbalagem })}
              aria-describedby="tipo-embalagem-help"
            />
            <ShowErrorHelper id="tipo-embalagem-help" error={form.errors.tipoEmbalagem} />
          </>
          )}
        </div>

        <div className="p-field p-col-12 p-md-3">
          <label aria-labelledby="unidade" htmlFor="unidade">Unidade</label>
          {fieldsOptions?.unidade?.render
          || (
          <>
            <Dropdown
              value={form.values.unidade}
              name="unidade"
              id="unidade"
              appendTo={document.body}
              options={unidades}
              optionLabel="descricao"
              optionValue="id"
              disabled={!unidades.length || fieldsOptions?.unidade?.disabled}
              onChange={form.handleChange}
              placeholder="Selecione a unidade"
              filter
              className={classNames({ 'p-invalid': form.errors.unidade })}
              aria-describedby="unidade-help"
            />
            <ShowErrorHelper id="unidade-help" error={form.errors.unidade} />
          </>
          )}
        </div>

        <div className="p-field p-col-12 p-md-3">
          <label aria-labelledby="pms" htmlFor="pms">PMS</label>
          {fieldsOptions?.pms?.render
          || (
          <>
            <InputText
              type="number"
              name="pms"
              id="pms"
              value={form.values.pms ?? ''}
              placeholder="PMS"
              onChange={form.handleChange}
              className={classNames({ 'p-invalid': form.errors.pms })}
              step=".01"
              aria-describedby="pms-help"
              readOnly={fieldsOptions?.pms?.readonly}
            />
            <ShowErrorHelper id="pms-help" error={form.errors.pms} />
          </>
          )}
        </div>

        <div className={isByPeso ? 'p-field p-col-12 p-md-3 p-disabled' : 'p-field p-col-12 p-md-3'}>
          <label aria-labelledby="pesoEmbalagem" htmlFor="pesoEmbalagem">Peso da embalagem (KG)</label>
          {fieldsOptions?.pesoEmbalagem?.render
          || (
          <>
            <span className={classNames({ 'p-input-icon-right': loadingPesoEmbalagem })}>
              {loadingPesoEmbalagem && <i className="pi pi-spin pi-spinner" />}
              <InputText
                type="number"
                name="pesoEmbalagem"
                id="pesoEmbalagem"
                readOnly={isByPeso || fieldsOptions?.pesoEmbalagem?.readonly}
                disabled={loadingPesoEmbalagem}
                placeholder="Peso da embalagem (KG)"
                value={form.values.pesoEmbalagem ?? ''}
                onChange={form.handleChange}
                className={classNames({ 'p-invalid': form.errors.pesoEmbalagem })}
                aria-describedby="peso-embalagem-help"
                tooltip={tooltipPesoEmbalagem}
              />
              <ShowErrorHelper id="peso-embalagem-help" error={form.errors.pesoEmbalagem} />
            </span>
          </>
          )}
        </div>

        <div className="p-field p-col-12 p-md-3">
          <label aria-labelledby="quantidade" htmlFor="quantidade">Quantidade</label>
          {fieldsOptions?.quantidade?.render
          || (
          <>
            <InputText
              type="number"
              name="quantidade"
              id="quantidade"
              placeholder="Quantidade"
              value={form.values.quantidade ?? ''}
              onChange={form.handleChange}
              className={classNames({ 'p-invalid': form.errors.quantidade })}
              aria-describedby="quantidade-help"
              readOnly={fieldsOptions?.quantidade?.readonly}
            />
            <ShowErrorHelper id="quantidade-help" error={form.errors.quantidade} />
          </>
          )}
        </div>

        <div className="p-field p-col-12 p-md-3 p-disabled">
          <label aria-labelledby="pesoLote" htmlFor="pesoLote">Peso do lote</label>
          {fieldsOptions?.pesoLote?.render
          || (
          <>
            <InputText
              type="number"
              name="pesoLote"
              id="pesoLote"
              readOnly
              placeholder="Peso do lote"
              value={form.values.pesoLote ?? ''}
              className={classNames({ 'p-invalid': form.errors.pesoLote })}
              aria-describedby="peso-lote-help"
            />
            <ShowErrorHelper id="peso-lote-help" error={form.errors.pesoLote} />
          </>
          )}
        </div>

        <div className="p-field p-col-12 p-md-3">
          <label aria-labelledby="validade" htmlFor="validade">Validade</label>
          {fieldsOptions?.validade?.render
          || (
          <>
            <Calendar
              id="validade"
              value={form.values.validade}
              onChange={form.handleChange}
              showIcon
              className={classNames({ 'p-invalid': form.errors.validade })}
              appendTo={document.body}
              placeholder="Validade"
              aria-describedby="validade-help"
              dateFormat="mm/yy"
              view="month"
              disabled={fieldsOptions?.validade?.disabled}
            />
            <ShowErrorHelper
              id="validade-help"
              error={form.errors.validade as string}
              // TODO: https://github.com/formium/formik/issues/2028
            />
          </>
          )}
        </div>

        <div className="p-field p-col-12 p-md-3">
          <label aria-labelledby="periodo" htmlFor="periodo">Período</label>
          {fieldsOptions?.periodo?.render
          || (
          <>
            <Dropdown
              value={form.values.periodo}
              name="periodo"
              id="periodo"
              appendTo={document.body}
              options={periodos}
              optionLabel="descricao"
              optionValue="id"
              disabled={!periodos.length || fieldsOptions?.periodo?.disabled}
              onChange={form.handleChange}
              placeholder="Selecione o período"
              filter
              className={classNames({ 'p-invalid': form.errors.periodo })}
              aria-describedby="periodo-help"
            />
            <ShowErrorHelper id="periodo-help" error={form.errors.periodo} />
          </>
          )}
        </div>
      </div>

      <div className="p-d-flex">
        <div className="p-buttonset p-mt-auto p-mb-4 p-ml-auto" hidden={!showSubmitButton}>
          <Button
            aria-label="Cancelar"
            label="Cancelar"
            type="button"
            icon="pi pi-times"
            onClick={() => {
              form.resetForm();
              dispatch(setMode('list'));
            }}
            className="p-button-text"
          />
          <Button
            aria-label="Salvar"
            label="Salvar"
            type="submit"
            icon="pi pi-check"
            disabled={!enableSubmit}
          />
        </div>
      </div>
    </form>
  );
};

interface EditLoteFormProps {
  planejamento?: Planejamento,
  lote: PlanejamentoLoteFormFields | undefined,
  showSubmitButton?: boolean,
  setEnableSubmitForm?: (bool: boolean) => void,
  afterSubmit?: (Lote: Lote, action: 'create' | 'edit') => void,
  onSubmit?: (lote: CrudCreateUpdateLote) => void,
  fieldsOptions?: Partial<FieldsOptions>,
  formErrors?: FormikErrors<PlanejamentoLoteFormFields>,
}
Yup.setLocale(ptForm);
export interface PlanejamentoLoteFormFields {
  id?: string,
  numeroLote?: string,
  dataLote: string,
  peneira?: string,
  tipoEmbalagem?: string,
  cultivar?: Cultivar
  unidade?: string,
  pms?: number,
  ubs?: Ubs,
  pesoEmbalagem?: number,
  quantidade?: number,
  pesoLote?: number,
  validade: Date,
  periodo?: PeriodoEnum,
  status?: LoteStatus,
  especieId: string,
}

interface FieldOption {
  render?: (onChange: (option: DropdownChangeParams) => void, errors?: string) => JSX.Element,
  disabled?: boolean,
  readonly?: boolean,
}

type FieldsOptions = Record<keyof PlanejamentoLoteFormFields, FieldOption>

export default EditLoteForm;
