import { loginService } from '@agro1desenvolvimento/apis-js-package';
import { keysToSnakeCase } from '@agro1desenvolvimento/utils-js';
import { HeaderParams } from '../../@types/headers';
import { Equipamento } from '../../@types/laboratorio/equipamento';
import { CrudCreateUpdate } from '../../utilities';
import CrudBase from '../crud-base';
import api from '../erp-api';

export interface EquipamentoListReturnType extends HeaderParams {
  data: Equipamento[];
}

class EquipamentosService extends CrudBase<Equipamento> {
  get endpoint() {
    return `/${loginService.scope}/sementes/equipamentos`;
  }

  async create(equipamento: CrudCreateUpdate<Equipamento>) {
    return (await api.post<Equipamento[]>(
      this.endpoint, { equipamentos: [this.toSnakeCase(equipamento)] },
    )).data[0];
  }

  async update(equipamento: CrudCreateUpdate<Equipamento>) {
    return (await api.patch<Equipamento>(`${this.endpoint}/${equipamento.id}`, this.toSnakeCase(equipamento))).data;
  }

  toSnakeCase(equipamento: CrudCreateUpdate<Equipamento>): CrudCreateUpdate<Equipamento> {
    return keysToSnakeCase({
      ...equipamento,
      tipo: equipamento.tipo.id,
    });
  }
}

export default new EquipamentosService();
