import { loginService } from '@agro1desenvolvimento/apis-js-package';
import { Config, ConfiguracaoFormField } from '../../@types/sementes/config';
import api from '../erp-api';

class ConfigService {
  get endpoint() {
    return `/${loginService.scope}/sementes/config`;
  }

  async get() {
    return (await api.get<Config>(this.endpoint)).data;
  }

  async create(configuracao: ConfiguracaoFormField) {
    return (await api.post<ConfiguracaoFormField>(
      this.endpoint,
      {
        config_sementes: {
          sigef_username: configuracao.sigefUsername,
          sigef_password: configuracao.sigefPassword,
          sigef_matricula: configuracao.sigefMatricula,
          rastreabilidade_url: configuracao.rastreabilidadeUrl,
          margem_seguranca_peso_sementes: configuracao.margemSegurancaPesoSementes,
        },
      },
    )).data;
  }
}

export default new ConfigService();
