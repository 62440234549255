import {
  Column, ConfirmPopup, DataTable, Message, PaginatorPageState,
} from '@agro1desenvolvimento/react-components';
import { useIsMobile } from '@agro1desenvolvimento/react-hooks';
import { flatten } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AuditoriaType } from '../../../../@types/enums';
import { Descarte } from '../../../../@types/sementes/descarte';
import DropwDownButtons from '../../../../components/DropwDownButtons';
import { selectFilterState } from '../../../../components/Filter/filterSlice';
import Pagination from '../../../../components/Pagination';
import { selectTopBarState } from '../../../../components/Topbar/topBarSlice';
import { usePagination } from '../../../../hooks';
import { formatNumber, formatWithoutTime, scrollToTop } from '../../../../utilities';
import Filters from './Filters';
import { deleteDescarte, fetchDescartes, selectDescartesState } from '../descartesSlice';

const DescartesList = () => {
  const isMobile = useIsMobile();
  const history = useHistory();
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState('');
  const [expandRows, setExpandRows] = useState<Descarte[]>([]);
  const { currentProdutor, currentSafra } = useSelector(selectTopBarState);
  const { descartes, pagination } = useSelector(selectDescartesState);
  const [selectedDescarte, setSelectedDescarte] = useState<Descarte | null>();
  const { descartes: filterDescartes } = useSelector(selectFilterState);
  const {
    maxPerPage = 1,
    totalRecords,
    pages,
  } = pagination;
  const { currentPage, goToPage, firstIndexOnPage } = usePagination({ maxPerPage });
  const onPageChange = (e: PaginatorPageState) => goToPage(e.page + 1);

  const dispatchFetchDescartes = (page: number) => {
    scrollToTop();
    dispatch(fetchDescartes({
      conditions: [
        ...flatten(Object.values(filterDescartes.conditions)),
        {
          field: 'safra_id',
          operator: 'eq',
          value: currentSafra?.id || '',
        }, {
          field: 'produtor_id',
          operator: 'eq',
          value: currentProdutor?.id || '',
        },
      ],
      sorts: filterDescartes.sort ? [filterDescartes.sort] : undefined,
      extraParameters: {
        page: page.toString(),
      },
    }));
  };

  const confirm = async (id: string) => {
    if ((await dispatch(deleteDescarte(id))).meta.requestStatus === 'fulfilled') {
      setSelectedDescarte(null);
      dispatchFetchDescartes(1);
    }
  };

  const optionButtonId = (id: string | undefined) => `btn-option-${id}`;

  const renderActionButtons = (descarte: Descarte) => (
    <div>
      <DropwDownButtons
        auditoriaAction={() => history.push(`/auditoria/${AuditoriaType.descarte}/${descarte.id}`)}
        deleteAction={() => setSelectedDescarte(descarte)}
        optionButtonId={optionButtonId(descarte.id)}
      />
    </div>
  );

  useEffect(() => {
    if (!currentSafra || !currentProdutor) {
      setErrorMessage('Selecione safra/produtor.');
      return;
    }
    setErrorMessage('');
    dispatchFetchDescartes(currentPage);
  }, [filterDescartes, currentPage, currentSafra, currentProdutor]);

  if (errorMessage) return <Message severity="error" text={errorMessage} className="width-full p-justify-start" />;
  return (
    <>
      <ConfirmPopup
        target={document.getElementById(optionButtonId(selectedDescarte?.id)) || undefined}
        visible={!!selectedDescarte}
        onHide={() => setSelectedDescarte(undefined)}
        message="Deseja realmente excluir?"
        icon="pi pi-exclamation-triangle"
        accept={() => confirm(selectedDescarte?.id as string)}
      />
      <DataTable
        value={descartes}
        emptyMessage="Nenhum registro encontrado."
        dataKey="id"
        header={<Filters />}
        rowExpansionTemplate={
          (descarte: Descarte) => (
            <div className="p-p-2 p-ml-1">
              <p className="allow-escape-notation">
                <b>Observação:</b> {descarte.observacao || 'N/D'}
              </p>
            </div>
          )
        }
        expandedRows={expandRows}
        onRowToggle={(event) => setExpandRows(event.data)}
        responsiveLayout="scroll"
      >
        <Column expander className="expander-column" />
        {isMobile || (
          <Column
            field="data"
            header="Data"
            body={(descarte: Descarte) => formatWithoutTime(descarte.data)}
            sortable
          />
        )}
        <Column field="campoProducao.identificacaoSigef" header="Campo de Produção" className="col-status" />
        <Column field="cultivar.descricaoCategoria" header="Cultivar" sortable />
        <Column field="subProduto.descricao" header="Subproduto" sortable />
        {isMobile || <Column field="depositoOrigem.descricao" header="Depósito Origem" sortable />}
        {isMobile || <Column field="depositoDestino.descricao" header="Depósito Destino" sortable />}
        <Column body={(descarte: Descarte) => formatNumber(descarte.quantidade, 1) || 'N/D'} header="Quantidade" sortable sortField="quantidade" />
        <Column header="Ações" body={renderActionButtons} className="col-actions" />
      </DataTable>
      <Pagination
        first={firstIndexOnPage}
        pages={pages}
        rows={maxPerPage}
        totalRecords={totalRecords}
        onPageChange={onPageChange}
        pageRecords={descartes.length}
      />
    </>
  );
};

export default DescartesList;
