import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import Header from './components/Header';
import { selectArmazemState } from '../armazemSlice';
import ArmazemService from '../../../../services/sementes/armazem';
import { promiseWithLoaderAndMessages } from '../../../../utilities';
import { Croqui } from '../../../../@types/sementes/croqui';
import EditCroqui from './components/Edit';
import { falhaAoCarregar, falhaAoSalvar } from '../../../../utilities/default-confirmation-messages';

const DEFAULT_SIZE = 20;

const CroquiPage = () => {
  const { currentArmazem } = useSelector(selectArmazemState);
  const dispatch = useDispatch();
  const [croqui, setCroqui] = useState<Croqui>();

  useEffect(() => {
    if (!currentArmazem) return;

    const createDefaultCroqui = async () => {
      const defaultCroqui = {
        quantidadeColunas: DEFAULT_SIZE,
        quantidadeLinhas: DEFAULT_SIZE,
      };

      const croquiCreated = await promiseWithLoaderAndMessages(
        dispatch,
        ArmazemService.createOrUpdateCroqui(currentArmazem.id, defaultCroqui),
        { errorMessage: falhaAoSalvar('croqui inicial') },
      );

      setCroqui(croquiCreated);
    };

    const fetchCroqui = async () => {
      const promiseFetchCroqui = promiseWithLoaderAndMessages(
        dispatch,
        ArmazemService.croqui(currentArmazem.id),
        { errorMessage: falhaAoCarregar('croqui') },
      );
      setCroqui(await promiseFetchCroqui);
    };

    if (!currentArmazem.croquiId) {
      createDefaultCroqui();
      return;
    }

    fetchCroqui();
  }, [currentArmazem]);

  return (
    <>
      <div className="p-grid croqui-page">
        <div className="p-col-12">
          <div className="card">
            <Header />

            {croqui && <EditCroqui croqui={croqui} />}
          </div>
        </div>
      </div>
    </>
  );
};

export default CroquiPage;
