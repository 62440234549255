import React, {
  FC, useEffect, useMemo, useRef, useState,
} from 'react';
import {
  Button, Dialog, InputText, Messages,
} from '@agro1desenvolvimento/react-components';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { ptForm } from 'yup-locale-pt';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { Amostra } from '../../../../@types/sementes/amostra';
import amostrasService from '../../../../services/sementes/amostras';
import { promiseWithLoaderAndMessages, transitionOptionsTimeout as timeout } from '../../../../utilities';
import catchApiErrorsAndSetFormErrors from '../../../../utilities/catch-api-errors';
import ShowErrorHelper from '../../../../components/ShowErrorHelper';
import { toastSaveMessages } from '../../../../utilities/default-confirmation-messages';

const Footer: React.FC<FooterProps> = ({ onClose, valid }) => (
  <div>
    <Button label="Cancelar" icon="pi pi-times" onClick={onClose} className="p-button-text" />
    <Button label="Salvar" disabled={!valid} icon="pi pi-check" autoFocus type="submit" form="form-save-ubs" />
  </div>
);

Yup.setLocale(ptForm);
const AmostraSchema = Yup.object().shape({
  numeroAmostra: Yup.string().required(),
});

const AmostraCreateEditForm: FC<AmostraCreateFormProps> = ({ loteId, amostra, onClose }) => {
  const dispatch = useDispatch();
  const messages = useRef<Messages>(null);
  const [modalVisible, setModalVisible] = useState(true);

  const fechaModal = () => {
    setModalVisible(false);
  };

  const formik = useFormik({
    validationSchema: AmostraSchema,
    initialValues: {
      numeroAmostra: amostra?.numeroAmostra,
    },
    onSubmit: async (values) => {
      try {
        await promiseWithLoaderAndMessages(
          dispatch,
          amostrasService.createOrUpdate(loteId, {
            id: amostra?.id, numeroAmostra: values?.numeroAmostra,
          }),
          toastSaveMessages('amostra'),
        );
        fechaModal();
      } catch (error) {
        catchApiErrorsAndSetFormErrors(formik.setFieldError, error);
      }
    },
  });

  const classes = useMemo(() => (
    classNames({ 'p-invalid': formik.errors.numeroAmostra })
  ), [formik.errors]);

  useEffect(() => {
    formik.setFieldValue('numeroAmostra', amostra?.numeroAmostra);
  }, [amostra]);

  return (
    <Dialog
      onHide={fechaModal}
      transitionOptions={{ timeout, onExited: onClose }}
      visible={modalVisible}
      maximizable
      header={amostra?.id ? 'Alterar Amostra' : 'Adicionar Amostra'}
      className="dialog-md"
      footer={(
        <Footer
          onClose={fechaModal}
          valid={formik.dirty && formik.isValid}
        />
      )}
    >
      <form onSubmit={formik.handleSubmit} id="form-save-ubs">
        <div className="p-fluid">
          <div className="p-field">
            <label htmlFor="numeroAmostra">Número da amostra</label>
            <InputText
              autoFocus
              required
              name="numeroAmostra"
              id="numeroAmostra"
              value={formik.values.numeroAmostra}
              onChange={formik.handleChange}
              className={classes}
              aria-describedby="numero-amostra-help"
            />
            <ShowErrorHelper id="numero-amostra-help" error={formik.errors.numeroAmostra} />
          </div>
        </div>
      </form>
      <Messages ref={messages} />
    </Dialog>
  );
};

export default AmostraCreateEditForm;

interface AmostraCreateFormProps {
  loteId: string;
  amostra: Amostra | undefined;
  onClose: () => void;
}

interface FooterProps {
  valid: boolean;
  onClose: () => void;
}
