import {
  AutoComplete, Button, MultiSelect, Toolbar,
} from '@agro1desenvolvimento/react-components';
import { isEmpty, sortBy } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Cultivar } from '../../../@types/estoque/item';
import { selectCultivarState } from '../../Estoque/Cultivar/cultivaresSlice';
import { fetchEspecies, selectEspecieState } from '../Especies/especiesSlice';
import { selectDashboardsState, setCurrentCultivaresIds, setCurrentEspeciesIds } from './dashboardSlice';
import CultivaresService from '../../../services/estoque/cultivares';
import queryable from '../../../services/queryable';
import dispatchFetchCultivares from '../../../utilities/dispatch-fetch-cultivares';

const ButtonNew = (
  <Link to="/dashboard/novo">
    <Button
      label="Novo"
      aria-label="Novo"
      icon="pi pi-plus"
      className="p-button-success"
    />
  </Link>
);

const Header = () => {
  const dispatch = useDispatch();
  const { currentEspeciesIds, currentCultivaresIds } = useSelector(selectDashboardsState);
  const { especies } = useSelector(selectEspecieState);
  const { cultivares } = useSelector(selectCultivarState);
  const [cultivaresSelected, setCultivaresSelected] = useState<Cultivar[]>([]);

  const fetchCultivaresByParams = async (query?: string) => {
    dispatchFetchCultivares({ dispatch, query });
  };

  const setEspecies = (id: string[]) => {
    dispatch(setCurrentEspeciesIds(id));
  };

  useEffect(() => {
    dispatch(fetchEspecies());
    fetchCultivaresByParams();
  }, []);

  useEffect(() => {
    if (!isEmpty(especies)) {
      dispatch(setCurrentEspeciesIds(
        currentEspeciesIds.filter((id) => especies.find((especie) => especie.id === id)),
      ));
    }
  }, [especies]);

  useEffect(() => {
    const fetchCultivaresSelected = async () => {
      try {
        const resultCultivares = (await queryable.query({
          service: CultivaresService,
          conditions: [{
            field: 'id',
            operator: 'in',
            value: currentCultivaresIds,
          }],
        })).data;
        setCultivaresSelected(resultCultivares);
      } catch (error) {
        console.error(error);
        setCultivaresSelected([]);
      }
    };
    if (currentCultivaresIds.length) fetchCultivaresSelected();
  }, []);

  useEffect(() => {
    dispatch(setCurrentCultivaresIds(cultivaresSelected.map((cultivar) => cultivar.id)));
  }, [cultivaresSelected]);

  const LeftContents = (
    <>
      <MultiSelect
        name="especie"
        id="especie"
        options={sortBy(especies, ['descricao'])}
        onChange={(event) => setEspecies(event.value)}
        optionLabel="descricao"
        optionValue="id"
        placeholder="Todas as Espécies"
        appendTo={document.body}
        className="p-ml-2"
        value={currentEspeciesIds}
        showClear
      />

      <AutoComplete
        dropdown
        value={cultivaresSelected}
        name="cultivar"
        id="cultivar"
        field="descricaoCategoria"
        suggestions={cultivares}
        onChange={(e) => setCultivaresSelected(e.value)}
        placeholder={cultivaresSelected.length ? '' : 'Todas as Cultivares'}
        appendTo={document.body}
        completeMethod={(e) => fetchCultivaresByParams(e.query)}
        className="p-ml-2"
        multiple
      />

    </>
  );

  return (
    <Toolbar className="dashboard-toolbar" left={ButtonNew} right={LeftContents} />
  );
};

export default Header;
