import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import ListPessoas from './components/List';
import Header from './components/Header';
import EditPessoa from './components/Edit';
import { fetchPessoas, selectPessoaState } from './pessoaSlice';
import FormPessoa from './components/Form';
import { usePagination } from '../../../hooks';

const PessoasPage = () => {
  const dispatch = useDispatch();
  const { pagination: { maxPerPage = 1 } } = useSelector(selectPessoaState);
  const { currentPage } = usePagination({ maxPerPage });

  useEffect(() => {
    dispatch(fetchPessoas({
      extraParameters: {
        page: currentPage.toString(),
      },
    }));
  }, [currentPage]);

  return (
    <>
      <div className="p-grid">
        <div className="p-col-12">
          <div className="card">
            <Header />
            <h1>Pessoas</h1>
            <ListPessoas />
          </div>
        </div>
      </div>

      <Switch>
        <Route exact path="/geral/pessoas/editar/:id?">
          <EditPessoa />
        </Route>
        <Route exact path="/geral/pessoas/novo">
          <FormPessoa />
        </Route>
      </Switch>
    </>
  );
};

export default PessoasPage;
