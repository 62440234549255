import { loginService } from '@agro1desenvolvimento/apis-js-package';
import { Safra } from '../../@types/geral/safra';
import api from '../erp-api';

class SafrasService {
  get endpoint() {
    return `/${loginService.scope}/geral/safras`;
  }

  async list() {
    return (await api.get<Safra[]>(this.endpoint)).data;
  }
}

export default new SafrasService();
