import { ThunkDispatch } from '@reduxjs/toolkit';
import { fetchCultivares } from '../features/Estoque/Cultivar/cultivaresSlice';
import { Condition } from '../services/queryable';

const dispatchFetchCultivares = async ({
  dispatch, query = '', extraConditions = [], extraParameters,
} : Params) => {
  dispatch(fetchCultivares({
    conditions: extraConditions.concat(
      {
        field: 'by_descricao',
        operator: 'matches',
        value: `%${query}%`,
      },
    ),
    extraParameters,
  }));
};

type Params = {
  dispatch: ThunkDispatch<any, any, any>,
  query?: string,
  extraParameters?: any,
  extraConditions?: Condition[]
}
export default dispatchFetchCultivares;
