import Filter from '../../../components/Filter';
import { FilterField } from '../../../components/Filter/FilterField';
import UnidadeService from '../../../services/estoque/unidades';
import EspecieService from '../../../services/geral/especies';
import ObtentorService from '../../../services/sementes/obtentores';
import CategoriaService from '../../../services/sementes/categorias';

const filters: FilterField[] = [
  {
    field: 'tipo', type: 'select', label: 'Tipo', items: ['Estoque::Cultivar', 'Estoque::Produto'],
  },
  {
    field: 'especieId',
    type: 'select',
    label: 'Espécie',
    service: EspecieService,
    optionKey: 'id',
    optionLabel: 'descricao',
  },
  {
    field: 'unidadeId',
    type: 'select',
    label: 'Unidade',
    service: UnidadeService,
    optionKey: 'id',
    optionLabel: 'descricao',
  },
  {
    field: 'obtentorId',
    type: 'select',
    label: 'Obtentor',
    service: ObtentorService,
    optionKey: 'id',
    optionLabel: 'descricao',
  },
  {
    field: 'categoriaId',
    type: 'select',
    label: 'Categoria',
    service: CategoriaService,
    optionKey: 'id',
    optionLabel: 'descricao',
  },
];

const Filters = () => (
  <Filter
    context="itensEstoque"
    filters={filters}
  />
);

export default Filters;
