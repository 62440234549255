import { Message } from '@agro1desenvolvimento/react-components';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { selectTopBarState } from '../../../components/Topbar/topBarSlice';
import { fetchUbs } from './ubsSlice';
import NewEditUbsPage from './NewEditPage';
import ListUbs from './List';
import Header from './Header';

const UbsPage = () => {
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState('');

  const { currentProdutor } = useSelector(selectTopBarState);

  useEffect(() => {
    if (!currentProdutor) {
      setErrorMessage('Selecione o produtor.');
      return;
    }

    setErrorMessage('');
    dispatch(fetchUbs());
  }, [currentProdutor]);

  return (
    <>
      <div className="p-grid">
        <div className="p-col-12">
          <div className="card">
            <Header />
            <h1>UBS</h1>
            {
              errorMessage
                ? <Message severity="error" text={errorMessage} className="width-full p-justify-start" />
                : <ListUbs />
            }
          </div>
        </div>
      </div>

      <Switch>
        <Route exact path="/sementes/ubs/:action(novo|editar)/:id?">
          <NewEditUbsPage />
        </Route>
      </Switch>
    </>
  );
};

export default UbsPage;
