import { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Column, ConfirmPopup, DataTable } from '@agro1desenvolvimento/react-components';
import { useHistory } from 'react-router-dom';
import { Analise } from '../../../../../@types/sementes/analises';
import { AnaliseTipos } from '../../../../../utilities/static-enums';
import { deleteAnalise, fetchAnalises, selectTipoAnalisesState } from './analiseSlice';
import DropwDownButton from '../../../../../components/DropwDownButtons';
import { AuditoriaType } from '../../../../../@types/enums';

const AnalisesList: FC<AmostrasListProps> = ({
  loteId, amostraId,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [selectedAnalise, setSelectedAnalise] = useState<Analise>();
  const { analises } = useSelector(selectTipoAnalisesState);

  const onDelete = async () => {
    if (!selectedAnalise) {
      console.error('Não tem uma analise selecionada!');
      return;
    }

    await dispatch(
      deleteAnalise({ loteId, amostraId, analise: selectedAnalise }),
    );
    dispatch(fetchAnalises({ loteId, amostraId }));
  };

  const renderAnaliseTipo = (analise: Analise) => (
    AnaliseTipos.find((tipo) => tipo.key === analise.tipo)?.value
  );

  const optionButtonId = (id: string | undefined) => `btn-option-${id}`;

  const renderActionButtons = (analise: Analise) => (
    <div>
      <DropwDownButton
        auditoriaAction={() => history.push(`/auditoria/${AuditoriaType.analises}/${analise.id}`)}
        deleteAction={() => setSelectedAnalise(analise)}
        editAction={() => history.push({
          pathname: `/sementes/lotes/${loteId}/amostras/${amostraId}/analises/${analise.id}/editar`,
          search: location.search,
        })}
        viewAction={() => history.push({
          pathname: `/sementes/lotes/${loteId}/amostras/${amostraId}/analises/${analise.id}/visualizar`,
          search: location.search,
        })}
        optionButtonId={optionButtonId(analise.id)}
      />
    </div>
  );

  return (
    <>
      <ConfirmPopup
        target={document.getElementById(optionButtonId(selectedAnalise?.id)) || undefined}
        visible={!!selectedAnalise}
        onHide={() => setSelectedAnalise(undefined)}
        message="Deseja realmente excluir?"
        icon="pi pi-exclamation-triangle"
        accept={() => onDelete()}
      />

      <DataTable
        dataKey="id"
        className="amostras"
        value={analises}
        emptyMessage="Nenhum registro encontrado."
        sortField="descricao"
        sortOrder={1}
        removableSort
        responsiveLayout="scroll"
      >
        <Column field="tipo" header="Tipo" body={renderAnaliseTipo} sortable />
        <Column field="descricao" header="Descrição" sortable />
        <Column header="Ações" body={renderActionButtons} className="col-actions" />
      </DataTable>
    </>
  );
};

export default AnalisesList;

interface AmostrasListProps {
  loteId: string;
  amostraId: string;
}
