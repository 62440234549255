import { useIsMobile } from '@agro1desenvolvimento/react-hooks';
import { Reembalagem } from '../../../../@types/sementes/reembalagem';
import ExpandedRowItemTemplate from '../../../../components/ExpandedRowItemTemplate';
import { formatWithoutTime } from '../../../../utilities';
import ListaLotes from './ListaLotes';

const ListHeader = ({ title } : { title: string }) => (
  <h4 className="p-m-0">{title}</h4>
);

const RowExpandedTemplate = ({ reembalagem }: { reembalagem: Reembalagem }) => {
  const isMobile = useIsMobile();

  return (
    <>
      <div className="row-expanded">
        <ExpandedRowItemTemplate hidden={!isMobile} label="Data" value={formatWithoutTime(reembalagem.data)} />
        <ExpandedRowItemTemplate hidden={!isMobile} label="Espécie" value={reembalagem.cultivar.especie.descricao} />
        <ExpandedRowItemTemplate hidden={!isMobile} label="Peneira" value={reembalagem?.peneira?.descricao} />
        <ExpandedRowItemTemplate className="allow-escape-notation" hidden={!reembalagem.observacao} label="Observação" value={reembalagem.observacao} />
      </div>
      <div className="p-col-12">
        <div className="p-pt-3">
          <ListaLotes lotes={reembalagem.lotesOrigem} header={<ListHeader title="Lotes de origem" />} footer />
        </div>
        <div className="p-pt-3">
          <ListaLotes lotes={reembalagem.lotesDestino} header={<ListHeader title="Lotes de destino" />} footer />
        </div>
      </div>
    </>
  );
};

export default RowExpandedTemplate;
