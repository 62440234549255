import { Button, Toolbar } from '@agro1desenvolvimento/react-components';
import { useHistory } from 'react-router-dom';
import { getIncrementedUrlPath } from '../../../utilities';

const Header = () => {
  const history = useHistory();

  return (
    <Toolbar left={(
      <Button
        label="Novo"
        aria-label="Novo"
        icon="pi pi-plus"
        onClick={() => history.push({
          pathname: getIncrementedUrlPath('novo'),
          search: location.search,
        })}
        className="p-button-success"
      />
      )}
    />
  );
};

export default Header;
