import React, { useState } from 'react';
import {
  Button,
  Dialog,
  InputText,
} from '@agro1desenvolvimento/react-components';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-ruby';
import * as Yup from 'yup';
import { ptForm } from 'yup-locale-pt';
import { TemplateScript } from '../../../@types/relatorio/script';
import { createOrUpdateScript, fetchTemplateScripts } from './templateScriptSlice';
import catchApiErrorsAndSetFormErrors from '../../../utilities/catch-api-errors';
import ShowErrorHelper from '../../../components/ShowErrorHelper';
import { transitionOptionsTimeout as timeout } from '../../../utilities';

const Footer: React.FC<{close: () => void, valid: boolean}> = ({ close, valid }) => (
  <div>
    <Button label="Cancelar" icon="pi pi-times" onClick={close} className="p-button-text" />
    <Button label="Salvar" icon="pi pi-check" type="submit" disabled={!valid} form="form-save-script" />
  </div>
);

Yup.setLocale(ptForm);
const TemplateScriptSchema = Yup.object().shape({
  descricao: Yup.string().required(),
  script: Yup.string().required(),
});

const FormScript = ({ script }: {script: TemplateScript}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [modalVisible, setModalVisible] = useState(true);
  const fechaModal = () => setModalVisible(false);
  const close = () => {
    dispatch(fetchTemplateScripts());
    history.push('/geral/relatorio_script');
  };

  const form = useFormik({
    validationSchema: TemplateScriptSchema,
    initialValues: {
      id: script.id || '',
      descricao: script.descricao,
      script: script.script,
    },
    onSubmit: async (values) => {
      const promise = await dispatch(createOrUpdateScript(values));
      const success = promise.meta.requestStatus === 'fulfilled';

      if (success) {
        fechaModal();
      } else {
        catchApiErrorsAndSetFormErrors(form.setFieldError, promise.payload, true);
        close();
      }
    },
  });

  return (
    <Dialog
      onHide={fechaModal}
      transitionOptions={{ timeout, onExited: close }}
      visible={modalVisible}
      maximizable
      header={`Script - ${script.id ? 'Editar' : 'Novo'}`}
      className="dialog-md"
      closeOnEscape={false}
      footer={(
        <Footer
          close={fechaModal}
          valid={form.isValid}
        />
      )}
    >
      <form onSubmit={form.handleSubmit} id="form-save-script">
        <div className="p-fluid">
          <div className="p-field">
            <label htmlFor="descricao">Descricao</label>
            <InputText
              required
              value={form.values.descricao}
              name="descricao"
              id="descricao"
              onChange={form.handleChange}
              aria-describedby="descricao-help"
            />
            <ShowErrorHelper id="descricao-help" error={form.errors.descricao} />
          </div>
        </div>
        <div className="p-fluid">
          <div className="p-field">
            <label htmlFor="script">Script</label>
            <AceEditor
              mode="ruby"
              width="100%"
              name="script"
              theme="monokai"
              minLines={35}
              fontSize={15}
              showGutter
              showPrintMargin
              highlightActiveLine
              onChange={(v) => form.setFieldValue('script', v)}
              value={form.values.script}
              setOptions={{
                enableBasicAutocompletion: true,
                enableLiveAutocompletion: true,
                enableSnippets: true,
                showLineNumbers: true,
              }}
            />
          </div>
        </div>
      </form>
    </Dialog>
  );
};

export default FormScript;
