import {
  FC, useEffect, useLayoutEffect, useMemo, useState,
} from 'react';
import {
  Button, Dialog, Dropdown, InputText, SelectButton,
} from '@agro1desenvolvimento/react-components';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, sumBy } from 'lodash';
import * as Yup from 'yup';
import { ptForm } from 'yup-locale-pt';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { formatNumber, getCroquiItemLabel, promiseWithLoaderAndMessages } from '../../../../utilities';
import { fetchArmazens, selectArmazemState } from '../../Armazem/armazemSlice';
import { Croqui } from '../../../../@types/sementes/croqui';
import armazemService from '../../../../services/sementes/armazem';
import CroquiSketch from '../../../../components/CroquiSketch';
import { OnClickInCroquiItem } from '../../../../components/CroquiSketch/CroquiSketchItem';
import { CroquiItem } from '../../../../@types/sementes/croquiItem';
import { Lote, MovimentoLote } from '../../../../@types/sementes/lote';
import ShowErrorHelper from '../../../../components/ShowErrorHelper';
import { selectNewEditPlanejamentoState, setLotesConfirmados } from '../newEditPlanejamentoSlice';
import { Armazem } from '../../../../@types/sementes/armazem';
import { useYup } from '../../../../hooks';
import { falhaAoCarregar } from '../../../../utilities/default-confirmation-messages';

const Footer: FC<{onClick: () => void}> = ({ onClick }) => (
  <Button
    icon="pi pi-check"
    className="p-button p-m-1"
    label="Confirmar"
    onClick={onClick}
  />
);

const BUTTONS: {value: 'armazenar' | 'limpar', label: string, icon: string}[] = [
  { icon: 'fas fa-plus', value: 'armazenar', label: 'Armazenar' },
  { icon: 'fas fa-eraser', value: 'limpar', label: 'Limpar' },
];

Yup.setLocale(ptForm);

const getQuantidadeValidation = ({ restante }: {restante: number}) => Yup.number()
  .required()
  .positive()
  .max(restante, 'Quantidade maior do que o restante');

const autoSelectArmazem = (armazens: Armazem[]) => armazens.length === 1;

const MovimentacaoLote: FC<PropTypes> = ({
  onHide, lote, onSubmit, itemOrigem, allowPartialMovimentation, totalDisponivel,
}) => {
  const { armazens } = useSelector(selectArmazemState);
  const dispatch = useDispatch();
  const [movimentos, setMovimentos] = useState<Movimento[]>([]);
  const [croqui, setCroqui] = useState<Croqui>();
  const { lotesConfirmados } = useSelector(selectNewEditPlanejamentoState);
  const armazensWithCroqui = useMemo(() => armazens.filter((v) => v.croquiId), [armazens]);
  const visible = !!lote && !!armazensWithCroqui.length;
  const [
    [quantidade, setQuantidade],
    quantidadeError,
    [, setQuantidadeValidation],
  ] = useYup(
    () => getQuantidadeValidation({ restante: 0 }),
    undefined as number | undefined,
  );

  const totalArmazenado = useMemo(
    () => sumBy(movimentos, 'quantidade'),
    [movimentos],
  );

  const validationSchema = useMemo(() => Yup.object().shape({
    restante: allowPartialMovimentation ? Yup.number() : Yup.number().equals([0], 'Lote parcialmente armazenado'),
    armazemId: Yup.string().required(),
  }), [allowPartialMovimentation]);

  const updateLotesConfirmados = () => {
    if (lote) dispatch(setLotesConfirmados([...lotesConfirmados, lote]));
  };

  const form = useFormik<Inputs>({
    validationSchema,
    initialValues: {
      acao: BUTTONS[0].value,
      armazemId: '',
      restante: 0,
    },
    async onSubmit() {
      if (!lote) return;

      updateLotesConfirmados();

      const newLotesMovimentados = movimentos.map((movimento) => ({
        blocoOrigem: itemOrigem,
        blocoDestino: movimento.croquiItem,
        quantidade: movimento.quantidade,
        quantidadeKg: movimento.quantidade * lote.pesoEmbalagem,
        lote,
        data: new Date().toISOString(),
      }));

      onSubmit(newLotesMovimentados);
      onHide();
    },
  });

  const { acao, armazemId, restante } = form.values;

  const armazem = useMemo(
    () => armazensWithCroqui.find(({ id }) => id === armazemId),
    [armazensWithCroqui, armazemId],
  );

  const findArmazenamentoByCroquiItem = (croquiItem: CroquiItem) => {
    if (croquiItem.tipo !== 'bloco') return undefined;
    return movimentos.find((armazenamento) => armazenamento.croquiItem.id === croquiItem.id);
  };

  const onCroquiItemClick: OnClickInCroquiItem = (params) => {
    const item = params?.item;
    if (item?.tipo !== 'bloco') return;

    const removeItemAtual = (itens: Movimento[]) => (
      itens.filter((v) => v.croquiItem.id !== item.id)
    );

    if (acao === 'limpar') setMovimentos(removeItemAtual);
    if (acao === 'armazenar' && !quantidadeError) {
      setMovimentos((itens) => [
        ...removeItemAtual(itens),
        { croquiItem: item, quantidade: quantidade! },
      ]);
    }
  };

  const getCroquiItemClassName = (item: CroquiItem | undefined) => {
    if (!item) return undefined;
    const armazenamento = findArmazenamentoByCroquiItem(item);

    if (armazenamento) return 'contrasted';
  };

  const getCroquiItemTooltip = (item: CroquiItem | undefined) => {
    if (!item) return undefined;
    const armazenamento = findArmazenamentoByCroquiItem(item);

    if (armazenamento) {
      return (
        <>
          {getCroquiItemLabel({ item })}
          <br />
          Armazenado: {armazenamento.quantidade}
        </>
      );
    }
  };

  const onQuantidadeChange = ({ currentTarget }: React.ChangeEvent<HTMLInputElement>) => {
    setQuantidade(currentTarget.value ? +currentTarget.value : undefined);
  };

  useEffect(() => {
    setQuantidadeValidation(getQuantidadeValidation({ restante }));
  }, [restante]);

  useLayoutEffect(() => {
    form.setFieldValue('restante', lote ? totalDisponivel - totalArmazenado : 0);
  }, [totalArmazenado, lote]);

  useEffect(() => {
    const fetchCroqui = async () => {
      if (!armazem?.croquiId) {
        setCroqui(undefined);
        return;
      }

      const fetchedCroqui = await promiseWithLoaderAndMessages(
        dispatch,
        armazemService.croqui(armazem.id),
        { errorMessage: falhaAoCarregar('croqui') },
      );

      setCroqui(fetchedCroqui);
    };

    fetchCroqui();
  }, [armazem]);

  useEffect(() => {
    if (autoSelectArmazem(armazensWithCroqui)) {
      form.setFieldValue('armazemId', armazensWithCroqui[0].id);
    }
  }, [armazensWithCroqui]);

  useEffect(() => {
    const setArmazemDefault = async () => {
      const ultimoArmazemSelecionado = async () => promiseWithLoaderAndMessages(
        dispatch,
        armazemService.ultimoSelecionadoParaMovimentacao(),
      );

      form.setFieldValue('armazemId', form.values.armazemId || (await ultimoArmazemSelecionado()).id);
    };

    if (!lote) {
      form.resetForm({
        values: {
          ...form.initialValues,
          armazemId: autoSelectArmazem(armazensWithCroqui) ? armazemId : '',
        },
      });
      setMovimentos([]);
    }

    if (visible) setArmazemDefault();
  }, [lote]);

  useEffect(() => {
    setQuantidade(restante);
  }, [restante]);

  useEffect(() => {
    if (lote && isEmpty(armazensWithCroqui)) {
      updateLotesConfirmados();
      onHide();
    }
  }, [lote, armazensWithCroqui]);

  useEffect(() => {
    dispatch(fetchArmazens());
  }, []);

  return (
    <Dialog
      className="armazenamento-select"
      header="Armazenamento"
      visible={visible}
      onHide={onHide}
      footer={<Footer onClick={form.submitForm} />}
    >
      <div className="p-fluid p-formgrid p-grid ">
        <div className="p-field p-mb-0 p-col">
          <label aria-labelledby="armazem-select" htmlFor="armazem-select">Armazem</label>
          <Dropdown
            id="armazemId"
            options={armazensWithCroqui}
            className={classNames({ 'p-invalid': form.errors.armazemId })}
            optionLabel="descricao"
            optionValue="id"
            value={armazemId}
            onChange={form.handleChange}
            required
            autoFocus
            placeholder="Selecione o armazém"
            aria-describedby="armazemId-help"
          />
          <ShowErrorHelper id="armazemId-help" error={form.errors.armazemId} />
        </div>
        <div className="p-field p-mb-0 p-col">
          <label aria-labelledby="quantidade" htmlFor="quantidade">Quantidade</label>
          <InputText
            type="number"
            name="quantidade"
            id="quantidade"
            placeholder="Quantidade"
            value={quantidade ?? ''}
            onChange={onQuantidadeChange}
            aria-describedby="quantidade-help"
            className={classNames({ 'p-invalid': quantidadeError })}
          />
          <ShowErrorHelper id="quantidade-help" error={quantidadeError} />
        </div>
        <div className="p-field p-mb-0 p-col-fixed p-ml-auto p-mt-3">
          <SelectButton
            value={acao}
            onChange={({ value }) => { form.setFieldValue('acao', value); }}
            options={BUTTONS}
            itemTemplate={(button) => (
              <div className="p-ai-center">
                <i className={button.icon} />
                <br />
                <span>{button.label}</span>
              </div>
            )}
          />
        </div>
      </div>
      <div className="p-my-1">
        <div className="armazenamento-info">
          <span className={classNames('p-px-1', { 'p-text-bold': form.submitCount && form.errors.restante })}>
            Restante: {formatNumber(restante)}
          </span>
          <span className="p-px-1">Armazenado: {formatNumber(totalArmazenado)}</span>
          <span className="p-px-1">Total: {formatNumber(totalDisponivel)}</span>
        </div>
        <ShowErrorHelper id="restante-help" error={form.submitCount ? form.errors.restante : undefined} />
      </div>

      {croqui && (
        <CroquiSketch
          croqui={croqui}
          onItemClick={onCroquiItemClick}
          itemClassName={getCroquiItemClassName}
          itemTooltip={getCroquiItemTooltip}
        />
      )}
    </Dialog>
  );
};

interface Inputs {
  armazemId: string,
  acao: (typeof BUTTONS)[number]['value']
  restante: number;
}

interface Movimento {
  croquiItem: CroquiItem,
  quantidade: number
}

interface PropTypes {
  onHide: () => void,
  lote: Lote | undefined,
  onSubmit: (movimentos: MovimentoLote[]) => void,
  itemOrigem?: CroquiItem,
  allowPartialMovimentation?: boolean
  totalDisponivel: number,
}

export default MovimentacaoLote;
