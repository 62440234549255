import { Message } from '@agro1desenvolvimento/react-components';
import { useIsFirstRender } from '@agro1desenvolvimento/react-hooks';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { Route, useHistory } from 'react-router-dom';
import { selectTopBarState } from '../../../components/Topbar/topBarSlice';
import PlanejamentosList from './components/PlanejamentosList';
import NewEditPlanejamentoPage from './NewEditPlanejamentoPage';
import { fetchPlanejamentos, selectPlanejamentoState } from './planejamentosSlice';
import Header from './components/Header';
import { selectFilterState } from '../../../components/Filter/filterSlice';
import { usePagination } from '../../../hooks';
import { scrollToTop } from '../../../utilities';

const PlanejamentosPage = () => {
  const dispatch = useDispatch();
  const isFirstRender = useIsFirstRender();
  const [errorMessage, setErrorMessage] = useState('');
  const { currentProdutor, currentSafra } = useSelector(selectTopBarState);
  const history = useHistory();
  const { pagination: { maxPerPage = 1 } } = useSelector(selectPlanejamentoState);
  const { planejamentos: filterPlanejamentos } = useSelector(selectFilterState);
  const { currentPage, goToPage } = usePagination({ maxPerPage });

  const dispatchFetchPlanejamentos = (page = currentPage.toString()) => {
    if (!currentProdutor || !currentSafra) {
      setErrorMessage('Selecione safra/produtor.');
      return;
    }
    setErrorMessage('');

    scrollToTop();
    dispatch(fetchPlanejamentos({
      page,
      filter: filterPlanejamentos,
      produtor: currentProdutor,
      safra: currentSafra,
    }));
  };

  const closeCreateUpdatePage = () => {
    dispatchFetchPlanejamentos();
    history.push({
      pathname: '/sementes/planejamentos',
      search: history.location.search,
    });
  };

  useEffect(() => {
    dispatchFetchPlanejamentos();
  }, [currentSafra, currentProdutor, currentPage]);

  useEffect(() => {
    if (isFirstRender) return;
    goToPage(1);
    dispatchFetchPlanejamentos('1');
  }, [filterPlanejamentos]);

  return (
    <>
      <div className="p-grid">
        <div className="p-col-12">
          <div className="card">
            <Header />
            <h1>Planejamentos</h1>
            {errorMessage
              ? <Message severity="error" text={errorMessage} className="width-full p-justify-start" />
              : <PlanejamentosList onDestroy={dispatchFetchPlanejamentos} />}
          </div>
        </div>
      </div>
      <Route path="/sementes/planejamentos/:id?/:action(editar|duplicar|novo|lotes)">
        <NewEditPlanejamentoPage
          fetchPlanejamentos={dispatchFetchPlanejamentos}
          closeModal={closeCreateUpdatePage}
        />
      </Route>
    </>
  );
};

export default PlanejamentosPage;
