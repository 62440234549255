import { Button, Toolbar } from '@agro1desenvolvimento/react-components';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { clearAuditorias } from '../../features/geral/Auditorias/auditoriasSlice';

const Header = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const goBack = () => {
    dispatch(clearAuditorias());
    history.goBack();
  };

  const leftContents = (
    <Button
      label="Voltar"
      icon="pi pi-arrow-left"
      className="p-mr-auto p-button-primary"
      onClick={goBack}
    />
  );

  return (
    <Toolbar left={leftContents} />
  );
};

export default Header;
