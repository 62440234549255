import { useEffect, useRef, useState } from 'react';
import { Button, Steps } from '@agro1desenvolvimento/react-components';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { noop } from 'lodash';
import Filtros from './components/Filtros';
import Simulacao from './components/Simulacao';
import Resumo from './components/Resumo';
import { SteepComponentRef, StepModel } from './types';
import { selectVinculoLotesCamposProducaoState, goToStep, clearSlice } from './VinculoLotesCamposProducaoSlice';

const steps: StepModel[] = [
  { label: 'Filtros', component: Filtros },
  { label: 'Resumo', component: Resumo },
  { label: 'Simulação', component: Simulacao },
];

const VinculoPage = () => {
  const { currentStepIndex } = useSelector(selectVinculoLotesCamposProducaoState);
  const dispatch = useDispatch();
  const currentStepPageRef = useRef<SteepComponentRef | null>(null);
  const inFirstStep = currentStepIndex === 0;
  const inLastStep = steps.length - 1 === currentStepIndex;
  const rightIcon = inLastStep ? 'pi-check' : 'pi-angle-right';
  const [isValidSteeps, setIsValidSteeps] = useState(() => steps.map(() => false));

  const canGoNext = isValidSteeps[currentStepIndex];

  const setCanGoNext = (value: boolean) => {
    setIsValidSteeps((prev) => {
      prev[currentStepIndex] = value;
      return [...prev];
    });
  };

  useEffect(() => {
    const cleanup = () => {
      dispatch(clearSlice());
    };

    return cleanup;
  }, []);

  return (
    <>
      <div className="custom-steps-parent">
        <div className="card">
          <h1>Vínculo de lotes com campos de produção</h1>

          <Steps model={steps} activeIndex={currentStepIndex} />

          <div className="step-actions">
            <Button
              onClick={() => dispatch(goToStep('previos'))}
              icon="pi pi-angle-left"
              className="p-button-outlined"
              iconPos="left"
              disabled={inFirstStep}
              label="Voltar"
            />
            <Button
              onClick={() => {
                currentStepPageRef.current?.submit();
              }}
              icon={`pi ${rightIcon}`}
              className={classNames({ 'p-button-outlined': !inLastStep })}
              iconPos="right"
              disabled={!canGoNext}
              label={inLastStep ? 'Salvar' : 'Avançar'}
            />
          </div>

          {steps.map(({ component: Component, label }, index) => {
            const visible = index === currentStepIndex;
            return (
              <div key={label} hidden={!visible}>
                <Component
                  setCanGoNext={visible ? setCanGoNext : noop}
                  visible={visible}
                  ref={(ref) => {
                    if (visible) currentStepPageRef.current = ref;
                  }}
                />
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default VinculoPage;
