import React, {
  FC,
  useRef,
  useMemo,
  useState,
} from 'react';
import {
  Dialog,
  InputText,
  Button,
  Messages,
  RadioButton,
} from '@agro1desenvolvimento/react-components';
import classNames from 'classnames';
import * as Yup from 'yup';
import { ptForm } from 'yup-locale-pt';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Deposito } from '../../../@types/estoque/deposito';
import { createOrUpdateDeposito, fetchDepositos } from './depositosSlice';
import catchApiErrorsAndSetFormErrors from '../../../utilities/catch-api-errors';
import ShowErrorHelper from '../../../components/ShowErrorHelper';
import { transitionOptionsTimeout as timeout } from '../../../utilities';

const Footer: React.FC<{close: () => void, valid: boolean}> = ({ close, valid }) => (
  <div>
    <Button label="Cancelar" icon="pi pi-times" onClick={close} className="p-button-text" />
    <Button label="Salvar" disabled={!valid} icon="pi pi-check" autoFocus type="submit" form="form-save-deposito" />
  </div>
);

Yup.setLocale(ptForm);
const DepositoSchema = Yup.object().shape({
  descricao: Yup.string().required(),
  tipo: Yup.string().required(),
});

interface FormularioProps {
  deposito?: Deposito
}

const FormDeposito: FC<FormularioProps> = ({ deposito }) => {
  const messages = useRef<Messages>(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const [modalVisible, setModalVisible] = useState(true);
  const tiposDepositos = [{ key: 'proprio', name: 'Próprio' }, { key: 'terceiro', name: 'Terceiro' }];

  const close = () => {
    history.push('/estoque/depositos');
  };

  const fechaModal = () => {
    setModalVisible(false);
  };

  const form = useFormik({
    validationSchema: DepositoSchema,
    initialValues: {
      id: deposito?.id,
      descricao: deposito?.descricao,
      tipo: deposito?.tipo,
    },

    onSubmit: async (values) => {
      const promise = await dispatch(createOrUpdateDeposito(values));
      const success = promise.meta.requestStatus === 'fulfilled';
      if (success) {
        fechaModal();
        dispatch(fetchDepositos());
      } else {
        catchApiErrorsAndSetFormErrors(form.setFieldError, promise.payload, true);
      }
    },
  });

  const classes = useMemo(() => (
    classNames({ 'p-invalid': form.errors.descricao })
  ), [form.errors]);

  return (
    <Dialog
      onHide={fechaModal}
      transitionOptions={{ timeout, onExited: close }}
      visible={modalVisible}
      maximizable
      header={deposito?.id ? 'Alterar depósito' : 'Adicionar depósito'}
      className="dialog-md"
      footer={<Footer close={fechaModal} valid={form.dirty && form.isValid} />}
    >
      <form onSubmit={form.handleSubmit} id="form-save-deposito">
        <div className="p-fluid">
          <div className="p-field">
            <label htmlFor="descricao">Descrição</label>
            <InputText
              autoFocus
              required
              name="descricao"
              id="descricao"
              value={form.values.descricao}
              onChange={form.handleChange}
              className={classes}
              aria-describedby="descricao-help"
            />
            <ShowErrorHelper id="descricao-help" error={form.errors.descricao} />
          </div>
          <div className="p-field">
            {
              tiposDepositos.map((tipo) => (
                <div key={tipo.key} className="p-field-radiobutton">
                  <RadioButton
                    inputId={tipo.key}
                    name="tipo"
                    value={tipo.key}
                    key={tipo.key}
                    onChange={form.handleChange}
                    checked={form.values.tipo === tipo.key}
                  />
                  <label htmlFor={tipo.key}>{tipo.name}</label>
                </div>
              ))
            }
            <ShowErrorHelper id="tipo-help" error={form.errors.tipo} />
          </div>
        </div>
      </form>
      <Messages ref={messages} />
    </Dialog>
  );
};

export default FormDeposito;
