import { Button, Toolbar } from '@agro1desenvolvimento/react-components';
import { useHistory } from 'react-router-dom';
import { getIncrementedUrlPath } from '../../../utilities';

const Header = () => {
  const history = useHistory();

  const leftContents = (
    <Button
      label="Novo"
      aria-label="Novo"
      icon="pi pi-plus"
      onClick={() => history.push({
        pathname: getIncrementedUrlPath('novo'),
        search: location.search,
      })}
      className="p-button-success"
    />
  );

  return (
    <Toolbar left={leftContents} />
  );
};

export default Header;
