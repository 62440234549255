import { Croqui, MatrizCroqui } from '../../@types/sementes/croqui';

class CroquiService {
  getMatriz(croqui: Croqui) {
    const matriz: MatrizCroqui = Array(croqui.quantidadeLinhas)
      .fill(undefined)
      .map(() => Array(croqui.quantidadeColunas).fill(undefined));

    croqui.itens?.forEach((item) => {
      matriz[item.posicao.x][item.posicao.y] = item;
    });

    return matriz;
  }
}

export default new CroquiService();
