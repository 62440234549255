import { sortBy } from 'lodash';
import Filter from '../../../../../components/Filter';
import { FilterField } from '../../../../../components/Filter/FilterField';
import cultivares from '../../../../../services/estoque/cultivares';
import unidades from '../../../../../services/estoque/unidades';
import especies from '../../../../../services/geral/especies';
import categorias from '../../../../../services/sementes/categorias';
import peneiras from '../../../../../services/sementes/peneiras';
import { lotesStatuses } from '../../../../../utilities';

const filters: FilterField[] = sortBy([
  {
    field: 'loteStatus', type: 'select', label: 'Status', items: lotesStatuses,
  },
  {
    field: 'loteCultivarId',
    type: 'autocomplete',
    label: 'Cultivar',
    service: cultivares,
    optionKey: 'id',
    optionLabel: 'descricaoCategoria',
    optionQuery: 'descricao',
  },
  {
    field: 'loteCategoriaId',
    type: 'select',
    label: 'Categoria',
    service: categorias,
    optionKey: 'id',
    optionLabel: 'descricao',
  },
  {
    field: 'lotePeneiraId',
    type: 'select',
    label: 'Peneira',
    service: peneiras,
    optionKey: 'id',
    optionLabel: 'descricao',
  },
  {
    field: 'loteEspecieId',
    type: 'select',
    label: 'Espécie',
    service: especies,
    optionKey: 'id',
    optionLabel: 'descricao',
  },
  {
    field: 'loteUnidadeId',
    type: 'select',
    label: 'Unidade',
    service: unidades,
    optionKey: 'id',
    optionLabel: 'descricao',
  },
], 'label');

const Filters = () => (
  <Filter
    context="localizacao"
    filters={filters}
  />
);

export default Filters;
