import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { isMobile } from '@agro1desenvolvimento/utils-js';
import { deactivate, toggleActive } from '../Sidebar/sidebarSlice';
import { fetchSafras, fetchProdutores } from './topBarSlice';
import RightContents from './RightContents';

const AppTopbar = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const mobileAutoCloseMenuOnNavigation = () => {
    if (!isMobile()) return;
    dispatch(deactivate());
  };

  useEffect(() => history?.listen(() => {
    mobileAutoCloseMenuOnNavigation();
  }), [history]);

  useEffect(() => {
    dispatch(fetchSafras());
    dispatch(fetchProdutores());
  }, []);

  const clickToggleMenu = () => {
    dispatch(toggleActive());
  };

  return (
    <div id="layout-topbar" className="clearfix">

      <button type="button" className="p-link layout-menu-button" onClick={clickToggleMenu}>
        <span className="pi pi-bars" />
      </button>

      <RightContents />
    </div>
  );
};

export default AppTopbar;
