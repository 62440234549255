import React, {
  FC,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Dialog,
  InputText,
  Button,
  Messages,
} from '@agro1desenvolvimento/react-components';
import classNames from 'classnames';
import * as Yup from 'yup';
import { ptForm } from 'yup-locale-pt';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ConfiguracaoFormField } from '../../../@types/sementes/config';
import catchApiErrorsAndSetFormErrors from '../../../utilities/catch-api-errors';
import ShowErrorHelper from '../../../components/ShowErrorHelper';
import { transitionOptionsTimeout as timeout } from '../../../utilities';
import { fetchSementesConfig, selectConfigSementesState, updateConfiguracoes } from './configSlice';
import { useScreenSize } from '../../../hooks';

const Footer: React.FC<{close: () => void, valid: boolean}> = ({ close, valid }) => (
  <div>
    <Button label="Cancelar" icon="pi pi-times" onClick={close} className="p-button-text" />
    <Button label="Salvar" disabled={!valid} icon="pi pi-check" autoFocus type="submit" form="form-save-configuracoes" />
  </div>
);

Yup.setLocale(ptForm);
const ConfiguracaoSchema = Yup.object().shape({
  sigefUsername: Yup.string().required(),
  sigefPassword: Yup.string().required(),
  sigefMatricula: Yup.string().required(),
});

const ConfiguracaoPage: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const onClose = history.goBack;
  const messages = useRef<Messages>(null);
  const fechaModal = () => { setModalVisible(false); };
  const [modalVisible, setModalVisible] = useState(true);
  const sementesConfig = useSelector(selectConfigSementesState);
  const { isXL, isXXL, isXS } = useScreenSize();

  const gridClasses = useMemo(() => {
    if (isXXL || isXL) return 'p-col-6';

    return 'p-col-12';
  }, [isXL, isXXL, isXS]);

  const form = useFormik<ConfiguracaoFormField>({
    validationSchema: ConfiguracaoSchema,
    initialValues: {
      sigefPassword: '',
      sigefUsername: sementesConfig?.sigefUsername || '',
      sigefMatricula: sementesConfig?.sigefMatricula || '',
      rastreabilidadeUrl: sementesConfig?.rastreabilidadeUrl || '',
      margemSegurancaPesoSementes: sementesConfig?.margemSegurancaPesoSementes || 0,
    },
    onSubmit: async (values) => {
      const toSubmit: ConfiguracaoFormField = {
        sigefUsername: values.sigefUsername,
        sigefPassword: values.sigefPassword,
        sigefMatricula: values.sigefMatricula,
        rastreabilidadeUrl: values.rastreabilidadeUrl,
        margemSegurancaPesoSementes: values.margemSegurancaPesoSementes,
      };

      const promise = await dispatch(updateConfiguracoes(toSubmit));
      const success = promise.meta.requestStatus === 'fulfilled';
      if (success) {
        fechaModal();
        dispatch(fetchSementesConfig());
      } else {
        catchApiErrorsAndSetFormErrors(form.setFieldError, promise.payload, true);
      }
    },
  });

  const fields = [
    { key: 'sigefUsername', description: 'Usuário SIGEF', type: 'string' },
    { key: 'sigefMatricula', description: 'Matricula SIGEF', type: 'string' },
    { key: 'sigefPassword', description: 'Senha SIGEF', type: 'password' },
    { key: 'rastreabilidadeUrl', description: 'URL Rastreabilidade', type: 'url' },
    {
      type: 'number',
      key: 'margemSegurancaPesoSementes',
      description: 'Margem segurança peso lotes (%)',
      tooltip: 'Margem de segurança para o cálculo de peso da embalagem dos lotes',
    },
  ];

  return (
    <Dialog
      onHide={fechaModal}
      transitionOptions={{ timeout, onExited: onClose }}
      visible={modalVisible}
      maximizable
      header="Configurações"
      className="dialog-md"
      footer={<Footer close={fechaModal} valid={form.dirty && form.isValid} />}
    >
      <form onSubmit={form.handleSubmit} id="form-save-configuracoes">
        <div className="p-fluid p-formgrid  p-grid">
          { fields.map((f) => (
            <div className={`p-field ${gridClasses}`} key={f.key}>
              <label htmlFor={f.key}>{f.description}</label>
              <InputText
                id={f.key}
                name={f.key}
                type={f.type}
                tooltip={f.tooltip}
                onChange={form.handleChange}
                aria-describedby={`${f.key}-help`}
                value={(form.values as any)[f.key]}
                placeholder={f.key === 'sigefPassword' ? '*******' : undefined}
                className={classNames({ 'p-invalid': (form.errors as any)[f.key] })}
              />
              <ShowErrorHelper id={`${f.key}-help`} error={(form.errors as any)[f.key]} />
            </div>
          )) }
        </div>
      </form>
      <Messages ref={messages} />
    </Dialog>
  );
};

export default ConfiguracaoPage;
