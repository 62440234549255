import { loginService } from '@agro1desenvolvimento/apis-js-package';
import { HeaderParams } from '../../@types/headers';
import { Peneira } from '../../@types/sementes/peneira';
import { CrudCreateUpdate } from '../../utilities';
import CrudBase from '../crud-base';
import api from '../erp-api';

export interface PeneiraListReturnType extends HeaderParams {
  data: Peneira[];
}

class PeneirasService extends CrudBase<Peneira> {
  get endpoint() {
    return `/${loginService.scope}/sementes/peneiras`;
  }

  async create(peneira: CrudCreateUpdate<Peneira>) {
    return (await api.post<Peneira[]>(
      this.endpoint,
      { peneiras: [{ descricao: peneira.descricao, especie_id: peneira.especie.id }] },
    )).data[0];
  }

  async update(peneira: CrudCreateUpdate<Peneira>) {
    return (await api.patch<Peneira>(
      `${this.endpoint}/${peneira.id}`,
      { descricao: peneira.descricao, especie_id: peneira.especie.id },
    )).data;
  }
}

export default new PeneirasService();
