import {
  Dialog,
  Button,
  Calendar,
  CalendarProps,
} from '@agro1desenvolvimento/react-components';
import dayjs from 'dayjs';
import { isDate } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useMemo, useState } from 'react';
import { selectConfirmacaoReembalagemState, setDataConfirmacao } from '../confirmacaoReembalagemSlice';
import { dateAmericanFormat } from '../../../../../utilities';

const Footer: React.FC<{ onSubmit: () => void, closeModal: () => void }> = ({
  onSubmit, closeModal,
}) => {
  const {
    lotesConfirmados,
    dataConfirmacao,
    reembalagem,
  } = useSelector(selectConfirmacaoReembalagemState);
  const [inputDateVisible, setInputDateVisible] = useState(false);
  const dispatch = useDispatch();

  const dataConfirmacaoDate = useMemo(() => dayjs(dataConfirmacao).toDate(), [dataConfirmacao]);

  const onChangeDate = (calendarDate: CalendarProps) => {
    const data = isDate(calendarDate.value) ? calendarDate.value : new Date();
    dispatch(setDataConfirmacao(dateAmericanFormat(data)));
  };

  const submit = () => {
    setInputDateVisible(false);
    onSubmit();
  };

  return (
    <div className="p-buttonset p-col">
      <Dialog
        header="Data de confirmação dos lotes"
        visible={inputDateVisible}
        onHide={() => setInputDateVisible(false)}
      >
        <Calendar
          id="data"
          showIcon
          value={dataConfirmacaoDate}
          onChange={onChangeDate}
          appendTo={document.body}
          placeholder="Data"
        />
        <Button
          aria-label="submitLotes"
          icon="pi pi-check"
          className="p-button p-m-1"
          onClick={submit}
        />
      </Dialog>
      <Button label="Cancelar" icon="pi pi-times" onClick={closeModal} className="p-button p-button-text p-m-0" />
      <Button
        label="Salvar"
        aria-label="salvar"
        icon="pi pi-check"
        autoFocus
        disabled={!lotesConfirmados.length || reembalagem?.status === 'finalizado'}
        className="p-button"
        onClick={() => setInputDateVisible(true)}
      />
    </div>
  );
};

export default Footer;
