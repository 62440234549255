import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';

const usePagination = ({ maxPerPage }: { maxPerPage: number }) => {
  const history = useHistory();
  const getSearchParams = () => new URLSearchParams(history.location.search);
  const currentPage = useMemo(
    () => Number(getSearchParams().get('page')) || 1,
    [history.location.search],
  );
  const firstIndexOnPage = useMemo(
    () => (currentPage - 1) * maxPerPage,
    [currentPage, maxPerPage],
  );

  const goToPage = (page: number) => {
    const searchParams = getSearchParams();
    searchParams.set('page', page.toString());

    history.push({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  };

  return { currentPage, goToPage, firstIndexOnPage };
};

export default usePagination;
