import { loginService } from '@agro1desenvolvimento/apis-js-package';
import { keysToSnakeCase } from '@agro1desenvolvimento/utils-js';
import { pick } from 'lodash';
import { Armazem, ArmazemSaldo } from '../../@types/sementes/armazem';
import { Croqui } from '../../@types/sementes/croqui';
import { CroquiItem } from '../../@types/sementes/croquiItem';
import { CrudCreateUpdate } from '../../utilities';
import CrudBase from '../crud-base';
import api from '../erp-api';

class ArmazemService extends CrudBase<Armazem> {
  get endpoint() {
    return `/${loginService.scope}/sementes/armazens`;
  }

  async create(armazem: CrudCreateUpdate<Armazem>) {
    return (await api.post<Armazem[]>(
      this.endpoint,
      { armazens: [{ descricao: armazem.descricao }] },
    )).data[0];
  }

  async update(armazem: CrudCreateUpdate<Armazem>) {
    return (await api.patch<Armazem>(
      `${this.endpoint}/${armazem.id}`,
      { descricao: armazem.descricao },
    )).data;
  }

  async croqui(armazemId: string) {
    return (await api.get<Croqui>(`${this.endpoint}/${armazemId}/croqui`)).data;
  }

  async createOrUpdateCroqui(armazemId: string, croqui: CrudCreateUpdate<Croqui>) {
    const payload = pick(croqui, ['quantidadeLinhas', 'quantidadeColunas']);
    return (await api.post<Croqui>(
      `${this.endpoint}/${armazemId}/croqui`,
      keysToSnakeCase(payload),
    )).data;
  }

  async createOrUpdateCroquiItem(armazemId: string, item: CrudCreateUpdate<CroquiItem>) {
    const payload = {
      id: item.id,
      tipo: item.tipo,
      posicao: [
        item.posicao.x,
        item.posicao.y,
      ],
      descricao: item.descricao,
    };
    return (await api.post<CroquiItem[]>(
      `${this.endpoint}/${armazemId}/croqui/itens`,
      keysToSnakeCase({ itensCroqui: [payload] }),
    )).data[0];
  }

  async deleteCroquiItem(armazemId: string, itemId: string) {
    return (await api.delete(
      `${this.endpoint}/${armazemId}/croqui/itens/${itemId}`,
    )).data;
  }

  async saldos(armazemId: string) {
    return (await api.get<ArmazemSaldo[]>(
      `${this.endpoint}/${armazemId}/saldos`,
    )).data;
  }

  async ultimoSelecionadoParaMovimentacao() {
    return (await api.get<Armazem>(
      `${this.endpoint}/ultimo_selecionado_movimentacao`,
    )).data;
  }
}

export default new ArmazemService();
