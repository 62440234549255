import { Redirect, Route, RouteProps } from 'react-router-dom';
import { uniqueId } from 'lodash';
import { useSelector } from 'react-redux';
import CamposProducaoPage from './features/sementes/CamposProducao';
import UbsPage from './features/sementes/Ubs/UbsPage';
import PeneiraPage from './features/sementes/Peneira/PeneiraPage';
import LotesPage from './features/sementes/Lote/LotesPage';
import SubprodutoPage from './features/sementes/Subproduto';
import PlanejamentosPage from './features/sementes/Planejamento/PlanejamentosPage';
import AmostrasPage from './features/sementes/Lote/Amostras/AmostrasPage';
import RegistrosExcluidosPage from './features/geral/RegistrosExcluidos/RegistrosExcluidosPage';
import AnalisesPage from './features/sementes/Lote/Amostras/Analises/AnalisesPage';
import AuditoriaComponent from './components/Auditoria';
import RelatorioTemplatePage from './features/Relatorio/RelatorioTemplate/RelatorioTemplatePage';
import TemplateScriptPage from './features/Relatorio/TemplateScript/TemplateScriptPage';
import DashboardPage from './features/geral/Dashboard/DashboardPage';
import CreateEditDashboardPage from './features/geral/Dashboard/CreateEditDashboardPage';
import { selectTopBarState } from './components/Topbar/topBarSlice';
import DepositosPage from './features/Estoque/Depositos/DepositosPage';
import ReembalagensPage from './features/sementes/Reembalagem/ReembalagensPage';
import ArmazemPage from './features/sementes/Armazem';
import LocalizacaoLotesPage from './features/sementes/Localizacao';
import VinculoPage from './features/sementes/VinculoLotesCamposProducao';
import ItensEstoquePage from './features/Estoque/Item/ItensEstoquePage';
import NewReembalagemPage from './features/sementes/Reembalagem/Novo';
import EquipamentoPage from './features/laboratorio/Equipamento';
import PessoasPage from './features/geral/Pessoas';
import ConfiguracaoPage from './features/sementes/Config';

const pageRoutes: RouteProps[] = [
  { path: ['/sementes/lotes/', '/sementes/lotes/:loteId/editar'], component: LotesPage, exact: true },
  { path: ['/sementes/campos_producao/', '/sementes/campos_producao/editar/:id'], component: CamposProducaoPage },
  { path: '/sementes/ubs', component: UbsPage },
  { path: '/sementes/armazens', component: ArmazemPage },
  { path: '/sementes/configs', component: ConfiguracaoPage },
  { path: '/sementes/peneiras', component: PeneiraPage },
  { path: '/sementes/planejamentos/', component: PlanejamentosPage },
  { path: '/sementes/subproduto/', component: SubprodutoPage },
  { path: ['/sementes/reembalagens/', '/sementes/reembalagens/:id/confirmar'], component: ReembalagensPage, exact: true },
  { path: '/sementes/reembalagens/novo', component: NewReembalagemPage, exact: true },
  { path: '/sementes/vinculo-lotes-campos/', component: VinculoPage },
  { path: '/estoque/depositos/', component: DepositosPage },
  { path: '/estoque/itens/', component: ItensEstoquePage },
  { path: '/geral/auditorias/', component: RegistrosExcluidosPage },
  { path: '/auditoria/:type/:id', component: AuditoriaComponent },
  {
    path: [
      '/sementes/lotes/:loteId/amostras',
      '/sementes/lotes/:loteId/amostras/:action(novo)',
      '/sementes/lotes/:loteId/amostras/:id/:action(editar)',
    ],
    component: AmostrasPage,
    exact: true,
  },
  {
    path: [
      '/sementes/lotes/:loteId/amostras/:amostraId/analises',
      '/sementes/lotes/:loteId/amostras/:amostraId/analises/:action(novo)',
      '/sementes/lotes/:loteId/amostras/:amostraId/analises/:id/:action(visualizar|editar)',
    ],
    component: AnalisesPage,
    exact: true,
  },
  { path: '/sementes/equipamentos', component: EquipamentoPage },
  { path: '/geral/pessoas', component: PessoasPage },
  { path: '/geral/relatorio_template', component: RelatorioTemplatePage },
  { path: '/geral/relatorio_script', component: TemplateScriptPage },
  { path: '/dashboard', component: DashboardPage, exact: true },
  { path: ['/dashboard/novo', '/dashboard/editar/:id'], component: CreateEditDashboardPage },
  { path: '/sementes/lotes/localizacao/:armazemId?/:numeroLote?', component: LocalizacaoLotesPage, exact: true },
];

const routesObject = [...pageRoutes].map((route) => ({
  ...route,
  key: uniqueId(),
}));

const Routes = () => {
  const { loading } = useSelector(selectTopBarState);

  if (loading.length) return null;

  return (
    <>
      {/* eslint-disable-next-line react/jsx-key */}
      {routesObject.map((route) => <Route {...route} />)}
      <Route exact path="/">
        <Redirect to="/dashboard" />
      </Route>
    </>
  );
};

export default Routes;
