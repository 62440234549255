import { FC, useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { Dropdown, InputNumber } from '@agro1desenvolvimento/react-components';
import { FilterFieldProps } from './FilterFieldProps';
import { ConditionOperator } from '../../services/queryable';

const OPERATORS = [
  { id: 'eq', label: '=' },
  { id: 'gt', label: '>' },
  { id: 'lt', label: '<' },
];

const FilterInputNumber: FC<FilterFieldProps> = ({
  filterField, filterConditions, setFilterConditions,
}) => {
  const [operator, setOperator] = useState<ConditionOperator>(
    filterConditions[filterField.field]?.[0]?.operator || 'eq',
  );
  const [value, setValue] = useState<string>(filterConditions[filterField.field]?.[0]?.value || '');

  useEffect(() => {
    if (isEmpty(value)) {
      setFilterConditions(filterField.field, []);
    } else {
      setFilterConditions(filterField.field, [{
        field: filterField.field,
        value,
        operator,
      }]);
    }
  }, [value, operator]);

  return (
    <div className="p-inputgroup">
      <span className="p-float-label">
        <InputNumber
          id={`filter-${filterField.field}`}
          type="text"
          value={+value}
          onValueChange={(e) => setValue(e.value?.toString() || '')}
          placeholder={filterField.label}
        />
        <label htmlFor={`filter-${filterField.field}`}>{filterField.label}</label>
      </span>
      <Dropdown
        options={OPERATORS}
        optionLabel="label"
        optionValue="id"
        className="select-operator"
        onChange={(e) => setOperator(e.value)}
        value={operator}
      />
    </div>
  );
};

export default FilterInputNumber;
