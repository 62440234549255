import { Button, Toolbar } from '@agro1desenvolvimento/react-components';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { map } from 'lodash';
import { useEffect, useState } from 'react';
import { selectPlanejamentoState } from '../planejamentosSlice';
import { promiseWithLoaderAndMessages, downloader, getIncrementedUrlPath } from '../../../../utilities';
import PlanejamentosService from '../../../../services/sementes/planejamentos';
import SelectTemplateToPrint from '../../../../components/SelectTemplateToPrint';
import { fetchTemplatesByTipo, selectTemplateState } from '../../../Relatorio/RelatorioTemplate/relatorioTemplateslice';

const Header = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { planejamentosSelecionados } = useSelector(selectPlanejamentoState);
  const [downloadTemplate, setDownloadTemplate] = useState(false);
  const { templates } = useSelector(selectTemplateState);

  const print = async (templateId: string) => {
    setDownloadTemplate(false);
    const result = await promiseWithLoaderAndMessages(
      dispatch,
      PlanejamentosService.report(map(planejamentosSelecionados, 'id'), templateId),
      {
        errorMessage: {
          summary: 'Erro',
          detail: 'Falha ao gerar relatórios',
        },
      },
    );
    downloader(URL.createObjectURL(result), 'Planejamentos');
  };

  useEffect(() => {
    dispatch(fetchTemplatesByTipo('planejamento'));
  }, []);

  const leftContents = (
    <Button
      label="Novo"
      aria-label="Novo"
      icon="pi pi-plus"
      onClick={() => history.push({
        pathname: getIncrementedUrlPath('novo'),
        search: location.search,
      })}
      className="p-mr-2 p-button-success"
    />
  );

  const rightContents = (
    <Button
      id="btn-gerar-pdf"
      label="Gerar PDF"
      aria-label="PDF"
      icon="pi pi-file-pdf"
      className="p-ml-auto p-button-help"
      onClick={() => setDownloadTemplate(true)}
      disabled={!planejamentosSelecionados || planejamentosSelecionados.length === 0}
    />
  );

  return (
    <>
      <Toolbar left={leftContents} right={rightContents} />
      <SelectTemplateToPrint
        templates={templates}
        print={print}
        onClose={() => setDownloadTemplate(false)}
        targetId="btn-gerar-pdf"
        download={downloadTemplate}
      />
    </>
  );
};

export default Header;
