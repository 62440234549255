import { Tooltip } from '@agro1desenvolvimento/react-components';
import classNames from 'classnames';
import { FC, ReactElement } from 'react';
import { CroquiItem } from '../../@types/sementes/croquiItem';
import { getCroquiItemLabel } from '../../utilities';
import { CROQUI_ELEMENT_ICONS } from '../../utilities/constants';

export interface ItemProps {
  item: CroquiItem | undefined;
  xIndex: number;
  yIndex: number;
}

export type OnClickInCroquiItem = (
  params: ItemProps
) => void;

interface CroquiSkecthItemProps {
  item: CroquiItem | undefined;
  xIndex: number;
  yIndex: number;
  onClick: OnClickInCroquiItem;
  className?: string;
  tooltip?: ReactElement
}

const CroquiSketchItem: FC<CroquiSkecthItemProps> = ({
  item, xIndex, yIndex, onClick, className, tooltip,
}) => {
  const id = `skecth-${xIndex}-${yIndex}`;

  return (
    <div
      onClick={() => onClick({ item, xIndex, yIndex })}
      className={classNames(item?.tipo, 'croqui-item', 'cursor-pointer', className)}
      role="button"
      tabIndex={-1}
      id={id}
    >
      {item && <i className={CROQUI_ELEMENT_ICONS[item.tipo]} />}
      <Tooltip target={`#${id}`}>
        {tooltip || getCroquiItemLabel({ item, x: xIndex, y: yIndex })}
      </Tooltip>
    </div>
  );
};

export default CroquiSketchItem;
