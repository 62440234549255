import {
  Panel,
  Button,
  Message,
  InputTextarea,
  Dropdown,
} from '@agro1desenvolvimento/react-components';
import {
  camelCase, forEach, isEmpty, map, sum, sumBy,
} from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import {
  useMemo,
  useEffect,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { ptForm } from 'yup-locale-pt';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { showMessage } from '../../../../components/Agro1Toast/toast';
import {
  fetchLotesComLocalizacao,
  selectNewReembalagensState,
  setLotesDestino,
} from './newReembalagemSlice';
import ReembalagemService from '../../../../services/sementes/reembalagens';
import Header from './components/Header';
import LotesParaReembalagem from './components/LotesParaReembalagem';
import CriacaoNovosLotes from './components/CriacaoNovosLotes';
import CardWithValue from '../../../../components/CardWithValue';
import { selectLotesState } from '../../Lote/lotesSlice';
import { selectTopBarState } from '../../../../components/Topbar/topBarSlice';
import { Reembalagem } from '../../../../@types/sementes/reembalagem';
import { promiseWithLoaderAndMessages } from '../../../../utilities';
import { fetchDepositos, selectDepositoState } from '../../../Estoque/Depositos/depositosSlice';
import { falhaAoSalvar } from '../../../../utilities/default-confirmation-messages';
import ShowErrorHelper from '../../../../components/ShowErrorHelper';

Yup.setLocale(ptForm);

const NewReembalagemPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState('');
  const { depositos } = useSelector(selectDepositoState);
  const { lotesDestino, lotesComLocalizacao } = useSelector(selectNewReembalagensState);
  const { lotesSelecionados } = useSelector(selectLotesState);
  const { currentSafra, currentProdutor } = useSelector(selectTopBarState);
  const pesoLotesCriados = useMemo(() => sumBy(lotesDestino, 'pesoTotalKg'), [lotesDestino]);

  const saldoTotalDisponivel = useMemo(() => sum(
    map(lotesComLocalizacao,
      (localizacao) => (localizacao.quantidade * localizacao.lote.pesoEmbalagem!)),
  ), [lotesComLocalizacao]);

  const saldoDisponivel = useMemo(
    () => saldoTotalDisponivel - pesoLotesCriados, [pesoLotesCriados, saldoTotalDisponivel],
  );

  const form = useFormik({
    validationSchema: Yup.object().shape({
      depositoDestino: Yup.string().required(),
    }),
    initialValues: {
      observacao: '',
      depositoDestino: undefined,
    },
    async onSubmit(values) {
      if (!lotesComLocalizacao?.length || !currentSafra || !currentProdutor) return;
      const constroiReembalagem = (): Omit<Reembalagem, 'id'> => {
        const { cultivar, peneira, periodo } = lotesComLocalizacao[0].lote;
        return {
          data: new Date().toISOString(),
          status: 'rascunho',
          safra: currentSafra,
          produtor: currentProdutor,
          observacao: values.observacao,
          cultivar,
          peneira,
          periodo,
          deposito: values.depositoDestino!,
          lotesOrigem: map(lotesComLocalizacao, 'lote'),
          lotesDestino: undefined,
        };
      };

      const reembalagem = constroiReembalagem();

      try {
        await promiseWithLoaderAndMessages(
          dispatch,
          ReembalagemService.createWithLotes(reembalagem, lotesDestino),
        );
        history.push('/sementes/reembalagens/');
      } catch (error) {
        const obj = error.response?.data;
        const keys = obj ? Object.keys(obj) : [];
        forEach(keys, (k) => {
          forEach(obj[k], (message) => {
            dispatch(showMessage({
              severity: 'error',
              summary: 'Erro',
              detail: `${camelCase(k)}: ${message}`,
            }));
          });
        });

        if (isEmpty(keys)) {
          dispatch(showMessage(falhaAoSalvar('reembalagem')));
        }
      }
    },
  });

  useEffect(() => {
    const loadInitialData = () => {
      if (!currentProdutor || !currentSafra) {
        setErrorMessage('Selecione safra/produtor.');
        return;
      }

      if (!lotesSelecionados?.length) {
        setErrorMessage('Nenhum lote de origem selecionado.');
        return;
      }
      setErrorMessage('');

      dispatch(fetchDepositos());
      dispatch(fetchLotesComLocalizacao(lotesSelecionados));
      dispatch(setLotesDestino([]));
    };

    loadInitialData();
  }, [currentProdutor, currentSafra, lotesSelecionados]);

  useEffect(() => {
    if (!lotesComLocalizacao?.length) {
      setErrorMessage('Lotes de origem sem saldo disponível.');
    } else {
      setErrorMessage('');
    }
  }, [lotesComLocalizacao]);

  return (
    <>
      <div className="p-grid">
        <div className="p-col-12 p-pb-0">
          <div className="card p-mb-0">
            <Header />
            <h1>Nova reembalagem</h1>
            {errorMessage && <Message severity="error" text={errorMessage} className="width-full p-justify-start" />}
          </div>
        </div>
      </div>
      {!errorMessage
        && (
          <>
            <LotesParaReembalagem />
            <CriacaoNovosLotes saldoDisponivel={saldoDisponivel} />
            <Panel className="p-mt-3" header={<h3 className="p-mb-0">Resumo</h3>}>
              <div className="p-grid p-col-12 p-md-7 p-lg-6">
                <CardWithValue className="p-col-12 p-md-10 p-p-0 cy-saldo-total" title="Saldo total" value={saldoTotalDisponivel} unidade="KG" />
                <CardWithValue className="p-col-12 p-md-10 p-p-0 cy-lotes-criados" title="Lotes criados" value={pesoLotesCriados} unidade="KG" />
                <CardWithValue className="p-col-12 p-md-10 p-p-0 cy-saldo-disponivel" title="Saldo disponível" value={saldoDisponivel} unidade="KG" />
              </div>
            </Panel>
            <Panel className="p-mt-3 p-fluid" header={<h3 className="p-mb-0">Dados da reembalagem</h3>}>
              <form id="reembalagem-form" onSubmit={form.handleSubmit}>
                <div className="p-field p-pt-2 p-mb-0 p-pb-0 p-sm-6">
                  <label aria-labelledby="depositoDestino" htmlFor="depositoDestino">Depósito de destino:</label><br />
                  <Dropdown
                    value={form.values.depositoDestino}
                    name="depositoDestino"
                    id="depositoDestino"
                    options={depositos}
                    optionLabel="descricao"
                    optionValue="id"
                    disabled={!depositos.length}
                    onChange={form.handleChange}
                    required
                    placeholder="Selecione o depósito de destino"
                    filter
                    appendTo={document.body}
                    aria-describedby="deposito-destino-help"
                    className={classNames({ 'p-invalid': form.errors.depositoDestino })}
                  />
                  <ShowErrorHelper id="deposito-destino-help" error={form.errors.depositoDestino} />
                </div>
                <div className="p-field p-pt-0 p-col-12">
                  <label aria-labelledby="observacao" htmlFor="observacao">Observação:</label><br />
                  <InputTextarea
                    className="width-full"
                    id="observacao"
                    rows={6}
                    value={form.values.observacao}
                    onChange={form.handleChange}
                    autoResize
                  />
                </div>
                <div className="p-grid p-pr-0 p-col-12 p-justify-end">
                  <div>
                    <Button
                      type="submit"
                      onClick={() => {
                        form.validateForm();
                      }}
                      form="reembalagem-form"
                      label="Gerar reembalagem"
                      className="p-button-primary"
                      disabled={!lotesDestino.length}
                    />
                  </div>
                </div>
              </form>
            </Panel>
          </>
        )}
    </>
  );
};

export default NewReembalagemPage;
