import { loginService } from '@agro1desenvolvimento/apis-js-package';
import api from '../erp-api';
import { MovimentoLote } from '../../@types/sementes/lote';

class MovimentoLoteArmazem {
  get endpoint() {
    return `/${loginService.scope}/sementes/movimento_lotes`;
  }

  async create(movimentos: MovimentoLote[]) {
    const movimentosParsed = movimentos.map((movimento) => ({
      bloco_destino: movimento.blocoDestino.id,
      bloco_origem: movimento.blocoOrigem?.id,
      lote: movimento.lote.id,
      quantidade: movimento.quantidade,
      quantidade_kg: movimento.quantidadeKg,
      data: movimento.data,
    }));

    if (!movimentosParsed.length) return;

    return (await api.post(this.endpoint, { movimentos: movimentosParsed })).data;
  }
}

export default new MovimentoLoteArmazem();
