import {
  Column,
  ConfirmPopup,
  DataTable,
} from '@agro1desenvolvimento/react-components';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { TemplateScript } from '../../../@types/relatorio/script';
import { deleteTemplateScript, fetchTemplateScripts, selectScriptState } from './templateScriptSlice';
import DropwDownButtons from '../../../components/DropwDownButtons';
import { AuditoriaType } from '../../../@types/enums';
import { getIncrementedUrlPath } from '../../../utilities';

const ListScripts = () => {
  const [selectedScript, setSelectedScript] = useState<TemplateScript | null>();
  const { scripts } = useSelector(selectScriptState);
  const history = useHistory();
  const dispatch = useDispatch();

  const confirm = async (id: string) => {
    if ((await dispatch(deleteTemplateScript(id))).meta.requestStatus === 'fulfilled') {
      dispatch(fetchTemplateScripts());
      setSelectedScript(null);
    }
  };

  const optionButtonId = (id: string | undefined) => `btn-option-${id}`;

  const renderActionButtons = (script: TemplateScript) => (
    <div>
      <DropwDownButtons
        editAction={() => history.push({
          pathname: getIncrementedUrlPath(`editar/${script.id}`),
          search: location.search,
        })}
        deleteAction={() => setSelectedScript(script)}
        auditoriaAction={() => history.push(`/auditoria/${AuditoriaType.script}/${script.id}`)}
        optionButtonId={optionButtonId(script.id)}
      />
    </div>
  );

  return (
    <>
      <ConfirmPopup
        target={document.getElementById(optionButtonId(selectedScript?.id)) || undefined}
        visible={!!selectedScript}
        onHide={() => setSelectedScript(undefined)}
        message="Deseja realmente excluir?"
        icon="pi pi-exclamation-triangle"
        accept={() => confirm(selectedScript?.id as string)}
      />
      <DataTable value={scripts} responsiveLayout="scroll">
        <Column
          header="Descrição"
          field="descricao"
        />
        <Column
          header="Ações"
          body={renderActionButtons}
          className="acoes-column"
        />
      </DataTable>
    </>
  );
};

export default ListScripts;
