import { loginService } from '@agro1desenvolvimento/apis-js-package';
import { keysToSnakeCaseDeep } from '@agro1desenvolvimento/utils-js';
import api from '../erp-api';
import { Amostra, AmostraCreateUpdate } from '../../@types/sementes/amostra';

class AmostrasService {
  endpoint(loteId: string) {
    return `/${loginService.scope}/sementes/lotes/${loteId}/amostras`;
  }

  async list(loteId: string) {
    return (await api.get<Amostra[]>(this.endpoint(loteId))).data;
  }

  async create(loteId: string, amostra: AmostraCreateUpdate) {
    const amostras = [
      keysToSnakeCaseDeep({
        numeroAmostra: amostra.numeroAmostra,
      }),
    ];

    return (await api.post<Amostra[]>(this.endpoint(loteId), { amostras })).data;
  }

  async find(loteId: string, amostraId: string) {
    return (await api.get<Amostra>(`${this.endpoint(loteId)}/${amostraId}`)).data;
  }

  async update(loteId: string, amostra: AmostraCreateUpdate) {
    const snakeAmostra = keysToSnakeCaseDeep({
      numeroAmostra: amostra.numeroAmostra,
    });
    return (await api.put(`${this.endpoint(loteId)}/${amostra.id}`, snakeAmostra)).data;
  }

  async createOrUpdate(loteId: string, amostra?: AmostraCreateUpdate) {
    if (!amostra) return;

    if (!amostra.id) {
      await this.create(loteId, amostra);
      return;
    }

    await this.update(loteId, amostra);
  }

  async delete(loteId: string, amostra: Amostra) {
    return (await api.delete(`${this.endpoint(loteId)}/${amostra.id}`)).data;
  }
}

export default new AmostrasService();
