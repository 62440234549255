import { loginService } from '@agro1desenvolvimento/apis-js-package';
import { Deposito } from '../../@types/estoque/deposito';
import { CrudCreateUpdate } from '../../utilities';
import CrudBase from '../crud-base';
import api from '../erp-api';

class DepositosService extends CrudBase<Deposito> {
  get endpoint() {
    return `/${loginService.scope}/estoque/depositos`;
  }

  async create(deposito: CrudCreateUpdate<Deposito>) {
    return (await api.post<Deposito[]>(
      this.endpoint,
      { depositos: [{ descricao: deposito.descricao, tipo: deposito.tipo }] },
    )).data[0];
  }

  async update(deposito: CrudCreateUpdate<Deposito>) {
    return (await api.patch<Deposito>(
      `${this.endpoint}/${deposito.id}`,
      { descricao: deposito.descricao, tipo: deposito.tipo },
    )).data;
  }
}

export default new DepositosService();
