import { FC, useRef } from 'react';
import {
  Button, ButtonProps, Menu, MenuItem,
} from '@agro1desenvolvimento/react-components';
import { filter } from 'lodash';

type DropwDownOptions = {
  editAction?: () => void,
  deleteAction?: () => void,
  viewAction?: () => void,
  auditoriaAction?: () => void,
  customActions?: MenuItem[],
  menuButtonProps?: Omit<
    ButtonProps,
    'icon' | 'onClick' | 'aria-controls' | 'title' | 'aria-haspopup' | 'ref'
  >,
  optionButtonId?: string,
};

const DropwDownButtons: FC<DropwDownOptions> = ({
  editAction,
  deleteAction,
  viewAction,
  auditoriaAction,
  customActions = [],
  menuButtonProps,
  optionButtonId,
}) => {
  const menu = useRef<Menu>(null);

  const buttons = [
    {
      command: editAction,
      label: 'Editar',
      icon: 'pi pi-fw pi-pencil',
    },
    {
      command: deleteAction,
      label: 'Remover',
      icon: 'pi pi-fw pi-trash',
    },
    {
      command: auditoriaAction,
      label: 'Auditoria',
      icon: 'pi pi-fw pi-info-circle',
    },
    {
      command: viewAction,
      label: 'Visualizar',
      icon: 'pi pi-fw pi-eye',
    },
    ...customActions,
  ];

  return (
    <>
      <Menu
        model={filter(buttons, 'command')}
        popup
        ref={menu}
        appendTo={document.body}
        baseZIndex={5}
        autoZIndex={false}
      />
      <Button
        {...menuButtonProps}
        className={`p-button-rounded ${menuButtonProps?.className || ''}`}
        icon="pi pi-fw pi-ellipsis-v"
        onClick={(event) => {
          event.stopPropagation();
          menu?.current?.toggle(event);
        }}
        aria-controls="popup_menu"
        title="Opções"
        data-test="buttonActions"
        aria-haspopup
        id={optionButtonId}
      />
    </>
  );
};

export default DropwDownButtons;
