import { isInteger } from 'lodash';
import { FC } from 'react';
import { formatNumber } from '../../utilities';

interface CardWithValueProps {
  value?: number,
  title: string,
  description?: string,
  unidade?: string,
  className: string,
}

const CardWithValue: FC<CardWithValueProps> = ({
  value, title, description, unidade, className,
}) => (
  <div className={`${className} card-with-value`}>
    <div className="item-sumary">
      <span className="item-title">{title}</span>
      <span className="item-description">{description}</span>
      <span className="item-value">{formatNumber(value || 0, isInteger(value) ? undefined : 2)}{unidade}</span>
    </div>
  </div>
);

export default CardWithValue;
