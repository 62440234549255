import React, {
  FC, useMemo, useRef, useState,
} from 'react';
import {
  Button, InputText, Menu,
} from '@agro1desenvolvimento/react-components';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { filter, isEmpty, reject } from 'lodash';
import {
  addFilterValues,
  FilterState,
  resetFilterState,
  selectFilterState,
} from './filterSlice';
import FilterInputNumber from './FilterInputNumber';
import { FilterField, FilterFiledType } from './FilterField';
import FilterInputText from './FilterInputText';
import FilterAutoComplete from './FilterAutoComplete';
import FilterDateInterval from './FilterDateInterval';
import FilterSelect from './FilterSelect';
import { Condition } from '../../services/queryable';

interface FilterProps {
  filters: FilterField[];
  placeholder?: string;
  context: keyof FilterState;
}

const CONTAINER_CLASSES = 'p-col-12 p-md-6 field';

const Filter: FC<FilterProps> = ({ filters, context, placeholder = 'Pesquisa...' }) => {
  const menu = useRef<Menu>(null);
  const dispatch = useDispatch();
  const filterStateSelector = useSelector(selectFilterState);
  const [filtrosSelecionados, setFiltrosSelecionados] = useState<Record<string, Condition[]>>();
  const filterConditions = useMemo(
    () => ({ ...filterStateSelector[context]?.conditions, ...filtrosSelecionados } || {}),
    [filterStateSelector, filtrosSelecionados],
  );
  const [visibleFilters, setVisibleFilters] = useState<string[]>(() => reject(Object.keys(filterConditions), (item) => item === 'globalSearch'));
  const globalSearch = useMemo(() => filterConditions.globalSearch?.[0].value || '', [filterConditions.globalSearch]);

  const setFilterConditions = (field: string, value: Condition[]) => {
    setFiltrosSelecionados((prev) => ({ ...prev, [field]: value }));
  };

  const renderFilter = (filterField: FilterField) => {
    if (!visibleFilters.includes(filterField.field)) return;

    const ELEMENTS: Record<FilterFiledType, any> = {
      autocomplete: FilterAutoComplete,
      number: FilterInputNumber,
      string: FilterInputText,
      dateInterval: FilterDateInterval,
      select: FilterSelect,
    };

    const element = React.createElement(ELEMENTS[filterField.type], {
      filterField,
      filterConditions,
      setFilterConditions,
    });

    return (
      <div className={CONTAINER_CLASSES} key={`filter-${filterField.field}`}>
        {element}
      </div>
    );
  };

  const buttons = useMemo(
    () => filters.map(({ field, label }) => ({
      label,
      icon: visibleFilters.includes(field) ? 'pi pi-check' : '',
      command: () => {
        setVisibleFilters((futureVisibleFilters) => {
          const found = futureVisibleFilters.includes(field);

          if (found) return filter(futureVisibleFilters, (item) => item !== field);
          return [...futureVisibleFilters, field];
        });
      },
    })),
    [visibleFilters, filters],
  );

  const activateFilters = (conditions: typeof filterConditions = filterConditions) => {
    const validFilters = Object.keys(conditions).filter((f) => (
      !isEmpty(conditions[f]) && (f === 'globalSearch' || visibleFilters.includes(f))
    ));

    const validConditions = validFilters.reduce<typeof conditions>((result, item) => (
      {
        ...result,
        [item]: conditions[item],
      }
    ), {});

    dispatch(addFilterValues({
      conditions: validConditions,
      context,
    }));
  };

  const clearFilters = () => {
    setFiltrosSelecionados({});
    setVisibleFilters([]);
    dispatch(resetFilterState({ context }));
  };

  const actionsClasses = useMemo(() => (
    classNames({ 'actions-buttons': visibleFilters.length % 2 !== 0 }, 'p-col')
  ), [visibleFilters]);

  return (
    <div className="filters p-grid p-align-center">
      <Menu model={buttons} popup ref={menu} appendTo={document.body} />
      <div className={CONTAINER_CLASSES}>
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            type="search"
            className="global-filter"
            value={globalSearch}
            onChange={(e) => setFilterConditions('globalSearch', [{ field: 'globalSearch', value: e.currentTarget.value, operator: '' }])}
            placeholder={placeholder}
            onKeyPress={(e) => e.key === 'Enter' && activateFilters()}
            autoFocus
          />
        </span>
      </div>
      {
        filters.map((filterElement) => renderFilter(filterElement))
      }
      <div className={actionsClasses}>
        <span className="p-buttonset">
          <Button
            icon="pi pi-times"
            className="p-button-text"
            data-test="clearFilter"
            onClick={clearFilters}
            tooltip="Limpar Filtros"
            tooltipOptions={{ position: 'bottom' }}
          />
          <Button
            icon="pi pi-ellipsis-v"
            data-test="addFilter"
            className="p-button-text"
            onClick={(event) => menu.current?.toggle(event)}
            tooltip="Adicionar Filtros"
            tooltipOptions={{ position: 'bottom' }}
          />
          <Button
            icon="pi pi-search"
            data-test="submitFilter"
            onClick={() => activateFilters()}
            label="Buscar"
            tooltip="Pesquisar"
            tooltipOptions={{ position: 'bottom' }}
          />
        </span>
      </div>
    </div>
  );
};

export default Filter;
