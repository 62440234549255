import utc from 'dayjs/plugin/utc';
import dayjs from 'dayjs';
import localizadFormat from 'dayjs/plugin/localizedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import 'dayjs/locale/pt-br';

dayjs.locale('pt-br');
dayjs.extend(localizadFormat);
dayjs.extend(utc);
dayjs.extend(customParseFormat);
