import { sum, values } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
} from 'recharts';
import { SaldoCultivar } from '../../../../@types/sementes/saldoCultivar';
import saldoChartLabel from './SaldoChartLabel';

const SaldoChart = ({ saldos }: {saldos : SaldoCultivar | undefined}) => {
  const showGraph = useMemo(() => !!sum(values(saldos)), [saldos]);
  const [graphData, setGraphData] = useState<{ name: string, value: number }[]>([]);
  const COLORS = ['#0088FE', '#FF8042', '#00C49F'];

  useEffect(() => {
    if (saldos) {
      const {
        totalLotes, totalDescartado, totalRecebido,
      } = saldos;
      const disponivel = totalRecebido - totalLotes - totalDescartado;

      setGraphData(
        [
          { name: 'Lotes', value: totalLotes },
          { name: 'Disponível', value: disponivel },
          { name: 'Descartado', value: totalDescartado },
        ],
      );
    } else {
      setGraphData([]);
    }
  }, [saldos]);

  return (
    <div className="p-col-12 p-sm-offset-3 p-md-offset-0 p-sm-6 p-md-5 p-grid p-justify-center" hidden={!showGraph}>
      <PieChart width={140} height={140}>
        <Pie
          data={graphData}
          cx="50%"
          cy="50%"
          animationDuration={900}
          labelLine={false}
          label={saldoChartLabel}
          outerRadius={70}
          dataKey="value"
        >
          {graphData.map((entry, index) => (
            <Cell key={`cell-${entry.name}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip />
      </PieChart>
    </div>
  );
};

export default SaldoChart;
