import { keysToSnakeCaseDeep } from '@agro1desenvolvimento/utils-js';
import { loginService } from '@agro1desenvolvimento/apis-js-package';
import { map } from 'lodash';
import api from '../erp-api';
import { Safra } from '../../@types/geral/safra';
import { PessoaProdutor } from '../../@types/geral/pessoa';
import { Planejamento } from '../../@types/sementes/planejamento';
import { CrudCreateUpdate } from '../../utilities/crud';
import { Lote } from '../../@types/sementes/lote';
import GeradorService from '../relatorio/gerador';
import CrudBase from '../crud-base';
import { HeaderParams } from '../../@types/headers';
import responseHeaders from '../../utilities/response-headers';
import LotesService from './lotes';

export interface PlanejamentoListReturnType {
  data: Planejamento[];
  headers: HeaderParams;
}

class PlanejamentosService extends CrudBase<Planejamento> {
  get endpoint() {
    return `/${loginService.scope}/sementes/planejamentos`;
  }

  async list(safra: Safra, produtor: PessoaProdutor, page: number) {
    const { data, headers } = await api.get<Planejamento[]>(this.endpoint, {
      params: {
        safra: safra.id,
        produtor: produtor.id,
        page,
      },
    });

    return { headers: responseHeaders(headers), data };
  }

  async create(planejamento: CrudCreateUpdate<Planejamento>) {
    return (await api.post<Planejamento[]>(this.endpoint, {
      planejamentos: [this.parsePayloadToSend(planejamento)],
    })).data[0];
  }

  async update(planejamento: CrudCreateUpdate<Planejamento>) {
    return (await api.patch<Planejamento>(`${this.endpoint}/${planejamento.id}`,
      this.parsePayloadToSend(planejamento))).data;
  }

  async report(planejamentoIds: Array<string>, id: string) {
    return GeradorService.gerar('planejamento', { planejamentoIds }, id);
  }

  async lotes(planejamentoId: string) {
    const { data } = await api.get<Lote[]>(`${this.endpoint}/${planejamentoId}/lotes`);
    return data.map(LotesService.responseSerializer);
  }

  parsePayloadToSend(planejamento: CrudCreateUpdate<Planejamento>) {
    return keysToSnakeCaseDeep({
      safraId: planejamento.safra.id,
      ubsId: planejamento.ubs.id,
      data: planejamento.data,
      status: planejamento.status,
      depositoMateriaPrimaId: planejamento.depositoMateriaPrima.id,
      cultivarId: planejamento.cultivar.id,
      depositoDestinoId: planejamento.depositoDestino.id,
      campoProducaoIds: map(planejamento.campoProducoes, 'id'),
    });
  }

  isEditable(planejamento: Planejamento) {
    return planejamento.status !== 'finalizado';
  }
}

export default new PlanejamentosService();
