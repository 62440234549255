import { FC, useState } from 'react';
import {
  DataTable,
  Column,
  ConfirmPopup,
} from '@agro1desenvolvimento/react-components';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Deposito } from '../../../@types/estoque/deposito';
import { deleteDeposito, fetchDepositos, selectDepositoState } from './depositosSlice';
import DropwDownButton from '../../../components/DropwDownButtons';
import { AuditoriaType } from '../../../@types/enums';

const ListDepositos: FC = () => {
  const { depositos } = useSelector(selectDepositoState);
  const dispatch = useDispatch();
  const [selectedDeposito, setSelectedDeposito] = useState<Deposito | null>();
  const history = useHistory();

  const confirm = async (id: string) => {
    if ((await dispatch(deleteDeposito(id))).meta.requestStatus === 'fulfilled') {
      setSelectedDeposito(null);
      dispatch(fetchDepositos());
    }
  };

  const optionButtonId = (id: string | undefined) => `btn-option-${id}`;

  const renderBtnAcoes = (deposito: Deposito) => (
    <div>
      <DropwDownButton
        editAction={() => history.push(`/estoque/depositos/editar/${deposito.id}`)}
        deleteAction={() => setSelectedDeposito(deposito)}
        auditoriaAction={() => history.push(`/auditoria/${AuditoriaType.deposito}/${deposito.id}`)}
        optionButtonId={optionButtonId(deposito.id)}
      />
    </div>
  );

  return (
    <>
      <ConfirmPopup
        target={document.getElementById(optionButtonId(selectedDeposito?.id)) || undefined}
        visible={!!selectedDeposito}
        onHide={() => setSelectedDeposito(undefined)}
        message="Deseja realmente excluir?"
        icon="pi pi-exclamation-triangle"
        accept={() => confirm(selectedDeposito?.id as string)}
      />

      <DataTable value={depositos} className="deposito-lista" responsiveLayout="scroll" filterDisplay="row">
        <Column
          body={(rowData: Deposito) => rowData.descricao}
          header="Descrição"
          field="descricao"
          filter
          filterMatchMode="contains"
          sortable
          filterHeaderClassName="input-full-width"
          showFilterMenu={false}
          bodyClassName="p-col-4"
        />
        <Column
          body={(rowData: Deposito) => (rowData.tipo === 'proprio' ? 'Próprio' : 'Terceiro')}
          header="Tipo"
          field="tipo"
          sortable
        />
        <Column header="Ações" body={renderBtnAcoes} bodyClassName="p-col-2" />
      </DataTable>
    </>
  );
};

export default ListDepositos;
