import { loginService } from '@agro1desenvolvimento/apis-js-package';
import { keysToCamelCase, keysToSnakeCase } from '@agro1desenvolvimento/utils-js';
import { RelatorioTemplate } from '../../@types/relatorio/template';
import api from '../erp-api';
import { CrudCreateUpdate } from '../../utilities';
import CrudBase from '../crud-base';
import { ListReturnType } from '../queryable-base';
import { HeaderParams } from '../../@types/headers';

export interface RelatorioTemplateListReturnType extends HeaderParams {
  data: RelatorioTemplate[];
}

class TemplateService extends CrudBase<RelatorioTemplate> {
  get endpoint() {
    return `/${loginService.scope}/relatorio/templates`;
  }

  async list(page: number): Promise<ListReturnType<RelatorioTemplate>> {
    const { data, headers } = await api.get<RelatorioTemplate[]>(this.endpoint, {
      params: {
        page,
      },
    });
    const returnedData = data.map((elem) => keysToCamelCase(elem) as RelatorioTemplate);

    return { headers, data: returnedData };
  }

  async listAll(params?: any) {
    const { data, headers } = await super.listAll(params);
    const returnedData = data.map((elem) => keysToCamelCase(elem) as RelatorioTemplate);

    return { headers, data: returnedData };
  }

  async find(id: RelatorioTemplate['id']) {
    const { data } = await api.get<RelatorioTemplate>(`${this.endpoint}/${id}`);
    return keysToCamelCase(data) as RelatorioTemplate;
  }

  async create(template: CrudCreateUpdate<RelatorioTemplate>) {
    return (await api.post<RelatorioTemplate[]>(
      this.endpoint,
      {
        templates: [convertToSnakeCase(template)],
      },
    )).data[0];
  }

  async update(template: CrudCreateUpdate<RelatorioTemplate>) {
    return (await api.patch<RelatorioTemplate>(
      `${this.endpoint}/${template.id}`,
      convertToSnakeCase(template),
    )).data;
  }
}

const parseJson = (report: string | JSON) => {
  if (typeof report === 'string') {
    return JSON.parse(report);
  }
  return report;
};

export const convertToSnakeCase = (
  template: CrudCreateUpdate<RelatorioTemplate>,
) => keysToSnakeCase({
  descricao: template.descricao,
  scriptId: template.scriptId,
  tipo: template.tipo,
  tenantsHabilitados: template.tenantsHabilitados,
  tenantsDesabilitados: template.tenantsDesabilitados,
  reportTemplate: parseJson(template.reportTemplate),
});

export default new TemplateService();
