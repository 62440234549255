import {
  Dialog,
  Button,
  CalendarProps,
  Calendar,
} from '@agro1desenvolvimento/react-components';
import React, {
  useState,
  useEffect,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isDate } from 'lodash';
import { promiseWithLoaderAndMessages, dateAmericanFormat } from '../../../../utilities';
import { setDataConfirmacao, selectNewEditPlanejamentoState } from '../newEditPlanejamentoSlice';

const Footer: React.FC<PropTypes> = ({
  isEnableSubmitForm,
  print,
  submitLotes,
  action,
  closeModal,
}) => {
  const newEditPlanejamentoState = useSelector(selectNewEditPlanejamentoState);
  const { lotesConfirmados, lotes } = newEditPlanejamentoState;
  const [confirmationDateVisible, setConfirmationDateVisible] = useState(false);
  const dispatch = useDispatch();

  const submitLotesWithData = async () => {
    setConfirmationDateVisible(false);
    await promiseWithLoaderAndMessages(
      dispatch,
      submitLotes(),
    );
  };

  const onClickSave = () => {
    if (action !== 'lotes') return;

    if (lotesConfirmados.length) {
      setConfirmationDateVisible(true);
    } else {
      submitLotes();
    }
  };

  useEffect(() => {
    if (confirmationDateVisible) dispatch(setDataConfirmacao(dateAmericanFormat(new Date())));
  }, [confirmationDateVisible]);

  return (
    <div className="p-buttonset p-col" hidden={newEditPlanejamentoState.mode !== 'list'}>
      <Dialog
        header="Data da confirmação"
        visible={confirmationDateVisible}
        onHide={() => setConfirmationDateVisible(false)}
      >
        <Calendar
          id="data"
          showIcon
          value={new Date()}
          onChange={(data: CalendarProps) => {
            dispatch(
              setDataConfirmacao(dateAmericanFormat(isDate(data.value) ? data.value : new Date())),
            );
          }}
          appendTo={document.body}
          placeholder="Data"
        />
        <Button
          aria-label="submitLotes"
          icon="pi pi-check"
          className="p-button p-m-1"
          onClick={() => submitLotesWithData()}
        />
      </Dialog>
      <Button label="Cancelar" icon="pi pi-times" onClick={closeModal} className="p-button p-button-text p-m-0" />
      <Button
        id="btn-gerar-etiquetas"
        label="Etiquetas"
        aria-label="imprimir_etiquetas"
        icon="pi pi-print"
        className="p-button p-button-secondary p-m-0"
        onClick={print}
        disabled={!lotes.length}
      />
      <Button
        label="Salvar"
        aria-label="salvar"
        disabled={!isEnableSubmitForm()}
        icon="pi pi-check"
        autoFocus
        className="p-button"
        onClick={onClickSave}
        type="submit"
        form="form-planejamento"
      />
    </div>
  );
};

interface PropTypes {
  isEnableSubmitForm: () => boolean,
  print: () => void,
  submitLotes: () => Promise<void>,
  action: string,
  closeModal: () => void,
}

export default Footer;
