import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ArmazemSaldo } from '../../../@types/sementes/armazem';
import type { RootState } from '../../../app/rootReducer';
import { ListReturnType } from '../../../services/queryable-base';
import queryable, { Query } from '../../../services/queryable';
import { promiseWithLoaderAndMessages } from '../../../utilities';
import LocalizacaoSaldoService from '../../../services/sementes/localizacao-saldo';
import { falhaAoCarregar } from '../../../utilities/default-confirmation-messages';

interface LocalizacaoState {
  saldos: ArmazemSaldo[],
}

const initialState: LocalizacaoState = {
  saldos: [],
};

export const fetchSaldosLotesArmazem = createAsyncThunk<
ListReturnType<ArmazemSaldo>,
undefined | Omit<Query<ArmazemSaldo>, 'service'>
>(
  'sementes/armazens/saldos',
  (param = {}, { dispatch }) => {
    const query = queryable.query({
      ...param,
      service: LocalizacaoSaldoService,
    });

    return promiseWithLoaderAndMessages(
      dispatch,
      query,
      {
        errorMessage: falhaAoCarregar('lotes'),
      },
    );
  },
);

export const localizacaoSlice = createSlice({
  name: 'localizacao',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(fetchSaldosLotesArmazem.fulfilled, (state, { payload }) => {
      state.saldos = payload.data;
    });
  },
});

export const selectLocalizacaoState = (state: RootState) => state.localizacaoReducer;

export default localizacaoSlice.reducer;
