import {
  Column, DataTable, Button, Message, Toolbar,
} from '@agro1desenvolvimento/react-components';
import { useIsMobile } from '@agro1desenvolvimento/react-hooks';
import { useParams } from 'react-router-dom';
import { filter, reject, sumBy } from 'lodash';
import {
  FC, useEffect, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Lote } from '../../../../@types/sementes/lote';
import LoteStatusTag from '../../Lote/components/LoteStatusTag';
import {
  selectNewEditPlanejamentoState,
  setMode,
  setLotes,
  setLotesMovimentados,
  fetchPlanejamentoLotes,
  fetchPlanejamento,
} from '../newEditPlanejamentoSlice';
import DropDownButtons from './DropDownButtons';
import EditLoteForm, {
  PlanejamentoLoteFormFields,
} from '../../Lote/EditLoteForm';
import planejamentosService from '../../../../services/sementes/planejamentos';
import MovimentacaoLote from './MovimentacaoLote';
import { formatNumber } from '../../../../utilities';
import ReabrirLoteConfirmationModal from '../../Lote/components/ReabrirLoteConfirmationModal';
import { PlanejamentoPageRouteParams } from './planejamentoPageRouteParams';

const renderStatus = (lote: Lote) => <LoteStatusTag lote={lote} />;

const PlanejamentoLoteForm: FC<PlanejamentoLoteFormProps> = ({ visible }) => {
  const dispatch = useDispatch();
  const [rascunhos, setRascunhos] = useState<Lote[]>([]);
  const [todos, setTodos] = useState<Lote[]>([]);
  const [lotesFiltrados, setLotesFiltrados] = useState<Lote[]>([]);
  const isMobile = useIsMobile();
  const [filtroSelecionado, setFiltroSelecionado] = useState<string>();
  const [loteToEdit, setLoteToEdit] = useState<PlanejamentoLoteFormFields>();
  const newEditPlanejamentoState = useSelector(selectNewEditPlanejamentoState);
  const [selectedLote, setSelectedLote] = useState<Lote>();
  const { sumQuantidadeEmbalagens, sumPesoTotalKG } = useMemo(() => ({
    sumPesoTotalKG: sumBy(lotesFiltrados, 'pesoTotalKg'),
    sumQuantidadeEmbalagens: sumBy(lotesFiltrados, 'quantidade'),
  }),
  [lotesFiltrados]);
  const { action } = useParams<PlanejamentoPageRouteParams>();
  const creating = useMemo(() => ['duplicar', 'novo'].includes(action), [action]);
  const [loteToReopen, setLoteToReopen] = useState<Lote>();

  const {
    lotesConfirmados, lotesExcluidos, lotes, planejamento, lotesMovimentados,
  } = newEditPlanejamentoState;

  useEffect(() => {
    setRascunhos(filter(lotes, { status: 'rascunho' }));
    setTodos(lotes);
    setLotesFiltrados(lotes);
  }, [lotes]);

  const openEdit = (lote: PlanejamentoLoteFormFields) => {
    dispatch(setMode('newEdit'));
    setLoteToEdit(lote);
  };

  const isEditable = useMemo(
    () => !!planejamento && planejamentosService.isEditable(planejamento),
    [planejamento],
  );

  const afterSubmitLote = (lote: Lote) => {
    dispatch(setLotes([
      ...reject(lotes, { id: lote.id }),
      lote,
    ]));
  };

  useEffect(() => {
    if (planejamento && !creating) dispatch(fetchPlanejamentoLotes({ planejamento }));
  }, [planejamento]);

  useEffect(() => {
    if (newEditPlanejamentoState.mode !== 'newEdit') setLoteToEdit(undefined);
  }, [newEditPlanejamentoState.mode]);

  return (
    <div hidden={!visible}>
      <div hidden={!isEditable || newEditPlanejamentoState.mode !== 'newEdit'}>
        <EditLoteForm
          lote={loteToEdit}
          planejamento={planejamento}
          afterSubmit={afterSubmitLote}
        />
      </div>

      {!isEditable && (
        <Message
          text="Planejamento já Finalizado. Não é possível manipular os lotes."
          severity="warn"
          className="width-full"
        />
      )}

      <div hidden={newEditPlanejamentoState.mode !== 'list'}>
        <div hidden={!isEditable}>
          <Toolbar
            left={() => (
              <Button
                label="Novo"
                aria-label="Novo"
                icon="pi pi-plus"
                onClick={() => dispatch(setMode('newEdit'))}
                className="p-mr-2 p-button-success"
                data-test="new-formLote"
              />
            )}
            right={() => (
              <div className="p-buttonset">
                <Button
                  badge={String(rascunhos.length)}
                  className="p-button-warning p-button-sm"
                  label="Rascunhos"
                  title="Rascunhos"
                  style={filtroSelecionado === 'rascunho' ? { opacity: 0.5 } : {}}
                  onClick={() => { setFiltroSelecionado('rascunho'); setLotesFiltrados(rascunhos); }}
                />
                <Button
                  badge={String(todos.length)}
                  className="p-button-primary p-button-sm"
                  label="Todos"
                  title="Todos"
                  style={filtroSelecionado === 'todos' ? { opacity: 0.5 } : {}}
                  onClick={() => { setFiltroSelecionado('todos'); setLotesFiltrados(todos); }}
                />
              </div>
            )}
          />
        </div>
        <ReabrirLoteConfirmationModal
          lote={loteToReopen}
          visible={!!loteToReopen}
          onHide={() => setLoteToReopen(undefined)}
          onExit={() => dispatch(fetchPlanejamento({ planejamentoId: planejamento!.id }))}
        />
        <DataTable
          stripedRows
          value={lotesFiltrados}
          emptyMessage="Nenhum registro encontrado."
          className="p-datatable-hoverable-rows p-mt-5"
          dataKey="id"
          sortField="data"
          rowClassName={(lote:Lote) => {
            if (lotesExcluidos.includes(lote)) return { 'tr-danger': true };
            if (lotesConfirmados.includes(lote)) return { 'tr-success': true };
            return { 'tr-danger': false, 'tr-success': false };
          }}
          responsiveLayout="scroll"
        >
          <Column field="numeroLote" header="Nº do lote" sortable footer={!!lotesFiltrados?.length && <>Totais:</>} />
          {isMobile || <Column body={(lote: Lote) => lote.peneira?.descricao || 'N/A'} header="Peneira" />}
          <Column body={(lote: Lote) => lote.unidade.abreviatura || 'N/D'} header="Unidade" />
          <Column
            body={(lote: Lote) => formatNumber(lote.quantidade, 1) || 'N/D'}
            header="Qtd"
            footer={formatNumber(sumQuantidadeEmbalagens, 1)}
          />
          <Column
            body={(lote: Lote) => formatNumber(lote.pesoTotalKg || 0, 1)}
            header="Peso Total (kg)"
            footer={formatNumber(sumPesoTotalKG, 1)}
          />
          <Column body={(lote: Lote) => formatNumber(lote.pms || 0, 1) || 'N/D'} header="PMS" />
          <Column bodyClassName="p-text-center" body={renderStatus} header="Status" />
          <Column
            bodyClassName="p-text-center"
            body={(lote: Lote) => (
              lote.status === 'rascunho' ? (
                <DropDownButtons
                  lote={lote}
                  openEdit={openEdit}
                  onPressConfirmation={() => setSelectedLote(lote)}
                />
              ) : (
                <Button
                  className="p-button p-component p-button-rounded"
                  icon="fas fa-reply"
                  onClick={() => setLoteToReopen(lote)}
                  tooltipOptions={{ position: 'top' }}
                  tooltip="Reabrir lote"
                />
              )
            )}
          />
        </DataTable>
      </div>

      <MovimentacaoLote
        onHide={() => setSelectedLote(undefined)}
        lote={selectedLote}
        totalDisponivel={selectedLote?.quantidade || 0}
        onSubmit={(newLotesMovimentados) => (
          dispatch(setLotesMovimentados([...newLotesMovimentados, ...lotesMovimentados]))
        )}
      />
    </div>
  );
};

interface PlanejamentoLoteFormProps {
  visible: boolean,
}

export default PlanejamentoLoteForm;
