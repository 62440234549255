import {
  AutoComplete,
  AutoCompleteChangeParams,
  Dropdown,
  InputText,
} from '@agro1desenvolvimento/react-components';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import classNames from 'classnames';
import { useFormik } from 'formik';
import { find, map, round } from 'lodash';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { Produto } from '../../../../../@types/estoque/item';
import { fetchDepositos, selectDepositoState } from '../../../../Estoque/Depositos/depositosSlice';
import { SteepComponent } from '../../../VinculoLotesCamposProducao/types';
import {
  goToStep,
  selectDescartesState,
  setConfiguracoesDescarte,
  SimulaDescartesFormField,
} from '../novoDescarteSlice';
import { fetchProdutos, selectProdutoState } from '../../produtosSlice';
import { promiseWithLoaderAndMessages } from '../../../../../utilities';
import { falhaAoCarregar } from '../../../../../utilities/default-confirmation-messages';
import { selectTopBarState } from '../../../../../components/Topbar/topBarSlice';
import { SaldoCultivar } from '../../../../../@types/sementes/saldoCultivar';
import SaldosCultivaresService from '../../../../../services/sementes/saldos-cultivares';

const SimulaDescartesSchema = Yup.object().shape({
  subProduto: Yup.string().required(),
  quantidade: Yup.number().required(),
  depositoDestino: Yup.string().required(),
});

const SimulacaoDescarte: SteepComponent = forwardRef(({ setCanGoNext }, ref) => {
  const dispatch = useDispatch();
  const { produtos } = useSelector(selectProdutoState);
  const { depositos } = useSelector(selectDepositoState);
  const { currentSafra, currentProdutor } = useSelector(selectTopBarState);
  const [currentProduto, setCurrentProduto] = useState<Produto | undefined>(undefined);
  const [quantidadePercent, setQuantidadePercent] = useState<number>(0);
  const [saldoCultivarByDeposito, setSaldoCultivarByDeposito] = useState<SaldoCultivar>();
  const { cultivar, depositoOrigem, camposProducoes } = useSelector(selectDescartesState);

  const formSimulacao = useFormik<SimulaDescartesFormField>({
    validationSchema: SimulaDescartesSchema,
    initialValues: {
      subProduto: undefined,
      depositoDestino: undefined,
      quantidade: 0,
    },
    async onSubmit({ quantidade, subProduto, depositoDestino }) {
      const produtoFound = find(produtos, { id: subProduto });
      const depositoFound = find(depositos, { id: depositoDestino });
      if (!quantidade || !produtoFound || !depositoFound) return;

      dispatch(setConfiguracoesDescarte(
        { quantidade, subProduto: produtoFound, depositoDestino: depositoFound },
      ));
      dispatch(goToStep('next'));
    },
  });

  const changeProduto = (value: AutoCompleteChangeParams) => {
    const produtoObject = {
      ...value,
      target: {
        ...value.target,
        value: value.target.value.id,
      },
    };
    setCurrentProduto(value.target.value);
    formSimulacao.handleChange(produtoObject);
  };

  const dispatchFetchProdutos = (query = '') => {
    dispatch(fetchProdutos({
      conditions: [
        {
          field: 'descricao',
          operator: 'matches',
          value: `%${query}%`,
        }, {
          field: 'especie_id',
          operator: 'eq',
          value: cultivar?.especie.id || '',
        },
      ],
    }));
  };

  useImperativeHandle(ref, () => ({
    submit: formSimulacao.handleSubmit,
  }));

  useEffect(() => {
    const loadSaldosCultivares = async () => {
      if (!cultivar || !depositoOrigem) return;

      const params = {
        cultivarId: cultivar.id,
        safraId: currentSafra!.id,
        produtorId: currentProdutor!.id,
        depositoOrigemId: depositoOrigem.id,
        camposProducaoIds: map(camposProducoes, 'campo.id'),
      };

      setSaldoCultivarByDeposito(await promiseWithLoaderAndMessages(
        dispatch,
        SaldosCultivaresService.saldos(params),
        { errorMessage: falhaAoCarregar('saldos de cultivares') },
      ));
    };

    loadSaldosCultivares();
  }, [cultivar, depositoOrigem, currentSafra, currentProdutor, camposProducoes]);

  useEffect(() => {
    setCanGoNext(formSimulacao.isValid);
  }, [formSimulacao.isValid]);

  const refreshQuantidade = (quantidade: number, totalRecebido: number) => {
    const porcentagem = round((quantidade / totalRecebido) * 100, 2);
    setQuantidadePercent(Math.max(0, porcentagem));
    formSimulacao.setFieldValue('quantidade', Math.max(0, quantidade));
  };

  useEffect(() => {
    if (saldoCultivarByDeposito) {
      const { saldoFinal: disponivelDescarte, totalRecebido } = saldoCultivarByDeposito;
      refreshQuantidade(disponivelDescarte, totalRecebido);
    }
  }, [saldoCultivarByDeposito]);

  useEffect(() => {
    if (formSimulacao.values.quantidade && saldoCultivarByDeposito?.totalRecebido) {
      refreshQuantidade(formSimulacao.values.quantidade, saldoCultivarByDeposito?.totalRecebido);
    }
  }, [formSimulacao.values.quantidade]);

  useEffect(() => {
    dispatch(fetchDepositos());
  }, []);

  useEffect(() => {
    formSimulacao.resetForm();
    setCurrentProduto(undefined);
  }, [cultivar]);

  return (
    <>
      <div>
        <h5 className="p-ml-2 p-my-1">Cultivar: <strong>{cultivar?.descricao}</strong></h5>
        <h5 className="p-ml-2 p-my-1">Depósito de matéria prima: <strong>{depositoOrigem?.descricao}</strong></h5>
        <h5 className="p-ml-2 p-my-1">Campos: <strong>{map(camposProducoes, 'campo.identificacaoSigef').join(', ') || 'Todos'}</strong></h5>
      </div>
      <div className="p-fluid p-grid p-align-center p-mt-4">
        <div className="p-field p-col-4">
          <label aria-labelledby="quantidade" htmlFor="quantidade">Quantidade</label>
          <div className="p-inputgroup">
            <InputText
              required
              type="number"
              id="quantidade"
              name="quantidade"
              placeholder="Quantidade"
              onChange={formSimulacao.handleChange}
              value={formSimulacao.values.quantidade}
            />
            <span className="p-inputgroup-addon">KG ({quantidadePercent}%)</span>
          </div>
        </div>
        <div className="p-field p-col-4">
          <label aria-labelledby="depositoDestino" htmlFor="depositoDestino">Depósito destino</label>
          <Dropdown
            filter
            required
            name="depositoDestino"
            id="depositoDestino"
            options={depositos}
            optionValue="id"
            optionLabel="descricao"
            placeholder="Depósito de Destino"
            onChange={formSimulacao.handleChange}
            value={formSimulacao.values.depositoDestino}
            className={classNames({ 'p-invalid': formSimulacao.errors.depositoDestino })}
            appendTo={document.body}
          />
        </div>
        <div className="p-field p-col-4">
          <label aria-labelledby="subprodutoId" htmlFor="subprodutoId">Subproduto</label>
          <AutoComplete
            dropdown
            value={currentProduto}
            name="subProduto"
            id="subProduto"
            field="descricao"
            suggestions={produtos}
            onChange={changeProduto}
            placeholder="Selecione o Subproduto"
            appendTo={document.body}
            className={classNames({ 'p-invalid': formSimulacao.errors.subProduto })}
            completeMethod={(e) => dispatchFetchProdutos(e.query)}
          />
        </div>
      </div>
    </>
  );
});

export default SimulacaoDescarte;
