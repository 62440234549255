import {
  FC,
  useEffect,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import Form from './Form';
import CampoProducaoService from '../../../../services/sementes/campos-producao';
import { CampoProducao } from '../../../../@types/sementes/campoProducao';
import { promiseWithLoaderAndMessages } from '../../../../utilities';
import { falhaAoCarregar } from '../../../../utilities/default-confirmation-messages';

const EditCampoProducao: FC = () => {
  const dispatch = useDispatch();
  const [campoProducao, setCampoProducao] = useState<CampoProducao>();
  const { id } = useParams<{id: string}>();

  useEffect(() => {
    const findCampoProducao = async () => {
      setCampoProducao(
        await promiseWithLoaderAndMessages(
          dispatch,
          CampoProducaoService.find(id),
          { errorMessage: falhaAoCarregar('campo produção') },
        ),
      );
    };

    if (id) findCampoProducao();
  }, [id]);

  return campoProducao ? <Form campoProducao={campoProducao} /> : null;
};

export default EditCampoProducao;
