import {
  FC,
  useEffect,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Message, Dialog } from '@agro1desenvolvimento/react-components';
import {
  selectTopBarState,
} from '../../../components/Topbar/topBarSlice';
import { Ubs } from '../../../@types/sementes/ubs';
import FormularioUbs from './FormularioUbs';
import UbsService from '../../../services/sementes/ubs';

const onHide = () => [];

const NewEditUbsPage: FC = () => {
  const history = useHistory();
  const [ubs, setUbs] = useState<Ubs>();
  const { id } = useParams<{id: string}>();
  const [errorMessage, setErrorMessage] = useState('');

  const {
    currentProdutor,
  } = useSelector(selectTopBarState);

  if (!currentProdutor) {
    history.replace('/sementes/ubs');
    return null;
  }

  useEffect(() => {
    if (id) {
      try {
        const find = async () => setUbs(await UbsService.find(id));
        find();
      } catch (error) {
        setErrorMessage(error);
      }
    } else {
      setUbs({ produtor: currentProdutor, descricao: '', id: '' });
    }
  }, [id, currentProdutor]);

  if (!ubs) return null;

  if (errorMessage) {
    return (
      <Dialog onHide={onHide} visible header="Erro ao buscar UBS" className="dialog-md">
        <Message severity="error" text={errorMessage} className="width-full p-justify-start" />
      </Dialog>
    );
  }

  return <FormularioUbs ubs={ubs} />;
};
export default NewEditUbsPage;
