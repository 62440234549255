import { useEffect, useState } from 'react';
import { Route, useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import AmostrasList from './List';
import AmostraCreateEditPage from './AmostraCreateEditPage';
import { selectTopBarState } from '../../../../components/Topbar/topBarSlice';
import promiseWithLoaderAndMessages from '../../../../utilities/promise-with-loader-and-messages';
import amostrasService from '../../../../services/sementes/amostras';
import lotesService, { CrudCreateUpdateLote } from '../../../../services/sementes/lotes';
import { Amostra } from '../../../../@types/sementes/amostra';
import Header from './Header';
import { falhaAoCarregar } from '../../../../utilities/default-confirmation-messages';

const AmostrasPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    currentSafra,
    currentProdutor,
  } = useSelector(selectTopBarState);
  const { loteId } = useParams<{ loteId: string }>();
  const [amostras, setAmostras] = useState<Amostra[]>([]);
  const [lote, setLote] = useState<CrudCreateUpdateLote>();
  const url = new URL(location.href);
  const onFormClose = () => {
    fetchAmostras();
  };

  if (!currentSafra || !currentProdutor) {
    url.searchParams.set('page', '1');
    history.replace({
      pathname: '/sementes/lotes/:page',
      search: url.search,
    });
    return null;
  }

  const fetchAmostras = async () => {
    setAmostras(await promiseWithLoaderAndMessages(
      dispatch,
      amostrasService.list(loteId),
      { errorMessage: falhaAoCarregar('amostras') },
    ));
  };

  const fetchLote = async () => {
    setLote(await promiseWithLoaderAndMessages(
      dispatch,
      lotesService.find(loteId),
      { errorMessage: falhaAoCarregar('lote') },
    ));
  };

  useEffect(() => {
    fetchAmostras();
    fetchLote();
  }, [loteId]);

  return (
    <>
      <div className="p-grid">
        <div className="p-col-12">
          <div className="card">
            <Header loteId={loteId} />
            <h1>Amostras do lote: {lote?.numeroLote}</h1>
            <AmostrasList amostras={amostras} setAmostra={setAmostras} loteId={loteId} />
          </div>
        </div>
      </div>
      <Route path="/sementes/lotes/:loteId/amostras/:id?/:action(novo|editar)">
        <AmostraCreateEditPage onClose={onFormClose} />
      </Route>
    </>
  );
};

export default AmostrasPage;
