import Logo from './Logo/Logo';
import MainMenu from './MainMenu/MainMenu';
import Profile from './Profile/Profile';

const Sidebar = () => (
  <div className="layout-sidebar layout-sidebar-dark">
    <Logo />
    <Profile />
    <MainMenu />
  </div>
);

export default Sidebar;
