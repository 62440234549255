import {
  DataTable,
  Column,
  PaginatorPageState,
} from '@agro1desenvolvimento/react-components';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { flatten } from 'lodash';
import { formatWithoutTime, scrollToTop } from '../../utilities';
import { Auditoria } from '../../@types/geral/auditoria';
import { fetchAuditorias, selectAuditoriasState } from '../../features/geral/Auditorias/auditoriasSlice';
import AuditoriaActionTag from './AuditoriaActionTag';
import { AuditoriaAction } from '../../@types/enums';
import Header from './Header';
import Pagination from '../Pagination';
import Filters from './Filters';
import { selectFilterState } from '../Filter/filterSlice';
import { usePagination } from '../../hooks';

export const renderChanges = (auditoria: Auditoria) => {
  if ([AuditoriaAction.destroy, AuditoriaAction.create].includes(auditoria.action)) {
    return (
      <>
        {Object.entries(auditoria.auditedChanges).map(([key, values]) => (
          <p key={key}>
            <b>{key}: </b> <span className="new-value">{JSON.stringify(values)}</span>
          </p>
        ))}
      </>
    );
  }
  if ([AuditoriaAction.update].includes(auditoria.action)) {
    return (
      <>
        {Object.entries(auditoria.auditedChanges).map(([key, [oldValue, newValue]]) => (
          <p key={key}>
            <b>{key}: </b> <span className="old-value">{JSON.stringify(oldValue)}</span> {'->'} <span className="new-value">{JSON.stringify(newValue)}</span>
          </p>
        ))}
      </>
    );
  }
};

const AuditoriaComponent = () => {
  const { auditorias, pagination } = useSelector(selectAuditoriasState);
  const { auditoriaFilter } = useSelector(selectFilterState);
  const { maxPerPage = 1, totalRecords, pages } = pagination;
  const dispatch = useDispatch();
  const renderAction = (auditoria: Auditoria) => <AuditoriaActionTag action={auditoria.action} />;
  const { id } = useParams<PageParams>();
  const { currentPage, goToPage, firstIndexOnPage } = usePagination({ maxPerPage });

  const onPageChange = (e: PaginatorPageState) => {
    goToPage(e.page + 1);
  };

  useEffect(() => {
    scrollToTop();
    dispatch(fetchAuditorias({
      conditions: flatten(Object.values(auditoriaFilter.conditions)),
      sorts: auditoriaFilter.sort ? [auditoriaFilter.sort] : undefined,
      extraParameters: {
        page: currentPage.toString(),
        id,
      },
    }));
  }, [auditoriaFilter]);

  return (
    <div className="p-grid auditoria">
      <div className="p-col-12">
        <div className="card">
          <Header />
          <h1>Auditoria</h1>
          <DataTable value={auditorias} header={<Filters />} responsiveLayout="scroll">
            <Column header="Data/Hora" body={(auditoria: Auditoria) => formatWithoutTime(auditoria.createdAt)} bodyClassName="cy-column-date" />
            <Column field="action" body={renderAction} header="Ação" className="column-acao p-text-center" />
            <Column field="username" header="Usuário" />
            <Column header="Alterações" body={renderChanges} />
          </DataTable>
          <Pagination
            pages={pages}
            first={firstIndexOnPage}
            rows={maxPerPage}
            totalRecords={totalRecords}
            onPageChange={onPageChange}
            pageRecords={auditorias.length}
          />
        </div>
      </div>
    </div>
  );
};

export default AuditoriaComponent;

interface PageParams {
  id: string,
  loteId: string,
  page: string,
}
