import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../../../app/rootReducer';
import EspeciesService from '../../../services/geral/especies';
import { Especie } from '../../../@types/geral/especie';
import promiseWithLoaderAndMessages from '../../../utilities/promise-with-loader-and-messages';
import { ListReturnType } from '../../../services/queryable-base';
import queryable, { Query } from '../../../services/queryable';
import { falhaAoCarregar } from '../../../utilities/default-confirmation-messages';

interface EspecieState {
  especies: Especie[],
}

const initialState: EspecieState = {
  especies: [],
};

export const fetchEspecies = createAsyncThunk<
  ListReturnType<Especie>,
  undefined | Omit<Query<Especie>, 'service'>
>(
  'especies/list',
  async (params = {}, { dispatch }) => {
    const query = queryable.query({
      ...params,
      service: EspeciesService,
    });

    return promiseWithLoaderAndMessages(
      dispatch,
      query,
      { errorMessage: falhaAoCarregar('espécies') },
    );
  },
);

export const especiesSlice = createSlice({
  name: 'especie',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(fetchEspecies.fulfilled, (state, { payload }) => {
      state.especies = payload.data;
    });
  },
});

export const selectEspecieState = (state: RootState) => state.especiesReducer;

export default especiesSlice.reducer;
