import qs from 'qs';
import { keysToCamelCaseDeep } from '@agro1desenvolvimento/utils-js';
import { APIBase, loginService } from '@agro1desenvolvimento/apis-js-package';
import { ENV } from '../utilities/constants';
import { inBlacklist } from '../utilities/blacklist-camel-case';

class ErpApi extends APIBase {
  constructor() {
    super();

    let baseURL = 'http://localhost:3000';

    if (ENV === 'homologation') baseURL = 'https://app-homologacao.agro1.inf.br/api';
    else if (ENV === 'production') baseURL = 'https://app.agro1.inf.br/api';
    else if (ENV === 'cypress') baseURL = 'http://api:3000';

    this.initialize({ baseURL });

    this.request.interceptors.response.use((response) => {
      const contentType = response.headers['content-type'];
      if (!inBlacklist(response?.config?.url || '') && contentType?.includes('js')) {
        response.data = keysToCamelCaseDeep(response.data);
      }
      return response;
    });

    this.request.interceptors.request.use((request) => {
      if (request.method === 'get' && request.params && typeof request.params === 'object') {
        request.paramsSerializer = (params) => qs.stringify(params, { arrayFormat: 'brackets' });
      }

      request.headers = {
        ...loginService.requestHeaders(),
        ...request.headers,
      };

      return request;
    });
  }
}

export default new ErpApi();
