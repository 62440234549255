import dayjs from 'dayjs';
import { DAYJS_FORMATS } from './constants';

export const dateFormat = (data: string) => dayjs(data).format(DAYJS_FORMATS.default);
export const dateAmericanFormat = (date: Date) => dayjs(date).format(DAYJS_FORMATS.american);
export const formatWithoutTime = (data: string) => dayjs(data).format(DAYJS_FORMATS.withoutTime);
export const dateWithLastDayOfMonth = (data: Date) => dayjs(data).utc().endOf('month');

const iso8601Regex = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)((-(\d{2}):(\d{2})|Z)?)$/;
export const isISODate = (date: string) => iso8601Regex.test(date);
