import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../../../app/rootReducer';
import itensEstoque from '../../../services/estoque/itens';
import { promiseWithLoaderAndMessages } from '../../../utilities';
import queryable, { Query } from '../../../services/queryable';
import { ListReturnType } from '../../../services/queryable-base';
import { HeaderParams } from '../../../@types/headers';
import { falhaAoCarregar } from '../../../utilities/default-confirmation-messages';
import { Item } from '../../../@types/estoque/item';

interface ItensEstoqueState {
  itens: Item[],
  pagination: HeaderParams,
}

const initialState: ItensEstoqueState = {
  itens: [],
  pagination: {
    currentPage: undefined,
    totalRecords: undefined,
    maxPerPage: undefined,
    pages: undefined,
  },
};

export const fetchItensEstoque = createAsyncThunk<
  ListReturnType<Item>,
  Omit<Query<Item>, 'service'>
>(
  'itensEstoque/fetchItens',
  (params = {}, { dispatch }) => {
    const query = queryable.query({
      ...params,
      service: itensEstoque,
    });

    return promiseWithLoaderAndMessages(
      dispatch,
      query,
      { errorMessage: falhaAoCarregar('itens de estoque') },
    );
  },
);

export const itensEstoqueSlice = createSlice({
  name: 'itensEstoque',
  initialState,
  reducers: {
  },
  extraReducers(builder) {
    builder.addCase(fetchItensEstoque.fulfilled, (state, { payload }) => {
      state.itens = payload.data;
      state.pagination = payload.headers;
    });
  },
});

export const selectItensEstoqueState = (state: RootState) => state.itensEstoqueReducer;

export default itensEstoqueSlice.reducer;
