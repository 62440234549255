import { FC, useMemo, useState } from 'react';
import {
  DataTable,
  Column,
  ConfirmPopup,
  PaginatorPageState,
} from '@agro1desenvolvimento/react-components';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { join, map } from 'lodash';
import PessoasService from '../../../../services/geral/pessoas';
import { Pessoa } from '../../../../@types/geral/pessoa';
import { fetchPessoas, selectPessoaState } from '../pessoaSlice';
import DropwDownButton from '../../../../components/DropwDownButtons';
import { AuditoriaType } from '../../../../@types/enums';
import { usePagination, useScreenSize } from '../../../../hooks';
import Pagination from '../../../../components/Pagination';
import { promiseWithLoaderAndMessages } from '../../../../utilities';
import { toastExcludeMessages } from '../../../../utilities/default-confirmation-messages';
import ExpandedRowItemTemplate from '../../../../components/ExpandedRowItemTemplate';

const RowExpandedTemplate: FC<{ pessoa: Pessoa}> = ({ pessoa }) => {
  const { isSM, isXS } = useScreenSize();

  return (
    <div className="p-p-2 row-expanded">
      <ExpandedRowItemTemplate label="ID" value={pessoa.id} />
      <ExpandedRowItemTemplate label="Cpf/Cnpj" value={pessoa.cpfCnpj} />
      <ExpandedRowItemTemplate label="Tipo" value={pessoa.tipo} />
      <ExpandedRowItemTemplate label="Endereço" value={pessoa.endereco} />
      <ExpandedRowItemTemplate label="Inscrição Estadual" value={pessoa.inscricaoEstadual} />
      <ExpandedRowItemTemplate label="Funções" value={pessoa.funcoes} hidden={!(isSM || isXS)} />
      <ExpandedRowItemTemplate
        label="Produtor - Renasem"
        value={pessoa.dadosProdutor?.renasem}
        hidden={!pessoa.dadosProdutor?.renasem}
      />
      <ExpandedRowItemTemplate
        label="Laboratório - Renasem"
        value={pessoa.dadosLaboratorio?.renasem}
        hidden={!pessoa.dadosLaboratorio?.renasem}
      />
      <ExpandedRowItemTemplate
        label="Responsável Técnico - Renasem"
        value={pessoa.dadosTecnicoSementes?.renasem}
        hidden={!pessoa.dadosTecnicoSementes?.renasem}
      />
    </div>
  );
};

const ListPessoas: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { pessoas, pagination } = useSelector(selectPessoaState);
  const { maxPerPage = 1, totalRecords, pages } = pagination;
  const [selectedPessoa, setSelectedPessoa] = useState<Pessoa>();
  const [expandedRows, setExpandedRows] = useState<Pessoa[]>([]);
  const { goToPage, firstIndexOnPage } = usePagination({ maxPerPage });
  const { isSM, isXS } = useScreenSize();

  const pessoasMapped = useMemo(() => map(pessoas, (pessoa) => (
    {
      ...pessoa,
      funcoes: join(map(pessoa.funcoes, 'descricao'), ', '),
    }
  )),
  [pessoas]);

  const confirm = async (id: string) => {
    await promiseWithLoaderAndMessages(
      dispatch,
      PessoasService.delete(id),
      toastExcludeMessages('pessoa'),
    );
    setSelectedPessoa(undefined);
    dispatch(fetchPessoas());
  };

  const onPageChange = (e: PaginatorPageState) => goToPage(e.page + 1);

  const optionButtonId = (id: string | undefined) => `btn-option-${id}`;

  const renderBtnAcoes = (pessoa: Pessoa) => (
    <div>
      <DropwDownButton
        editAction={() => history.push(`/geral/pessoas/editar/${pessoa.id}`)}
        deleteAction={() => setSelectedPessoa(pessoa)}
        auditoriaAction={() => history.push(`/auditoria/${AuditoriaType.pessoa}/${pessoa.id}`)}
        optionButtonId={optionButtonId(pessoa.id)}
      />
    </div>
  );

  return (
    <>
      <ConfirmPopup
        target={document.getElementById(optionButtonId(selectedPessoa?.id)) || undefined}
        visible={!!selectedPessoa}
        onHide={() => setSelectedPessoa(undefined)}
        message="Deseja realmente excluir?"
        icon="pi pi-exclamation-triangle"
        accept={() => confirm(selectedPessoa!.id)}
      />

      <DataTable
        value={pessoasMapped}
        emptyMessage="Nenhum registro encontrado."
        responsiveLayout="scroll"
        rowExpansionTemplate={(pessoa) => <RowExpandedTemplate pessoa={pessoa} />}
        expandedRows={expandedRows}
        onRowToggle={(event) => setExpandedRows(event.data)}
      >
        <Column expander className="expander-column" />
        <Column header="Nome" showFilterMenu={false} field="nome" sortable bodyClassName="p-col-4" />
        <Column
          header="Cidade"
          body={(pessoa) => `${pessoa.cidade.descricao} - ${pessoa.cidade.uf.sigla}`}
          sortField="cidade.descricao"
          sortable
        />
        { !(isSM || isXS) && <Column header="Funções" field="funcoes" sortable /> }
        <Column header="Ações" body={renderBtnAcoes} bodyClassName="p-col-2" />
      </DataTable>

      <Pagination
        first={firstIndexOnPage}
        pages={pages}
        rows={maxPerPage}
        totalRecords={totalRecords}
        onPageChange={onPageChange}
        pageRecords={pessoas.length}
      />
    </>
  );
};

export default ListPessoas;
