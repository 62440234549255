import { Button, Dialog, InputText } from '@agro1desenvolvimento/react-components';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { createOrUpdateDashboard } from '../dashboardSlice';
import { Dashboard } from '../../../../@types/geral/dashboard';
import { CrudCreateUpdate } from '../../../../utilities';

const Footer: React.FC<
  { onCancel: () => void, onConfirm: () => void, valid: boolean, loading: boolean }
> = ({
  onCancel, onConfirm, valid, loading,
}) => (
  <>
    <Button label="Cancelar" icon="pi pi-times" onClick={onCancel} className="p-button-text" disabled={loading} />
    <Button label="Salvar" disabled={!valid} icon="pi pi-check" onClick={onConfirm} loading={loading} />
  </>
);

const DialogSaveChart: React.FC<PropTypes> = ({
  onHide,
  visible,
  item,
  afterSave,
}) => {
  const [dashboardTitle, setDashboardTile] = useState('');
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const save = async () => {
    try {
      const { meta: { requestStatus } } = await dispatch(createOrUpdateDashboard({
        ...item,
        titulo: dashboardTitle,
      }));

      if (requestStatus === 'fulfilled') afterSave();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setDashboardTile(item.titulo);
  }, [item.titulo]);

  return (
    <Dialog
      visible={visible}
      onHide={onHide}
      header="Salvar gráfico"
      footer={(
        <Footer
          onCancel={onHide}
          onConfirm={save}
          valid={!!dashboardTitle}
          loading={loading}
        />
      )}
    >
      <InputText
        value={dashboardTitle}
        onChange={({ currentTarget: { value } }) => setDashboardTile(value)}
      />
    </Dialog>
  );
};

interface PropTypes {
  visible: boolean,
  onHide: () => void,
  item: CrudCreateUpdate<Dashboard>,
  afterSave: () => void
}

export default DialogSaveChart;
