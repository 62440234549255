import { keysToSnakeCaseDeep } from '@agro1desenvolvimento/utils-js';
import { loginService } from '@agro1desenvolvimento/apis-js-package';
import { CampoProducao } from '../../@types/sementes/campoProducao';
import { PessoaProdutor } from '../../@types/geral/pessoa';
import { Safra } from '../../@types/geral/safra';
import api from '../erp-api';
import CrudBase from '../crud-base';
import responseHeaders from '../../utilities/response-headers';
import { HeaderParams } from '../../@types/headers';
import { CrudCreateUpdate } from '../../utilities';

export interface SyncPayload {
  periodo: string,
  especieId: string,
  safraLocalId: string,
  produtorId: string,
}

export interface CampoProducaoListReturnType {
  data: CampoProducao[];
  headers: HeaderParams;
}

class CamposProducaoService extends CrudBase<CampoProducao> {
  get endpoint() {
    return `/${loginService.scope}/sementes/campos_producao`;
  }

  async update(campoProducao: Partial<CrudCreateUpdate<CampoProducao>>) {
    return (await api.patch<CampoProducao>(`${this.endpoint}/${campoProducao.id}`, { area: campoProducao.area })).data;
  }

  async list(safra: Safra, produtor: PessoaProdutor, page: number):
    Promise<CampoProducaoListReturnType> {
    const { data, headers } = await api.get<CampoProducao[]>(this.endpoint, {
      params: {
        safra: safra.id,
        produtor: produtor.id,
        page,
      },
    });

    return { headers: responseHeaders(headers), data };
  }

  async sync(payload: SyncPayload) {
    const { data } = await api.post(`${this.endpoint}/sync_sigef`, keysToSnakeCaseDeep(payload));
    return data;
  }
}

export default new CamposProducaoService();
