import { combineReducers } from '@reduxjs/toolkit';

import sideBarReducer from '../components/Sidebar/sidebarSlice';
import topBarReducer from '../components/Topbar/topBarSlice';
import filterReducer from '../components/Filter/filterSlice';
import camposProducaoReducer from '../features/sementes/CamposProducao/camposProducaoSlice';
import ubsReducer from '../features/sementes/Ubs/ubsSlice';
import armazemReducer from '../features/sementes/Armazem/armazemSlice';
import lotesReducer from '../features/sementes/Lote/lotesSlice';
import localizacaoReducer from '../features/sementes/Localizacao/localizacaoSlice';
import toastReducer from '../components/Agro1Toast/toast';
import planejamentosReducer from '../features/sementes/Planejamento/planejamentosSlice';
import loaderReducer from '../components/Loader/loaderSlice';
import unidadesReducer from '../features/Estoque/Unidade/unidadesSlice';
import especiesReducer from '../features/geral/Especies/especiesSlice';
import depositosReducer from '../features/Estoque/Depositos/depositosSlice';
import itensEstoqueReducer from '../features/Estoque/Item/itensEstoqueSlice';
import peneirasReducer from '../features/sementes/Peneira/peneiraSlice';
import equipamentosReducer from '../features/laboratorio/Equipamento/equipamentoSlice';
import periodosReducer from '../features/geral/Periodos/periodosSlice';
import tiposEmbalagensReducer from '../features/sementes/TiposEmbalagens/tiposEmbalagensSlice';
import templateScriptReducer from '../features/Relatorio/TemplateScript/templateScriptSlice';
import tipoAnalisesReducer from '../features/sementes/Lote/Amostras/Analises/analiseSlice';
import auditoriasReducer from '../features/geral/Auditorias/auditoriasSlice';
import dashboardReducer from '../features/geral/Dashboard/dashboardSlice';
import relatorioTemplateReducer from '../features/Relatorio/RelatorioTemplate/relatorioTemplateslice';
import cultivarReducer from '../features/Estoque/Cultivar/cultivaresSlice';
import produtoReducer from '../features/sementes/Subproduto/produtosSlice';
import obtentoresReducer from '../features/sementes/Obtentores/obtentoresSlice';
import pessoasReducer from '../features/geral/Pessoas/pessoaSlice';
import cidadesReducer from '../features/geral/Cidades/cidadeSlice';
import descartesReducer from '../features/sementes/Subproduto/descartesSlice';
import novoDescarteReducer from '../features/sementes/Subproduto/Novo/novoDescarteSlice';
import reembalagensReducer from '../features/sementes/Reembalagem/reembalagemSlice';
import newReembalagensReducer from '../features/sementes/Reembalagem/Novo/newReembalagemSlice';
import confirmacaoReembalagemReducer from '../features/sementes/Reembalagem/Confirmacao/confirmacaoReembalagemSlice';
import newEditPlanejamentoReducer from '../features/sementes/Planejamento/newEditPlanejamentoSlice';
import configReducer from '../features/sementes/Config/configSlice';
import vinculoLotesCamposProducaoReducer from '../features/sementes/VinculoLotesCamposProducao/VinculoLotesCamposProducaoSlice';

const rootReducer = combineReducers({
  cultivarReducer,
  produtoReducer,
  descartesReducer,
  novoDescarteReducer,
  sideBarReducer,
  topBarReducer,
  camposProducaoReducer,
  ubsReducer,
  itensEstoqueReducer,
  obtentoresReducer,
  lotesReducer,
  toastReducer,
  planejamentosReducer,
  newEditPlanejamentoReducer,
  loaderReducer,
  unidadesReducer,
  especiesReducer,
  depositosReducer,
  peneirasReducer,
  periodosReducer,
  tiposEmbalagensReducer,
  templateScriptReducer,
  tipoAnalisesReducer,
  auditoriasReducer,
  relatorioTemplateReducer,
  filterReducer,
  dashboardReducer,
  armazemReducer,
  localizacaoReducer,
  configReducer,
  vinculoLotesCamposProducaoReducer,
  reembalagensReducer,
  newReembalagensReducer,
  confirmacaoReembalagemReducer,
  equipamentosReducer,
  pessoasReducer,
  cidadesReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
