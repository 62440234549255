import { Button, Dialog } from '@agro1desenvolvimento/react-components';
import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Lote } from '../../../../@types/sementes/lote';
import { reabrir } from '../lotesSlice';
import { transitionOptionsTimeout as timeout } from '../../../../utilities';

const ReabrirLoteConfirmationModal: FC<{
  onExit: () => void, onHide: () => void, visible: boolean, lote: Lote | undefined
}> = ({
  lote, visible, onHide, onExit,
}) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const hide = () => setShow(false);

  useEffect(() => {
    setShow(visible);
  }, [visible]);

  const reabrirLote = async () => {
    await dispatch(reabrir(lote!));
    hide();
    onExit();
  };

  if (!lote) return <></>;

  return (
    <Dialog
      onHide={hide}
      transitionOptions={{ timeout, onExited: onHide }}
      visible={show}
      header="Reabrir lote"
      footer={(
        <>
          <Button label="Cancelar" icon="pi pi-times" onClick={hide} className="p-button p-button-text p-m-0" />
          <Button label="Reabrir" icon="pi pi-check" onClick={reabrirLote} className="p-button p-button-success p-m-0" />
        </>
      )}
      closeOnEscape={false}
    >
      <div className="p-row">
        <p>Deseja reabrir o lote {lote!.numeroLote}?</p>
        Ao fazer isso, será necessário remover o lote de seu ERP e integrá-lo novamente.
      </div>
    </Dialog>
  );
};

export default ReabrirLoteConfirmationModal;
