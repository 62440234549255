import { join, orderBy } from 'lodash';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useIsMobile } from '@agro1desenvolvimento/react-hooks';
import { Column, DataTable, Panel } from '@agro1desenvolvimento/react-components';
import { Lote } from '../../../../../@types/sementes/lote';
import { selectNewReembalagensState } from '../newReembalagemSlice';
import { formatNumber } from '../../../../../utilities';
import { useScreenSize } from '../../../../../hooks';

const LotesParaReembalagem = () => {
  const { lotesComLocalizacao } = useSelector(selectNewReembalagensState);
  const isMobile = useIsMobile();
  const { isXL, isXXL, isXS } = useScreenSize();
  const lotesWithLocalizacao = useMemo(() => orderBy(lotesComLocalizacao.map((localizacao) => {
    const { lote, armazem, itemCroqui } = localizacao;
    return (
      {
        ...lote,
        ...localizacao,
        localizacao: armazem ? join([armazem.descricao, itemCroqui.descricao], ' - ') : '',
        pesoTotalKg: lote.pesoEmbalagem
          ? localizacao.quantidade * lote.pesoEmbalagem : lote.pesoTotalKg,
      }
    );
  }), 'numeroLote'), [lotesComLocalizacao]);

  return (
    <Panel
      className="p-mt-3"
      header={<h3 className="p-mb-0">Lotes de origem</h3>}
    >
      <DataTable dataKey="id" id="lotesOrigem" value={lotesWithLocalizacao} className="p-datatable-gridlines" emptyMessage="Nenhum lote selecionado" responsiveLayout="scroll">
        <Column field="numeroLote" header={(isXS) ? 'Nº' : 'Número'} sortable bodyClassName="cy-numero-lote" />
        <Column field="unidade.abreviatura" header={(isXL || isXXL) ? 'Unidade' : 'Und.'} sortable bodyClassName="cy-unidade" />
        { isMobile || <Column body={(lote: Lote) => formatNumber(lote.quantidade, 1)} header="Quantidade" sortable sortField="quantidade" bodyClassName="cy-quantidade" /> }
        <Column body={(lote: Lote) => formatNumber(lote.pesoTotalKg || 0, 1)} header="Peso Total (kg)" sortable sortField="pesoTotalKg" bodyClassName="cy-peso-total" />
        <Column field="localizacao" header={(isXS) ? 'Local' : 'Localização'} sortable bodyClassName="cy-localizacao" />
        { isXS || <Column field="deposito.descricao" header="Depósito" sortable bodyClassName="cy-deposito" /> }
      </DataTable>
    </Panel>
  );
};

export default LotesParaReembalagem;
