import { FC, ReactElement, useMemo } from 'react';
import { times } from 'lodash';
import { Croqui, MatrizCroqui } from '../../@types/sementes/croqui';
import { CroquiItem } from '../../@types/sementes/croquiItem';
import CroquiSketchItem, { OnClickInCroquiItem } from './CroquiSketchItem';
import CroquiService from '../../services/estoque/croqui';

const CroquiSketch: FC<CroquiSketchProps> = ({
  croqui, onItemClick, itemClassName, itemTooltip, matriz: propMatriz,
}) => {
  const matriz = useMemo(
    () => propMatriz || CroquiService.getMatriz(croqui),
    [propMatriz, croqui],
  );

  return (
    <div className="croqui-sketch p-flex-column" style={{ gridTemplateColumns: `repeat(${croqui.quantidadeColunas + 1}, auto)` }}>
      <div className="line">
        <div className="matriz-info" />
        {times(
          croqui.quantidadeColunas,
          (index) => (
            <div className="matriz-info" key={`indication-${index}`}>
              {index + 1}
            </div>
          ),
        )}
      </div>

      {matriz.map((line, xIndex) => (
        // eslint-disable-next-line react/no-array-index-key
        <div className="line" key={`line-${xIndex}`}>
          <div className="matriz-info">
            {xIndex + 1}
          </div>

          {line.map((lineItem, yIndex) => (
            <CroquiSketchItem
              // eslint-disable-next-line react/no-array-index-key
              key={`line-'${xIndex}-${yIndex}`}
              item={lineItem}
              xIndex={xIndex}
              yIndex={yIndex}
              onClick={(param) => { if (onItemClick) onItemClick(param); }}
              className={itemClassName && itemClassName(lineItem)}
              tooltip={itemTooltip && itemTooltip(lineItem)}
            />
          ))}
        </div>
      ))}
    </div>
  );
};

interface CroquiSketchProps {
  croqui: Croqui;
  onItemClick?: OnClickInCroquiItem;
  itemClassName?: (item: CroquiItem | undefined) => string | undefined;
  itemTooltip?: (item: CroquiItem | undefined) => ReactElement | undefined
  matriz?: MatrizCroqui
}

export default CroquiSketch;
