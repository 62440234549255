class Agro1Error extends Error {
  static produtorOrSafraNotSelected() {
    return new Agro1Error('Produtor ou safra não selecionados');
  }

  static produtorNotSelected() {
    return new Agro1Error('Produtor não selecionado');
  }

  static safraNotSelected() {
    return new Agro1Error('Safra não selecionada');
  }

  static notImplemented() {
    return new Agro1Error('Não implementado');
  }
}

export default Agro1Error;
