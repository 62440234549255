/* eslint-disable import/prefer-default-export */

import { AnaliseTipo } from '../@types/sementes/analises';

export const AnaliseTipos: AnaliseTipo[] = [
  {
    key: 'germinacao',
    value: 'Germinação',
  },
  {
    key: 'vigor',
    value: 'Vigor',
  },
  {
    key: 'pureza',
    value: 'Pureza',
  },
  {
    key: 'pms',
    value: 'PMS',
  },
  {
    key: 'tetrazolio',
    value: 'Tetrazólio',
  },
  {
    key: 'dosn',
    value: 'DOSN',
  },
];
