import { Column, DataTable } from '@agro1desenvolvimento/react-components';
import { sumBy } from 'lodash';
import { useMemo } from 'react';
import { Lote } from '../../../../@types/sementes/lote';
import { useScreenSize } from '../../../../hooks';
import { CrudCreateUpdateLote } from '../../../../services/sementes/lotes';
import { formatNumber } from '../../../../utilities';

const ListaLotes = (
  {
    lotes,
    header,
    footer,
    actionButtons,
  } : {
    lotes: undefined | (Lote | CrudCreateUpdateLote)[],
    header?: React.ReactNode,
    footer?: boolean,
    actionButtons?: (lote: Lote) => JSX.Element,
  },
) => {
  const sumPeso = useMemo(() => sumBy(lotes, 'pesoTotalKg'), [lotes]);
  const { isXS, isXL, isXXL } = useScreenSize();

  return (
    <DataTable
      id="novosLotes"
      dataKey="id"
      value={lotes}
      className="p-datatable-gridlines "
      emptyMessage="Nenhum registro encontrado."
      header={header}
      responsiveLayout="scroll"
    >
      <Column field="numeroLote" header={(isXS) ? 'Nº' : 'Nº do lote'} sortable bodyClassName="cy-numero-lote" />
      <Column field="unidade.abreviatura" header={(isXL || isXXL) ? 'Unidade' : 'Und.'} sortable bodyClassName="cy-unidade" />
      <Column body={(lote: Lote) => formatNumber(lote.quantidade, 1)} header={(isXL || isXXL) ? 'Quantidade' : 'Qtd.'} sortable sortField="quantidade" bodyClassName="cy-quantidade" />
      <Column
        body={(lote: Lote) => formatNumber(lote.pesoTotalKg || 0, 1)}
        header="Peso Total (kg)"
        sortable
        sortField="pesoTotalKg"
        bodyClassName="cy-peso-total"
        footer={footer && formatNumber(sumPeso, 1)}
      />
      { actionButtons && <Column header="Ações" body={actionButtons} className="p-text-center col-actions" data-test="buttonActions" /> }
    </DataTable>
  );
};

export default ListaLotes;
