import { useRef } from 'react';
import { Button, OrderList, OverlayPanel } from '@agro1desenvolvimento/react-components';
import { OrderUpdater, QueryBuilderRenderProps } from '@cubejs-client/react';
import { QueryOrder, TOrderMember } from '@cubejs-client/core';
import classNames from 'classnames';

const orderOptions: { title: string, value: 'none' | QueryOrder }[] = [
  {
    title: 'Desabilitado',
    value: 'none',
  },
  {
    title: 'Crescente',
    value: 'asc',
  },
  {
    title: 'Decrescente',
    value: 'desc',
  },
];

const getNextOrderOption = (current: typeof orderOptions[number]) => {
  const nextIndex = orderOptions.indexOf(current) + 1;
  return orderOptions[nextIndex in orderOptions ? nextIndex : 0];
};

const itemTemplate = (updateOrder: OrderUpdater) => (item: TOrderMember) => {
  const currentOption = orderOptions.find((v) => v.value === item.order)!;

  return (
    <>
      {item.title}
      <Button
        className={
          classNames(
            { 'p-button-secondary': currentOption.value === 'none' },
            'p-py-0 p-px-1 p-button-raised p-button-text p-ml-2',
          )
        }
        onClick={() => updateOrder.set(item.id, getNextOrderOption(currentOption).value)}
        label={currentOption.title}
      />
    </>
  );
};

const OrderGroup: React.FC<
  Pick<QueryBuilderRenderProps, 'orderMembers' | 'updateOrder'>
> = ({ orderMembers, updateOrder }) => {
  const ref = useRef<OverlayPanel>(null);

  return (
    <div className="p-col">
      <Button
        label="Ordenação"
        onClick={(e) => ref.current?.toggle(e, e.currentTarget)}
        className="p-button-outlined p-mt-1 p-button-plain"
        style={{ height: '85%', width: '100%', backgroundColor: '#fff' }}
        disabled={!orderMembers.length}
      />
      <OverlayPanel ref={ref}>
        <OrderList
          value={orderMembers}
          itemTemplate={itemTemplate(updateOrder)}
          onChange={({ value }: { value: TOrderMember[] }) => {
            value.forEach((v, destinationIndex) => {
              const sourceIndex = orderMembers.indexOf(v);

              if (sourceIndex !== destinationIndex) {
                updateOrder.reorder(sourceIndex, destinationIndex);
              }
            });
          }}
        />

      </OverlayPanel>
    </div>
  );
};

export default OrderGroup;
