import { loginService } from '@agro1desenvolvimento/apis-js-package';
import { keysToSnakeCase } from '@agro1desenvolvimento/utils-js';
import api from '../erp-api';
import CrudBase from '../crud-base';
import { TemplateScript } from '../../@types/relatorio/script';
import { CrudCreateUpdate } from '../../utilities';

class ScriptService extends CrudBase<TemplateScript> {
  get endpoint() {
    return `/${loginService.scope}/relatorio/scripts`;
  }

  async create(script: CrudCreateUpdate<TemplateScript>) {
    return (await api.post<TemplateScript[]>(
      this.endpoint,
      {
        scripts: [keysToSnakeCase(script)],
      },
    )).data[0];
  }

  async update(script: CrudCreateUpdate<TemplateScript>) {
    return (await api.patch<TemplateScript>(
      `${this.endpoint}/${script.id}`, keysToSnakeCase(script),
    )).data;
  }
}

export default new ScriptService();
