import {
  find, isNil, List, ListIterateeCustom,
} from 'lodash';

export default <T>(
  collection: List<T> | null | undefined,
  predicate?: ListIterateeCustom<T, boolean>,
  fromIndex?: number,
) => {
  const result = find(collection, predicate, fromIndex);

  if (isNil(result)) throw new Error('Don`t found');

  return result as NonNullable<T>;
};
