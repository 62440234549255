import React, { useRef, useState } from 'react';
import { Card, ConfirmPopup } from '@agro1desenvolvimento/react-components';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import DropwDownButtons from '../../../../components/DropwDownButtons';
import { deleteDashboard } from '../dashboardSlice';

const CardHeader: React.FC<CardHeaderPropsType> = ({ title, itemId, onDeleted }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [deleteConfirmationVisible, setDeleteConfirmationVisible] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const onConfirmDelete = async () => {
    const { meta: { requestStatus } } = await dispatch(deleteDashboard(itemId));

    if (requestStatus === 'fulfilled') onDeleted();
  };

  return (
    <div
      className="p-d-flex p-pl-4 p-pr-3 p-pt-3 item-header"
      ref={containerRef}
    >
      <ConfirmPopup
        target={containerRef.current || undefined}
        visible={deleteConfirmationVisible}
        onHide={() => setDeleteConfirmationVisible(false)}
        message="Realmente deseja excluir?"
        icon="pi pi-exclamation-triangle"
        accept={onConfirmDelete}
      />
      <h4>{title}</h4>
      <DropwDownButtons
        deleteAction={() => setDeleteConfirmationVisible(true)}
        editAction={() => history.push(`/dashboard/editar/${itemId}`)}
        menuButtonProps={{ className: 'p-button-outlined p-button-plain' }}
      />
    </div>
  );
};

const DashboardItem: React.FC<DashboardItemPropsType> = (
  {
    itemId, children, title, onDeleted,
  },
) => (
  <Card
    className="p-d-flex p-flex-column dashboard-item"
    header={<CardHeader title={title} itemId={itemId} onDeleted={onDeleted} />}
  >
    {children}
  </Card>
);

interface DashboardItemPropsType {
  title: React.ReactNode,
  itemId: string,
  onDeleted: () => void
}

interface CardHeaderPropsType {
  title: React.ReactNode,
  itemId: string,
  onDeleted: () => void
}

export default DashboardItem;
