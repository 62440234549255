import {
  AnyAction,
  createAsyncThunk,
  createSlice,
  PayloadAction,
  ThunkDispatch,
} from '@reduxjs/toolkit';
import { flatten } from 'lodash';
import { PessoaProdutor } from '../../../@types/geral/pessoa';
import { Safra } from '../../../@types/geral/safra';
import { HeaderParams } from '../../../@types/headers';
import { Planejamento } from '../../../@types/sementes/planejamento';
import type { RootState } from '../../../app/rootReducer';
import { showMessage } from '../../../components/Agro1Toast/toast';
import { FilterState } from '../../../components/Filter/filterSlice';
import { ListReturnType } from '../../../services/queryable-base';
import queryable from '../../../services/queryable';
import PlanejamentosService from '../../../services/sementes/planejamentos';
import { CrudCreateUpdate } from '../../../utilities/crud';
import { excluidoComSucesso, falhaAoCarregar, salvoComSucesso } from '../../../utilities/default-confirmation-messages';
import promiseWithLoaderAndMessages from '../../../utilities/promise-with-loader-and-messages';

interface PlanejamentoState {
  planejamentos: Planejamento[],
  planejamentosSelecionados: Planejamento[],
  pagination: HeaderParams,
}

const initialState: PlanejamentoState = {
  planejamentos: [],
  planejamentosSelecionados: [],
  pagination: {
    currentPage: undefined,
    totalRecords: undefined,
    maxPerPage: undefined,
    pages: undefined,
  },
};

interface FetchPlanejamentosParams {
  page: string,
  filter: FilterState['planejamentos'],
  produtor: PessoaProdutor,
  safra: Safra
}

export const fetchPlanejamentos = createAsyncThunk<
  ListReturnType<Planejamento>,
  FetchPlanejamentosParams
>(
  'planejamentos/list',
  ({
    page, filter, produtor, safra,
  }, { dispatch }) => {
    const query = queryable.query({
      conditions: flatten(Object.values(filter.conditions)),
      sorts: filter.sort ? [filter.sort] : undefined,
      extraParameters: {
        produtor: produtor.id,
        safra: safra.id,
        page,
      },
      service: PlanejamentosService,
    });

    return promiseWithLoaderAndMessages(
      dispatch,
      query,
      { errorMessage: falhaAoCarregar('planejamentos') },
    );
  },
);

const showError = (
  { error, alternativeMessage, dispatch }:
    {
      error: any,
      alternativeMessage: string,
      dispatch: ThunkDispatch<unknown, unknown, AnyAction>
    },
) => {
  dispatch(showMessage({
    severity: 'error',
    summary: 'Erro',
    detail: error.response?.status === 400 && typeof error.response.data === 'string' ? error.response.data : alternativeMessage,
  }));
  throw error;
};

export const deletePlanejamento = createAsyncThunk(
  'planejamentos/delete',
  async ({ id }: { id: string }, { dispatch }) => {
    try {
      return await promiseWithLoaderAndMessages(
        dispatch,
        PlanejamentosService.delete(id),
        {
          successMessage: excluidoComSucesso('planejamento'),
        },
      );
    } catch (error) {
      showError({ error, alternativeMessage: 'Falha ao excluir planejamento', dispatch });
    }
  },
);

export const createOrUpdatePlanejamento = createAsyncThunk(
  'planejamentos/createOrUpdate',
  async (planejamento: Partial<CrudCreateUpdate<Planejamento>>, { dispatch }) => {
    try {
      return await promiseWithLoaderAndMessages(
        dispatch,
        PlanejamentosService.createOrUpdate(planejamento),
        {
          successMessage: salvoComSucesso('planejamento'),
        },
      );
    } catch (error) {
      showError({ error, alternativeMessage: 'Falha ao salvar planejamento', dispatch });
    }
  },
);

export const planejamentosSlice = createSlice({
  name: 'planejamentos',
  initialState,
  reducers: {
    setPlanejamentosSelecionados: (state, action: PayloadAction<Planejamento[]>) => {
      state.planejamentosSelecionados = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchPlanejamentos.pending, (state) => {
      state.planejamentosSelecionados = [];
    });
    builder.addCase(fetchPlanejamentos.fulfilled, (state, { payload }) => {
      state.planejamentos = payload.data;
      state.pagination = payload.headers;
    });
  },
});

export const selectPlanejamentoState = (state: RootState) => state.planejamentosReducer;

export const { setPlanejamentosSelecionados } = planejamentosSlice.actions;

export default planejamentosSlice.reducer;
