import { loginService } from '@agro1desenvolvimento/apis-js-package';
import { keysToSnakeCaseDeep } from '@agro1desenvolvimento/utils-js';
import _, { map } from 'lodash';
import dayjs from 'dayjs';
import api from '../erp-api';
import { Lote } from '../../@types/sementes/lote';
import { CrudCreateUpdate } from '../../utilities/crud';
import GeradorService from '../relatorio/gerador';
import CrudBase from '../crud-base';
import { HeaderParams } from '../../@types/headers';
import { LotesCamposSimplified } from '../../@types/sementes/descarte';
import type { PlanejamentoLoteFormFields } from '../../features/sementes/Lote/EditLoteForm';
import unidadesService from '../estoque/unidades';
import { TipoEmbalagem } from '../../@types/enums';

export interface LotesListReturnType {
  data: Lote[];
  headers: HeaderParams;
}

class LotesService extends CrudBase<Lote> {
  get endpoint() {
    return `/${loginService.scope}/sementes/lotes`;
  }

  async listAll(params?: any) {
    const { data, ...rest } = await super.listAll(params);

    return { ...rest, data: data.map(this.responseSerializer) };
  }

  async find(id: string) {
    return this.responseSerializer(await super.find(id));
  }

  etiquetas(loteIds: Array<string>, id: string) {
    return GeradorService.gerar('etiqueta', { loteIds }, id);
  }

  async pesoEmbalagem(unidadeID: string, pms?: number) {
    const { data: { pesoEmbalagem } } = await api.get<{ pesoEmbalagem: string }>(
      `${this.endpoint}/peso_embalagem`,
      { params: { unidade: unidadeID, pms } },
    );

    return +pesoEmbalagem;
  }

  async create(lote: CrudCreateUpdateLote) {
    const lotes = [this.toSnakeCaseDeep(lote)];
    return this.responseSerializer((await api.post<Lote[]>(this.endpoint, { lotes })).data[0]);
  }

  async createBatch(lotes: CrudCreateUpdateLote[]) {
    const createdLote = (
      await api.post<Lote[]>(this.endpoint, { lotes: lotes.map(this.toSnakeCaseDeep) })
    ).data;

    return createdLote.map(this.responseSerializer);
  }

  async update(lote: CrudCreateUpdateLote) {
    const { data } = await api.patch<Lote>(`${this.endpoint}/${lote.id}`, this.toSnakeCaseDeep(lote));
    return this.responseSerializer(data);
  }

  async vinculaLotesCampos(lotesCampos: LotesCamposSimplified[]) {
    const lotesWithCampos = map(lotesCampos, (loteCampo) => (
      { ...loteCampo.lote, campoProducaoIds: map(loteCampo.campos, 'id') }
    ));

    const lotesSnakeCase = keysToSnakeCaseDeep(lotesWithCampos);
    const lotesSaved = (await api.post<Lote[]>(this.endpoint, { lotes: lotesSnakeCase })).data;
    return lotesSaved.map(this.responseSerializer);
  }

  async reabrir(lote: Lote) {
    const { data } = await api.get<Lote>(`${this.endpoint}/${lote.id}/reabrir`);
    return this.responseSerializer(data);
  }

  toSnakeCaseDeep = (lote: CrudCreateUpdateLote): CrudCreateUpdateLote => (
    keysToSnakeCaseDeep({
      numeroLote: lote.numeroLote,
      cultivarId: lote.cultivar?.id,
      safraId: lote.safra?.id,
      peneiraId: lote.peneira?.id,
      dataLote: lote.dataLote,
      unidadeId: lote.unidade?.id,
      quantidade: lote.quantidade,
      pesoTotalKg: (lote.pesoEmbalagem || 0) * (lote.quantidade || 0),
      pesoEmbalagem: lote.pesoEmbalagem,
      pesoEmbalagemReal: lote.pesoEmbalagem,
      observacao: lote.observacao,
      fornecedorId: lote.fornecedor?.id,
      status: lote.status,
      pms: lote.pms,
      ubsId: lote.ubs?.id,
      periodo: lote.periodo,
      dataValidade: lote.dataValidade,
      planejamentoId: lote.planejamento?.id,
      campoProducaoIds: lote?.campoProducaoIds,
    })
  );

  toEdit(lote: Lote, tipoEmbalagem = ''): PlanejamentoLoteFormFields {
    return {
      id: lote.id,
      numeroLote: lote.numeroLote,
      dataLote: lote.dataLote,
      periodo: lote.periodo || undefined,
      status: lote.status,
      especieId: lote.especieId,
      pesoEmbalagem: lote.pesoEmbalagem || 0,
      pesoLote: lote.pesoTotalKg || 0,
      pms: lote.pms || 0,
      quantidade: lote.quantidade || 0,
      peneira: lote.peneira?.id,
      unidade: lote.unidade.id,
      tipoEmbalagem,
      validade: dayjs(lote.dataValidade).toDate(),
      cultivar: lote.cultivar,
      ubs: lote.ubs,
    };
  }

  toDuplicate(lote: Lote, tipoEmbalagem = '') {
    return {
      ...this.toEdit(lote, tipoEmbalagem),
      id: '',
      numeroLote: '',
      dataLote: new Date().toISOString(),
    };
  }

  async fetchTipoEmbalagem(lote: Lote) {
    const unidades = await unidadesService.listCompativeis(String(lote.unidade.id));
    const hasUnidadeKg = !!unidades.find((unidade) => unidade.abreviatura === 'KG');

    return hasUnidadeKg ? TipoEmbalagem.peso : TipoEmbalagem.quantidade;
  }

  responseSerializer(lote: Lote): Lote {
    const serializeNumbers = (toSerialize: Lote): Lote => {
      const numbers = _(toSerialize)
        .pick('quantidade', 'pesoTotalKg', 'pesoEmbalagem', 'pesoEmbalagemReal', 'pms')
        .mapValues((v) => v && +v)
        .value() as Partial<Lote>;

      return { ...toSerialize, ...numbers };
    };

    const loteOriginal = serializeNumbers(lote);
    const lotesDestino = map(lote.lotesDestino, serializeNumbers);
    const lotesOrigem = map(lote.lotesOrigem, serializeNumbers);

    return { ...loteOriginal, lotesOrigem, lotesDestino };
  }
}

export type CrudCreateUpdateLote = CrudCreateUpdate<LoteWithCampos>;
interface LoteWithCampos extends Lote {
  campoProducaoIds?: string[];
}

export default new LotesService();
