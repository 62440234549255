import { Button, Toolbar } from '@agro1desenvolvimento/react-components';
import { useHistory, useRouteMatch } from 'react-router-dom';

const Header = () => {
  const history = useHistory();
  const matchNewUrl = useRouteMatch('/sementes/subproduto/descarte/simulacao');

  return (
    <>
      <Toolbar
        left={(
          <>
            { matchNewUrl?.isExact
              ? (
                <Button
                  label="Voltar"
                  icon="pi pi-angle-left"
                  className="p-ml-auto"
                  onClick={() => history.push('/sementes/subproduto')}
                />
              )
              : (
                <Button
                  label="Novo"
                  icon="pi pi-plus"
                  className="p-ml-1 p-button-success"
                  onClick={() => history.push('/sementes/subproduto/descarte/simulacao')}
                />
              )}
          </>
        )}
      />
    </>
  );
};

export default Header;
