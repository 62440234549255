import {
  Dropdown,
} from '@agro1desenvolvimento/react-components';
import { useIsMobile } from '@agro1desenvolvimento/react-hooks';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectTopBarState,
  setCurrentSafra,
  setCurrentProdutor,
} from './topBarSlice';

const RightContents = () => {
  const dispatch = useDispatch();
  const {
    safras,
    currentSafra,
    produtores,
    currentProdutor,
  } = useSelector(selectTopBarState);
  const isMobile = useIsMobile();

  return (
    <div className="layout-topbar-icons">
      {isMobile
        || (
          <>
            <span className="layout-topbar-dropdown p-mr-2">
              <Dropdown
                id="selectProdutor"
                value={currentProdutor?.id}
                onChange={(event) => dispatch(setCurrentProdutor(event.value))}
                options={produtores}
                optionValue="id"
                optionLabel="nomeComCidade"
                filterBy="nome"
                filter
                placeholder="Produtor"
                required
                appendTo={document.body}
              />
            </span>
            <span className="layout-topbar-dropdown">
              <Dropdown
                id="selectSafra"
                value={currentSafra?.id}
                onChange={(event) => dispatch(setCurrentSafra(event.value))}
                options={safras}
                optionValue="id"
                optionLabel="descricao"
                filterBy="descricao"
                filter
                placeholder="Safra"
                required
                appendTo={document.body}
              />
            </span>
          </>
        )}

    </div>
  );
};

export default RightContents;
