import React, {
  FC,
  useRef,
  useMemo,
  useState,
} from 'react';
import {
  Dialog,
  InputText,
  Button,
  Messages,
} from '@agro1desenvolvimento/react-components';
import classNames from 'classnames';
import * as Yup from 'yup';
import { ptForm } from 'yup-locale-pt';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Ubs } from '../../../@types/sementes/ubs';
import { createOrUpdateUbs } from './ubsSlice';
import catchApiErrorsAndSetFormErrors from '../../../utilities/catch-api-errors';
import ShowErrorHelper from '../../../components/ShowErrorHelper';
import { transitionOptionsTimeout as timeout } from '../../../utilities';

const Footer: React.FC<{close: () => void, valid: boolean}> = ({ close, valid }) => (
  <div>
    <Button label="Cancelar" icon="pi pi-times" onClick={close} className="p-button-text" />
    <Button label="Salvar" disabled={!valid} icon="pi pi-check" autoFocus type="submit" form="form-save-ubs" />
  </div>
);

Yup.setLocale(ptForm);
const UbsSchema = Yup.object().shape({
  descricao: Yup.string().required(),
  produtor: Yup.object().required(),
});

interface FormularioProps {
  ubs: Ubs
}

const FormularioUbs: FC<FormularioProps> = ({ ubs }) => {
  const messages = useRef<Messages>(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const [modalVisible, setModalVisible] = useState(true);
  const close = () => {
    history.push('/sementes/ubs');
  };

  const fechaModal = () => {
    setModalVisible(false);
  };

  const formik = useFormik({
    validationSchema: UbsSchema,
    initialValues: {
      id: ubs.id,
      descricao: ubs.descricao,
      produtor: ubs.produtor,
    },

    onSubmit: async (values) => {
      const promise = await dispatch(createOrUpdateUbs(values));
      const success = promise.meta.requestStatus === 'fulfilled';
      if (success) {
        fechaModal();
      } else {
        catchApiErrorsAndSetFormErrors(formik.setFieldError, promise.payload, true);
      }
    },
  });

  const classes = useMemo(() => (
    classNames({ 'p-invalid': formik.errors.descricao })
  ), [formik.errors]);

  return (
    <Dialog
      onHide={fechaModal}
      transitionOptions={{ timeout, onExited: close }}
      visible={modalVisible}
      maximizable
      header={ubs.id ? 'Alterar UBS' : 'Adicionar UBS'}
      className="dialog-md"
      footer={<Footer close={fechaModal} valid={formik.dirty && formik.isValid} />}
    >
      <form onSubmit={formik.handleSubmit} id="form-save-ubs">
        <div className="p-fluid">
          <div className="p-field p-disabled">
            <label htmlFor="produtor">Produtor</label>
            <InputText
              name="produtor"
              id="produtor"
              value={formik.values.produtor.nome}
              readOnly
            />
          </div>
          <div className="p-field">
            <label htmlFor="descricao">Descrição</label>
            <InputText
              autoFocus
              required
              name="descricao"
              id="descricao"
              value={formik.values.descricao}
              onChange={formik.handleChange}
              className={classes}
              aria-describedby="descricao-help"
            />
            <ShowErrorHelper id="descricao-help" error={formik.errors.descricao} />
          </div>
        </div>
      </form>
      <Messages ref={messages} />
    </Dialog>
  );
};

export default FormularioUbs;
