import {
  Dispatch, SetStateAction, useMemo, useState,
} from 'react';
import { AnySchema, ValidationError } from 'yup';

const useYup: UseYup = (initialSchema, initialValue) => {
  const [value, setValue] = useState(initialValue);
  const [schema, setSchema] = useState(initialSchema);

  const error = useMemo(() => {
    try {
      schema.validateSync(value);
    } catch (e) {
      if (ValidationError.isError(e)) return e.errors[0];
      throw e;
    }
  }, [value, schema]);

  return [
    [value, setValue],
    error,
    [schema, setSchema],
  ];
};

type UseYup = <T extends S['__inputType'], S extends AnySchema>(
  initialSchema: S | (() => S),
  initialValue: T | (() => T)
) => [
  [T, Dispatch<SetStateAction<T>>],
  (string | undefined),
  [S, Dispatch<SetStateAction<S>>]
]

export default useYup;
