import { Button, Toolbar } from '@agro1desenvolvimento/react-components';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectArmazemState } from '../../armazemSlice';

const Header = () => {
  const history = useHistory();
  const { currentArmazem } = useSelector(selectArmazemState);

  const leftContents = (
    <>
      <Button
        label="Voltar"
        icon="pi pi-arrow-left"
        className="p-button-primary p-mr-2"
        onClick={() => history.push({
          pathname: '/sementes/armazens',
          search: location.search,
        })}
      />
    </>
  );

  return (
    <>
      <Toolbar left={leftContents} />
      <h1>Croqui do {currentArmazem?.descricao}</h1>
    </>
  );
};

export default Header;
