import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, useHistory, useParams } from 'react-router-dom';
import { selectTopBarState } from '../../../../../components/Topbar/topBarSlice';
import amostrasService from '../../../../../services/sementes/amostras';
import promiseWithLoaderAndMessages from '../../../../../utilities/promise-with-loader-and-messages';
import AnaliseCreateEditPage from './AnaliseCreateEditPage';
import { fetchAnalises, fetchTipoAnalises } from './analiseSlice';
import AnalisesList from './List';
import { Amostra } from '../../../../../@types/sementes/amostra';
import Header from './Header';
import { falhaAoCarregar } from '../../../../../utilities/default-confirmation-messages';

const AnalisesPage: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    currentSafra,
    currentProdutor,
  } = useSelector(selectTopBarState);
  const { loteId, amostraId } = useParams<{ loteId: string, amostraId: string }>();
  const [amostra, setAmostra] = useState<Amostra>();

  const onFormClose = () => {
    dispatch(fetchAnalises({ loteId, amostraId }));
  };

  if (!currentSafra || !currentProdutor) {
    history.replace('/sementes/lotes/');
    return null;
  }

  const fetchAmostra = async () => {
    setAmostra(await promiseWithLoaderAndMessages(
      dispatch,
      amostrasService.find(loteId, amostraId),
      { errorMessage: falhaAoCarregar('amostra') },
    ));
  };

  useEffect(() => {
    dispatch(fetchAnalises({ loteId, amostraId }));
    dispatch(fetchTipoAnalises());
  }, []);

  useEffect(() => {
    fetchAmostra();
  }, [loteId, amostraId]);

  return (
    <>
      <div className="p-grid">
        <div className="p-col-12">
          <div className="card">
            <Header loteId={loteId} amostraId={amostraId} />
            <h1>Analises da amostra: {amostra?.numeroAmostra}</h1>
            <AnalisesList loteId={loteId} amostraId={amostraId} />
          </div>
        </div>
      </div>
      <Route path="/sementes/lotes/:loteId/amostras/:amostraId/analises/:id?/:action(visualizar|novo|editar)">
        <AnaliseCreateEditPage loteId={loteId} amostraId={amostraId} onClose={onFormClose} />
      </Route>
    </>
  );
};

export default AnalisesPage;
