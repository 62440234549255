import { loginService } from '@agro1desenvolvimento/apis-js-package';
import { Cidade } from '../../@types/geral/cidade';
import QueryableBase from '../queryable-base';

class CidadesService extends QueryableBase<Cidade> {
  get endpoint() {
    return `/${loginService.scope}/geral/cidades`;
  }
}

export default new CidadesService();
