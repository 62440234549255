import { ThunkDispatch } from '@reduxjs/toolkit';
import { isFunction, isString, uniqueId } from 'lodash';
import { ToastMessage } from '@agro1desenvolvimento/react-components';
import { showMessage } from '../components/Agro1Toast/toast';
import { showLoader, closeLoader } from '../components/Loader/loaderSlice';

type MessageData = ToastMessage | string
type Message<T> = ((data: T) => MessageData) | MessageData

interface Options<T> {
  successMessage?: Message<T>,
  errorMessage?: Message<any>,
  withLoader?: boolean
}

const getMessageData = <T>(message: Message<T>, data: T) => (
  isFunction(message) ? message(data) : message
);

const getToastMessageObject = (messageData: MessageData): ToastMessage => (
  isString(messageData) ? { summary: messageData } : messageData
);

type promiseWithLoaderAndMessagesType = <T>(
  dispatch: ThunkDispatch<any, any, any>,
  promise: Promise<T>,
  options?: Options<T>,
) => Promise<T>

const promiseWithLoaderAndMessages: promiseWithLoaderAndMessagesType = (async (
  dispatch,
  promise,
  {
    errorMessage,
    withLoader = true,
    successMessage,
  } = {},
) => {
  const loaderId = uniqueId('loader_');

  try {
    if (withLoader) dispatch(showLoader(loaderId));
    const result = await promise;

    if (successMessage) {
      const messageData = getMessageData(successMessage, result);
      dispatch(showMessage({
        severity: 'success',
        ...getToastMessageObject(messageData),
      }));
    }

    return result;
  } catch (error) {
    if (errorMessage) {
      const messageData = getMessageData(errorMessage, error);
      dispatch(showMessage({
        severity: 'error',
        ...getToastMessageObject(messageData),
      }));
    }

    throw error;
  } finally {
    if (withLoader) dispatch(closeLoader(loaderId));
  }
}) as promiseWithLoaderAndMessagesType;

export default promiseWithLoaderAndMessages;
