import { loginService } from '@agro1desenvolvimento/apis-js-package';
import { keysToSnakeCaseDeep } from '@agro1desenvolvimento/utils-js';
import api from '../erp-api';
import {
  Analise, AnaliseCreateUpdate, AnaliseTipoStruct,
} from '../../@types/sementes/analises';

class AmostrasService {
  endpoint(loteId: string, amostraId: string) {
    return `/${loginService.scope}/sementes/lotes/${loteId}/amostras/${amostraId}/analises`;
  }

  async list(loteId: string, amostraId: string) {
    return (await api.get<Analise[]>(this.endpoint(loteId, amostraId))).data;
  }

  async create(loteId: string, amostraId: string, analiseObject: AnaliseCreateUpdate) {
    const analises = [keysToSnakeCaseDeep(analiseObject)];
    return (await api.post<Analise[]>(this.endpoint(loteId, amostraId), { analises })).data;
  }

  async find(loteId: string, amostraId: string, analiseId: string) {
    return (await api.get<Analise>(`${this.endpoint(loteId, amostraId)}/${analiseId}`)).data;
  }

  async update(loteId: string, amostraId: string, analise: AnaliseCreateUpdate) {
    const snakeAmostra = keysToSnakeCaseDeep(analise);
    return (await api.put<Analise>(`${this.endpoint(loteId, amostraId)}/${analise.id}`, snakeAmostra)).data;
  }

  async createOrUpdate(loteId: string, amostraId: string, analise?: AnaliseCreateUpdate) {
    if (!analise) return;

    if (!analise.id) {
      return this.create(loteId, amostraId, analise);
    }

    return this.update(loteId, amostraId, analise);
  }

  async delete(loteId: string, amostraId: string, analise: Analise) {
    return (await api.delete(`${this.endpoint(loteId, amostraId)}/${analise.id}`)).data;
  }

  async tiposAnalises() {
    return (await api.get<AnaliseTipoStruct[]>(`/${loginService.scope}/sementes/tipos_analises`)).data;
  }
}

export default new AmostrasService();
