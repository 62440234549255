import { Route, Switch } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchDepositos } from './depositosSlice';
import Header from './Header';
import List from './List';
import FormDeposito from './FormDeposito';
import NewEditDepositoPage from './NewEditDepositoPage';

const DepositosPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchDepositos());
  }, []);

  return (
    <>
      <div className="p-grid">
        <div className="p-col-12">
          <div className="card">
            <Header />
            <h1>Depositos</h1>
            <List />
          </div>
        </div>
      </div>

      <Switch>
        <Route exact path="/estoque/depositos/editar/:id?">
          <NewEditDepositoPage />
        </Route>
        <Route exact path="/estoque/depositos/novo">
          <FormDeposito />
        </Route>
      </Switch>
    </>
  );
};

export default DepositosPage;
