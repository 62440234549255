import { loginService } from '@agro1desenvolvimento/apis-js-package';
import { SaldoCampoProducao } from '../../@types/sementes/saldoCampoProducao';
import api from '../erp-api';

interface SaldosCamposProps {
  cultivarId: string,
  safraId: string,
  produtorId: string,
  depositoId?: string,
}

class SaldosCamposProducaoService {
  get endpoint() {
    return `/${loginService.scope}/sementes/saldos_campos_producao/`;
  }

  async saldos({
    cultivarId, safraId, produtorId, depositoId,
  }: SaldosCamposProps) {
    const { data } = await api.get<SaldoCampoProducao[]>(
      this.endpoint,
      {
        params: {
          safra: safraId,
          cultivar: cultivarId,
          produtor: produtorId,
          deposito: depositoId || '',
        },
      },
    );
    return data;
  }
}

export default new SaldosCamposProducaoService();
