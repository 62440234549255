import { FC, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Dialog, Message } from '@agro1desenvolvimento/react-components';
import { RelatorioTemplate } from '../../../@types/relatorio/template';
import FormularioTemplate from './FormularioTemplate';
import TemplateService from '../../../services/relatorio/template';

const initialValues: RelatorioTemplate = {
  id: '',
  descricao: '',
  reportTemplate: '',
  scriptId: '',
  tenantsDesabilitados: [],
  tenantsHabilitados: [],
  tipo: 'etiqueta',
};

const onHide = () => [];

const NewEditTemplatePage: FC<
  {fetchRelatoriosTemplate: (page?: string) => void}
> = ({ fetchRelatoriosTemplate }) => {
  const history = useHistory();
  const { id } = useParams<{id: string, page: string}>();

  const close = () => history.goBack();
  const [formulario, setFormulario] = useState<RelatorioTemplate>();
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (id) {
      try {
        const find = async () => setFormulario(await TemplateService.find(id));
        find();
      } catch (error) {
        setErrorMessage(error);
      }
    } else {
      setFormulario(initialValues);
    }
  }, [id]);

  if (!formulario) return null;

  if (errorMessage) {
    return (
      <Dialog onHide={onHide} visible header="Erro ao buscar Relatórios" className="dialog-md">
        <Message severity="error" text={errorMessage} className="width-full p-justify-start" />
      </Dialog>
    );
  }

  return (
    <FormularioTemplate
      close={close}
      template={formulario}
      fetchRelatoriosTemplate={fetchRelatoriosTemplate}
    />
  );
};

export default NewEditTemplatePage;
