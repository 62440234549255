import { Message, RadioButton } from '@agro1desenvolvimento/react-components';
import { forwardRef, useEffect, useImperativeHandle } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CardWithValue from '../../../../components/CardWithValue';
import { SteepComponent } from '../types';
import {
  goToStep,
  selectVinculoLotesCamposProducaoState,
  TiposDeVinculos,
  setTipoDeVinculo,
} from '../VinculoLotesCamposProducaoSlice';

const tiposDeVinculacoes: TipoDeVinculacao[] = [
  { key: 'campo_unico', name: 'Único campo' },
  { key: 'multiplos_campos', name: 'Multiplos campos' },
];

const Resumo: SteepComponent = forwardRef(({ setCanGoNext }, ref) => {
  const dispatch = useDispatch();

  const {
    saldoLotesSemVinculo,
    tipoDeVinculo,
  } = useSelector(selectVinculoLotesCamposProducaoState);

  useImperativeHandle(ref, () => ({
    submit: () => {
      if (tipoDeVinculo) dispatch(goToStep('next'));
    },
  }));

  useEffect(() => {
    setCanGoNext(!!tipoDeVinculo);
  }, [tipoDeVinculo]);

  if (!saldoLotesSemVinculo) {
    return (
      <Message
        severity="error"
        text="Nenhum resultado encontrado"
        className="width-full p-justify-start"
      />
    );
  }

  const {
    quantidadeLotesNaoVinculados,
    saldoLotesNaoVinculados,
    saldoMateriaPrima,
  } = saldoLotesSemVinculo;

  return (
    <>
      <div className="p-col-12 p-md-8 p-p-0 panel-saldos-lotes-sem-vinculos">
        <CardWithValue className="p-col-12 p-md-11 cy-lotesSemVinculo" value={quantidadeLotesNaoVinculados} title="Quantidade de lotes sem vínculo" />
        <CardWithValue className="p-col-12 p-md-11 cy-saldoSemVinculo" value={saldoLotesNaoVinculados} title="Saldo de lotes sem vínculo" unidade="KG" />
        <CardWithValue className="p-col-12 p-md-11 cy-saldoMateriaPrima" value={saldoMateriaPrima} title="Saldo de matéria prima" unidade="KG" />
      </div>
      <div className="p-fluid p-formgrid p-grid p-align-center p-mt-3">
        <div className="p-field p-col-12">
          <b className="p-mb-3 p-d-block">Modo de vincular lotes com campo de produção.</b>
          {tiposDeVinculacoes.map((tipoDeVinculacao) => (
            <div key={tipoDeVinculacao.key} className="p-field-radiobutton">
              <RadioButton
                value={tipoDeVinculacao.key}
                key={tipoDeVinculacao.key}
                onChange={({ value }) => dispatch(setTipoDeVinculo(value))}
                checked={tipoDeVinculo === tipoDeVinculacao.key}
                inputId={tipoDeVinculacao.key}
              />
              <label htmlFor={tipoDeVinculacao.key}>{tipoDeVinculacao.name}</label>
            </div>
          ))}
        </div>
      </div>
    </>
  );
});

interface TipoDeVinculacao {
  key: TiposDeVinculos,
  name: string
}

export default Resumo;
