import { Button, Toolbar } from '@agro1desenvolvimento/react-components';
import { FC } from 'react';
import { useHistory } from 'react-router-dom';

type amostrasProps = {
   loteId: string;
};

const Header: FC<amostrasProps> = ({ loteId }) => {
  const history = useHistory();

  const leftContents = (
    <>
      <Button
        label="Voltar"
        icon="pi pi-arrow-left"
        className="p-button-primary p-mr-2"
        onClick={() => history.push({
          pathname: '/sementes/lotes',
          search: location.search,
        })}
      />

      <Button
        label="Novo"
        icon="pi pi-plus"
        className="p-ml-auto p-button-success"
        onClick={() => history.push({
          pathname: `/sementes/lotes/${loteId}/amostras/novo`,
          search: location.search,
        })}
      />
    </>
  );

  return (
    <Toolbar left={leftContents} />
  );
};

export default Header;
