import { HeaderParams, HeaderType } from '../@types/headers';

const responseHeaders = (headers: HeaderType): HeaderParams => ({
  currentPage: +headers['x-pagination-current-page'],
  totalRecords: +headers['x-pagination-total'],
  maxPerPage: +headers['x-pagination-per-page'],
  pages: +headers['x-pagination-pages'],
});

export default responseHeaders;
