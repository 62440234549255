import {
  DataTable,
  Column,
  PaginatorPageState,
} from '@agro1desenvolvimento/react-components';
import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { flatten } from 'lodash';
import { renderChanges } from '../../../components/Auditoria/index';
import { AuditoriaAction } from '../../../@types/enums';
import { fetchAuditorias, selectAuditoriasState } from '../Auditorias/auditoriasSlice';
import { dateFormat, scrollToTop } from '../../../utilities';
import { Auditoria } from '../../../@types/geral/auditoria';
import Pagination from '../../../components/Pagination';
import Filters from './Filters';
import { selectFilterState } from '../../../components/Filter/filterSlice';
import { usePagination } from '../../../hooks';

const ListRegistros: FC = () => {
  const { auditorias, pagination } = useSelector(selectAuditoriasState);
  const { registrosExcluidos } = useSelector(selectFilterState);
  const { maxPerPage = 1, totalRecords, pages } = pagination;
  const dispatch = useDispatch();
  const { currentPage, goToPage, firstIndexOnPage } = usePagination({ maxPerPage });

  const onPageChange = (e: PaginatorPageState) => {
    goToPage(e.page + 1);
  };

  const dispatchAuditorias = (page: number) => {
    scrollToTop();
    dispatch(fetchAuditorias({
      conditions: flatten(Object.values(registrosExcluidos.conditions)),
      sorts: registrosExcluidos.sort ? [registrosExcluidos.sort] : undefined,
      extraParameters: {
        crudAction: AuditoriaAction.destroy,
        page: page.toString(),
      },
    }));
  };

  useEffect(() => {
    dispatchAuditorias(currentPage);
  }, [registrosExcluidos, currentPage]);

  return (
    <>
      <DataTable emptyMessage="Nenhum registro disponível." header={<Filters />} value={auditorias} responsiveLayout="scroll">
        <Column header="Tipo" field="humanizedAuditableType" />
        <Column header="Data/Hora" body={(auditoria: Auditoria) => dateFormat(auditoria.createdAt)} />
        <Column header="Versão" field="version" />
        <Column header="Usuário" field="username" />
        <Column header="Última alteração" body={renderChanges} className="column-alteracao" />
      </DataTable>
      <Pagination
        pages={pages}
        first={firstIndexOnPage}
        rows={maxPerPage}
        totalRecords={totalRecords}
        onPageChange={(e) => onPageChange(e)}
        pageRecords={auditorias.length}
      />
    </>
  );
};

export default ListRegistros;
