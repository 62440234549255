import Filter from '../Filter';
import { FilterField } from '../Filter/FilterField';

const filters: FilterField[] = [
  { field: 'created_at', label: 'Período', type: 'dateInterval' },
  { field: 'username', type: 'string', label: 'Usuário' },
];

const Filters = () => (
  <Filter
    context="auditoriaFilter"
    filters={filters}
  />
);

export default Filters;
