import Filter from '../../../components/Filter';
import { FilterField } from '../../../components/Filter/FilterField';

const dropdownTipos = [{ key: 'planejamento', value: 'Planejamento' }, { key: 'etiqueta', value: 'Etiqueta' }];

const filters: FilterField[] = [
  { field: 'descricao', type: 'string', label: 'Descrição' },
  {
    field: 'tipo',
    type: 'select',
    label: 'Tipo',
    items: dropdownTipos,
    optionKey: 'key',
    optionLabel: 'value',
  },
];

const Filters = () => (
  <Filter
    context="relatorioTemplate"
    filters={filters}
  />
);

export default Filters;
