import { loginService } from '@agro1desenvolvimento/apis-js-package';
import { keysToSnakeCaseDeep } from '@agro1desenvolvimento/utils-js';
import { map } from 'lodash';
import { Pessoa, PessoaFuncao, PessoaProdutor } from '../../@types/geral/pessoa';
import { CrudCreateUpdate } from '../../utilities';
import CrudBase from '../crud-base';
import api from '../erp-api';

class PessoasService extends CrudBase<Pessoa> {
  get endpoint() {
    return `/${loginService.scope}/geral/pessoas`;
  }

  async listProdutores(): Promise<PessoaProdutor[]> {
    const { data } = await api.get<PessoaProdutor[]>(this.endpoint, { params: { tipo: 'produtor' } });

    return this.translateResponse(data);
  }

  private translateResponse(responseData: PessoaProdutor[]) {
    return responseData.map((item) => ({
      ...item,
      nomeComCidade: `${item.nome} (${item.cidade.descricao} - ${item.cidade.uf.sigla})`,
    }));
  }

  async create(pessoa: CrudCreateUpdate<Pessoa>) {
    return (await api.post<Pessoa[]>(
      this.endpoint, { pessoas: [this.toSnakeCaseDeep(pessoa)] },
    )).data[0];
  }

  async update(pessoa: CrudCreateUpdate<Pessoa>) {
    return (await api.patch<Pessoa>(`${this.endpoint}/${pessoa.id}`, this.toSnakeCaseDeep(pessoa))).data;
  }

  async funcoes(): Promise<PessoaFuncao[]> {
    return (await api.get<PessoaFuncao[]>(`${this.endpoint}/funcoes`)).data;
  }

  toSnakeCaseDeep = (pessoa: CrudCreateUpdate<Pessoa>): CrudCreateUpdate<Pessoa> => (
    keysToSnakeCaseDeep({
      nome: pessoa.nome,
      cpfCnpj: pessoa.cpfCnpj,
      inscricaoEstadual: pessoa.inscricaoEstadual,
      cidadeId: pessoa.cidade.id,
      tipo: pessoa.tipo,
      endereco: pessoa.endereco,
      funcoes: map(pessoa.funcoes, 'enum'),
      dadosProdutor: pessoa.dadosProdutor || null,
      dadosLaboratorio: pessoa.dadosLaboratorio || null,
      dadosTecnicoSementes: pessoa.dadosTecnicoSementes || null,
    })
  );
}

export default new PessoasService();
