import React, { useMemo, useRef } from 'react';
import {
  Button, Column, DataTable, Tooltip,
} from '@agro1desenvolvimento/react-components';
import { PivotConfig, ResultSet } from '@cubejs-client/core';
import JsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import * as xlsx from 'xlsx';
import { saveAs } from 'file-saver';
import dayjs from 'dayjs';
import { DAYJS_FORMATS, formatNumber, isISODate } from '../../../../utilities';

const exportExcel = (dataTableValues: DataTableValueProps[], dataTableColumns: { title: string; key: string;}[], chartTitle = '') => {
  const data = [
    dataTableColumns.map((column) => column.title),
    ...dataTableValues.map((value) => dataTableColumns.map((column) => value[column.key])),
  ];

  const worksheet = xlsx.utils.aoa_to_sheet(data);
  const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
  const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
  saveAsExcelFile(excelBuffer, chartTitle);
};

const saveAsExcelFile = (buffer: any, fileName: string) => {
  const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const EXCEL_EXTENSION = '.xlsx';
  const data = new Blob([buffer], {
    type: EXCEL_TYPE,
  });
  saveAs(data, `${fileName}-${new Date().getTime()}${EXCEL_EXTENSION}`);
};

const exportPdf = (dataTableValues: DataTableValueProps[],
  dataTableColumns: { title: string; key: string;}[], chartTitle = '') => {
  const doc = new JsPDF({
    orientation: 'landscape',
  });
  doc.text(chartTitle, 14, 10);
  autoTable(doc, {
    body: dataTableValues,
    columns: dataTableColumns,
    bodyStyles: { halign: 'center' },
  });
  doc.save(`${chartTitle}-${new Date().getTime()}.pdf`);
};

const exportCSV = (dataTable: React.RefObject<DataTable>) => {
  if (dataTable.current) {
    dataTable.current.exportCSV();
  }
};

const convertDataTableValues = (
  dataTableValues: DataTableValueProps[],
) => dataTableValues.map((v) => {
  const novoObj : DataTableValueProps = {};
  Object.keys(v).forEach((key) => {
    novoObj[key.replace('.', '')] = format(v[key]);
  });
  return novoObj;
});

const convertDataTableColumns = (
  dataTableColumns: { title: string; key: string;}[],
) => dataTableColumns.map(({ title, key }) => ({
  title: title.replace('.', ''),
  key: key.replace('.', ''),
}));

const isValidDate = (value: any) => (
  isISODate(value) || dayjs(value, DAYJS_FORMATS.american, true).isValid()
);

const format = (value: any) => {
  if (!isNaN(+value)) return formatNumber(value);

  const valid = isValidDate(value);
  if (valid) return dayjs(value).format('L');

  return value;
};

const TableRendererWithExport:React.FC<
{ resultSet: ResultSet<any>, pivotConfig: PivotConfig, chartTitle: string }
> = ({ resultSet, pivotConfig, chartTitle }) => {
  const dataTable = useRef<DataTable>(null);
  const dataTableValues = useMemo(
    () => convertDataTableValues(resultSet.tablePivot(pivotConfig)), [resultSet],
  );
  const dataTableColumns = useMemo(
    () => convertDataTableColumns(resultSet.tableColumns(pivotConfig)), [resultSet],
  );

  const header = (
    <div className="p-d-flex p-ai-center export-buttons">
      <Button type="button" icon="pi pi-file" onClick={() => exportCSV(dataTable)} className="p-mr-2" data-pr-tooltip="CSV" />
      <Button type="button" icon="pi pi-file-excel" onClick={() => exportExcel(dataTableValues, dataTableColumns, chartTitle)} className="p-button-success p-mr-2" data-pr-tooltip="XLS" />
      <Button type="button" icon="pi pi-file-pdf" onClick={() => exportPdf(dataTableValues, dataTableColumns, chartTitle)} className="p-button-warning p-mr-2" data-pr-tooltip="PDF" />
    </div>
  );

  return (
    <>
      <Tooltip target=".export-buttons>button" position="bottom" />
      <DataTable ref={dataTable} value={dataTableValues} className="p-datatable-striped" header={header} exportFilename={`${chartTitle}-${new Date().getTime()}`}>
        {dataTableColumns.map(({ title, key }, index) => (
          <Column key={index} header={title} field={key} body={(v) => format(v[key])} />
        ))}
      </DataTable>
    </>
  );
};

interface DataTableValueProps {
  [k: string]: string | number | boolean
}

export default TableRendererWithExport;
