import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../../../app/rootReducer';
import camposProducao from '../../../services/sementes/campos-producao';
import { CampoProducao } from '../../../@types/sementes/campoProducao';
import { promiseWithLoaderAndMessages } from '../../../utilities';
import queryable, { Query } from '../../../services/queryable';
import { ListReturnType } from '../../../services/queryable-base';
import { HeaderParams } from '../../../@types/headers';
import { falhaAoCarregar } from '../../../utilities/default-confirmation-messages';

interface CamposProducaoState {
  campos: CampoProducao[],
  pagination: HeaderParams,
}

const initialState: CamposProducaoState = {
  campos: [],
  pagination: {
    currentPage: undefined,
    totalRecords: undefined,
    maxPerPage: undefined,
    pages: undefined,
  },
};

export const fetchCampos = createAsyncThunk<
  ListReturnType<CampoProducao>,
  undefined | Omit<Query<CampoProducao>, 'service'>
>(
  'camposProducao/fetchCampos',
  (params = {}, { dispatch }) => {
    const query = queryable.query({
      ...params,
      service: camposProducao,
    });

    return promiseWithLoaderAndMessages(
      dispatch,
      query,
      { errorMessage: falhaAoCarregar('campos de produção') },
    );
  },
);

export const camposProducaoSlice = createSlice({
  name: 'camposProducao',
  initialState,
  reducers: {
  },
  extraReducers(builder) {
    builder.addCase(fetchCampos.fulfilled, (state, { payload }) => {
      state.campos = payload.data;
      state.pagination = payload.headers;
    });
  },
});

export const selectCamposProducaoState = (state: RootState) => state.camposProducaoReducer;

export default camposProducaoSlice.reducer;
