import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { HeaderParams } from '../../../@types/headers';
import { Descarte } from '../../../@types/sementes/descarte';
import type { RootState } from '../../../app/rootReducer';
import { ListReturnType } from '../../../services/queryable-base';
import queryable, { Query } from '../../../services/queryable';
import DescartesService from '../../../services/sementes/descartes';
import { promiseWithLoaderAndMessages } from '../../../utilities';
import { toastExcludeMessages, falhaAoCarregar } from '../../../utilities/default-confirmation-messages';

interface DescartesState {
  descartes: Descarte[],
  pagination: HeaderParams,
}

const initialState: DescartesState = {
  descartes: [],
  pagination: {
    currentPage: undefined,
    totalRecords: undefined,
    maxPerPage: undefined,
    pages: undefined,
  },
};

export const deleteDescarte = createAsyncThunk(
  `${DescartesService.endpoint}/delete`,
  async (id: string, { dispatch }) => {
    await promiseWithLoaderAndMessages(
      dispatch,
      DescartesService.delete(id),
      toastExcludeMessages('descarte'),
    );

    return id;
  },
);

export const fetchDescartes = createAsyncThunk<
  ListReturnType<Descarte>,
  undefined | Omit<Query<Descarte>, 'service'>
>(
  'sementes/descartes',
  (param = {}, { dispatch }) => {
    const query = queryable.query({
      ...param,
      service: DescartesService,
    });

    return promiseWithLoaderAndMessages(
      dispatch,
      query,
      { errorMessage: falhaAoCarregar('descartes') },
    );
  }
  ,
);

export const descartesSlice = createSlice({
  name: 'descartes',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(fetchDescartes.fulfilled, (state, { payload }) => {
      state.descartes = payload.data;
      state.pagination = payload.headers;
    });
  },
});

export const selectDescartesState = (state: RootState) => state.descartesReducer;

export default descartesSlice.reducer;
