import { loginService } from '@agro1desenvolvimento/apis-js-package';
import api from '../erp-api';
import { TipoEmbalagem } from '../../@types/sementes/tiposEmbalagens';

class TiposEmbalagensService {
  get endpoint() {
    return `/${loginService.scope}/sementes/tipos_embalagens`;
  }

  async list() {
    return (await api.get<TipoEmbalagem[]>(this.endpoint)).data;
  }
}

export default new TiposEmbalagensService();
