import { loginService } from '@agro1desenvolvimento/apis-js-package';
import { Periodo, PeriodoEnum } from '../../@types/geral/periodo';
import CrudBase from '../crud-base';

const PERIODOS = { inverno: 'Inverno', verao: 'Verão', safrinha: 'Safrinha' };

class PeriodosService extends CrudBase<Periodo> {
  get endpoint() {
    return `/${loginService.scope}/geral/periodos`;
  }

  translate(key: PeriodoEnum) {
    return PERIODOS[key];
  }
}

export default new PeriodosService();
