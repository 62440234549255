import { MultiSelect, MultiSelectChangeParams } from '@agro1desenvolvimento/react-components';
import { MemberUpdater } from '@cubejs-client/react';
import { map, filter } from 'lodash';
import React, { useMemo } from 'react';

const QueryMultiSelect: React.FC<
  { updater: MemberUpdater<any>, values: any[], options: any[], placeholder: string }
> = ({
  options, updater, values, placeholder,
}) => {
  const multiSelectValues = useMemo(
    () => {
      const names = map(values, 'name');
      return filter(options, ({ name }) => names.includes(name));
    },
    [options, values],
  );

  const onChange = (event: MultiSelectChangeParams) => {
    const names = map(event.value, 'name');
    const toAdd = event.value.filter((v: any) => !multiSelectValues.includes(v));
    const toRemove = values.filter((v: any) => !names.includes(v.name));

    toAdd.map(updater.add);
    toRemove.map(updater.remove);
  };

  return (
    <div className="p-col-3">
      <MultiSelect
        value={multiSelectValues}
        options={options}
        onChange={onChange}
        optionLabel="title"
        panelClassName="multi-select-hide-toggle-all"
        className="p-m-1"
        style={{ width: '100%' }}
        filter
        placeholder={placeholder}
      />
    </div>
  );
};

export default QueryMultiSelect;
