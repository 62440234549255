import { flatten } from 'lodash';
import { Message } from '@agro1desenvolvimento/react-components';
import { useIsFirstRender } from '@agro1desenvolvimento/react-hooks';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Header from './components/Header';
import { selectTopBarState } from '../../../components/Topbar/topBarSlice';
import CamposProducaoSyncSigefPage from './components/CamposProducaoSyncSigefPage';
import ListCamposProducao from './components/List';
import { fetchCampos, selectCamposProducaoState } from './camposProducaoSlice';
import { selectFilterState } from '../../../components/Filter/filterSlice';
import { usePagination } from '../../../hooks';
import { scrollToTop } from '../../../utilities';
import EditCampoProducao from './components/Edit';

const CamposProducaoPage = () => {
  const [errorMessage, setErrorMessage] = useState('');
  const isFirstRender = useIsFirstRender();
  const { pagination: { maxPerPage = 1 } } = useSelector(selectCamposProducaoState);
  const { currentSafra, currentProdutor } = useSelector(selectTopBarState);
  const { camposProducao: filterCamposProducao } = useSelector(selectFilterState);
  const dispatch = useDispatch();
  const { currentPage, goToPage } = usePagination({ maxPerPage });

  const dispatchFetchCampos = (page = currentPage.toString()) => {
    scrollToTop();
    dispatch(fetchCampos({
      conditions: flatten(Object.values(filterCamposProducao.conditions)),
      sorts: filterCamposProducao.sort ? [filterCamposProducao.sort] : undefined,
      extraParameters: {
        produtor: currentProdutor?.id as string,
        safra: currentSafra?.id as string,
        page,
      },
    }));
  };

  useEffect(() => {
    if (!currentSafra || !currentProdutor) {
      setErrorMessage('Selecione a safra/produtor.');
      return;
    }

    dispatchFetchCampos();
    setErrorMessage('');
  }, [currentSafra, currentProdutor, currentPage]);

  useEffect(() => {
    if (isFirstRender) return;
    goToPage(1);
    dispatchFetchCampos('1');
  }, [filterCamposProducao]);

  return (
    <>
      <div className="p-grid">
        <div className="p-col-12">
          <div className="card">
            <Header />
            <h1>Campos de Produção</h1>
            {
              errorMessage
                ? <Message severity="error" text={errorMessage} className="width-full p-justify-start" />
                : <ListCamposProducao />
            }
          </div>
        </div>
      </div>

      <Switch>
        <Route exact path="/sementes/campos_producao/sync_sigef">
          <CamposProducaoSyncSigefPage close={dispatchFetchCampos} />
        </Route>
        <Route exact path="/sementes/campos_producao/editar/:id">
          <EditCampoProducao />
        </Route>
      </Switch>
    </>
  );
};

export default CamposProducaoPage;
