import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../../../app/rootReducer';
import { SaldoLotesNaoVinculados } from '../../../@types/sementes/saldoLotesNaoVinculados';
import { LocalizaSaldosValues } from './components/SaldosFilters';

export type TiposDeVinculos = 'campo_unico' | 'multiplos_campos'
interface VinculoLotesCamposProducaoState {
  canGoToNextStep: boolean;
  currentStepIndex: number;
  saldoLotesSemVinculo: SaldoLotesNaoVinculados | undefined;
  filtros: LocalizaSaldosValues;
  tipoDeVinculo: TiposDeVinculos | undefined
}

const initialState: VinculoLotesCamposProducaoState = {
  canGoToNextStep: false,
  currentStepIndex: 0,
  saldoLotesSemVinculo: undefined,
  tipoDeVinculo: undefined,
  filtros: {
    cultivarId: '',
    depositoOrigemId: '',
    camposProducaoIds: [],
  },
};

export const vinculoLotesCamposProducaoSlice = createSlice({
  name: 'planejamentos',
  initialState,
  reducers: {
    setCanGoToNextStep(state, action: PayloadAction<boolean>) {
      state.canGoToNextStep = action.payload;
    },
    goToStep(state, action: PayloadAction<'next' | 'previos'>) {
      const { currentStepIndex } = state;

      state.currentStepIndex = action.payload === 'previos' ? currentStepIndex - 1 : currentStepIndex + 1;
    },
    setSaldoLotesSemVinculo(state, action: PayloadAction<SaldoLotesNaoVinculados | undefined>) {
      state.saldoLotesSemVinculo = action.payload;
    },
    setFiltros(state, action: PayloadAction<LocalizaSaldosValues>) {
      state.filtros = action.payload;
    },
    setTipoDeVinculo(state, action: PayloadAction<TiposDeVinculos>) {
      state.tipoDeVinculo = action.payload;
    },
    clearSlice: () => initialState,
  },
});

export const selectVinculoLotesCamposProducaoState = (state: RootState) => (
  state.vinculoLotesCamposProducaoReducer
);

export const {
  setCanGoToNextStep,
  goToStep,
  clearSlice,
  setSaldoLotesSemVinculo,
  setFiltros,
  setTipoDeVinculo,
} = vinculoLotesCamposProducaoSlice.actions;

export default vinculoLotesCamposProducaoSlice.reducer;
